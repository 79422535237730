import { useMemo } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventType } from '@shared/utils/eventType';
import { EventType } from '@graphql/generated';
import { useAdminRegistryState } from '../../state/context/AdminRegistry';
import { OVERVIEW_LAYOUT_CONFIG } from '.';

export const useMergeAddGiftsAndOverviewFeature = () => {
  const { eventType } = useEventType();
  const skip = eventType !== EventType.wedding;
  const { value, payload, loading } = useFeatureValue('registryMergeAddGiftAndOverviewPagesEnabled', { skip });
  const layoutConfig = useMemo(() => {
    if (loading) {
      return null;
    }
    return {
      ...OVERVIEW_LAYOUT_CONFIG,
      ...(payload ?? {})
    };
  }, [payload, loading]);

  return {
    loading,
    isEnabled: value === 'treatment',
    layoutConfig
  };
};

export const useAddGiftsAndOverviewController = () => {
  const { registryItemsCount: giftCount, loadingRegistryRegistries } = useAdminRegistryState();
  const { layoutConfig } = useMergeAddGiftsAndOverviewFeature();

  const layoutVariant = useMemo(() => {
    if (typeof giftCount !== 'number' || !layoutConfig) {
      return null;
    }
    const variant = layoutConfig.variants.find(({ condition }) => {
      if (condition.minGiftCount && condition.minGiftCount > giftCount) {
        return false;
      }
      return true;
    });
    return variant ?? layoutConfig.default;
  }, [giftCount, layoutConfig]);

  return {
    layoutVariant,
    giftCount,
    loadingRegistryRegistries
  };
};
