import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { Flex, Box, TextV2 } from '@withjoy/joykit';
import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { useTextLineClampOverflow } from '@shared/hooks/useTextLineClampOverflow';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { body2ToParagraphOverride, button1ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { Category } from '@graphql/generated';
import { pxToRem } from '@withjoy/joykit/theme';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';

export const AccommodationTileDescription: React.FC<{ description?: string | null; eventPhoto: string; isFavorite: boolean }> = ({ description, eventPhoto, isFavorite }) => {
  const [isCoupleNoteOpen, setIsCoupleNoteOpen] = useState(false);
  const { textRef, isOverflowing } = useTextLineClampOverflow(4);
  const handleCoupleNoteOpen = useEventCallback(() => setIsCoupleNoteOpen(isOpen => !isOpen));
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  if (!description) {
    if (isFavorite) {
      return (
        <Flex flexDirection={'row'} columnGap={5}>
          <Box
            minWidth={48}
            width={48}
            height={48}
            borderRadius={48}
            border="2px solid"
            borderColor="mono3"
            objectFit={'contain'}
            backgroundSize={'cover'}
            backgroundPosition={'center'}
            backgroundRepeat={'no-repeat'}
            backgroundImage={`url(${eventPhoto})`}
          ></Box>
          <Flex flexDirection={'column'} justifyContent="center">
            <GuestSiteTypographyOverride override={button1ToParagraphOverride} defaultFontFamily="Inter UI">
              <TextV2
                typographyVariant={'button1'}
                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                fontWeight={700}
              >
                {accTrans.favoriteHotel()}
              </TextV2>
            </GuestSiteTypographyOverride>
          </Flex>
        </Flex>
      );
    } else {
      return null;
    }
  }

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Box
        minWidth={48}
        width={48}
        height={48}
        borderRadius={48}
        border="2px solid"
        borderColor="mono3"
        objectFit={'contain'}
        backgroundSize={'cover'}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundImage={`url(${eventPhoto})`}
      ></Box>
      <Flex flexDirection={'column'}>
        {isFavorite && (
          <GuestSiteTypographyOverride override={button1ToParagraphOverride} defaultFontFamily="Inter UI">
            <TextV2
              typographyVariant={'button1'}
              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
              fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
              fontWeight={700}
            >
              {accTrans.favoriteHotel()}
            </TextV2>
          </GuestSiteTypographyOverride>
        )}
        <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
          <Flex flexDirection={'column'}>
            <TextEllipsisWrapper lineClamp={isCoupleNoteOpen ? 'none' : 4} ref={textRef}>
              <TextV2
                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                fontStyle={'italic'}
                fontWeight={400}
                lineHeight={'150%'}
                letterSpacing={'-0.15px'}
                color={'mono12'}
              >
                {'"'}
                {description}
                {'"'}
              </TextV2>
            </TextEllipsisWrapper>
            {isOverflowing && (
              <span>
                <TextV2
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                  fontWeight={700}
                  _hover={{ cursor: 'pointer' }}
                  color="mono12"
                  onClick={handleCoupleNoteOpen}
                >
                  {isCoupleNoteOpen ? `${accTrans.less()}` : `${accTrans.more()}`}
                </TextV2>
              </span>
            )}
          </Flex>
        </GuestSiteTypographyOverride>
      </Flex>
    </Flex>
  );
};
