import React from 'react';
import { RenderPaginationProps } from 'react-elastic-carousel';
import {
  StyledContentSection,
  StyledImageSection,
  StyledPaginationContainer,
  Slide,
  StyledCarousel,
  DotsContainer,
  Dot,
  PhotoPlaceholder,
  GraphicAccent,
  StyledIcon,
  styles,
  StyledCloseIcon,
  StyledImage,
  getDialogOverrides,
  StyledDescription,
  StyledAnimatedDiv
} from './ProfileDialog.styles';
import { ReactComponent as ChevronLeft } from '@assets/icons/back-chevron.svg';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { getInitialsFromName } from '@shared/utils/getInitials';
import { VipFragment } from '@graphql/generated';
import { TextV2, DialogV2 } from '@withjoy/joykit';
import { shouldRenderGraphicAccent } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { useProfileDialogController } from './ProfileDialog.controller';
import { URLS } from '@shared/utils/urls';

export interface ProfileDialogProps {
  graphicAccent: Maybe<string> | undefined;
  activeIndex: number;
  list: VipFragment[];
  isOpen: boolean;
  onClose: () => void;
}

const CloseButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <StyledCloseIcon role="button" __css={styles.iconCloseButton} position={'absolute'} top={5} right={5} onClick={onClick}>
    <Close />
  </StyledCloseIcon>
);

const NavButton: React.FC<{ type: 'prev' | 'next'; disabled: boolean; onClick: () => void }> = ({ type, disabled, onClick }) => (
  <StyledIcon role="button" as="button" __css={styles.iconNavButton} disabled={disabled} transform={type === 'next' ? 'rotate(180deg)' : 'none'} onClick={onClick}>
    <ChevronLeft />
  </StyledIcon>
);

const ProfileSlideItem: React.FC<{
  item: VipFragment;
  graphicAccent?: Maybe<JSX.Element>;
}> = React.memo(({ item, graphicAccent }) => (
  <Slide key={item.id}>
    <StyledImageSection __css={styles.imageSection}>
      {item?.photo?.url ? (
        <StyledImage as="img" __css={styles.image} src={item?.photo?.url} />
      ) : (
        <PhotoPlaceholder __css={styles.placeholder}>
          <TextV2 typographyVariant="body1" as="h2" fontSize={'100px'} color="white">
            {getInitialsFromName(item.alias || '')}
          </TextV2>
        </PhotoPlaceholder>
      )}
    </StyledImageSection>
    <StyledContentSection __css={styles.contentSection}>
      {graphicAccent}
      <TextV2 typographyVariant={['display3', 'display6']} __css={styles.alias}>
        {item.alias && item.alias.replace(/\u00A0/g, ' ')}
      </TextV2>
      <TextV2 typographyVariant="body1" as="h2" color="mono12">
        {item.title}
      </TextV2>
      <StyledDescription typographyVariant="body4" as="p">
        {item.description}
      </StyledDescription>
    </StyledContentSection>
  </Slide>
));

export const ProfileDialog: React.FC<ProfileDialogProps> = ({ graphicAccent, activeIndex = 0, list, isOpen, onClose }) => {
  const { carouselRef, goTo, isMobile, scrollToTop, slidePrev, slideNext, springStyles } = useProfileDialogController();

  const graphicAccentMarkup = shouldRenderGraphicAccent(graphicAccent) ? (
    <GraphicAccent id="graphic-accent" __css={styles.graphicAccent} data-testid={'body-accent'} backgroundImage={`url(${URLS.graphicAccent(graphicAccent!, 3)})`} />
  ) : null;

  const renderPagination = ({ pages, activePage }: RenderPaginationProps) => {
    if (pages.length <= 1) return <div />;

    const DOT_CONTAINER = (
      <DotsContainer __css={styles.dotsContainer}>
        {pages.map(page => (
          <Dot key={page} onClick={() => goTo(page)} $isActive={activePage === page} />
        ))}
      </DotsContainer>
    );

    return isMobile ? (
      DOT_CONTAINER
    ) : (
      <StyledPaginationContainer>
        <NavButton type="prev" onClick={slidePrev} disabled={activePage <= 0} />
        {DOT_CONTAINER}
        <NavButton type="next" onClick={slideNext} disabled={activePage >= pages.length - 1} />
      </StyledPaginationContainer>
    );
  };

  return (
    <DialogV2 id="ProfileDialog" onClose={onClose} isOpen={isOpen} size={isMobile ? 'xl' : '6xl'} overrides={getDialogOverrides(isMobile)}>
      <DialogV2.Body __css={styles.dialogBody}>
        <StyledCarousel
          ref={carouselRef}
          showArrows={false}
          initialActiveIndex={activeIndex}
          itemsToShow={1}
          itemsToScroll={1}
          isRTL={false}
          enableMouseSwipe={false}
          transitionMs={isMobile ? 500 : 0}
          renderPagination={renderPagination}
          onChange={currentItem => scrollToTop(list[activeIndex].id, currentItem)}
        >
          {list.map(vip => (
            <StyledAnimatedDiv key={vip.id} id={vip.id} isMobile={!!isMobile} style={isMobile ? {} : springStyles}>
              <ProfileSlideItem item={vip} graphicAccent={graphicAccentMarkup} />
            </StyledAnimatedDiv>
          ))}
        </StyledCarousel>
      </DialogV2.Body>
      <CloseButton onClick={onClose} />
    </DialogV2>
  );
};

ProfileDialog.displayName = 'ProfileDialog';
