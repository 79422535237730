import React, { useMemo } from 'react';
import { RoutesProvider, RoutesPaths, useRouterHelper } from '@shared/core';

export type RoutesNames = 'albums' | 'viewAlbums';

export const routePaths: RoutesPaths<RoutesNames> = {
  albums: {
    path: '/:magicToken',
    goToPath: (magicToken: string) => `/albums/${magicToken}`
  },
  viewAlbums: {
    path: '/:magicToken/view',
    goToPath: (magicToken: string) => `/albums/${magicToken}/view`
  }
};

export const useRoutePaths = () => {
  const { buildPath } = useRouterHelper();
  const memoizedPaths = useMemo(() => {
    return (Object.keys(routePaths) as RoutesNames[]).reduce((acc, route) => {
      acc[route] = {
        path: buildPath(routePaths[route].path),
        goToPath: routePaths[route].goToPath
      };
      return acc;
    }, {} as RoutesPaths<RoutesNames>);
  }, [buildPath]);
  return memoizedPaths;
};

export const MediaCollectionRouter: React.FC<{ eventHandle: string }> = ({ eventHandle, children }) => {
  return (
    <RoutesProvider isGuestRoute={true} handle={eventHandle} appUrl="albums">
      {children}
    </RoutesProvider>
  );
};
