import { PriceConfig, CardPriceModifiers } from '../routes/CardCustomizer/CardCustomizer.types';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { CardPromotion } from '../components/PriceSummary/PriceSummary.controller';
import { useMinorUnitsToPriceString } from './useMinorUnitsToPriceString';
import { convertPriceTotalWithRounding } from './convertPriceTotalWithRounding';
import { useFoilConfig } from './useFoilConfig';

export const minorToMajorUnits = (val: number) => val / 100;

/**
 * TODO:
 * - Must test the case when a flat discount amount is applied instead of a discount percentage
 */
const useCalculatePrintOrderPrice = (
  templateCategory: StationeryTemplateCategoryEnum,
  priceUpTo: 'cardFront' | 'cardBack' | 'envelope' | 'total',
  config: PriceConfig,
  customizations: CardPriceModifiers,
  promotion?: Maybe<CardPromotion>,
  salesTax?: Maybe<number>
) => {
  const { pricePerCardInMinorUnits: foilPrice } = useFoilConfig();

  const promotionDiscountAmountInMinorUnits = promotion?.discountAmountInMinorUnits;
  const promotionDiscountPercentage = promotion?.discountPercentage;
  const quantity = customizations.quantity;
  const minorUnitsToDollars = useMinorUnitsToPriceString();

  // Configs contain additional formatting helpers
  // The following 3 fields are expected to always be present:
  //  - quantity (customizations -> quantity)
  //  - shape (customizations -> shape)
  //  - return address (card -> envelope -> layout)
  const quantityConfig = config.quantity[templateCategory][quantity];
  const dieCutConfig = config.shape[customizations.shape];
  const returnAddressConfig = config.returnAddress[customizations.returnAddress];
  const paperTypeConfig = config.paperType[customizations.paperType];
  const recipientAddressConfig = customizations.recipientAddress ? config.recipientAddress?.[customizations.recipientAddress] : undefined;

  // ==============================
  // Prices in minor units
  // ==============================
  const pricePerCard = quantityConfig.individualPriceInMinorUnits + (customizations.foilData ? foilPrice : 0);
  const totalPriceInMinorUnitsPerQuantity = pricePerCard * quantity;
  const totalPricePerCardShapeInMinorUnits = dieCutConfig.getTotalPriceByQuantityInMinorUnits(quantity);
  const totalReturnAddressPriceInMinorUnits = returnAddressConfig.getTotalPriceByQuantityInMinorUnits(quantity);
  const totalRecipientAddressPriceInMinorUnits = recipientAddressConfig?.getTotalPriceByQuantityInMinorUnits(quantity) ?? 0;
  // customizations.delivery is not automatically set, user has to make the selection
  const totalDeliveryPriceInMinorUnits = customizations.delivery ? config.delivery[customizations.delivery] : 0;
  const totalPaperTypePriceInMinorUnits = paperTypeConfig[quantity].totalPriceInMinorUnits;
  // Static prices in minor units (not configurable in MVP)
  const totalPaperSizePriceInMinorUnits = 0;
  const totalBackLayoutPriceInMinorUnits = 0;
  const totalEnvelopesPriceInMinorUnits = 0;
  const totalFoilPriceInMinorUnits = 0;

  // ==============================
  // Calculating total prices
  // ==============================
  let runningTotalInMinorUnits = 0;
  const taxInMinorUnits = salesTax || 0;

  // Totals for each configurable step
  const cardFrontTotalPrice = totalPaperTypePriceInMinorUnits + totalPaperSizePriceInMinorUnits + totalPricePerCardShapeInMinorUnits + totalPriceInMinorUnitsPerQuantity;
  const cardBackTotalPrice = totalBackLayoutPriceInMinorUnits;
  const envelopeTotalPrice = totalReturnAddressPriceInMinorUnits + totalRecipientAddressPriceInMinorUnits;

  // Calculating running total based
  if (priceUpTo === 'cardFront') {
    runningTotalInMinorUnits += cardFrontTotalPrice;
  } else if (priceUpTo === 'cardBack') {
    runningTotalInMinorUnits += cardFrontTotalPrice + cardBackTotalPrice;
  } else if (priceUpTo === 'envelope') {
    runningTotalInMinorUnits += cardFrontTotalPrice + cardBackTotalPrice + envelopeTotalPrice;
  } else if (priceUpTo === 'total') {
    const excludingDelivery = cardFrontTotalPrice + cardBackTotalPrice + envelopeTotalPrice;
    runningTotalInMinorUnits += convertPriceTotalWithRounding(excludingDelivery, quantity).resultWithFloor + totalDeliveryPriceInMinorUnits;
  }

  // Only want to include delivery price in the running total if the priceUpTo is 'total'
  // eg. if user has gone through every step and has chosen a delivery method but they refresh the page,
  //     we don't want to factor in the delivery cost since they have to go through each step again
  //
  // Will need to revisit once card back and envelope configurations are not free
  const runningTotalExcludingDeliveryInMinorUnits = runningTotalInMinorUnits - (priceUpTo === 'total' ? totalDeliveryPriceInMinorUnits : 0);

  let discountedTotalInMinorUnits: number | undefined = undefined;
  let discountAmountInMinorUnits: number | undefined = undefined;
  /**
   * This value depends on whether viewing a draft or a print order.
   */
  let discountPercentage: number | undefined = undefined;

  if (typeof promotionDiscountAmountInMinorUnits === 'number' && promotionDiscountAmountInMinorUnits !== 0) {
    discountAmountInMinorUnits = promotionDiscountAmountInMinorUnits;
    discountedTotalInMinorUnits = runningTotalInMinorUnits + discountAmountInMinorUnits;
    // Don't back-calculate percentage if a clean one is provided.
    if (!(promotionDiscountPercentage && typeof promotionDiscountPercentage === 'number')) {
      // If NO percentage is given, we can infer one.
      discountPercentage = Math.abs(discountAmountInMinorUnits / runningTotalExcludingDeliveryInMinorUnits);
    } else {
      // But if we have one we should use it
      discountPercentage = promotionDiscountPercentage;
    }
  } else if (typeof promotionDiscountPercentage === 'number' && promotionDiscountPercentage > 0) {
    discountPercentage = promotionDiscountPercentage;
    // Must store discount amount as a negative number
    // Because discount percentage can result in a total number that is a floating point number,
    // we want to round to the nearest whole number
    discountAmountInMinorUnits = Math.floor(runningTotalExcludingDeliveryInMinorUnits * promotionDiscountPercentage) * -1;
    discountAmountInMinorUnits = convertPriceTotalWithRounding(discountAmountInMinorUnits, quantity).resultWithFloor;
    discountedTotalInMinorUnits = runningTotalInMinorUnits + discountAmountInMinorUnits;
  }

  // If on review step and sales tax provided, include.
  // Discounted total should still include tax even if tax isn't discounted.
  if (priceUpTo === 'total') {
    if (salesTax && discountedTotalInMinorUnits) {
      discountedTotalInMinorUnits += salesTax;
    }
  }

  // Formatted values to be directly interpolated
  return {
    // Card
    pricePerCard: minorUnitsToDollars(pricePerCard, { canShowFreeLabel: true }),
    totalPricePerQuantity: minorUnitsToDollars(totalPriceInMinorUnitsPerQuantity, { canShowFreeLabel: true }),
    totalPricePerCardShape: dieCutConfig.getTotalPriceStringByQuantity(quantity) || 'FREE',
    totalPaperTypePrice: minorUnitsToDollars(totalPaperTypePriceInMinorUnits, { canShowFreeLabel: true }),
    totalPaperSizePrice: minorUnitsToDollars(totalPaperSizePriceInMinorUnits, { canShowFreeLabel: true }),
    totalFoilPrice: minorUnitsToDollars(totalFoilPriceInMinorUnits, { canShowFreeLabel: true }),

    // Back
    totalBackLayoutPrice: minorUnitsToDollars(totalBackLayoutPriceInMinorUnits, { canShowFreeLabel: true }),

    // Envelope
    totalEnvelopesPrice: minorUnitsToDollars(totalEnvelopesPriceInMinorUnits, { canShowFreeLabel: true }),
    pricePerReturnAddress: returnAddressConfig.individualPriceString ? `${returnAddressConfig.individualPriceString} each` : '',
    pricePerRecipientAddress: recipientAddressConfig?.individualPriceString ? `${recipientAddressConfig.individualPriceString} each` : '',
    totalReturnAddressPrice: returnAddressConfig.getTotalPriceStringByQuantity(quantity),
    totalRecipientAddressPrice: recipientAddressConfig?.getTotalPriceStringByQuantity(quantity) ?? 'FREE',

    // Shipping
    totalDeliveryPrice: totalDeliveryPriceInMinorUnits ? minorUnitsToDollars(totalDeliveryPriceInMinorUnits, { canShowFreeLabel: false }) : undefined,
    // Tax
    tax: salesTax ? minorUnitsToDollars(taxInMinorUnits, { canShowFreeLabel: false }) : undefined,
    taxInMinorUnits: taxInMinorUnits,

    total: minorUnitsToDollars(runningTotalInMinorUnits + taxInMinorUnits, { canShowFreeLabel: false }),
    totalInMinorUnits: runningTotalInMinorUnits + taxInMinorUnits,

    discountedTotal: discountedTotalInMinorUnits ? minorUnitsToDollars(discountedTotalInMinorUnits, { canShowFreeLabel: false }) : undefined,
    discountedTotalInMinorUnits,
    discountAmountInMinorUnits,
    discountPercentage
  };
};

export default useCalculatePrintOrderPrice;
