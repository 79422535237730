import React, { useEffect, useRef } from 'react';
import { CardDieCut } from '../routes/CardCustomizer/CardCustomizer.types';
import ENVELOPE_BACK from '../routes/CardCustomizer/steps/Envelope/assets/back.png';
import ENVELOPE_FRONT from '../routes/CardCustomizer/steps/Envelope/assets/front.png';
import { Skeleton } from '@shared/components/Skeleton';
import { Flex } from '@withjoy/joykit';

const PREVIEW_SCALE = 3; // As defined by Card Service
export const BLANK_ENVELOPE_FOREGROUND = `data:image/svg+xml,${encodeURIComponent('<svg xmlns="http://www.w3.org/2000/svg" width="1566" height="1134" fill="red" />')}`;

type PreviewImages = {
  cardFront?: string | null | undefined;
  cardBack?: string | null | undefined;
  envelopFront?: string | null | undefined;
  envelopeBack?: string | null | undefined;
};

async function loadImage(src: string | null | undefined): Promise<HTMLImageElement | undefined> {
  if (!src) return undefined;
  return new Promise(resolve => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.src = src;
  });
}

function createCanvas(width: number, height: number, draw: (ctx: CanvasRenderingContext2D) => void) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  draw(canvas.getContext('2d')!);
  return canvas;
}

async function createCardCanvas(imagePromise: Promise<HTMLImageElement | undefined>, diecut: CardDieCut) {
  const bleed = 9 * PREVIEW_SCALE;
  const image = await imagePromise;
  if (!image) return undefined;

  return createCanvas(image.width - bleed * 2, image.height - bleed * 2, ctx => {
    const page = {
      width: image.width - bleed * 2,
      height: image.height - bleed * 2
    };
    const diecuts = {
      rectangle: [0, 0, 0, 0],
      rounded: [54 * PREVIEW_SCALE, 54 * PREVIEW_SCALE, 54 * PREVIEW_SCALE, 54 * PREVIEW_SCALE],
      arch: [page.width / 2, page.width / 2, 0, 0]
    } as const;
    ctx.roundRect(0, 0, page.width, page.height, diecuts[diecut]);
    ctx.clip();

    ctx.drawImage(image, -bleed, -bleed);
  });
}

async function createEnvelopeCanvas(imagePromise: Promise<HTMLImageElement | undefined>, backgroundPromise: Promise<HTMLImageElement | undefined>) {
  const image = await imagePromise;
  const background = await backgroundPromise;
  if (!image || !background) return undefined;

  return createCanvas(image.width, image.height, ctx => {
    ctx.drawImage(background, 0, 0, image.width, image.height);
    ctx.drawImage(image, 0, 0);
  });
}

interface DraftPreviewProps {
  images: PreviewImages;
  diecut?: CardDieCut;
  showBlankEnvelope?: boolean;
}

export const DraftPreview: React.FC<DraftPreviewProps> = ({ images, diecut = CardDieCut.rectangle, showBlankEnvelope = false }) => {
  const { cardFront, cardBack, envelopFront, envelopeBack } = images;
  const envelopeFallback = showBlankEnvelope ? BLANK_ENVELOPE_FOREGROUND : undefined;

  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    void (async () => {
      const gap = 32 * PREVIEW_SCALE;
      const pages = (
        await Promise.all([
          createCardCanvas(loadImage(cardFront), diecut),
          createCardCanvas(loadImage(cardBack), diecut),
          createEnvelopeCanvas(loadImage(envelopFront ?? envelopeFallback), loadImage(ENVELOPE_FRONT)),
          createEnvelopeCanvas(loadImage(envelopeBack ?? envelopeFallback), loadImage(ENVELOPE_BACK))
        ])
      ).filter(page => page !== undefined) as HTMLCanvasElement[]; // VSCode infers HTMLCanvasElement[], but the joy-web unit tests don't for some reason.

      const canvas = ref.current;
      if (!canvas) return; // Possibly umounted.
      canvas.width = pages.reduce((maxWidth, image) => Math.max(maxWidth, image.width), 0);
      canvas.height = pages.reduce((totalHeight, image) => totalHeight + image.height, 0) + (pages.length - 1) * gap;
      const ctx = canvas.getContext('2d')!;

      let y = 0;
      for (const page of pages) {
        const x = (canvas.width - page.width) / 2;
        ctx.drawImage(page, x, y, page.width, page.height);
        y += page.height + gap;
      }
    })();
  }, [cardBack, cardFront, diecut, envelopFront, envelopeBack, envelopeFallback]);

  return <canvas ref={ref} style={{ width: '100%', filter: 'drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.2))' }} />;
};

export const DraftPreviewSkeleton: React.FC = () => {
  return (
    <Flex className="DraftPreviewSkeleton" flexDirection="column" paddingX={{ _: '3rem', md: '8rem' }} width="100%">
      <DraftRevisionSkeleton />
    </Flex>
  );
};

export const DraftRevisionSkeleton: React.FC = () => {
  return (
    <Flex flexDirection="column" gap={6} width="100%">
      <Flex flexDirection="column" gap={7}>
        <Skeleton aspectRatio="5 / 7" />
        <Skeleton aspectRatio="5 / 7" />
      </Flex>
      <Flex flexDirection="column" gap={6}>
        <Skeleton aspectRatio="7.25 / 5.25" />
        <Skeleton aspectRatio="7.25 / 5.25" />
      </Flex>
    </Flex>
  );
};
