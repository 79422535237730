import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { ProductOffering } from './ConciergeServiceSection.types';
import { v4 as uuid } from 'uuid';
import { ConciergeServiceCategory } from '@apps/card/routes/Concierge/config';

const HIGHLIGHT_ENVELOPE_ADDRESSING = { text: 'Recipient and return address printing' };
const HIGHLIGHT_PERSONALIZATION = { text: 'Personalization with Joy concierge design services' };
const HIGHLIGHT_NOT_INCLUDED = { text: 'Photographed design suggestions such as ribbons, wax seals, string, etc are not included unless specifically stated' };

export const PRODUCT_OFFERINGS: Record<ConciergeServiceCategory, Array<ProductOffering>> = {
  [StationeryTemplateCategoryEnum.invitation]: [
    {
      id: uuid(),
      identifier: 'wildestDream',
      shortName: 'Wildest Dreams',
      longName: 'Wildest Dreams Wedding Invitation Suite',
      costPerHundredString: '$10.73',
      videoUrl: 'https://player.vimeo.com/video/947047706?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853442320-6f5b0b94f1e861bc7d5b8da0d7f23132d42880f26d2f5821f02ff6287c29c357-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/wildest-dreams/wildest-dreams-suite-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'Immerse yourself in the timeless allure of the Wildest Dreams invitation set' },
        { text: 'Customize the information card with the option to add your website or RSVP QR code' },
        { text: 'Each invitation is encased in a transparent delicate floral print vellum bellyband' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gold foil invitation card, 5”x 7“ on premium 380GSM paper' },
        { text: 'Details card, 4.2” x 5.5” on premium 380GSM paper' },
        { text: 'Vellum bellyband 80GSM ' },
        { text: 'Premium white envelope' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'together',
      shortName: 'Together',
      longName: 'Together Wedding Invitation Suite',
      costPerHundredString: '$7.40',
      videoUrl: 'https://player.vimeo.com/video/947047579?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853442234-787d4b800726a285c5dd2edc49aaab297f6efaa18a921d09b01bb853f971a48e-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/together-gatefold/together-gatefold-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'Timeless photo gatefold invitation includes all the information for guests on one folded invitation' },
        { text: 'This bundle includes a delicate vellum bellyband to wrap around your invitations for a finished look' },
        { text: 'Wax seals are not included, but can easily be purchased from suggested vendors' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Photo gatefold wedding invitation card, 10” x 7” on 380 GSM premium paper' },
        { text: 'Vellum bellyband 80GSM' },
        { text: 'Premium white envelope' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'sofia',
      shortName: 'Sofia',
      longName: 'Sofia Wedding Invitation Suite',
      costPerHundredString: '$9.64',
      videoUrl: 'https://player.vimeo.com/video/947047427?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441762-3cc48466d6550ed05108cb8f662fbd8e8446469012b444360a6f7961d28f950f-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/sofia/sofia-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'Eye catching modern suite printed on shimmery metallic paper for a subtle glow' },
        { text: 'Matching arch shaped wedding information card that fits perfectly with the main invitation' },
        { text: 'A small landscape arch RSVP card, with the option to add your wedding website QR code' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” arch on 300 GSM metallic paper' },
        { text: 'Details card, 5.8” x 3.7” arch on 300 GSM metallic paper' },
        { text: 'RSVP card, 4.2” x 2.1” landscape arch on 300 GSM metallic paper' },
        { text: 'Premium almond color envelope' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'sage',
      shortName: 'Sage',
      longName: 'Sage Wedding Invitation Suite',
      costPerHundredString: '$12.20',
      videoUrl: 'https://player.vimeo.com/video/947047267?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441651-c49e0f48614b2978086924dd41716baf10bdeced9b21d1a967e2be77542f22d1-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/sage/sage-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'Deep forest colors combined with elegant neutrals combine for a refined but natural look' },
        { text: 'Each set comes with gold paper clips to keep all your cards together' },
        { text: 'Includes premium almond color envelopes included' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” arch on Forest Green 300GSM paper with white ink' },
        { text: 'Details card, 4.2” x 5.5” curved edge on 300 GSM Almond Paper' },
        { text: 'RSVP card, 4.2” x 2.1” land scape arch on 300GSM paper' },
        { text: 'Premium Almond Envelope' },
        { text: 'Gold color paper clip' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'milkAndHoney',
      shortName: 'Milk and Honey',
      longName: 'Milk and Honey Wedding Invitation Suite',
      costPerHundredString: '$9.33',
      videoUrl: 'https://player.vimeo.com/video/947047142?badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441650-1e9eab448236c13eb967d5aa9eb793b23c6f4b09754dc3e4739d10c4c05017d3-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/milk-and-honey/milk-and-honey-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'Earthy tone invitation package perfect for those wanting neutral colors' },
        { text: 'The wedding details card features two-tone front and back with for wedding details and RSVP information' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7”, on 300GSM cotton' },
        { text: 'Wedding details card, 5” x 7” half arch on 300 GSM almond paper' },
        { text: 'Bellyband 120 GSM matte' },
        { text: 'Premium almond color envelopes with options to select to grey, or harvest' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'jardin',
      shortName: 'Jardin',
      longName: 'Jardin Wedding Invitation Suite',
      costPerHundredString: '$6.33',
      videoUrl: 'https://player.vimeo.com/video/947046975?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441362-42f030c8a90dd23ddd39ced311850e1e069f336c0c1fb00f0bd88d70c72ea8c0-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/jardin/jardin-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Jardin gatefold invitation is for those flower and nature lovers, sure to set the tone for your special day' },
        { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold wedding invitation card, 10” x 7” on 380GSM premium paper' },
        { text: 'Premium envelopes in forest green or peach' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'ivory',
      shortName: 'Ivory',
      longName: 'Ivory Wedding Invitation Suite',
      costPerHundredString: '$12.45',
      videoUrl: 'https://player.vimeo.com/video/947046859?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853441091-25ae32d4ca89fb7e08092400588fbbe28ee9a7d9df56b0fdc69171f18f7a60c6-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/ivory/ivory-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'This set includes arch shaped invitations printed on beautiful cotton paper' },
        { text: 'Matching double-sided information card to include all your wedding day details' },
        { text: 'A small landscape arch shape RSVP card, with the option to add your wedding website QR code,' },
        { text: 'Premium black color envelopes included' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” arch on premium 300 GSM cotten paper' },
        { text: 'Details card,  4.2” x 5.5” rounded edge on 300 GSM almond paper' },
        { text: 'RSVP card, 4.2” x 2.1” landscape arch on 270 GSM black paper with white ink' },
        { text: 'Premium black envelopes with white ink' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'harmony',
      shortName: 'Harmony',
      longName: 'Harmony Wedding Invitation Suite',
      costPerHundredString: '$12.71',
      videoUrl: 'https://player.vimeo.com/video/947046740?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853440971-50a8a1d4d9d5983e2ca0ee4b88d058f6e4633d9d99213e76dbe554008410e499-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/harmony-suite/harmony-suite-1-main.jpg?v=2',
      descriptionHighlights: [
        {
          text:
            'The Harmony set is a perfect balance between wedding neutrals and greenery. With stunning script fonts and quality print and paper, this layered invitation set is bound to set the tone for your big day'
        },
        { text: 'Matching double-sided information card that is big enough for all your wedding details' },
        { text: 'Small green name card that can be used as a guest name card to tie in a hint of green to this suit' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5”x7” arch on Premium 300 GSM Cotten Paper' },
        { text: 'Details card,  4.2” x 5.5” half arch on 300 GSM cotton paper' },
        { text: 'Guest name card,  3.1” x 3.7”' },
        { text: 'Premium grey envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'harmonyGatefold',
      shortName: 'Harmony Gatefold',
      longName: 'Harmony Gatefold Wedding Invitation Suite',
      costPerHundredString: '$6.33',
      videoUrl: 'https://player.vimeo.com/video/947044716?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853438016-64478fea48afc207f85486f7ad8248b0412f8a688d61e3e4c10d91036a8c4013-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/harmony-gatefold/harmony-gatefold-1-main.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Harmony gatefold invitations are a perfect way to send your guests all the info they need for the day' },
        { text: 'High quality photo printing on the outside' },
        { text: 'The inside of this all in one gatefold invitation includes all your wedding day finer details, RSVP, and wedding timeline' },
        { text: 'Harvest color envelopes included with each invitation' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold Wedding Invitation, 10” x 7” on premium 380 GSM paper' },
        { text: 'Premium harvest color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'empireNow',
      shortName: 'Empire Now',
      longName: 'Empire Now Wedding Invitation Suite',
      costPerHundredString: '$4.26',
      videoUrl: 'https://player.vimeo.com/video/947044156?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853437219-35b71461829d0e6f7be76a171df3842831ec96090781b290714cc573a26e43c1-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/empire-now/empire-now-gatefold.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Empire Now all-in-one Gatefold design is a combination of curvy die-cut and elegant typography' },
        { text: 'This all-in-one wedding invitation with high quality photo printing includes all your wedding day finer details and RSVP information in one elegant card' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold Wedding Invitation, 11.8” x 7” side wave on premium 380 GSM paper' },
        { text: 'Premium black envelopes with white ink' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'byronGatefold',
      shortName: 'Byron Gatefold',
      longName: 'Byron Gatefold Wedding Invitation Suite',
      costPerHundredString: '$6.33',
      videoUrl: 'https://player.vimeo.com/video/947043616?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1853436469-c1c8a5f3e50f5a917876fdbd74b67dd613cb6505c14702b1c5a0dd0437820abd-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/byron-gatefold/byron-gatefold.jpg?v=2',
      descriptionHighlights: [
        { text: 'All your invitation, RSVP, and wedding details in one gatefold card' },
        { text: 'Customize with your own photo on the inside' },
        { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold Wedding Invitation, 10” x 7” on premium 380 GSM paper' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'celadon',
      shortName: 'Celadon',
      longName: 'Celadon Wedding Invitation Suite',
      costPerHundredString: '$7.37',
      videoUrl: 'https://player.vimeo.com/video/1002121670?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918422896-2f6428fe3575721e753f510646ae83ec1a3f2d81f9cb31527eee26fbc7b723e0-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/celadon/celadon-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Celadon Gatefold design is a combination of curvy die-cut and elegant typography' },
        { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information in one elegant card' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Printed in white ink on rich and vibrant Forest Green paper' },
        { text: 'Gatefold Wedding Invitation, 11.8” x 7” side wave on premium 300 GSM paper' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        { text: 'Gold color paper clip' },
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'wildMeadow',
      shortName: 'Wild Meadow',
      longName: 'Wild Meadow Wedding Invitation Suite',
      costPerHundredString: '$9.07',
      videoUrl: 'https://player.vimeo.com/video/1002121907?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918423147-1c4cdcfd97be9311d442a0c54da8d65acee0db54f360560e92b9e91bea3e7373-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/wild-meadow/wild-meadow.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Wild Meadow gatefold invitation is perfect for flower lovers and sure to set the tone for your special day' },
        { text: 'This all-in-one wedding invitation includes all your wedding day finer details and RSVP information' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold wedding invitation card, 10” x 7” on 380 GSM premium paper' },
        { text: 'Kraft bellyband, 10.5” x 2” 80 GSM' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'eden',
      shortName: 'Eden',
      longName: 'Eden Wedding Invitation Suite',
      costPerHundredString: '$8.28',
      videoUrl: 'https://player.vimeo.com/video/1002122162?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918423449-e2cd083a494ea391fea3cbe969774e9083d8f6219e74cb5050701112f10853df-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/eden/eden.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Eden gatefold invitation is for flower and nature lovers, sure to set the tone for your special day' },
        { text: 'This all-in-one wedding invitation includes space for all your wedding day detail, RSVP, and wedding day timeline information' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Gatefold wedding invitation card, 10” x 7” on 380 GSM premium paper' },
        { text: 'Premium almond color envelopes' },
        { text: 'Delicate Leaves envelope liners' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'luminous',
      shortName: 'Luminous',
      longName: 'Luminous Wedding Invitation Suite',
      costPerHundredString: '$9.15',
      videoUrl: 'https://player.vimeo.com/video/1002122372?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918423640-11686ae80aff6e9d9f2b0dbc78b8e3007543c0c0115f63decb19ef4ed02528bc-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/luminous/luminous.jpg?v=2',
      descriptionHighlights: [
        { text: 'Immerse yourself in the timeless allure of this Luminous invitation set with foil-printing' },
        { text: 'Paired with a delicate transparent vellum info card' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7”, on 380 GSM premium paper' },
        { text: 'Details card, 4.2” x 5.5,” on 180 GSM Vellum with white ink' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'graceTrifold',
      shortName: 'Grace Trifold',
      longName: 'Grace Trifold Wedding Invitation Suite',
      costPerHundredString: '$7.22',
      videoUrl: 'https://player.vimeo.com/video/1002122659?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918423958-cc1c0364f8913c90d260424be616366337c6319be0f4c93c493ad3102085ec38-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/grace-trifold/grace-trifold-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'A unique arch-shaped trifold with elegant typography and space for all your wedding details' },
        { text: 'This all-in-one wedding invitation includes a detachable RSVP card' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Trifold wedding invitation card, 5” x 16.5” (5” x 7” folded) on 300 GSM almond colored paper' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'flutter',
      shortName: 'Flutter',
      longName: 'Flutter Wedding Invitation Suite',
      costPerHundredString: '$14.14',
      videoUrl: 'https://player.vimeo.com/video/1002122888?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918424244-443431f812254ce2f6b8808bea5253082cd7034e1fcc434121223dba98d2f23d-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/flutter/flutter-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Vibrant red and blush tones invitation suite is perfect for those wanting a pop of color' },
        { text: 'This set includes a wedding invitation, wedding info card with RSVP details, and an extra card on top customized with a favorite quote or your names' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” on Blush 300 GSM paper' },
        { text: 'Details and RSVP card, 4.3” x 7” side wave edge on Red 270 GSM paper with white ink' },
        { text: 'Top card, 3.7” x 7” side wave edge on 300 GSM cotton paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        { text: 'Gold color paper clip' },
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'koffee',
      shortName: 'Koffee',
      longName: 'Koffee Wedding Invitation Suite',
      costPerHundredString: '$13.02',
      videoUrl: 'https://player.vimeo.com/video/1002123089?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918424442-802f112f404bfdfdfebcd874365a398b0e2fd3a24df1f15f0228c69a26944cc2-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/koffee/koffee-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Rich browns and creams combine in this earth-toned invitation suite perfect for a neutral color palette' },
        { text: 'Share the event details and  timeline with your guests across three layers of cards' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” on 300 GSM harvest color paper with white ink' },
        { text: 'Program card,  4.3” x 7” side arch on 300 GSM almond color paper' },
        { text: 'Info and RSVP card, 3.7” x 5.8” side arch on 300 GSM cotton paper' },
        { text: 'Premium almond color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        { text: 'Gold color paper clip' },
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'grace',
      shortName: 'Grace',
      longName: 'Grace Wedding Invitation Suite',
      costPerHundredString: '$15.44',
      videoUrl: 'https://player.vimeo.com/video/1002123486?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918424894-895a879196f69e937bad2eed2d096867f77fdaae263ed53ef54379fc5d42aae5-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/grace/grace-1.jpg?v=2',
      descriptionHighlights: [
        {
          text:
            'The Grace set is a perfect balance between wedding neutrals and greenery. With stunning script fonts and quality print and paper, this layered invitation set is bound to set the tone for your big day'
        },
        { text: 'Matching double-sided information card that is big enough for all your wedding and RSVP details' },
        { text: 'Small grey name card that can be used as a guest name card and as a place card for your on-the-day stationery' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” arch on premium 300 GSM seedling color paper with white ink' },
        { text: 'Details card,  3.7” x 5.7” arch on 300 GSM almond color paper' },
        { text: 'Guest name card,  3.1” x 3.7” half arch on 300 GSM grey color paper with white ink' },
        { text: 'Premium seedling green color envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        { text: 'Gold color paper clip' },
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'roseEtGris',
      shortName: 'Rose et Gris',
      longName: 'Rose et Gris Wedding Invitation Suite',
      costPerHundredString: '$12.75',
      videoUrl: 'https://player.vimeo.com/video/1001767624?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1918024755-a3e71a0f1a6316b246394a620c45c40c4a5af2fd010ebe0c52cffc7a3a04788b-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/invitation-suites/rose-et-gris/rose-et-gris-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Immerse yourself in the timeless allure of this Rose Et Gris invitation suite with gold foil lettering' },
        { text: 'Opt for blush RSVP post-cards with your address or share a QR code for digital RSVP' },
        {
          text: 'An information card in an elegant shade of grey for all the finer details'
        },
        { text: 'A matching foil printed bellyband to tie it all together' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Wedding invitation card, 5” x 7” on premium 380 GSM paper with gold foil' },
        { text: 'Details card,  4.2” x 5.5” on 300 GSM grey color paper' },
        { text: 'RSVP card,  5.5” x 4.2” on 300 GSM blush color paper' },
        { text: 'Bellyband, 10.5” x 2” on 120 GSM with gold foil' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    }
  ].sort(() => Math.random() - 0.5),
  [StationeryTemplateCategoryEnum.saveTheDate]: [
    {
      id: uuid(),
      identifier: 'luna',
      shortName: 'Luna',
      longName: 'Luna Save the Date',
      costPerHundredString: '$3.79',
      videoUrl: 'https://player.vimeo.com/video/1006745499?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505068-b3897f91cf25802ae46805cb1a9bcc8d129f69f3d4603a208e96bb76cb741216-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/luna/luna-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'A blend of gold foil and digital printing, this arch-shaped Save the Date is perfect for setting the tone of your special day. ' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” arch on luxe 380 GSM paper with gold foil' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'linear',
      shortName: 'Linear',
      longName: 'Linear Save the Date',
      costPerHundredString: '$3.68',
      videoUrl: 'https://player.vimeo.com/video/1006745515?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505088-f3792b40df36dcf7a65e897aec116a73fe169d0f99c98696e83c24d4521faafa-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/linear/linear-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Modern typography meets premium cotton paper. The Linear is all about simplicity and elegance to set the tone for your big day.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” on 300 GSM cotton paper' },
        { text: 'Premium almond envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'minimalisme',
      shortName: 'Minimalisme',
      longName: 'Minimalisme Save the Date',
      costPerHundredString: '$3.79',
      videoUrl: 'https://player.vimeo.com/video/1006745485?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505048-4d254e0268e9772b6ef310131906f0887f52642d986b50a1040a9155fecef66b-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/minimalisme/minimalisme-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Personalized photo cards are the perfect way to announce your big day.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” arch on luxe 380 GSM paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'minimalistBlackWhite',
      shortName: 'Minimalist Black & White',
      longName: 'Minimalist Black & White Save the Date',
      costPerHundredString: '$3.93',
      videoUrl: 'https://player.vimeo.com/video/1006745470?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505038-5371188bd63a947e043838c4cfb959246b37092285e6748a634fee9575d8ebad-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/minimalistBlackWhite/minimalistBlackWhite-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'This Foil and personalized photo combo is the perfect way to send out your Save the Dates in style.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” on 350 GSM heavyweight paper with gold foil' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'polaroid',
      shortName: 'Polaroid',
      longName: 'Polaroid Save the Date',
      costPerHundredString: '$2.26',
      videoUrl: 'https://player.vimeo.com/video/1006745454?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505179-d7acabb7175f59d296b9ab8f2d55850b13325edbe75c7fb8ddbfa26ebc10cad4-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/polaroid/polaroid-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Announce your big day with your own photo captured on premium stock, creating a keepsake that lasts.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” on luxe 380 GSM paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    },
    {
      id: uuid(),
      identifier: 'byron',
      shortName: 'Byron',
      longName: 'Byron Save the Date',
      costPerHundredString: '$3.93',
      videoUrl: 'https://player.vimeo.com/video/1006744654?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923504073-4a5fc458782ee655d7fcc6dacc66ad0fe59cf7fc04f55bed2bb7d02db3eda8bd-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/byron/byron-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Inspired by the Byron shorelines in Australia, this arch shaped copper foil Save the Date is the perfect way to set the tone for your big day.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” arch on luxe 380 GSM paper with copper foil' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'summerMontage',
      shortName: 'Summer Montage',
      longName: 'Summer Montage Save the Date',
      costPerHundredString: '$3.79',
      videoUrl: 'https://player.vimeo.com/video/1006748982?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923509108-65dde415c0809e2f4bdd975b91e3895859f125f45e03a3e77e8ba3484528984f-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/summerMontage/summerMontage-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'The Summer Nights double-arch Save the Date is the perfect way to announce your big day, personalized with your very own photo.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” double arch on luxe 380 GSM paper with white ink' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'delilah',
      shortName: 'Delilah',
      longName: 'Delilah Save the Date',
      costPerHundredString: '$3.30',
      videoUrl: 'https://player.vimeo.com/video/1006745036?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923504570-0f3e7d691b23445a1d691f36e5c9e9db419fa7b90392f0af1bb17cb53970d78c-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/delilah/delilah-1.jpg?v=2',
      descriptionHighlights: [
        { text: 'Where rustic charm meets minimalism, the Delilah arch-shaped save the dates perfectly set the tone for your special day.' },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” arch on 300 GSM cotton paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'floweret',
      shortName: 'Floweret',
      longName: 'Floweret Save the Date',
      costPerHundredString: '$3.30',
      videoUrl: 'https://player.vimeo.com/video/1006745029?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923504483-d0831c1ac8d498058527e3674a3cd87e5e38b2d68ca59abcefda273d1bc9dace-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/floweret/floweret-1.jpg?v=2',
      descriptionHighlights: [
        {
          text:
            'The double-arch Floweret Save the Date features a graceful blend of blossom prints and typewriter-style fonts. It’s a charming way to announce your special day with a touch of timeless elegance.'
        },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” double arch on 300 GSM cotton paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'summerNights',
      shortName: 'Summer Nights',
      longName: 'Summer Nights Save the Date',
      costPerHundredString: '$3.79',
      videoUrl: 'https://player.vimeo.com/video/1006745437?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923505003-181970c8e4225285b9451234dac82ed661e32e9e3d7ca492ff24060064420b00-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/summerNights/summerNights-1.jpg?v=2',
      descriptionHighlights: [
        {
          text: 'Summer Nights Save the Dates are a perfect blend of blue and orange hues, a masterpiece crafted for those who dream of warm sands and the whisper of waves.'
        },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” arch on luxe 380 GSM paper' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 6
    },
    {
      id: uuid(),
      identifier: 'clairDeLune',
      shortName: 'Clair De Lune',
      longName: 'Clair De Lune Save the Date',
      costPerHundredString: '$3.27',
      videoUrl: 'https://player.vimeo.com/video/1006745058?title=0&byline=0&portrait=0&badge=0&autopause=0&player_id=0&app_id=58479',
      videoThumbnailUrl: 'https://i.vimeocdn.com/video/1923504604-6e59b3bf477e599bd64165ac6c2cce872523e6ab8027b2cbceeb0e19d2d36037-d_1280x720?r=pad',
      imageUrl: 'https://withjoy.com/assets/public/messaging/partnerships/paperlust/save-the-date-suites/clairDeLune/clair_de_lune-3.jpg?v=2',
      descriptionHighlights: [
        {
          text:
            'The Clair De Lune double-layer Save the Dates are a delightful blend of playfulness and elegance. The top layer, printed in white ink on delicate vellum, overlays your personalized photo on the second layer, creating a beautiful keepsake.'
        },
        { text: 'Upgrade to smooth magnetic backing for a fridge-friendly keepsake.' }
      ],
      suiteHighlights: [
        HIGHLIGHT_PERSONALIZATION,
        { text: 'Save the Date card, 4.2” x 5.5” on 350 GSM heavyweight paper' },
        { text: 'Sheer overlay, 4.2” x 5.5” on 180 GSM vellum with white ink' },
        { text: 'Premium white envelopes' },
        HIGHLIGHT_ENVELOPE_ADDRESSING,
        { text: 'Hole punch (ribbon not included)' },
        { text: 'Gold fastener' },
        HIGHLIGHT_NOT_INCLUDED
      ],
      printingBusinessDaysQuantity: 2
    }
  ].sort(() => Math.random() - 0.5)
};
