import React, { useMemo } from 'react';

import { Helmet, HelmetProps } from 'react-helmet-async';

interface SeoProps extends Readonly<{}> {
  description?: string;
  lang?: string;
  title: string;
  name?: string;
  author?: string;
  email?: string;
  url?: string;
  fbAppId?: string;
  keywords?: string;
  disableIndexing?: boolean;
  canonicalURL?: string;
  ogImage?: string;
  ogLogo?: string;
  twitterSite?: string;
  ogType?: string;
}

const useSeo = (props: SeoProps) => {
  const metaTags = useMemo<HelmetProps['meta']>(() => {
    const { name, url, author, title, description, ogImage, ogLogo, ogType = 'website', twitterSite } = props;
    return [
      // Enable once public shop goes live
      // {
      //   name: 'robots',
      //   content: disableIndexing ? 'noindex' : 'index'
      // },
      {
        name: `description`,
        content: description
      },
      {
        name: `author`,
        content: author
      },

      // Open Graph

      {
        property: `og:site_name`,
        content: name
      },
      {
        property: `og:url`,
        content: url
      },
      {
        property: `og:title`,
        content: title
      },
      {
        property: `og:description`,
        content: description
      },
      {
        property: `og:image`,
        content: ogImage
      },
      {
        property: `og:logo`,
        content: ogLogo
      },
      {
        property: `og:type`,
        content: ogType
      },

      // Twitter
      {
        name: `twitter:card`,
        content: `summary`
      },
      {
        name: `twitter:creator`,
        content: author
      },
      {
        name: `twitter:title`,
        content: title
      },
      {
        name: `twitter:description`,
        content: description
      },
      {
        name: `twitter:url`,
        content: url
      },
      {
        name: `twitter:site`,
        content: twitterSite
      }
    ].filter(({ content }) => !!content);
  }, [props]);

  return {
    metaTags
  };
};

export const Seo: React.FC<SeoProps> = props => {
  const { metaTags } = useSeo(props);
  return (
    <Helmet title={props.title} meta={metaTags}>
      {props.canonicalURL && <link rel="canonical" href={props.canonicalURL} />}
    </Helmet>
  );
};

Seo.defaultProps = {
  author: 'Joy',
  url: 'https://withjoy.com',
  disableIndexing: false
};
