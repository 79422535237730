import { TelemetryDataReturn } from '@apps/admin/common/IframeTelemetry/IframeTelemetry.types';
import { EventPageType } from '@graphql/generated';

const pagePrefix = 'admin';
const action = 'WebsiteInlineEditorInteracted';

const actionProps = {
  action,
  pagePrefix
};

export const telemetryData = {
  inlineEditCTAClick: (
    componentName: string,
    pageName: Maybe<EventPageType>,
    pageSlug: string,
    action: 'focusIn' | 'focusOut',
    category: 'admin' | 'guestsite' = 'admin',
    componentId?: string
  ): TelemetryDataReturn => ({
    telemetryType: 'track',
    ...actionProps,
    category,
    extraInfo: {
      name: 'inlineEditViewClicked',
      componentName,
      pageName,
      pageSlug: pageSlug ? `/${pageSlug}` : '',
      componentId: componentId || undefined,
      action
    }
  }),
  inlineActionClicked: (
    componentName: string,
    pageName: Maybe<EventPageType>,
    pageSlug: string,
    category: 'admin' | 'guestsite' = 'admin',
    componentId?: string
  ): TelemetryDataReturn => ({
    telemetryType: 'track',
    ...actionProps,
    category,
    extraInfo: {
      name: 'inlineEditActionClicked',
      componentName,
      pageName,
      pageSlug: pageSlug ? `/${pageSlug}` : '',
      componentId: componentId || undefined,
      action: 'editComponent'
    }
  })
};
