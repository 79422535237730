import { useEventNameMatch } from '@shared/components/AuthProvider/AuthProvider.utils';
import { get } from 'lodash-es';
import { EventDesignPurpose, useEventWebsiteGuestQuery } from '@graphql/generated';
import { useMemo } from 'react';
import { getAlohaApplicatorConfig } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.theme';
import { useStyleApplicator } from '@apps/guest/packages/layout-engine/common/utils/useStyleApplicator';
import { useFeatureValue } from '@shared/core/featureFlags';

export const useDialogThemeController = () => {
  const nameMatchResult = useEventNameMatch();
  const eventHandle = get(nameMatchResult, 'eventName', '');
  const { data: event } = useEventWebsiteGuestQuery({
    variables: { eventHandle, eventDesignPurpose: EventDesignPurpose.live },
    batchMode: 'fast',
    ssr: false
  });
  const eventDesign = event?.eventByName?.eventDesign;
  const styleAttrConfig = useMemo(
    () => ({
      prop: { 'data-layer': 'simple-unlock' },
      selector: '[data-layer="simple-unlock"]'
    }),
    []
  );
  const { designPreferences, colorPalette } = eventDesign || {};
  const themeId = eventDesign?.theme.themeId;
  const isGuestSiteColorsExperimentEnabled = useFeatureValue('guestSiteColorsExperiment').value === 'treatment';

  const applicatorConfig = useMemo(() => {
    return designPreferences && colorPalette
      ? getAlohaApplicatorConfig(designPreferences, colorPalette, eventDesign?.theme.websiteLayoutAlohaSettings, isGuestSiteColorsExperimentEnabled)
      : undefined;
  }, [designPreferences, colorPalette, eventDesign, isGuestSiteColorsExperimentEnabled]);

  useStyleApplicator(
    styleApplicator => {
      if (themeId && applicatorConfig) {
        return [styleApplicator.applyCompositionSprites(styleAttrConfig.selector, themeId, 'simple', 'content', applicatorConfig.simple)];
      }
      return [];
    },
    [themeId, styleAttrConfig, applicatorConfig]
  );

  return {
    styleAttrConfig
  };
};
