import React, { useRef, forwardRef, useLayoutEffect } from 'react';
import { OuterElementWrapper } from './VirtualScrollOuterElement.styles';
import { useScroll } from 'react-use-gesture';
import classNames from 'classnames';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useVirtualScrollWrapperContext } from './context';

export interface VirtualScrollOuterElementProps {
  onScroll: (props: unknown) => unknown;
  className?: string;
}

export const VirtualScrollOuterElement = forwardRef<HTMLElement, VirtualScrollOuterElementProps>(({ onScroll, children, className }, ref) => {
  const containerRef = useRef<HTMLDivElement>();
  const { virtualScrollWrapperElement } = useVirtualScrollWrapperContext();

  const bindScroller = useScroll(
    () => {
      const containerRefCurrent = containerRef.current;
      if (!virtualScrollWrapperElement || !containerRefCurrent) {
        return;
      }

      const { clientWidth, clientHeight, scrollLeft, scrollHeight, scrollWidth } = virtualScrollWrapperElement;
      onScroll({
        currentTarget: {
          clientHeight,
          clientWidth,
          scrollLeft,
          scrollTop: virtualScrollWrapperElement.getBoundingClientRect().top - containerRefCurrent.getBoundingClientRect().top,
          scrollHeight,
          scrollWidth
        }
      });
    },
    {
      domTarget: { current: virtualScrollWrapperElement }
    }
  );

  useLayoutEffect(() => {
    if (virtualScrollWrapperElement) {
      bindScroller();
    }
  }, [bindScroller, virtualScrollWrapperElement]);

  const onSetRef = useEventCallback((element: HTMLDivElement) => {
    containerRef.current = element;

    if (ref) {
      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    }
  });

  return (
    <OuterElementWrapper className={classNames('outer-scroll-element', className)} ref={onSetRef}>
      {children}
    </OuterElementWrapper>
  );
});
