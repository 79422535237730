import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getEventTypeConfig } from '@config';
import { EventType } from '@graphql/generated';
import { Seo } from '@shared/components/Seo';

/**
 * Adds a query param to the image url to optimize the image from Media Service and add a source query param
 */
const addOptimizationQueryParam = (url: Maybe<string>): string | undefined => {
  if (!url) return undefined;
  const hasQueryParam = url.includes('?');
  const separator = hasQueryParam ? '&' : '?';
  return `${url}${separator}rendition=small&source=websitepreview`;
};

type MediaCollectionHeadProps = {
  handle: Maybe<string>;
  eventDisplayName: Maybe<string>;
  imageUrl: Maybe<string>;
  ownerFirstName: Maybe<string>;
  ownerLastName: Maybe<string>;
  fianceeFirstName: Maybe<string>;
  eventType: EventType;
};

export const MediaCollectionHead: React.FC<MediaCollectionHeadProps> = props => {
  const { handle, eventDisplayName, eventType, ownerFirstName, ownerLastName, fianceeFirstName, imageUrl } = props;

  const userInfo = { partnerOneFirstName: ownerFirstName || '', partnerOneLastName: ownerLastName || '', partnerTwoFirstName: fianceeFirstName || '' };
  const pageTitle = getEventTypeConfig(eventType).guestSitePageTitle(eventDisplayName || '', userInfo) || '';
  const pageDescription = getEventTypeConfig(eventType).guestSitePageDescription(eventDisplayName || '', userInfo, 'event information');
  const pageUrl = `https://withjoy.com/${handle}/`;

  return (
    <>
      <Seo
        title={pageTitle}
        description={pageDescription}
        canonicalURL={pageUrl}
        name="Joy"
        url={pageUrl}
        ogImage={addOptimizationQueryParam(imageUrl)}
        author="@JoytheApp"
        twitterSite="@JoytheApp"
      />
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  );
};
