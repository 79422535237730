import { TextV2 } from '@withjoy/joykit';
import React from 'react';
import { styles, StyledMenuButton, StyledMenuButtonContent, StyledHamburger } from './MenuButton.styles';
import { useTranslation } from '@shared/core/i18n';
import { isInIframe } from '@shared/utils/isInIframe';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { label4ToNavigationOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

const isPreviewing = isInIframe();

interface Props
  extends Readonly<{
    onClick: () => void;
    isCustomPage?: boolean;
    topOffset?: number;
  }> {}

export const MenuButton: React.FC<Props> = ({ onClick, isCustomPage, topOffset = 0 }) => {
  const { t2 } = useTranslation('eventMenu');
  const menu = t2('menu');

  return (
    <StyledMenuButton {...styles.button(isCustomPage, topOffset)} onClick={onClick} typographyVariant="button3" isPreviewing={isPreviewing}>
      <StyledMenuButtonContent __css={styles.content}>
        <StyledHamburger />
        <GuestSiteTypographyOverride override={label4ToNavigationOverride}>
          <TextV2 typographyVariant="label4">{menu}</TextV2>
        </GuestSiteTypographyOverride>
      </StyledMenuButtonContent>
    </StyledMenuButton>
  );
};

export default MenuButton;
