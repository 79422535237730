import React from 'react';
import { ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { PhotoPresentationLayout } from './FocusPointEditor.types';
import { useTranslation } from '@shared/core';
import { FullWidthImage, CenteredImage } from '@assets/icons';

interface PhotoPresentationLayoutSelectorProps {
  currentPhotoLayout: PhotoPresentationLayout;
  onPhotoLayoutChange: (layout: PhotoPresentationLayout) => void;
}

interface LayoutOptionProps {
  isActive: boolean;
  onClick: (layout: PhotoPresentationLayout) => void;
  value: PhotoPresentationLayout;
  label: string;
  icon: React.ReactNode;
}

const LayoutOption = (props: LayoutOptionProps) => {
  const { isActive, label, value, onClick, icon } = props;
  return (
    <ButtonV2
      flex={1}
      padding={'10px'}
      intent="neutral"
      height="unset"
      borderWidth="1px"
      borderColor={isActive ? 'mono14' : 'mono5'}
      backgroundColor={isActive ? 'white' : 'mono2'}
      variant="outline"
      onClick={() => onClick(value)}
    >
      <Flex flexDirection="column" gap={3} justifyContent="center" alignItems="center">
        {icon}
        <TextV2 typographyVariant="button2">{label}</TextV2>
      </Flex>
    </ButtonV2>
  );
};
export const PhotoPresentationLayoutSelector = (props: PhotoPresentationLayoutSelectorProps) => {
  const { currentPhotoLayout, onPhotoLayoutChange } = props;

  const { t2 } = useTranslation('joykit');
  const focusPointTrans = t2('focusPointDialog');

  return (
    <Flex flexDirection="column" marginTop={7} rowGap={5}>
      <TextV2 typographyVariant="hed2">{focusPointTrans.photoLayout}</TextV2>
      <Flex columnGap={5}>
        <LayoutOption
          isActive={currentPhotoLayout === 'fullWidth'}
          label={focusPointTrans.fullWidthPhoto}
          onClick={onPhotoLayoutChange}
          value="fullWidth"
          icon={<FullWidthImage />}
        />
        <LayoutOption isActive={currentPhotoLayout === 'center'} label={focusPointTrans.centeredPhoto} onClick={onPhotoLayoutChange} value="center" icon={<CenteredImage />} />
      </Flex>
    </Flex>
  );
};
