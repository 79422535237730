import React from 'react';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import { ResponsiveDialog } from '@apps/card/components/ResponsiveDialog';

interface WarningDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onResolve: () => Promise<void>;
  isResolveLoading: boolean;
}

const WarningDialog: React.FC<WarningDialogProps> = ({ isOpen, onClose, onResolve, isResolveLoading }) => (
  <ResponsiveDialog
    isOpen={isOpen}
    onClose={onClose}
    header={() => <TextV2 typographyVariant="hed3">Send to Print</TextV2>}
    content={() => (
      <Flex flexDirection="column" gap={8}>
        <TextV2 fontSize="15px" lineHeight="21px">
          I understand that by clicking Send to Print, I will no longer be able to edit my card or envelopes.
        </TextV2>
        <Flex gap={4} flexDirection={{ _: 'column-reverse', sm2: 'row' }} width="100%" justifyContent="space-between">
          <ButtonV2 intent="neutral" shape="rounded" variant="outline" onClick={onClose}>
            Cancel
          </ButtonV2>
          <ButtonV2 intent="neutral" shape="rounded" onClick={onResolve} loading={isResolveLoading}>
            Send to Print
          </ButtonV2>
        </Flex>
      </Flex>
    )}
  />
);

export default WarningDialog;
