export const parseInputToInt = (input: string | number | undefined) => {
  switch (typeof input) {
    case 'string':
      return Number.parseInt(input, 2);
    case 'number':
      return input;
    default:
      return 0;
  }
};
