import React from 'react';
import { MainFrameContainer } from './MainFrame.styles';
import { useColorPaletteProvider } from '../../../ColorPaletteProvider/ColorPaletteProvider';
interface Props {}

export const MainFrame: React.FC<Props> = ({ children }) => {
  const { textColor, backgroundColor } = useColorPaletteProvider();
  const customTextColor = backgroundColor ? textColor : undefined;
  return (
    <MainFrameContainer textColor={customTextColor} backgroundColor={backgroundColor}>
      {children}
    </MainFrameContainer>
  );
};
