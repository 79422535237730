import React from 'react';
import { ButtonV2, CloseButton, Divider, Flex, TextV2 } from '@withjoy/joykit';
import { closeButtonOverrides } from '../ResponsiveDialog/ResponsiveDialog.styles';
import { StyledActionButton } from './FocusPointEditor.styles';
import { useTranslation } from '@shared/core';
import { Camera, Trash } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';

interface EditorLayoutProps {
  isCloseButtonDisabled: boolean;
  onCloseButtonClick: () => void;

  content: () => React.ReactNode;

  note: string;

  isDeletePhotoButtonDisabled: boolean;
  isDeletePhotoButtonLoading: boolean;
  onDeletePhotoButtonClick: () => void;

  onReplacePhotoButtonClick: () => void;
  isReplacePhotoButtonDisabled: boolean;

  onSavePhotoButtonClick: () => void;
  isSavePhotoButtonDisabled: boolean;
  isSavePhotoButtonLoading: boolean;

  onCancelButtonClick: () => void;
  isCancelButtonDisabled: boolean;
}
export const EditorLayout = (props: EditorLayoutProps) => {
  const {
    isCloseButtonDisabled,
    onCloseButtonClick,
    content,
    note,

    isDeletePhotoButtonDisabled,
    isDeletePhotoButtonLoading,
    onDeletePhotoButtonClick,

    onReplacePhotoButtonClick,
    isReplacePhotoButtonDisabled,

    onSavePhotoButtonClick,
    isSavePhotoButtonDisabled,
    isSavePhotoButtonLoading,

    onCancelButtonClick,
    isCancelButtonDisabled
  } = props;

  const { t2 } = useTranslation('joykit');
  const dialogTrans = t2('focusPointDialog');

  return (
    <>
      <CloseButton
        overrides={{
          Root: { props: { ...closeButtonOverrides.Root?.props, position: 'absolute', right: 0, marginRight: '-16px', marginTop: '-16px' } },
          Icon: { props: { ...closeButtonOverrides.Icon?.props } }
        }}
        onClick={onCloseButtonClick}
        aria-label={'close dialog'}
        disabled={isCloseButtonDisabled}
      />

      <Flex flexDirection="column" alignItems="center" gap={6} marginTop={7}>
        {content()}
        <TextV2 typographyVariant="label2" textAlign="center" color="mono10">
          {note}
        </TextV2>
        <Divider />
        <Flex flexDirection="row" gap={3} alignItems="flex-start" width="100%">
          <ButtonV2
            variant="ghost"
            size="lg"
            shape="square"
            intent="destructive"
            startIcon={Trash}
            width="100%"
            onClick={onDeletePhotoButtonClick}
            loading={isDeletePhotoButtonLoading}
            disabled={isDeletePhotoButtonDisabled}
          >
            {dialogTrans.delete}
          </ButtonV2>
          <ButtonV2
            variant="ghost"
            size="lg"
            shape="square"
            intent="neutral"
            startIcon={Camera}
            width="100%"
            onClick={onReplacePhotoButtonClick}
            disabled={isReplacePhotoButtonDisabled}
          >
            {dialogTrans.replacePhoto}
          </ButtonV2>
        </Flex>
      </Flex>

      <Flex flexDirection="column" width="100%" gap={5} marginTop={pxToRem(56)}>
        <StyledActionButton intent="neutral" onClick={onSavePhotoButtonClick} disabled={isSavePhotoButtonDisabled} loading={isSavePhotoButtonLoading}>
          {dialogTrans.save}
        </StyledActionButton>
        <StyledActionButton color="mono14" variant="ghost" backgroundColor="transparent" onClick={onCancelButtonClick} disabled={isCancelButtonDisabled}>
          {dialogTrans.cancel}
        </StyledActionButton>
      </Flex>
    </>
  );
};
