import { styled } from '@withjoy/joykit';
import { VirtualScrollWrapper } from '@apps/admin/common/VirtualScroll';
import { breakpoints } from '@shared/utils/style/breakpoints';

export const ScrollWrapper = styled(VirtualScrollWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  flex: 1;
  height: 100vh;
  height: 100dvh;
  @media (min-width: ${breakpoints.md}) {
    height: calc(100vh - 160px);
    height: calc(100dvh - 160px);
  }
`;
