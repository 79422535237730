import React, { useContext, useEffect, useMemo } from 'react';
import { ResponsiveDialog } from '@shared/components/ResponsiveDialog';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { TextV2, Spinner, Flex } from '@withjoy/joykit';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useGetUrlVerificationStatusLazyQuery } from '@graphql/generated';
import { StyledExternalLinkLabel, StyledSafeLinkCancelButton, StyledSafeLinkProceedButton, StyledSafeLinkCloseButton } from './LinkConfirmationDialog.styles';
import { withWindow } from '@shared/utils/withWindow';
import { formatRedirectURL } from '@shared/utils/formatting/redirectURL.formatter';
import { useFeatureValue } from '@shared/core/featureFlags';
import { addAction } from '@shared/utils/logger';
import { ensureHttpsPrefix } from '@shared/utils/urlHelpers';

enum LinkWithConfirmationDialogComponentState {
  LOADING = 'loading',
  UNSAFE = 'unsafe',
  SAFE = 'safe'
}

interface LinkConfirmationDialog {
  href: string;
  isOpen: boolean;
  target?: '_self' | '_blank' | '_parent' | '_top';
  onClose: () => void;
}

export const LinkConfirmationDialog: React.FC<LinkConfirmationDialog> = ({ href: hrefProp, isOpen, target = '_blank', onClose }) => {
  const { t } = useTranslation('sharedComponents');
  const translations = t('safeLink');
  const analytics = useContext(AnalyticsContext);

  const guestLinkUseRedirectService = useFeatureValue('guestLinkUseRedirectService').value === 'on';

  const href = ensureHttpsPrefix(hrefProp.trim());
  const [verifyUrl, { loading, data }] = useGetUrlVerificationStatusLazyQuery({ batchMode: 'fast' });

  const componentState = useMemo<LinkWithConfirmationDialogComponentState>(() => {
    if (data?.verifyUrl === 'valid') {
      analytics.track({
        action: 'VerifyUrl',
        category: 'safeLinkConfirmationDialog',
        extraInfo: { actionType: 'click', name: 'valid', url: href }
      });
      return LinkWithConfirmationDialogComponentState.SAFE;
    } else if (data?.verifyUrl === 'invalid') {
      analytics.track({
        action: 'VerifyUrl',
        category: 'safeLinkConfirmationDialog',
        extraInfo: { actionType: 'click', name: 'invalid', url: href }
      });
      return LinkWithConfirmationDialogComponentState.UNSAFE;
    } else {
      return LinkWithConfirmationDialogComponentState.LOADING;
    }
  }, [data?.verifyUrl, analytics, href]);

  const onCancel = useEventCallback(() => {
    onClose();
    analytics.track({
      action: 'ButtonInteracted',
      category: 'safeLinkConfirmationDialog',
      extraInfo: { actionType: 'click', name: 'cancel', url: href }
    });
  });

  const onProceed = useEventCallback(() => {
    onClose();
    analytics.track({
      action: 'ButtonInteracted',
      category: 'safeLinkConfirmationDialog',
      extraInfo: { actionType: 'click', name: 'proceed', url: href }
    });
    withWindow(global => global.open(guestLinkUseRedirectService ? formatRedirectURL({ url: href }) : href, target, 'noreferrer'));
  });

  useEffect(() => {
    if (isOpen && !href) {
      withWindow(global => {
        addAction('verifyUrl', { context: 'LinkConfirmationDialog', placeUrl: global.location.href });
        onClose();
      });
      return;
    }
    if (isOpen) {
      verifyUrl({ variables: { url: href } });
    }
  }, [href, isOpen, onClose, verifyUrl]);

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={onClose}
      title={componentState === 'safe' ? translations.safeLinkTitle() : componentState === 'unsafe' ? translations.unsafeLinkTitle() : ''}
      renderFooter={() =>
        componentState === 'safe' ? (
          <>
            <StyledSafeLinkCancelButton variant="ghost" onClick={onCancel} disabled={loading}>
              {translations.cancel()}
            </StyledSafeLinkCancelButton>
            <StyledSafeLinkProceedButton role="button" name="proceed" onClick={onProceed} disabled={loading}>
              {translations.proceed()}
            </StyledSafeLinkProceedButton>
          </>
        ) : componentState === 'unsafe' ? (
          <StyledSafeLinkCloseButton onClick={onCancel} disabled={loading}>
            {translations.close()}
          </StyledSafeLinkCloseButton>
        ) : (
          ''
        )
      }
    >
      {componentState === 'loading' && (
        <Flex marginTop={5} justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {componentState === 'unsafe' && (
        <>
          <TextV2 wordBreak="break-word">{translations.unsafeLinkMessage()}</TextV2>
          <StyledExternalLinkLabel>{translations.externalLinkLabel()}</StyledExternalLinkLabel>
          <TextV2 marginTop={1} wordBreak="break-all" typographyVariant="body2">
            {href}
          </TextV2>
        </>
      )}
      {componentState === 'safe' && (
        <>
          <TextV2>{translations.safeLinkMessage()}</TextV2>
          <StyledExternalLinkLabel>{translations.externalLinkLabel()}</StyledExternalLinkLabel>
          <TextV2 marginTop={1} wordBreak="break-all" typographyVariant="body2">
            {href}
          </TextV2>
        </>
      )}
    </ResponsiveDialog>
  );
};
