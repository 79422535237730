import React, { FC, useEffect, useState } from 'react';
import { ButtonV2, Flex, TextV2, useDisclosure } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useNativeState } from '@shared/core/native';
import { isInIframe } from '@shared/utils/isInIframe';
import { Close } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { ReactComponent as JoyIconMobile } from './icon-joy-mobile.svg';
import { SmartAppBannerTelemetryProvider, useSmartAppBannerTelemetry } from './SmartAppBanner.telemetry';
import { useSmartAppBannerProvider } from './SmartAppBannerProvider';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useEventUserRole } from '../AuthProvider';

const isIframed = isInIframe();

const appLink = 'withjoy://';
const appStoreLink = 'https://apps.apple.com/us/app/joy-wedding-app-website/id994411720';
const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.withjoy.joy';

type SmartAppBannerProps = {
  telemetryCategory: string;
  showBannerDescription?: boolean;
};

export const SmartAppBannerContent: FC<SmartAppBannerProps> = props => {
  const { telemetryCategory, showBannerDescription } = props;
  const [isLoading, setIsLoading] = useState(false);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });
  const { isNative } = useNativeState();

  const telemetry = useSmartAppBannerTelemetry();

  const { t } = useTranslation('joykit');
  const smartAppBannerTrans = t('smartAppBanner');

  const { isBannerVisible, calculateBannerHeight, updateBannerVisibility } = useSmartAppBannerProvider();
  const { eventHandle } = useEventInfo();

  const isSmartAppAutoOpenEnabled = useFeatureValue('smartAppAutoOpenEnabled').value === 'on';

  useEffect(() => {
    if (isBannerVisible && isMobile && !isNative && !isIframed) {
      onOpen();
    }
  }, [isBannerVisible, isMobile, isNative, onOpen]);

  useEffect(() => {
    if (!isMobile) {
      onClose();
    }
  }, [isMobile, onClose]);

  useEffect(() => {
    calculateBannerHeight();
  }, [isOpen, calculateBannerHeight]);

  const handleClose = useEventCallback(() => {
    telemetry.stayOnWebClicked(telemetryCategory);
    updateBannerVisibility(false);
    onClose();
  });

  const handleNavigateToApp = useEventCallback(() => {
    setIsLoading(true);
    const isAndroid = /android/i.test(navigator.userAgent);
    telemetry.weHaveAnAppClicked(isAndroid ? 'GooglePlay' : 'AppStore', telemetryCategory);
    updateBannerVisibility(false);
    const storeLink = isAndroid ? googlePlayLink : appStoreLink;
    if (isSmartAppAutoOpenEnabled) {
      window.location.href = appLink;

      setTimeout(function () {
        // if the app is opened, the document won't be focused
        // so if app is not installed, the document will be focused
        if (document.hasFocus()) {
          window.location.href = storeLink;
        }
        setIsLoading(false);
        onClose();
      }, 2000);
    } else {
      window.location.href = storeLink;
      setIsLoading(false);
      onClose();
    }
  });

  return (
    <Flex
      id="smart-app-banner"
      display={isOpen ? 'flex' : 'none'}
      position="fixed"
      zIndex={1300}
      top={0}
      paddingY={4}
      paddingLeft={4}
      paddingRight={6}
      gap={4}
      alignItems="center"
      backgroundColor="mono1"
      borderBottom="1px solid"
      borderColor="mono3"
      width="100%"
      justifyContent="center"
    >
      <Flex width={8} height={8} alignItems="center" justifyContent="center" onClick={handleClose}>
        <Close size={16} />
      </Flex>
      <Flex>
        <JoyIconMobile />
      </Flex>
      <Flex flexDirection="column" gap={0}>
        <TextV2 typographyVariant="hed2" color="mono14">
          {smartAppBannerTrans.title()}
        </TextV2>
        {showBannerDescription && (
          <TextV2 typographyVariant="body1" color="mono12">
            {smartAppBannerTrans.description({ eventHandle: eventHandle || '' })}
          </TextV2>
        )}
      </Flex>
      <Flex alignItems="center" height="100%" minHeight={pxToRem(56)} onClick={handleNavigateToApp}>
        <ButtonV2
          variant="link"
          typographyVariant="body1"
          color="mon14"
          textDecoration="underline"
          css={{ 'text-underline-position': 'from-font' }}
          onClick={handleNavigateToApp}
          loading={isLoading}
          disabled={isLoading}
        >
          {smartAppBannerTrans.view()}
        </ButtonV2>
      </Flex>
    </Flex>
  );
};

interface BannerPayload {
  showForGuest?: boolean;
  showForAdmin?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isBannerPayloadValid = (payload: any): payload is BannerPayload => {
  return payload && ('showForAdmin' in payload || 'showForGuest' in payload);
};

export const SmartAppBanner: FC<SmartAppBannerProps> = props => {
  const { isAdmin } = useEventUserRole();

  const { value, payload } = useFeatureValue('smartAppBannerExperiment');
  let isSmartAppBannerExperimentEnabled = false;
  let showBannerDescription = false;
  if (isBannerPayloadValid(payload)) {
    const bannerPayload = payload as BannerPayload;
    // We want to filter who sees this based on if they are an event admin or guest and the visibility settings
    // Admins will only see banner when showForAdmin is true and never see the description
    // Guests will only see banner when showForGuest is true and always see the description and they fail the isAdmin check
    isSmartAppBannerExperimentEnabled = value === 'treatment' && ((isAdmin && bannerPayload?.showForAdmin === true) || (!isAdmin && bannerPayload?.showForGuest === true));
    // We only want to show the description for guests and hide from admins
    showBannerDescription = !isAdmin && bannerPayload?.showForGuest === true;
  }
  return isSmartAppBannerExperimentEnabled ? (
    <SmartAppBannerTelemetryProvider>
      <SmartAppBannerContent {...props} showBannerDescription={showBannerDescription} />
    </SmartAppBannerTelemetryProvider>
  ) : (
    <></>
  );
};

SmartAppBanner.displayName = 'SmartAppBanner';
