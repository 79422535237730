import { ColumnsContextType } from '../../Columns.context';
import { styled } from '@withjoy/joykit';
import { Box } from '../../../Box';
import type { ResponsivePaddingProps } from '@withjoy/joykit/styled-system';
import { createShouldForwardProp } from '@withjoy/joykit/utils';
import { getColumnLayout } from './Column.utils';
import { ColumnV2Props } from './Column';

const shouldForwardProp = createShouldForwardProp<ColumnV2Props>(['span', 'offset']);

export type ColumnInternalProps = ColumnV2Props & Pick<ResponsivePaddingProps, 'paddingX' | 'padding'> & ColumnsContextType;

export const StyledColumnWrapper = styled(Box).withConfig<ColumnInternalProps>({
  shouldForwardProp
})`
  /* Baseline */
  display: block;

  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;

  ${getColumnLayout};
  ${({ theme }) => {
    return {
      // By default, the grid has only one column on viewports smaller than the first breakpoint (<480);
      [theme.mediaQueries.between(0, { viewport: 'sm' })]: {
        // maxWidth: '100%', // 4 out 4 = 100% width
        flexBasis: '100%',
        marginLeft: 0
      }
    };
  }}
`;
