import { Flex, Box, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { useTranslation } from '@shared/core';
import { StarFilled } from '@withjoy/joykit/icons';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { body1ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

export const AccommodationTileSubtitle: React.FC<{
  starRating: Maybe<number>;
  venueLatitude: Maybe<number>;
  venueLongitude: Maybe<number>;
  accommodationLatitude: Maybe<number>;
  accommodationLongitude: Maybe<number>;
}> = ({ starRating, venueLatitude, venueLongitude, accommodationLatitude, accommodationLongitude }) => {
  const haveDistanceToVenue = venueLatitude && venueLongitude && accommodationLatitude && accommodationLongitude;
  const milesToVenue = distanceInMiles(venueLatitude || 0, venueLongitude || 0, accommodationLatitude || 0, accommodationLongitude || 0).toFixed(1);
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  if (!haveDistanceToVenue && !starRating) return null;

  return (
    <Flex flexDirection={'row'} paddingTop={1} justifyContent={'space-between'}>
      {haveDistanceToVenue && (
        <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2 typographyVariant={'body1'} {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} textDecoration={'none !important'}>
            {haveDistanceToVenue ? accTrans.milesFromVenue({ miles: milesToVenue }) : ''}
          </TextV2>
        </GuestSiteTypographyOverride>
      )}
      {starRating && (
        <Flex alignItems="center">
          <Box paddingRight={1}>
            <StarFilled size={16} />
          </Box>
          <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
            <TextV2 typographyVariant={'body1'} alignSelf={'center'} {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}>
              {starRating}
            </TextV2>
          </GuestSiteTypographyOverride>
        </Flex>
      )}
    </Flex>
  );
};
