import React from 'react';
import { Category, EventPageFragment, EventPageType } from '@graphql/generated';
import { Box, BoxProps, ColumnsV2, Flex, TextV2 } from '@withjoy/joykit';
import { LayoutBrannanProps } from '../../LayoutBrannan';
import { pageColumnProps, pageWidgets, PreparedPages } from './Body.constants';
import { shouldRenderGraphicAccent } from '../../../layout.utils';
import { PrettyLink } from '@apps/guest/packages/layout-engine/components/PrettyLink';
import GraphicAccent from '@apps/guest/packages/layout-engine/components/GraphicAccent';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { InlineEditor } from '@shared/components';
import { useTranslation } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { EditFontData, sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { withWindow } from '@shared/utils/withWindow';
import { routePaths, RoutesNames } from '@apps/guest/utils/RouteHelper.utils';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body4ToSubHeadingOverride, display1ToPageTitleOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { SCREENS } from '@apps/admin/routes/WebsiteDesignerV2/routes/Fonts/components/FontPack/FontPack.constants';

export interface BodyProps extends Readonly<{ page: Maybe<EventPageFragment>; photo?: React.ReactNode } & LayoutBrannanProps & BoxProps> {}

const Body: React.FC<BodyProps> = ({ page, event, eventHandle, photo, ...restProps }) => {
  const { isNativeAccommodations, isAccommodations } = useGuestSiteState();

  const { t } = useTranslation('joykit');
  const inlineEditorTransFn = t('inlineEditor');

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  const handleEditTextClick = useEventCallback((textContent: string, label: string, pageId: string) => {
    // send the message to the parent window to open the text editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editTextArea', inlineEditData: { textContent, label, pageId, validationConstraints: { maxLength: 140 } } }
    });
  });

  const onPageEditClick = useEventCallback(() => {
    const path = page?.type === EventPageType.custom && page.pageSlug === 'accommodations' ? 'accommodations' : page?.type;

    withWindow(window => {
      if (path && routePaths.hasOwnProperty(path)) {
        window.open(`/${eventHandle}/edit/${routePaths[path as RoutesNames].goToPath(page?.pageSlug || '')}`, '_blank');
      }
    });
  });

  const onVisibilityClick = useEventCallback(() => {
    if (page) {
      // send the message to the parent window to open the text editor
      sendMessageToParentWindow({
        action: 'inlineEditingInteraction',
        source: 'joyPreview',
        value: {
          action: 'editPageSettings',
          inlineEditData: { pageId: page.id, pageType: page.type, pageTitle: page.pageTitle, pageVisibility: page.visibility, isPageContainer: page.isPageContainer }
        }
      });
    }
  });

  const handleEditFontClick = useEventCallback((inlineEditData: EditFontData) => {
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: { action: 'editFont', inlineEditData }
    });
  });

  if (!page) {
    return null;
  }
  const { type, pageTitle, subTitle, pageSlug, id } = page;
  const graphicAccent = event.eventDesign?.accent;

  const PageWidget = pageWidgets[type as PreparedPages];
  const isWelcomePage = type === EventPageType.welcome;
  const isSchedulePage = type === EventPageType.schedule;
  const isStoryPage = type === EventPageType.story;
  const isTravelPage = type === EventPageType.travel;
  const isCustomPage = type === EventPageType.custom;
  const isAppPage = type === EventPageType.app;

  const pageContentMarkup = PageWidget && (
    <ColumnsV2.Column
      id={pageSlug}
      {...(isCustomPage && !isAccommodations ? { span: [10, 10, 8] } : { ...pageColumnProps[type as PreparedPages] })}
      marginTop={isWelcomePage || isSchedulePage ? undefined : isCustomPage ? 0 : [10, null, 0]}
      {...(isTravelPage && { style: { display: 'flex', justifyContent: 'center' } })}
    >
      <PageWidget isAccommodations={isAccommodations} event={event} eventHandle={eventHandle} pageId={id} pageSlug={pageSlug} pageTitle={pageTitle} subTitle={subTitle} />
    </ColumnsV2.Column>
  );

  const graphicAccentMarkup = shouldRenderGraphicAccent(graphicAccent) && (
    <GraphicAccent id="graphic-accent" data-testid={'body-accent'} accentId={graphicAccent!} isInlineEditable={true} pageName={page.type} pageSlug={page.pageSlug} />
  );

  return (
    <ColumnsV2 {...restProps}>
      <>
        <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block' } : {}} span={[12, 10]} offset={[0, 1]}>
          {photo}
          <Flex
            flexDirection={'column'}
            alignItems={'center'}
            marginTop={9}
            marginBottom={shouldRenderGraphicAccent(graphicAccent) ? 8 : 0}
            marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
            maxWidth={isCustomPage ? '580px' : null}
          >
            {graphicAccentMarkup}
          </Flex>
        </ColumnsV2.Column>
        <InlineEditor
          actionData={{
            edit: onPageEditClick,
            visibility: {
              function: onVisibilityClick,
              visibility: page.visibility
            },
            font: () => handleEditFontClick({ screen: SCREENS.categoryList })
          }}
          inset={isMobile ? { x: 8, y: -16 } : { x: 4, y: -16 }}
          wrapperType="actionOutside"
          componentName="page"
          elementLabel={page.pageTitle}
          pageName={page.type}
          pageSlug={page.pageSlug}
          stickyOnScroll={true}
          isEligibleForInlineEditing={!isWelcomePage && !isAppPage && !isStoryPage}
          wrapperCSS={{ width: '100%' }}
        >
          <ColumnsV2.Column style={isCustomPage ? { display: isNativeAccommodations && !subTitle ? 'none' : 'block', zIndex: 1 } : { zIndex: 1 }} span={[12, 10]} offset={[0, 1]}>
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              marginBottom={isStoryPage ? 0 : isAccommodations ? { _: 6, sm2: 9 } : 9}
              marginX={isCustomPage ? [6, 'auto'] : [6, null, 0]}
              maxWidth={isCustomPage ? '580px' : null}
            >
              {isWelcomePage && pageContentMarkup}
              {!isWelcomePage && !isStoryPage && !isNativeAccommodations && (
                <GuestSiteTypographyOverride override={display1ToPageTitleOverride}>
                  <TextV2
                    data-testid={'body-pagetitle'}
                    typographyVariant={'display1'}
                    fontSize={scaleFontSize('2rem', Category.PAGE_TITLE)}
                    textAlign="center"
                    wordBreak="break-word"
                  >
                    {pageTitle}
                  </TextV2>
                </GuestSiteTypographyOverride>
              )}
              {!isStoryPage &&
                subTitle &&
                (isWelcomePage ? (
                  <Box marginTop={9}>
                    <InlineEditor
                      elementLabel={inlineEditorTransFn.greetings()}
                      actionData={{
                        edit: () => handleEditTextClick(subTitle, inlineEditorTransFn.greetings(), id),
                        font: () => handleEditFontClick({ category: Category.SUB_HEADING })
                      }}
                      wrapperCSS={{
                        zIndex: 1200
                      }}
                      componentName="greetings"
                      pageName={type}
                      pageSlug={pageSlug}
                    >
                      <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
                        <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} maxWidth={500} textAlign="center">
                          <PrettyLink source={page.pageSlug}>{subTitle}</PrettyLink>
                        </TextV2>
                      </GuestSiteTypographyOverride>
                    </InlineEditor>
                  </Box>
                ) : (
                  <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
                    <TextV2 data-testid="body-subtitle" tagName="div" typographyVariant={'body4'} marginTop={9} maxWidth={500} textAlign="center">
                      <PrettyLink source={page.pageSlug} {...(pageSlug === 'accommodations' ? { allowedTypes: ['EMAIL'] } : {})}>
                        {subTitle}
                      </PrettyLink>
                    </TextV2>
                  </GuestSiteTypographyOverride>
                ))}
            </Flex>
          </ColumnsV2.Column>

          {!isWelcomePage && (
            <Box width={isMobile && !isAccommodations ? 'unset' : '100%'} marginX={[isAccommodations ? 0 : 7, null, 0]} display={'flex'} justifyContent={'center'}>
              {pageContentMarkup}
            </Box>
          )}
        </InlineEditor>
      </>
    </ColumnsV2>
  );
};

Body.displayName = 'Body';

export { Body };
