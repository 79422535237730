import React from 'react';
import { useAuth } from '@shared/components/AuthProvider';
import { Redirect, Route, RouteProps } from '@react-router';

export const SuperAdminRoute = (routeProps: RouteProps) => {
  const { hasInitializedOnce, currentUser } = useAuth();
  const path = routeProps.path;

  if (!__DEV__) {
    if (!hasInitializedOnce) {
      return null;
    }

    if (!currentUser.profile?.superAdmin) {
      return <Redirect to={{ pathname: `social-login`, search: `prev=${path}` }} />;
    }
  }

  return <Route {...routeProps} />;
};
