import React from 'react';
import { TravelCurrencyCode, useAccommodationPagePricingForItemNuiteeQuery, useAccommodationPagePricingForItemZHQuery } from '@graphql/generated';
import { AccommodationCombined } from '../../Accommodations/Accommodation.types';
import { useEventInfo } from '@shared/utils/eventInfo';
import * as DateFNS from 'date-fns';
import { useTranslation } from '@shared/core';
import { Box, BoxProps, Divider, Flex, JoyKitThemeProvider, TooltipV2 } from '@withjoy/joykit';
import { Skeleton, SkeletonGroup } from '@shared/components/Skeleton';
import { Info } from '@withjoy/joykit/icons';

const translatedPrice = (priceInMinorUnits: number, currency: string) =>
  Math.floor(priceInMinorUnits / 100 / 2).toLocaleString('en-US', {
    style: 'currency',
    maximumSignificantDigits: 3,
    currency
  });

// Joy places display pricing differently from room blocks
export const JoyPlacePricing = ({ accommodation, showNuiteePrice, ...boxProps }: { accommodation: AccommodationCombined; showNuiteePrice: boolean } & BoxProps) => {
  const { eventInfo } = useEventInfo();
  const eventDisplayName = eventInfo?.eventDisplayName;
  const dateMs = eventInfo?.finalizedDate?.milliseconds;
  const checkInDate = dateMs ? DateFNS.format(DateFNS.subDays(dateMs, 1), 'yyyy-MM-dd') : null;
  const checkOutDate = dateMs ? DateFNS.format(DateFNS.addDays(dateMs, 1), 'yyyy-MM-dd') : null;
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  // const showNuiteePrice = false;
  // TODO remove this JUNK after the experiment is over ("should we show Nuitee price?")
  const payload = {
    currencyCode: TravelCurrencyCode.USD,
    dates: {
      checkIn: {
        // validated with skip
        high: checkInDate!,
        low: checkInDate!
      },
      // validated with skip
      checkOut: {
        high: checkOutDate!,
        low: checkOutDate!
      }
    },
    occupancies: {
      occupancies: [
        {
          adults: 2,
          childrenAges: []
        }
      ],
      unitCount: 1
    },
    propertyIdentifiers: [
      {
        joyPlaceId: accommodation.joyPlaceId || null
      }
    ]
  };
  const { data: dataNuitee, loading: loadingNuitee } = useAccommodationPagePricingForItemNuiteeQuery({
    batchMode: 'slow',
    variables: {
      payload: payload
    },
    skip: !checkInDate || !checkOutDate || !showNuiteePrice
  });
  const { data: dataZH, loading: loadingZH } = useAccommodationPagePricingForItemZHQuery({
    batchMode: 'slow',
    variables: {
      payload: payload
    },
    skip: !checkInDate || !checkOutDate || showNuiteePrice
  });
  const someOffers = dataZH?.travelOffersForAccommodationProperties.someOffers;
  const lowestBaseOffer = someOffers && [...someOffers].sort((a, b) => a.baseAmount.valueInMinorUnits - b.baseAmount.valueInMinorUnits)[0];
  const priceZH = lowestBaseOffer?.baseAmount && translatedPrice(lowestBaseOffer.baseAmount.valueInMinorUnits, lowestBaseOffer.baseAmount.currency);
  const totalAmountNuitee = dataNuitee && dataNuitee?.travelPricingsForAccommodationByPropertyId.pricings[0].pricingData?.totalAmount;
  const priceNuitee = totalAmountNuitee && translatedPrice(totalAmountNuitee.valueInMinorUnits, totalAmountNuitee.currency);

  const price = priceNuitee || priceZH;
  const loading = loadingZH || loadingNuitee;

  return loading || price ? (
    // reset typography
    <JoyKitThemeProvider>
      <Box {...boxProps}>
        <Divider marginBottom={5} />
        <SkeletonGroup
          isReady={!loading}
          placeholder={
            <Flex gap={2} justifyContent="space-between" alignItems={'flex-start'}>
              <Skeleton
                placeholder={
                  <Box width={'8rem'} typographyVariant={'body1'}>
                    &nbsp;
                  </Box>
                }
              />

              <Box>
                <Box display={'flex'} typographyVariant={'body1'} alignItems={'center'} justifyContent={'flex-end'} marginBottom={1}>
                  <Skeleton
                    placeholder={
                      <Box width={'3.5rem'} typographyVariant={'body1'}>
                        &nbsp;
                      </Box>
                    }
                  />
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                  <Skeleton
                    placeholder={
                      <Box width={'3.5rem'} typographyVariant={'hed2'}>
                        &nbsp;
                      </Box>
                    }
                  />
                </Box>
              </Box>
            </Flex>
          }
        >
          {price && (
            <Flex gap={2} justifyContent="space-between" alignItems={'flex-start'}>
              <Box typographyVariant={'body1'} color={'inputPlaceholderDisabled'}>
                {accTrans.roomsStartingFrom()}
              </Box>
              <Box>
                <Box display={'flex'} typographyVariant={'body1'} alignItems={'center'} justifyContent={'flex-end'} marginBottom={1}>
                  Exclusive Rate
                  {eventDisplayName ? (
                    <Box marginLeft={2}>
                      <TooltipV2 content={accTrans.exclusiveRateInfo({ name: eventDisplayName })} onMouseEnterDelay={500} placement={'topEnd'}>
                        <Info size={'sm'} color={'mono10'} title={undefined} />
                      </TooltipV2>
                    </Box>
                  ) : null}
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                  <Box typographyVariant={'hed2'} textAlign={'right'}>
                    {accTrans.pricePerNight({ price })}
                  </Box>
                </Box>
              </Box>
            </Flex>
          )}
        </SkeletonGroup>
      </Box>
    </JoyKitThemeProvider>
  ) : null;
};
