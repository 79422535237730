import React, { useCallback } from 'react';
import { StyledFooterWrapper, ParticleJoyLogoWithPadding, getLinkStyles } from './Footer.styles';
import { useLayoutEngineTranslations } from './Footer.i18n';
import { JOY_ABOUT_URL, JOY_HELP_URL } from '@shared/utils/constants';
import { LinkV2, useTheme } from '@withjoy/joykit';
import { useColorPaletteProvider } from '../../layouts/ColorPaletteProvider/ColorPaletteProvider';
import { getHoverColor, getFooterLinkColor } from '@apps/guest/packages/layout-engine/layouts/layout.utils';

const ITEM_NAMES = {
  HELP: 'Help',
  ABOUT: 'About',
  JOY: 'Joy'
};

const Footer: React.FC<{ onButtonClicked?: (item: string) => void }> = props => {
  const { help, about } = useLayoutEngineTranslations();
  const { colors } = useTheme();
  const { onButtonClicked, ...restProps } = props;
  const { backgroundColor } = useColorPaletteProvider();
  const linkColor = backgroundColor ? getFooterLinkColor(backgroundColor) : colors.mono10;
  const linkHoverColor = backgroundColor ? getHoverColor(linkColor) : colors.mono14;

  const handleButtonClicked = useCallback(
    (item: string) => {
      onButtonClicked && onButtonClicked(item);
    },
    [onButtonClicked]
  );

  return (
    <StyledFooterWrapper {...restProps}>
      <LinkV2 isExternal={true} onClick={() => handleButtonClicked(ITEM_NAMES.HELP)} {...getLinkStyles(linkColor, linkHoverColor)} href={JOY_HELP_URL}>
        {help}
      </LinkV2>
      <ParticleJoyLogoWithPadding onClick={() => handleButtonClicked(ITEM_NAMES.JOY)} id="footer2brand-desktop" logoColor={linkColor} href={'/'} />
      <LinkV2 isExternal={true} onClick={() => handleButtonClicked(ITEM_NAMES.ABOUT)} {...getLinkStyles(linkColor, linkHoverColor)} href={JOY_ABOUT_URL}>
        {about}
      </LinkV2>
    </StyledFooterWrapper>
  );
};

Footer.displayName = 'Footer';

export { Footer };
