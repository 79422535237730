import React from 'react';
import { GuestTravelMapComponentFragment } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { TravelMap as TravelMapV1 } from './TravelMap';
import { TravelMapV2 } from './TravelMapV2';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

interface TravelMapProps {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
  travelMapComponentData: GuestTravelMapComponentFragment;
}

export const TravelMap: React.FC<TravelMapProps> = props => {
  const { value, loading } = useFeatureValue('accommodationsGuestV2Enabled');
  const accommodationsGuestV2Enabled = value === 'on';
  if (loading) {
    return <JoyLogoLoader loaderKey="accommodations-guest-v2" />;
  }
  return accommodationsGuestV2Enabled ? <TravelMapV2 {...props} /> : <TravelMapV1 {...props} />;
};
