/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Import',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fill="currentColor"
            d="M10.389 6.891l-2-2a.55.55 0 0 0-.778 0l-2 2a.55.55 0 0 0 .778.778L7.45 6.608v7.561a.55.55 0 1 0 1.1 0V6.608l1.061 1.061a.55.55 0 0 0 .778-.778zM2.994 9.278a1.45 1.45 0 0 0 1.45 1.45h.778a.55.55 0 1 1 0 1.1h-.778a2.55 2.55 0 0 1-2.55-2.55V3.833a2.55 2.55 0 0 1 2.55-2.55h7.111a2.55 2.55 0 0 1 2.55 2.55v5.444a2.55 2.55 0 0 1-2.55 2.55h-.778a.55.55 0 0 1 0-1.1h.778a1.45 1.45 0 0 0 1.45-1.45V3.833a1.45 1.45 0 0 0-1.45-1.45H4.444a1.45 1.45 0 0 0-1.45 1.45v5.444z"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fill="currentColor"
            d="M15.283 10.259l-2.858-2.858a.6.6 0 0 0-.849 0l-2.858 2.858a.6.6 0 0 0 .849.848L11.4 9.274v11.255a.6.6 0 0 0 1.2 0V9.274l1.834 1.834a.6.6 0 0 0 .849-.848zM4.66 14.97a1.4 1.4 0 0 0 1.4 1.4h1.97a.6.6 0 0 1 0 1.2H6.06a2.6 2.6 0 0 1-2.6-2.6V5.473a2.6 2.6 0 0 1 2.6-2.6h11.879a2.6 2.6 0 0 1 2.6 2.6v9.497a2.6 2.6 0 0 1-2.6 2.6h-1.97a.6.6 0 0 1 0-1.2h1.97a1.4 1.4 0 0 0 1.4-1.4V5.473a1.4 1.4 0 0 0-1.4-1.4H6.06a1.4 1.4 0 0 0-1.4 1.4v9.497z"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fill="currentColor"
            d="M15.4 25.699a.6.6 0 1 0 1.2 0V12.748l2.476 2.476a.6.6 0 0 0 .848-.848l-3.5-3.5a.6.6 0 0 0-.848 0l-3.5 3.5a.6.6 0 0 0 .849.848l2.476-2.476v12.951zm6.6-4a2.4 2.4 0 0 0 2.4-2.4v-10a2.4 2.4 0 0 0-2.4-2.4H10a2.4 2.4 0 0 0-2.4 2.4v10a2.4 2.4 0 0 0 2.4 2.4h1.5a.6.6 0 0 1 0 1.2H10a3.6 3.6 0 0 1-3.6-3.6v-10a3.6 3.6 0 0 1 3.6-3.6h12a3.6 3.6 0 0 1 3.6 3.6v10a3.6 3.6 0 0 1-3.6 3.6h-1.5a.6.6 0 0 1 0-1.2H22z"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
