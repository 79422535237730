import React from 'react';

export interface VirtualScrollWrapperContextValue {
  virtualScrollWrapperElement: HTMLElement | undefined;
}

export const VirtualScrollWrapperContext = React.createContext<VirtualScrollWrapperContextValue>({
  virtualScrollWrapperElement: undefined
});

export function useVirtualScrollWrapperContext() {
  const context = React.useContext(VirtualScrollWrapperContext);
  if (!context) {
    throw Error('useVirtualScrollWrapperContext should be used inside a VirtualScrollWrapper component');
  }
  return context;
}
