import { useFeatureValue } from '@shared/core/featureFlags';
import { useMemo } from 'react';
import { isPromoValid, ProductInfo, PromotionConfig } from './usePromotionConfig';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';

export type Sale = PromotionConfig;

export const isValidSalesPayload = (payload: unknown): payload is Sale[] => {
  if (!payload) {
    return false;
  }

  if (!Array.isArray(payload)) {
    return false;
  }

  if (payload.length <= 0) {
    return false;
  }

  if (payload.some(item => !isValidSale(item))) {
    return false;
  }

  return true;
};

const isValidSale = (payload: unknown): payload is Sale => {
  if (!payload) {
    return false;
  }

  if (typeof payload !== 'object') {
    return false;
  }

  if (!Object.hasOwn(payload, 'code')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'categoryList')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'discountPercentage')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'active')) {
    return false;
  }

  return true;
};

export const useSalesConfig = (category: StationeryTemplateCategoryEnum, product?: ProductInfo) => {
  const { value, payload } = useFeatureValue('printSaleCodeV2');

  const sales: Sale[] | null = isValidSalesPayload(payload) ? payload : null;

  const currentSale = useMemo(() => {
    // TODO: Add occasion checking once we add further support for occasions.
    // Currently hardcoding occasion as we do not have a standardized way of fetching it.
    // And in the card customzier, it is not even tracked.
    const defaultProduct = { occasion: 'wedding', category };
    return sales?.find(sale => isPromoValid(sale, product ?? defaultProduct));
  }, [category, product, sales]);

  return {
    isEnabled: value !== undefined && currentSale !== undefined,
    sales,
    /**
     * The promotion config for the currently stored promo code.
     */
    currentSale: currentSale
  };
};
