import { createTelemetryObject, createTelemetry } from '@shared/core/analytics';
import { AccommodationCombinedType } from './components/TravelMap/components/Accommodations/Accommodation.types';

const category = 'wedding';
const pagePrefix = 'guest';
const action = 'GuestSiteAccommodationInteracted';

interface hotelExtraInfo {
  hotelName?: string;
  isHotelPreferred?: boolean;
  hotelPrice?: number;
  hotelStrikeThroughPrice?: number;
  clickedFrom?: 'tileFavorite' | 'dialog';
  type?: AccommodationCombinedType;
}

const actionProps = {
  action,
  category,
  pagePrefix
};

const telemObject = createTelemetryObject({
  pages: {
    customPage: (page: string) => ({
      category,
      pagePrefix,
      page
    })
  },
  actions: {
    shareRoomClicked: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'shareRoomClicked',
        ...args
      }
    }),
    hotelPinSelected: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'hotelPinSelected',
        ...args
      }
    }),
    hotelPinCardSelected: (page: string, args: hotelExtraInfo) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'hotelPinCardSelected',
        ...args
      }
    }),
    accommodationsMapClicked: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'accommodationsMapClicked'
      }
    }),
    mapExpanded: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'mapExpanded'
      }
    }),
    mapContracted: (page: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        name: 'mapContracted'
      }
    }),
    onClickConciergeEmailLink: (page: string, link: string) => ({
      ...actionProps,
      page,
      extraInfo: {
        link,
        name: 'linkClicked',
        source: 'AccommodationsNote'
      }
    }),
    leadGenBannerCtaClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'getStarted',
      extraInfo: {
        name: 'leadGenBanner'
      }
    }),
    leadGenDialogCtaClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'getStarted',
      extraInfo: {
        name: 'leadGenDialog'
      }
    }),
    leadGenDialogPromptedClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'selectRooms',
      extraInfo: {
        name: 'leadGenDialog_prompted'
      }
    }),
    leadGenDialogDismissClicked: () => ({
      category: 'GuestAccommodations',
      action: 'TravelButtonInteracted',
      label: 'Close',
      extraInfo: {
        name: 'leadGenDialog_dismiss'
      }
    }),
    openAccommodationsDialog: (page: string, args: hotelExtraInfo, label: 'viewPreview' | 'viewDetail') => ({
      ...actionProps,
      label,
      page,
      extraInfo: {
        name: label,
        ...args
      }
    }),
    viewHotelPDPPage: (hotel: { eventHandle: string; accommodationPlaceId: string; hotelName: string; venueName?: string; milesFromVenue?: string }) => ({
      category: 'EventAccommodations',
      action: 'TravelButtonInteracted',
      extraInfo: {
        name: 'ViewHotel',
        hotel
      }
    }),
    accommodationsLinkInteracted: (url: string, args: Object) => ({
      action: 'LinkInteracted',
      category: 'linkify',
      label: 'accommodations',
      extraInfo: {
        actionType: 'click',
        url: url,
        source: 'accommodations',
        type: 'url',
        ...args
      }
    })
  }
});

const { TelemetryProvider: customPageTelemetryProvider, useTelemetry: useCustomPageTelemetry } = createTelemetry(telemObject);

export { customPageTelemetryProvider, useCustomPageTelemetry };
