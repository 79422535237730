import React, { useMemo } from 'react';
import { Provider } from '@apps/card/components/PrintJobDetails/PrintJobDetailsTelemetryProxyContext';
import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { getConciergeCategoryFromDesignSetName } from '@apps/card/routes/Concierge/Concierge.utils';

interface PrintJobDetailsTelemetryProxyProviderProps {
  order: StationeryPrintOrderDetailsFragment;
  viewVariant: 'overview' | 'detailed';
}

/**
 * `PrintJobDetails` component can be rendered within the card app or service center,
 *  and we only want to track telemetry in the card app. So this provider acts as a middleman
 *  to provide telemetry functionality without having to pass it down through props.
 */
export const PrintJobDetailsTelemetryProxyProvider: React.FC<PrintJobDetailsTelemetryProxyProviderProps> = ({ children, order, viewVariant }) => {
  const {
    goToOrderDetailsClick,
    contactSupportButtonInteracted,
    trackOrderButtonInteracted,
    reorderButtonInteracted,
    visitCourierPageToResolveIssueButtonInteracted,
    editOrderCtaInteracted
  } = useCardTelemetry();
  const handleOnContactSupportClick = useEventCallback(() => {
    contactSupportButtonInteracted({
      orderId: order.id,
      orderNumber: order.orderNumber,
      orderStatus: order.currentStatus,
      templateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? ''
    });
  });
  const handleOnTrackOrderClick = useEventCallback(() => {
    trackOrderButtonInteracted({
      orderId: order.id,
      orderNumber: order.orderNumber,
      orderStatus: order.currentStatus,
      templateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? '',
      source: viewVariant === 'overview' ? 'orderHistory' : 'orderDetails'
    });
  });
  const handleOnReorderClick = useEventCallback(() => {
    reorderButtonInteracted({
      orderId: order.id,
      orderNumber: order.orderNumber,
      orderStatus: order.currentStatus,
      templateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? '',
      source: viewVariant === 'overview' ? 'orderHistory' : 'orderDetails',
      ownerId: order.ownerId,
      eventId: order.eventId || '',
      orderQuantity: order.quantity,
      orderPriceInMinorUnits: order.priceInMinorUnits,
      draftId: order.cardDraft?.id ?? ''
    });
  });

  const handleOnVisitCourierPageToResolveIssueClick = useEventCallback(() => {
    visitCourierPageToResolveIssueButtonInteracted({
      orderId: order.id,
      orderNumber: order.orderNumber,
      orderStatus: order.currentStatus,
      templateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? ''
    });
  });

  const handleOnGoToOrderDetailsClick = useEventCallback(() => {
    goToOrderDetailsClick({
      orderId: order.id,
      orderNumber: order.orderNumber,
      orderStatus: order.currentStatus,
      templateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? ''
    });
  });

  const handleOnEditOrderClick = useEventCallback(() => {
    editOrderCtaInteracted({
      source: viewVariant === 'overview' ? 'orderHistory' : 'orderDetails',
      orderNumber: order.orderNumber,
      stationeryTemplateCategory: order.cardDraft?.stationeryTemplate?.category ?? getConciergeCategoryFromDesignSetName(order.designSetName || ''),
      themeId: order.cardDraft?.stationeryTemplate?.themeId ?? ''
    });
  });

  const contextValue = useMemo(
    () => ({
      onContactSupportClick: handleOnContactSupportClick,
      onGoToOrderDetailsClick: handleOnGoToOrderDetailsClick,
      onTrackOrderClick: handleOnTrackOrderClick,
      onVisitCourierPageToResolveIssueClick: handleOnVisitCourierPageToResolveIssueClick,
      onReorderClick: handleOnReorderClick,
      onEditOrderClick: handleOnEditOrderClick
    }),
    [handleOnGoToOrderDetailsClick, handleOnContactSupportClick, handleOnTrackOrderClick, handleOnVisitCourierPageToResolveIssueClick, handleOnReorderClick, handleOnEditOrderClick]
  );

  return <Provider value={contextValue}>{children}</Provider>;
};
