import { ModalProps } from '@withjoy/joykit';

export const dialogOverridesDesktop: NonNullable<ModalProps['overrides']> = {
  Body: {
    props: {
      borderTop: '0px solid !important'
    }
  },
  Header: {
    props: {
      _first: {
        paddingTop: '40px',
        paddingBottom: '0px'
      }
    }
  },
  CloseButton: {
    props: {
      top: 2,
      paddingTop: 3
    }
  }
};

export const dialogOverridesMobile: NonNullable<ModalProps['overrides']> = {
  Body: {
    props: {
      borderTop: '0px solid !important'
    }
  },
  Header: {
    props: {
      _first: {
        paddingTop: '40px',
        paddingBottom: '0px'
      }
    }
  },
  CloseButton: {
    props: {
      top: 3,
      padding: 5
    }
  }
};
