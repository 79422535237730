import React, { useEffect, useRef } from 'react';
import { CanvasImageCropProps } from './CropAndRenderImage.types';

export const CropAndRenderImage: React.FC<CanvasImageCropProps> = ({ imageUrl, cropInfo }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const image = new Image();
    image.crossOrigin = 'anonymous'; // Allow cross-origin images
    image.src = imageUrl;

    image.onload = () => {
      const canvas = canvasRef.current;
      if (canvas) {
        const ctx = canvas.getContext('2d');
        if (ctx) {
          const { x, y, width, height } = cropInfo;

          let cropX = x;
          let cropY = y;
          let cropWidth = width;
          let cropHeight = height;

          // Compute crop dimensions in pixels if unit is 'percent'
          if (cropInfo.unit === 'percent') {
            cropX = (x / 100) * image.width;
            cropY = (y / 100) * image.height;
            cropWidth = (width / 100) * image.width;
            cropHeight = (height / 100) * image.height;
          }

          // Calculate canvas size for 100vw with aspect ratio preservation
          const canvasWidth = window.innerWidth;
          const aspectRatio = cropWidth / cropHeight;
          const canvasHeight = canvasWidth / aspectRatio;

          // Set canvas size
          canvas.width = canvasWidth;
          canvas.height = canvasHeight;

          ctx.imageSmoothingQuality = 'high';
          // Clear canvas and draw the scaled cropped image
          ctx.clearRect(0, 0, canvasWidth, canvasHeight);
          ctx.drawImage(
            image,
            cropX,
            cropY,
            cropWidth,
            cropHeight, // Source crop
            0,
            0,
            canvasWidth,
            canvasHeight // Destination canvas
          );
          ctx.restore();
        }
      }
    };
  }, [imageUrl, cropInfo]);

  return <canvas ref={canvasRef} style={{ width: '100vw', display: 'block' }} data-url={imageUrl} />;
};

CropAndRenderImage.displayName = 'CropAndRenderImage';
