import React, { useState, useEffect } from 'react';
import { Flex, TextV2, ButtonV2 } from '@withjoy/joykit';
import { useResolveStationeryPrintOrderIssueMutation } from '@graphql/generated';
import { ResponsiveDialog } from '@apps/card/components/ResponsiveDialog';

interface DialogProps {
  onClose: () => void;
  orderId: string;
  issueId: string | null;
}

const Dialog: React.FC<DialogProps> = ({ onClose, orderId, issueId }) => {
  const [showError, setShowError] = useState<boolean>(false);
  const [
    resolveStationeryPrintOrderIssue,
    { loading: isResolvingStationeryPrintOrderIssues, error: hasResolveStationeryPrintOrderIssuesError }
  ] = useResolveStationeryPrintOrderIssueMutation({
    refetchQueries: ['StationeryPrintOrderByOrderNumber']
  });
  const handleOnClose = () => {
    onClose();
    setShowError(false);
  };
  const handleOnResolveClick = async () => {
    try {
      if (orderId && issueId) {
        await resolveStationeryPrintOrderIssue({ variables: { stationeryPrintOrderId: orderId, issueId } });
        handleOnClose();
      }
    } catch {
      /* TODO: Add Telemetry */
    }
  };

  useEffect(() => setShowError(!!hasResolveStationeryPrintOrderIssuesError), [hasResolveStationeryPrintOrderIssuesError]);

  return (
    <ResponsiveDialog
      isOpen={!!issueId}
      onClose={handleOnClose}
      header={() => <TextV2 typographyVariant="hed3">Resolve the Issue</TextV2>}
      content={() => (
        <Flex flexDirection="column" gap={8}>
          <TextV2 fontSize="15px" lineHeight="21px">
            Are you sure you want to resolve this issue?
          </TextV2>
          {showError && (
            <TextV2 typographyVariant="label2" color="red">
              There was an error resolving the issue.
            </TextV2>
          )}
          <Flex gap={4} flexDirection={{ _: 'column-reverse', sm2: 'row' }} width="100%" justifyContent="space-between">
            <ButtonV2 intent="neutral" shape="rounded" variant="outline" onClick={handleOnClose}>
              Cancel
            </ButtonV2>
            <ButtonV2 intent="neutral" shape="rounded" onClick={handleOnResolveClick} loading={isResolvingStationeryPrintOrderIssues} disabled={!orderId || !issueId}>
              Resolve
            </ButtonV2>
          </Flex>
        </Flex>
      )}
    />
  );
};

export default Dialog;
