import urlParser from 'js-video-url-parser';

export const getVideoDataFromUrl = (url: string) => {
  const videoInfo = urlParser.parse(url);

  if (!videoInfo) {
    return;
  }

  const embedUrl = urlParser.create({
    videoInfo,
    format: 'embed'
  });
  const thumbnailUrl =
    videoInfo.provider === 'youtube'
      ? urlParser.create({
          videoInfo,
          params: {
            imageQuality: 'maxresdefault'
          },
          format: 'longImage'
        })
      : `https://vumbnail.com/${videoInfo.id}.jpg`;

  return {
    embedUrl: embedUrl,
    thumbnailUrl: thumbnailUrl
  };
};
