import React, { useMemo } from 'react';
import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import { createContext } from '@shared/utils/createContext';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface PremiumDigitalJobDetailsTelemetryProxyContext {
  onGoToOrderDetailsClick: () => void;

  /**
   * Shown on both order history + order details page
   */
  onTrackDeliveryClick: () => void;

  /**
   * Shown on both order history + order details page
   */
  onSendMoreOrResendClick: () => void;
  /**
   * Shown on both order history + order details page
   */
  onSelectRecipientsAndSendClick: () => void;
  /**
   * Shown on both order history + order details page
   */
  onOrderMatchingPaperCardClick: () => void;

  onContactSupportClick: () => void;
  onEditOrderClick: () => void;
}

const [Provider, useContext] = createContext<PremiumDigitalJobDetailsTelemetryProxyContext>({ name: 'PremiumDigitalJobDetailsTelemetryProxyContext', strict: false });

export const usePremiumDigitalJobDetailsTelemetryProxyContext = (): PremiumDigitalJobDetailsTelemetryProxyContext | undefined => {
  return useContext();
};

interface PremiumDigitalJobDetailsTelemetryProxyProviderProps {
  order: StationeryPrintOrderDetailsFragment;
  viewVariant: 'overview' | 'detailed';
}

export const PremiumDigitalJobDetailsTelemetryProxyProvider: React.FC<PremiumDigitalJobDetailsTelemetryProxyProviderProps> = props => {
  const { children, order, viewVariant } = props;

  const { premiumDigitalOrderCtaClick } = useCardTelemetry();

  const source: 'orderHistory' | 'orderDetails' = viewVariant === 'overview' ? 'orderHistory' : 'orderDetails';
  const sharedPayload = {
    source,
    orderNumber: order.orderNumber,
    themeId: order.cardDraft?.stationeryTemplate.themeId!,
    stationeryTemplateCategory: order.cardDraft?.stationeryTemplate.category!
  };

  const handleOnGoToOrderDetailsClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'goToOrderDetails'
    });
  });
  const handleOnTrackDeliveryClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'trackDelivery'
    });
  });
  const handleOnSendMoreOrResendClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'sendMoreOrResend'
    });
  });
  const handleOnSelectRecipientsAndSendClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'selectRecipientsAndSend'
    });
  });
  const handleOnOrderMatchingPaperCardClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'orderMatchingPaperCard'
    });
  });
  const handleOnContactSupportClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'contactSupport'
    });
  });

  const handleOnEditOrderClick = useEventCallback(() => {
    premiumDigitalOrderCtaClick({
      ...sharedPayload,
      name: 'editOrder'
    });
  });

  const ctx = useMemo<PremiumDigitalJobDetailsTelemetryProxyContext>(() => {
    return {
      onGoToOrderDetailsClick: handleOnGoToOrderDetailsClick,
      onTrackDeliveryClick: handleOnTrackDeliveryClick,
      onSendMoreOrResendClick: handleOnSendMoreOrResendClick,
      onSelectRecipientsAndSendClick: handleOnSelectRecipientsAndSendClick,
      onOrderMatchingPaperCardClick: handleOnOrderMatchingPaperCardClick,
      onContactSupportClick: handleOnContactSupportClick,
      onEditOrderClick: handleOnEditOrderClick
    };
  }, [
    handleOnGoToOrderDetailsClick,
    handleOnTrackDeliveryClick,
    handleOnSendMoreOrResendClick,
    handleOnSelectRecipientsAndSendClick,
    handleOnOrderMatchingPaperCardClick,
    handleOnContactSupportClick,
    handleOnEditOrderClick
  ]);

  return <Provider value={ctx}>{children}</Provider>;
};
