import React, { FC, useEffect, useState } from 'react';
import { Flex, TextV2, useToast } from '@withjoy/joykit';
import { useTranslation } from '@shared/core';
import { ChevronLeftSquare, WarningTriangle } from '@withjoy/joykit/icons';
import { EditorConsoleProps, ImageData, PhotoPresentationLayout } from './FocusPointEditor.types';
import { EventPageType, WebsitePagePhotoPresentationFragment, useRemoveEventPagePhotoMutation, useUpdatePageContainerMutation } from '@graphql/generated';
import { CheckIcon } from '@assets/icons';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';
import { derivePhotoPresentationLayout, findMatchingPageType } from './utils';
import { FocusPointEditor } from './FocusPointEditor';
import { useFeatureValue } from '@shared/core/featureFlags';
import { FullWidthPhotoEditor } from './FullWidthEditor';
import { PhotoPresentationLayoutSelector } from './PhotoPresentationLayoutSelector';
import { Crop, makeAspectCrop } from 'react-image-crop';

const useInMemoryPhotoCropState = (imageData: ImageData, photoPresentation: Maybe<WebsitePagePhotoPresentationFragment>) => {
  const [currentImage, setCurrentImage] = useState<string>(imageData.url);
  const [crop, setCrop] = useState<Crop>(() => {
    if (photoPresentation?.cropInfo && ['%', 'percent'].includes(photoPresentation.cropInfo.unit)) {
      return {
        // need to explicitly spread this so that `cropInfo.__typename` is not unintentionally set
        x: photoPresentation.cropInfo.x,
        y: photoPresentation.cropInfo.y,
        width: photoPresentation.cropInfo.width,
        height: photoPresentation.cropInfo.height,
        unit: '%'
      };
    }

    // Generate initial crop that is centered and has an aspect ratio of 16:9
    return makeAspectCrop(
      {
        // You don't need to pass a complete crop into
        // makeAspectCrop or centerCrop.
        unit: '%',
        width: 100,
        y: 25
      },
      16 / 9,
      imageData.width,
      imageData.height
    );
  });

  useEffect(() => {
    if (imageData.url && currentImage !== imageData.url) {
      const newCrop = makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: '%',
          width: 100,
          y: 25
        },
        16 / 9,
        imageData.width,
        imageData.height
      );
      setCurrentImage(imageData.url);
      setCrop(newCrop);
    }
  }, [currentImage, imageData]);

  return { crop, setCrop };
};

export const EditorConsole: FC<EditorConsoleProps> = props => {
  const {
    handleOnClose,
    handleOnCancel,
    pageType,
    pageId,
    openFilePicker,
    showNavigateBack,
    onPhotoDeleteSuccess,
    telemetryCategory,
    pageIdsData,
    allowPagePhoto,
    enablePhotoLayout,
    photoPresentation,
    imageData,
    updatePagePhotoPreview,
    refetchQueryList = []
  } = props;

  const telemetry = useFocusPointEditorTelemetry();

  const isPhotoLayoutFeatureEnabled = useFeatureValue('websiteBrannanFullBleedImageEnabled', { skip: !enablePhotoLayout || pageType === 'custom' }).value === 'on';

  const [photoPresentationLayout, setPhotoPresentationLayout] = useState<PhotoPresentationLayout>(() => {
    return isPhotoLayoutFeatureEnabled ? derivePhotoPresentationLayout(photoPresentation) : 'center';
  });

  const handleOnSetPhotoLayoutPlacement = useEventCallback((nextPhotoLayout: PhotoPresentationLayout) => {
    telemetry.photoPresentationLayoutClicked({
      category: telemetryCategory,
      presentationLayout: nextPhotoLayout,
      pageId,
      pageType
    });
    setPhotoPresentationLayout(nextPhotoLayout);
  });

  const { crop, setCrop } = useInMemoryPhotoCropState(imageData, photoPresentation);

  const handleOnCropComplete = useEventCallback(() => {
    telemetry.pagePhotoCropped(telemetryCategory, { pageType, pageId, photoPresentationLayout });
  });

  const { toast } = useToast();
  const { t2 } = useTranslation('joykit');
  const dialogTrans = t2('focusPointDialog');

  const [updatePageContainer, { loading: waitingForUpdatePageContainer }] = useUpdatePageContainerMutation({});

  // Shared

  const [removeEventPagePhoto, { loading: isDeletingPagePhoto }] = useRemoveEventPagePhotoMutation({
    onCompleted: () => toast(`${dialogTrans.photoDeleteSuccess}`, { icon: <CheckIcon /> }),
    onError: () => toast(`${dialogTrans.photoDeleteError}`, { icon: <WarningTriangle /> })
  });

  const handleChangePhoto = () => {
    telemetry.photoEditorChangePhotoClicked(telemetryCategory);
    openFilePicker();
  };

  const onCancelClick = () => {
    telemetry.photoEditorOpened(telemetryCategory, 'closed');
    handleOnCancel();
  };

  const handleRemovePhoto = useEventCallback(() => {
    // Custom and Normal page photo have different end points, we have to maintain both in global level
    if (props.pageType === EventPageType.custom) {
      // TODO: https://withjoy.atlassian.net/browse/PRNT-2050
      const pageIdToUse = props.pageId;
      updatePageContainer({
        variables: {
          payload: {
            id: props.pageId ?? '',
            mainPhotoId: null,
            mainPhotoAlignment: null
          }
        },
        refetchQueries: ['PageContainerBySlug', 'WebsiteInfo', ...refetchQueryList],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'Delete', 'succeeded', { pageType: props.pageSlug, actualPageType: pageType || '' });
          handleOnCancel();
          onPhotoDeleteSuccess && onPhotoDeleteSuccess(pageIdToUse || '', EventPageType.custom, pageId || '', pageType);
        }
      });
    } else {
      const pageTypeToUpdate = findMatchingPageType(props.pageType);
      // TODO: https://withjoy.atlassian.net/browse/PRNT-2050
      const pageIdToUse = pageIdsData.find(page => page.type === pageTypeToUpdate)?.id || props.pageId;
      removeEventPagePhoto({
        variables: {
          eventPageId: pageIdToUse || ''
        },
        refetchQueries: ['WebsiteInfo', ...refetchQueryList],
        awaitRefetchQueries: true
      }).then(info => {
        if (!info.errors) {
          telemetry.pagePhotoUpdated(telemetryCategory, 'Delete', 'succeeded', { pageType: pageTypeToUpdate, actualPageType: pageType || '' });
          handleOnCancel();
          onPhotoDeleteSuccess && onPhotoDeleteSuccess(pageIdToUse || '', pageTypeToUpdate, pageId || '', pageType);
        }
      });
    }
  });

  const handleCloseClick = () => {
    telemetry.photoEditorOpened(telemetryCategory, 'closed');
    handleOnClose();
  };

  return (
    <>
      <Flex flexDirection="column" position="relative">
        <Flex flexDirection="row" justifyContent="center" alignItems="center" position="relative" paddingX={6}>
          <Flex
            borderRadius={48}
            border="1px solid"
            borderColor="mono3"
            background="white"
            width={8}
            height={8}
            padding={2}
            justifyContent="center"
            alignItems="center"
            gap={3}
            marginLeft="-16px"
            marginTop="-16px"
            cursor="pointer"
            zIndex={999}
            onClick={onCancelClick}
            position="absolute"
            left={0}
            _hover={
              allowPagePhoto
                ? {
                    backgroundColor: 'mono3',
                    borderColor: 'mono5'
                  }
                : {}
            }
            _active={
              allowPagePhoto
                ? {
                    backgroundColor: 'mono5',
                    borderColor: 'mono5'
                  }
                : {}
            }
            visibility={showNavigateBack ? 'visible' : 'hidden'}
          >
            <ChevronLeftSquare size={16} />
          </Flex>
          <TextV2 as="h3" typographyVariant={['hed3', 'hed6']} textAlign="center">
            {dialogTrans.title}
          </TextV2>
        </Flex>

        {isPhotoLayoutFeatureEnabled && <PhotoPresentationLayoutSelector currentPhotoLayout={photoPresentationLayout} onPhotoLayoutChange={handleOnSetPhotoLayoutPlacement} />}

        {
          // Checking is value = `fullWidth` because it's a net new editor
          photoPresentationLayout === 'fullWidth' ? (
            <FullWidthPhotoEditor
              {...props}
              crop={crop}
              setCrop={setCrop}
              pageId={props.pageId || undefined}
              handleCloseClick={handleCloseClick}
              isDeletingPagePhoto={isDeletingPagePhoto || waitingForUpdatePageContainer}
              handleRemovePhoto={handleRemovePhoto}
              handleChangePhoto={handleChangePhoto}
              handleCancelClick={onCancelClick}
              onCropComplete={handleOnCropComplete}
              updatePagePhotoPreview={updatePagePhotoPreview}
            />
          ) : (
            // Existing editor
            <FocusPointEditor
              {...props}
              handleCloseClick={handleCloseClick}
              isDeletingPagePhoto={isDeletingPagePhoto || waitingForUpdatePageContainer}
              handleRemovePhoto={handleRemovePhoto}
              handleChangePhoto={handleChangePhoto}
              handleCancelClick={onCancelClick}
              pageIdsData={pageIdsData}
            />
          )
        }
      </Flex>
    </>
  );
};
