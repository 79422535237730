import React, { FC, useCallback, useEffect, useState } from 'react';
import { getLocalStorage } from '@shared/core';
import { createContext } from '@shared/utils/createContext';
import { withWindow } from '@shared/utils/withWindow';

type SmartAppBannerProviderContext = {
  isBannerVisible: boolean;
  updateBannerVisibility: (isVisible: boolean) => void;
  smartAppBannerHeight: number;
  calculateBannerHeight: () => void;
};

const [Provider, useSmartAppBannerProvider] = createContext<SmartAppBannerProviderContext>({
  name: 'SmartAppBannerProvider'
});

const localStorage = getLocalStorage();
const SMART_APP_BANNER_VISIBILITY_KEY = 'smartAppBannerVisibility';

const SmartAppBannerProvider: FC = ({ children }) => {
  const [isBannerVisible, setIsBannerVisble] = useState(localStorage.getItem(SMART_APP_BANNER_VISIBILITY_KEY) !== 'hidden');
  const [smartAppBannerHeight, setSmartAppBannerHeight] = useState(0);

  const calculateBannerHeight = useCallback(() => {
    const smartAppBanner = withWindow(() => document.getElementById('smart-app-banner'), null);
    const height = smartAppBanner?.clientHeight || 0;
    setSmartAppBannerHeight(height);
  }, []);

  useEffect(() => {
    calculateBannerHeight();
    withWindow(window => {
      window.addEventListener('resize', calculateBannerHeight);
    });
    return () => {
      withWindow(window => {
        window.removeEventListener('resize', calculateBannerHeight);
      });
    };
  }, [calculateBannerHeight]);

  const updateBannerVisibility = useCallback(
    (isVisible: boolean) => {
      localStorage.setItem(SMART_APP_BANNER_VISIBILITY_KEY, isVisible ? 'visible' : 'hidden');
      setIsBannerVisble(isVisible);
    },
    [setIsBannerVisble]
  );

  return <Provider value={{ isBannerVisible, updateBannerVisibility, smartAppBannerHeight, calculateBannerHeight }}>{children}</Provider>;
};

export { SmartAppBannerProvider, useSmartAppBannerProvider };
