import { EventUserStatus, useGetValidSendersQuery } from '@graphql/generated';
import { useAuth } from '@shared/components/AuthProvider';

export const useGetValidReplyToAddresses = (eventHandle: string): string[] => {
  const { data } = useGetValidSendersQuery({
    variables: {
      name: eventHandle
    },
    batchMode: 'fast'
  });
  const { currentUser } = useAuth();

  if (!data) {
    return [];
  }

  const emails =
    data.eventByName?.eventUsers.reduce((acc, user) => {
      const isOwnerOrAdmin = user.status === EventUserStatus.admin || user.status === EventUserStatus.owner;
      const isEmailVerified = user.user.emailIsVerified;

      if (isOwnerOrAdmin && isEmailVerified && user.user.email) {
        return [...acc, user.user.email];
      }

      return acc;
    }, [] as string[]) ?? [];

  const activeUserEmail = currentUser.profile?.email;
  if (activeUserEmail && !emails?.includes(activeUserEmail)) {
    emails?.push(activeUserEmail);
  }

  return emails;
};
