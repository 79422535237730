import React from 'react';

import { IconV2, TextV2, Box, Flex } from '@withjoy/joykit';
import { IconError, InfoStar, Info } from '@withjoy/joykit/icons';

const ICONS = {
  error: <IconError color="#E9646D" />,
  warning: <IconError color="#E0946D" />,
  info: <InfoStar color="#E0946D" />,
  originalInfo: <Info color="#E0946D" />
};

interface InlineMessageProps {
  title?: string;
  message: React.ReactNode;
  icon: keyof typeof ICONS;
  noMobileXMargin?: boolean;
}

export const InlineMessage: React.FC<InlineMessageProps> = ({ title, message, icon, noMobileXMargin = false }) => {
  return (
    <Box border={`1px solid ${icon === 'error' ? '#FFE1BE' : '#FFD8BB'}`} borderRadius="8px" padding="24px" marginX={[noMobileXMargin ? 0 : '24px', 0]}>
      <Flex width="100%" columnGap="24px">
        <IconV2 size="lg">{ICONS[icon]}</IconV2>
        <Flex flexDirection="column">
          {title && <TextV2 typographyVariant="hed1">{title}</TextV2>}
          <TextV2 textAlign="initial" typographyVariant="body1">
            {message}
          </TextV2>
        </Flex>
      </Flex>
    </Box>
  );
};
