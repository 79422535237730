import { AccommodationPlaceType } from '@graphql/generated';
import { AccommodationCombined, AccommodationCombinedType, AccommodationPlacesV2, ReservedRoomBlock } from './Accommodation.types';

export const RoomBlockToAccommodationCombined = (roomBlock: ReservedRoomBlock) => {
  return {
    id: roomBlock.id,
    type: AccommodationCombinedType.Roomblock,

    //   General Information
    displayName: roomBlock.displayName,
    address: roomBlock.address,
    city: undefined,
    country: undefined,
    state: undefined,
    postalCode: undefined,
    latitude: roomBlock.latitude,
    longitude: roomBlock.longitude,

    description: roomBlock.note,
    email: undefined,
    phone: undefined,
    url: undefined,

    // JoyPlaceID
    joyPlaceId: null,

    //   Provider Information
    googlePlaceId: undefined,
    zhId: undefined,

    //   Extra Information
    pricePerNight: roomBlock.pricePerNight,
    bookingUrl: roomBlock.bookingUrl,
    cutoffDate: roomBlock.cutoffDate,
    checkOutDate: roomBlock.checkOutDate,
    checkInDate: roomBlock.checkInDate,
    strikeoutPricePerNight: roomBlock.strikeoutPricePerNight,
    numberOfRoomsBooked: roomBlock.numberOfRoomsBooked,
    numberOfRoomsGuestBooked: roomBlock.numberOfRoomsGuestBooked,
    interestedGuestCount: roomBlock.interestedGuestCount,

    //   Custom Properties
    isFavorite: roomBlock.isFavorite,
    starRating: roomBlock.starRating,

    photo: roomBlock.photo
  } as AccommodationCombined;
};

export const AccommodationPlacesV2ToAccommodationCombined = (accommodationPlacesV2: AccommodationPlacesV2) => {
  return {
    id: accommodationPlacesV2.id,
    type: accommodationPlacesV2.type === AccommodationPlaceType.hotel ? AccommodationCombinedType.Hotel : AccommodationCombinedType.Custom,

    //   General Information
    displayName: accommodationPlacesV2.customName || accommodationPlacesV2.name,
    address: accommodationPlacesV2.customAddress || accommodationPlacesV2.address,
    customAddress: accommodationPlacesV2.customAddress,
    city: accommodationPlacesV2.city,
    country: accommodationPlacesV2.country,
    state: accommodationPlacesV2.state,
    postalCode: accommodationPlacesV2.postalCode,
    latitude: accommodationPlacesV2.lat,
    longitude: accommodationPlacesV2.long,

    description: accommodationPlacesV2.userNote,
    email: accommodationPlacesV2.email,
    phone: accommodationPlacesV2.customPhone || accommodationPlacesV2.phone,
    url: accommodationPlacesV2.customUrl || accommodationPlacesV2.url,
    customUrl: accommodationPlacesV2.customUrl,

    // JoyPlaceID
    joyPlaceId: accommodationPlacesV2.joyPlaceId,

    //   Provider Information
    googlePlaceId: accommodationPlacesV2.googlePlaceId,
    zhId: accommodationPlacesV2.zhId,

    //   Extra Information
    pricePerNight: undefined,
    bookingUrl: undefined,
    cutoffDate: undefined,
    checkOutDate: undefined,
    checkInDate: undefined,
    strikeoutPricePerNight: undefined,
    numberOfRoomsBooked: undefined,
    numberOfRoomsGuestBooked: undefined,

    //   Custom Properties
    isFavorite: undefined,
    starRating: undefined,

    photo: accommodationPlacesV2.isPhotoHidden ? null : accommodationPlacesV2.customPhoto || accommodationPlacesV2.photo,
    isFromAccommodationCatalog: accommodationPlacesV2.isFromAccommodationCatalog
  };
};
