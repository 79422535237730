import { styled, keyframes } from '@withjoy/joykit';

const ringRotateAnimation = keyframes`
    0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledRemoveSpinner = styled.div<{ width: string; height: string; borderWidth: string; borderColor: string; thumbColor: string }>`
  display: block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: 50%;
  position: relative;
  &::after,
  & > div {
    display: block;
    position: absolute;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    border-radius: 50%;
    border-width: ${({ borderWidth }) => borderWidth};
    border-style: solid;
    background: transparent;
  }

  &::after {
    border-color: ${({ borderColor }) => borderColor};
    content: ' ';
    z-index: -1;
  }
  & > div {
    border-color: ${({ thumbColor }) => `${thumbColor} transparent transparent transparent`};
    animation: ${ringRotateAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
