import { useImmer } from 'use-immer';
import { DraftData } from './CardCustomizer.types';
import { createContext } from '@shared/utils/createContext';
import { useEffect } from 'react';

export type ScopedUpdateHandler<T> = (scopedUpater: (scopedDraft: T) => void) => void;

type Scope = <T>(selector: (draft: DraftData) => T) => readonly [T, ScopedUpdateHandler<T>];

/**
 * - The shape of the state will change as we experiment
 * - This `initialConfig` prop is API data for layers.
 *
 * TODO:
 *  - Consider switching to valtio + jotai for performance optimization
 */
export function useCardCustomization(initialConfig: DraftData) {
  const [state, update] = useImmer<DraftData>(initialConfig);

  const scope = function <T>(selector: (draft: DraftData) => T) {
    const scopedState = selector(state);
    function setScopedState(scopedUpater: (scopedDraft: T) => void) {
      update(draft => scopedUpater(selector(draft)));
    }
    return [scopedState, setScopedState] as const;
  } as Scope;

  return {
    state,
    scope
  };
}

const [CustomizationProvider, useCustomizationContextInner] = createContext<Scope>({ name: 'LayerManagement' });

export { CustomizationProvider };
export function useCustomizationContext<T>(selector: (draft: DraftData) => T, readonly: boolean = false) {
  const scope = useCustomizationContextInner();
  const [state, updater] = scope(selector);
  return readonly ? ([state, () => undefined] as const) : ([state, updater] as const);
}

export function useDigitalCardCustomization() {
  const [config, update] = useCustomizationContext(draft => draft.config);

  useEffect(() => {
    if (!config.digitalCustomizations) {
      update(draft => {
        draft.digitalCustomizations = draft.digitalCustomizations || {};
      });
    }
  }, [config.digitalCustomizations, update]);

  return useCustomizationContext(draft => draft.config.digitalCustomizations || {});
}
