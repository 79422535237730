import { useFeatureValue } from '@shared/core/featureFlags';
import { useMemo } from 'react';

interface FoilConfig {
  foilColorOfferings: { colorId: string; displayLabel: string; assetThumbnailUrl: string }[];
}

const isFoilConfig = (value: unknown): value is FoilConfig => {
  return typeof value === 'object' && value !== null && 'foilColorOfferings' in value && Array.isArray(value.foilColorOfferings);
};

export const useFoilConfig = () => {
  const { value } = useFeatureValue('printPaperFoilEnabled');
  const { payload } = useFeatureValue('printPaperFoilConfig');

  const offerings = useMemo(() => (isFoilConfig(payload) ? payload.foilColorOfferings : []), [payload]);

  return {
    enabled: value === 'on',
    offerings,
    pricePerCardInMinorUnits: 50
  };
};
