import React from 'react';
import { ButtonV2 } from '@withjoy/joykit/components/ButtonV2';
import { Flex, TextV2, useToast } from '@withjoy/joykit';
import { GetDigitalAndPaperDraftsByEventIdDocument, GetDigitalAndPaperDraftsByEventIdQuery, useDeleteStationeryDraftByIdMutation } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { useTranslation } from '@shared/core';
import { ResponsiveDialog } from '@apps/card/components/ResponsiveDialog';
import { useCardTelemetry } from '@apps/card/Card.telemetry';

interface DeleteDraftDialogProps {
  draft: GetDigitalAndPaperDraftsByEventIdQuery['stationeryDrafts'][number] | null;
  onClose: () => void;
}

export const DeleteDraftDialog: React.FC<DeleteDraftDialogProps> = ({ draft, onClose }) => {
  const { eventInfo } = useEventInfo();
  const { toast } = useToast();
  const { t } = useTranslation('stationery');
  const tDialog = t('dashboard', 'draftsGallery', 'deleteDraftDialog');
  const { deleteDraftDialogButtonInteracted, cardDraftDeleted } = useCardTelemetry();

  const [deleteDraft, { loading: isDeleting }] = useDeleteStationeryDraftByIdMutation();

  const buttonInteractedTelemetry = (source: 'cancel' | 'confirm') => {
    if (!draft) return;

    deleteDraftDialogButtonInteracted({
      source: source,
      themeId: draft.stationeryTemplate.themeId,
      format: draft.format,
      isPremiumDesign: draft.stationeryTemplate.premium,
      stationeryTemplateCategory: draft.stationeryTemplate.category
    });
  };

  const handleDelete = async () => {
    if (!draft) return;

    buttonInteractedTelemetry('confirm');
    const eventId = eventInfo?.eventId;

    try {
      await deleteDraft({
        variables: { id: draft?.id as string },
        refetchQueries: [{ query: GetDigitalAndPaperDraftsByEventIdDocument, variables: { input: { eventId }, eventId } }, 'GetStationeryEntitiesCountForNavigationByEventId'],
        awaitRefetchQueries: true
      });

      cardDraftDeleted({
        themeId: draft.stationeryTemplate.themeId,
        format: draft.format,
        isPremiumDesign: draft.stationeryTemplate.premium,
        stationeryTemplateCategory: draft.stationeryTemplate.category
      });

      onClose();
      toast(tDialog.successToast());
    } catch (error) {
      toast(tDialog.errorToast());
    }
  };

  const handleCancel = () => {
    buttonInteractedTelemetry('cancel');
    onClose();
  };

  return (
    <ResponsiveDialog
      isOpen={!!draft?.id}
      onClose={onClose}
      header={() => <TextV2 typographyVariant="hed3">{tDialog.title()}</TextV2>}
      content={() => (
        <Flex flexDirection="column" gap={8}>
          <TextV2 fontSize="15px" lineHeight="21px">
            {tDialog.description()}
          </TextV2>
          <Flex gap={4} flexDirection={{ _: 'column-reverse', sm2: 'row' }} width="100%" justifyContent="space-between">
            <ButtonV2 intent="neutral" shape="rounded" variant="outline" onClick={handleCancel}>
              {tDialog.cancelCta()}
            </ButtonV2>
            <ButtonV2 intent="neutral" shape="rounded" onClick={handleDelete} loading={isDeleting}>
              {tDialog.deleteCta()}
            </ButtonV2>
          </Flex>
        </Flex>
      )}
    />
  );
};
