/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Phone',
  definitions: {
    '16': {
      viewBox: '0 0 16 16',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.18498 1.80913C3.66877 1.32534 4.45314 1.32534 4.93692 1.80913L6.64163 3.51384C7.12542 3.99763 7.12542 4.782 6.64163 5.26578L5.81289 6.09452L9.90568 10.1873L10.7344 9.35857C11.2182 8.87479 12.0026 8.87479 12.4864 9.35857L14.1911 11.0633C14.6749 11.5471 14.6749 12.3314 14.1911 12.8152L12.9734 14.0329C12.5557 14.4506 11.9788 14.5801 11.3956 14.5497C10.8122 14.5192 10.1588 14.3281 9.48402 14.0256C8.13071 13.4189 6.58034 12.3141 5.13323 10.867C3.68611 9.41986 2.5813 7.8695 1.97464 6.51618C1.67215 5.84139 1.48102 5.18801 1.45054 4.60463C1.42006 4.02143 1.5496 3.44451 1.96733 3.02678L3.18498 1.80913ZM4.1591 2.58695C4.1049 2.53274 4.01701 2.53274 3.9628 2.58695L2.74515 3.8046C2.62488 3.92486 2.52811 4.14668 2.54904 4.54722C2.56996 4.9476 2.70798 5.46297 2.9784 6.06622C3.51757 7.26899 4.5337 8.71182 5.91104 10.0892C7.28839 11.4665 8.73122 12.4826 9.93399 13.0218C10.5372 13.2922 11.0526 13.4302 11.453 13.4512C11.8535 13.4721 12.0753 13.3753 12.1956 13.2551L13.4133 12.0374C13.4675 11.9832 13.4675 11.8953 13.4133 11.8411L11.7085 10.1364C11.6543 10.0822 11.5665 10.0822 11.5122 10.1364L10.2946 11.354C10.0798 11.5688 9.73156 11.5688 9.51677 11.354L4.64616 6.48343C4.43138 6.26864 4.43138 5.9204 4.64616 5.70562L5.86382 4.48796C5.91803 4.43375 5.91803 4.34587 5.86382 4.29166L4.1591 2.58695Z"
            fill="currentColor"
          />
        </>
      )
    },
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <path
            fillRule="evenodd"
            d="M5.066 3.043c.63-.63 1.651-.63 2.281 0L9.855 5.55c.63.63.63 1.651 0 2.281L8.488 9.197l6.314 6.314 1.367-1.367c.63-.63 1.651-.63 2.281 0l2.507 2.507c.63.63.63 1.651 0 2.281l-1.791 1.791c-.558.558-1.339.742-2.162.699s-1.758-.314-2.737-.753c-1.962-.879-4.221-2.487-6.336-4.602s-3.723-4.375-4.602-6.336c-.439-.979-.71-1.914-.753-2.737s.141-1.604.699-2.162l1.791-1.791zm1.433.849c-.161-.161-.423-.161-.584 0L4.124 5.682c-.233.233-.382.627-.349 1.251s.246 1.408.649 2.308c.806 1.797 2.317 3.94 4.356 5.979s4.181 3.55 5.979 4.356c.901.404 1.685.617 2.308.649s1.017-.116 1.251-.349l1.791-1.791c.161-.161.161-.423 0-.584l-2.507-2.507c-.161-.161-.423-.161-.584 0l-1.791 1.791a.6.6 0 0 1-.849 0L7.215 9.622a.6.6 0 0 1 0-.849l1.791-1.791c.161-.161.161-.423 0-.584L6.499 3.891z"
            fill="currentColor"
          />
        </>
      )
    },
    '32': {
      viewBox: '0 0 32 32',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.20357 5.91191C8.88592 5.22956 9.99224 5.22956 10.6746 5.91191L13.514 8.75128C14.1963 9.43364 14.1963 10.54 13.514 11.2223L11.9101 12.8262L19.1741 20.0901L20.7779 18.4863C21.4603 17.8039 22.5666 17.8039 23.2489 18.4863L26.0883 21.3256C26.7707 22.008 26.7707 23.1143 26.0883 23.7967L24.0602 25.8248C23.4499 26.4351 22.591 26.6414 21.6721 26.5934C20.753 26.5454 19.7044 26.2423 18.601 25.7477C16.3902 24.7566 13.8388 22.9419 11.4486 20.5517C9.05829 18.1614 7.2436 15.61 6.25254 13.3992C5.75793 12.2958 5.45486 11.2472 5.40682 10.3281C5.3588 9.40921 5.56515 8.55033 6.17545 7.94003L8.20357 5.91191ZM9.82607 6.76044C9.61234 6.54672 9.26582 6.54672 9.0521 6.76044L7.02397 8.78856C6.73819 9.07435 6.56758 9.54584 6.60519 10.2655C6.64279 10.9849 6.88793 11.883 7.34756 12.9083C8.26499 14.9549 9.98294 17.389 12.2971 19.7031C14.6112 22.0173 17.0453 23.7352 19.0919 24.6527C20.1172 25.1123 21.0153 25.3574 21.7348 25.395C22.4544 25.4326 22.9259 25.262 23.2117 24.9763L25.2398 22.9481C25.4535 22.7344 25.4535 22.3879 25.2398 22.1742L22.4004 19.3348C22.1867 19.1211 21.8402 19.1211 21.6264 19.3348L19.5983 21.3629C19.364 21.5972 18.9841 21.5972 18.7498 21.3629L10.6373 13.2504C10.403 13.0161 10.403 12.6362 10.6373 12.4019L12.6654 10.3738C12.8792 10.1601 12.8792 9.81354 12.6654 9.59981L9.82607 6.76044Z"
            fill="currentColor"
          />
        </>
      )
    },
    '48': {
      viewBox: '0 0 48 48',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.84524 12.1987C7.76518 10.6667 8.11236 9.30537 9.06242 8.35531L12.598 4.81977C13.6231 3.79465 15.2851 3.79465 16.3103 4.81977L21.26 9.76952C22.2851 10.7946 22.2851 12.4567 21.26 13.4818L18.1664 16.5754L31.4247 29.8337L34.5183 26.7401C35.5434 25.715 37.2054 25.715 38.2306 26.7401L43.1803 31.6898C44.2054 32.715 44.2054 34.377 43.1803 35.4021L39.6448 38.9377C38.6947 39.8877 37.3334 40.2349 35.8014 40.1549C34.2692 40.0748 32.494 39.5668 30.5979 38.7168C26.8015 37.015 22.395 33.8854 18.2548 29.7453C14.1147 25.6051 10.9851 21.1986 9.28328 17.4021C8.43331 15.5061 7.92531 13.7309 7.84524 12.1987ZM10.4239 16.8908C12.0491 20.5162 15.0778 24.8005 19.1387 28.8614C23.1996 32.9223 27.4839 35.951 31.1093 37.5762C32.924 38.3897 34.5424 38.8374 35.8666 38.9066C37.191 38.9758 38.1489 38.6658 38.7609 38.0538L42.2964 34.5183C42.8334 33.9813 42.8334 33.1107 42.2964 32.5737L37.3467 27.624C36.8097 27.087 35.9391 27.087 35.4021 27.624L31.8666 31.1595C31.6225 31.4036 31.2268 31.4036 30.9827 31.1595L16.8406 17.0174C16.5965 16.7733 16.5965 16.3776 16.8406 16.1335L20.3761 12.5979C20.9131 12.061 20.9131 11.1904 20.3761 10.6534L15.4264 5.70366C14.8894 5.16668 14.0188 5.16668 13.4818 5.70366L9.9463 9.23919C9.33427 9.85122 9.02432 10.8091 9.09353 12.1335C9.16274 13.4577 9.6104 15.076 10.4239 16.8908Z"
            fill="currentColor"
          />
        </>
      )
    },
    '64': {
      viewBox: '0 0 64 64',
      path: (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.97132 18.7852C7.86486 16.7481 8.32677 14.9437 9.58451 13.6859L14.2986 8.97191C15.6524 7.61809 17.8473 7.61809 19.2012 8.97191L25.8008 15.5716C27.1546 16.9254 27.1546 19.1204 25.8008 20.4742L21.6525 24.6225L39.3773 42.3473L43.5256 38.199C44.8794 36.8452 47.0744 36.8452 48.4282 38.199L55.0279 44.7986C56.3817 46.1525 56.3817 48.3474 55.0279 49.7013L50.3139 54.4153C49.0561 55.673 47.2517 56.1349 45.2146 56.0285C43.1773 55.922 40.8146 55.2463 38.2886 54.114C33.2313 51.8469 27.3591 47.6769 21.841 42.1588C16.3229 36.6407 12.1529 30.7685 9.88584 25.7112C8.75352 23.1853 8.07779 20.8225 7.97132 18.7852ZM11.3459 25.0567C13.5148 29.8951 17.5558 35.6108 22.9724 41.0274C28.389 46.444 34.1047 50.485 38.9431 52.6539C41.365 53.7396 43.527 54.3381 45.2981 54.4307C47.0696 54.5232 48.3574 54.109 49.1825 53.2839L53.8965 48.5699C54.6255 47.8409 54.6255 46.659 53.8965 45.93L47.2969 39.3304C46.5679 38.6014 45.386 38.6014 44.657 39.3304L39.943 44.0444C39.7929 44.1944 39.5894 44.2787 39.3773 44.2787C39.1651 44.2787 38.9616 44.1944 38.8116 44.0444L19.9554 25.1882C19.8054 25.0382 19.7211 24.8347 19.7211 24.6225C19.7211 24.4104 19.8054 24.2069 19.9554 24.0568L24.6695 19.3428C25.3984 18.6138 25.3984 17.4319 24.6695 16.7029L18.0698 10.1033C17.3408 9.3743 16.1589 9.3743 15.4299 10.1033L10.7159 14.8173C9.89082 15.6424 9.47656 16.9302 9.56914 18.7017C9.66169 20.4728 10.2602 22.6348 11.3459 25.0567Z"
            fill="currentColor"
          />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
