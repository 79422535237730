import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import React, { useEffect, useState } from 'react';
import { differenceInHours, differenceInMinutes, isAfter } from 'date-fns';
import { useTranslation } from '@shared/core';

interface EditCardCountdownProps {
  editingEndsAt: Maybe<string>;
  onEditClick: () => void;
  isOrderPaused: boolean;
  isDigital?: boolean;
}

const EditCardCountdown: React.FC<EditCardCountdownProps> = ({ editingEndsAt, onEditClick, isOrderPaused, isDigital }) => {
  const [duration, setDuration] = useState('');
  const { t2 } = useTranslation('stationery');

  useEffect(() => {
    const calculate = () => {
      if (!editingEndsAt) return;
      const now = new Date();
      const hours = differenceInHours(new Date(editingEndsAt), now);
      const minutes = differenceInMinutes(new Date(editingEndsAt), now) % 60;

      setDuration(`${hours} hours ${minutes} minutes`);
    };

    let interval: NodeJS.Timeout | null;
    if (editingEndsAt) {
      calculate();
      interval = setInterval(calculate, 60 * 1000);
    }

    // Cleanup function for when we have an active interval set
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [editingEndsAt, setDuration]);

  const openEditCard = () => {
    onEditClick();
  };

  if (!isOrderPaused && (!editingEndsAt || isAfter(new Date(), new Date(editingEndsAt)))) {
    return null;
  }

  return (
    <Flex padding={6} border="1px solid #FFD8BB" borderRadius={3} gap={6}>
      <Info flex="none" color="#E0946D" size={32} />
      <Flex flexDirection="column" gap={3}>
        {!isDigital && (
          <TextV2 color="mono12">
            {t2('editCardBanner').paperDescription}
            {!isOrderPaused && duration && (
              <>
                You have{' '}
                <Box display="inline" fontWeight="bold" _hover={{ cursor: 'pointer' }} onClick={openEditCard}>
                  {duration}
                </Box>{' '}
                left to make the changes needed.
              </>
            )}
          </TextV2>
        )}
        {isDigital && <TextV2 color="mono12">{t2('editCardBanner').digitalDescription}</TextV2>}
        <Box
          alignSelf="flex-start"
          cursor="pointer"
          typographyVariant="body2"
          fontWeight={600}
          borderBottom="1px solid #EBEBEB"
          borderRadius={0}
          lineHeight={1}
          paddingY={1}
          onClick={openEditCard}
          __css={{ _hover: { borderBottomColor: 'transparent' } }}
        >
          {t2('editCardBanner').cta}
        </Box>
      </Flex>
    </Flex>
  );
};

export default EditCardCountdown;
