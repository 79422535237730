import { useEventNameMatch, useEventUserRole } from '@shared/components/AuthProvider/AuthProvider.utils';
import { useTranslation } from '@shared/core';
import { useGetEventCTAButtonsDataQuery, useLogoutEventSessionMutation } from '@graphql/generated';
import { performFullPageRedirect } from '@shared/utils/navigation';
import { useCallback } from 'react';
import { GraphQLError } from 'graphql';
import { CtaButtonsSessionData } from '@apps/guest/packages/layout-engine/components/CtaButtons/CtaButtons.types';
import { useCtaButtons } from '@apps/guest/packages/layout-engine/common/utils/useCtaButtons';
import useQueryString from '@shared/utils/hooks/useQueryString';

export const useCtaButtonsController = (props: { onRSVPButtonClicked?: () => void; onNotYouButtonClicked?: () => void; onNotYouErrorRequest?: (err: GraphQLError) => void }) => {
  const onRSVPButtonClicked = props.onRSVPButtonClicked;
  const { eventName } = useEventNameMatch();
  const { getButtonsVisibility, getUnlockButtonText, getRSVPButtonText } = useCtaButtons();
  const queryString = useQueryString();

  const [logoutEvent] = useLogoutEventSessionMutation();

  // Marking this query as `ssr: false` because any RSVP data query is proxied through app-server-api -- which takes long to resolve.
  const { data: sessionData } = useGetEventCTAButtonsDataQuery({
    variables: { name: eventName || '' },
    batchMode: 'fast',
    ssr: false
  });

  const { isAdmin } = useEventUserRole();

  const event: CtaButtonsSessionData = sessionData?.eventByName ?? {};

  const { activeSession, welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage, customPages } = event;
  const { hasIdentity, eventCTA, hasUnlocked, person } = activeSession || {};
  const { label: rsvpLabel, rsvpIsEnabled, rsvpAttendanceStatus, scheduleHasInviteOnlyItems } = eventCTA || {};

  const guestName = person?.fullName ?? '';
  const { t } = useTranslation('layoutEngine');
  const buttonNotYouText = t('components', 'eventCta', 'buttonNotYouText')();
  const personalizedText = t('components', 'eventCta', 'personalizedText')({ guestName });
  const rsvpButtonText = getRSVPButtonText(rsvpAttendanceStatus, rsvpLabel);

  const pages = [welcomePage, storyPage, tidbitsPage, schedulePage, travelPage, faqPage, weddingPage, registryPage, momentsPage, rsvpPage, ...(customPages ?? [])];

  const rsvpIsPasswordProtected = rsvpPage?.private;
  const { shouldDisplayUnlockButton, shouldDisplayRSVPButton } = getButtonsVisibility(
    pages,
    welcomePage,
    schedulePage,
    rsvpIsEnabled,
    rsvpIsPasswordProtected,
    scheduleHasInviteOnlyItems,
    hasUnlocked
  );
  const unlockButtonText = getUnlockButtonText(hasUnlocked);

  const rsvpHandle = useCallback(() => {
    onRSVPButtonClicked?.();
    performFullPageRedirect(`/${eventName}/rsvp${queryString || ''}`);
  }, [onRSVPButtonClicked, eventName, queryString]);

  const logoutHandle = async () => {
    await logoutEvent({ refetchQueries: () => ['GetEventSession', 'GetGuestSiteSchedule', 'GetEventCTAButtonsData'] })
      .then(() => props.onNotYouButtonClicked && props.onNotYouButtonClicked())
      .catch(err => props.onNotYouErrorRequest && props.onNotYouErrorRequest(err));
  };

  return {
    isAdmin,
    guestName,
    personalizedText,
    buttonNotYouText,
    rsvpButtonText,
    shouldDisplayRSVPButton,
    rsvpHandle,
    scheduleHasInviteOnlyItems,
    hasIdentity,
    logoutHandle,
    shouldDisplayUnlockButton,
    unlockButtonText
  };
};
