import React, { useState, useEffect, useRef } from 'react';
import { addAction } from '@shared/utils/logger';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { withWindow } from '@shared/utils/withWindow';

interface WithDirectionResultProps {
  originList: { lat: number; lng: number }[];
  destination: { lat: number; lng: number };
  hideDirection?: boolean;
}

export function withDirectionsResult<T>(WrappedComponent: React.ComponentType<T & { directions: google.maps.DirectionsResult | null; loading: boolean }>) {
  const WithDirectionService: React.FC<T & WithDirectionResultProps> = props => {
    const directionsService = useRef(
      (() => {
        return withWindow(() => new google.maps.DirectionsService(), null);
      })()
    );

    const { originList, destination, hideDirection: hideDirectionProps } = props;
    const hasDestination = destination.lat !== 0 && destination.lng !== 0;
    const hideDirection = !hasDestination || hideDirectionProps;

    const [loading, setLoading] = useState(false);
    const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);

    useEffect(() => {
      const origin = originList[0];
      if (!origin?.lat || !origin?.lng || !destination.lat || !destination.lng) return;

      const milesToVenue = distanceInMiles(origin.lat, origin.lng, destination.lat, destination.lng);

      if (originList.length > 0 && destination && !directions && !loading && !hideDirection && milesToVenue < 500) {
        setLoading(true);
        const request = {
          origin: { lat: origin.lat, lng: origin.lng },
          destination: { lat: destination.lat, lng: destination.lng },
          travelMode: google.maps.TravelMode.DRIVING
        };

        try {
          directionsService?.current?.route(request, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              setDirections(result);
              setLoading(false);
              addAction('directionMapSuccess');
            } else {
              setLoading(false);
              addAction('directionMapError', { status, request });
            }
          });
        } catch (e) {
          setLoading(false);
          const message = e instanceof Error ? e.message : '';
          addAction('directionMapError', { status: 'Failed', message, request });
        }
      }
    }, [originList, destination, directions, loading, hideDirection]);

    return <WrappedComponent {...props} directions={directions} loading={loading} />;
  };

  return WithDirectionService;
}
