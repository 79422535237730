import globalWindow from '@shared/core/globals';
import { useCallback } from 'react';
import copy from 'copy-to-clipboard';

const navigator = globalWindow.navigator;

export const useCopyToClipboard = (callback?: () => void) => {
  const primary = useCallback(
    (str: string) => {
      navigator?.clipboard.writeText(str);
      callback && callback();
    },
    [callback]
  );

  const fallback = useCallback(
    (str: string) => {
      copy(str);
      callback && callback();
    },
    [callback]
  );

  if (!navigator?.clipboard) {
    return fallback;
  }

  return primary;
};
