import { isInIframe } from '@shared/utils/isInIframe';
import { ColorSetFragment, EventDesignPreferenceRangeFragment, EventDesignFragment } from '@graphql/generated';
import { colorPointerUtils, EventDesignPreferences, parseColorPalette, parseDesignPreferences } from '@shared/utils/websiteDesign';
import { googleFontFamilies } from '../../common/fonts';
import { GuestSiteEventDesign, LayoutConfigBrannan, LayoutThemeOverrides, ApplicatorConfigVariantsBrannan } from '../layout.types';
import chroma from 'chroma-js';
import { useStyleApplicator } from '../../common/utils/useStyleApplicator';
import { Theme } from '@withjoy/joykit';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useMemo } from 'react';
import globalWindow from '@shared/core/globals';
import { Colors, createGlobalStyle } from '@withjoy/joykit/theme';
import memoizeOne from 'memoize-one';
import { CreateThemeArgs, createTheme } from '@withjoy/joykit/theme';
import { createTextStyleVariantBuilder, pxToRem } from '@shared/joykit/packages/core/common/themes/shared/typography';
import { mergeTypograhyThemeOverrides } from '../../common/theming';
import { useFeatureValue } from '@shared/core/featureFlags';
import { darkenColor } from '@shared/utils/color';
import { areDefaultColorsOverridden, getActiveColor, getHoverColor } from '../layout.utils';

const isIframed = isInIframe();

const BRANNAN_THEME_DEFAULTS: CreateThemeArgs['defaults'] = {
  breakpointWidths: [isIframed ? 375 : 480, 960, 1024, 1440, 1920]
};

export const getBrannanMobileMediaQuery = (theme: Theme) => theme.mediaQueries.between(0, { breakpointAlias: 'sm4' });

export const MAX_CONTENT_WIDTH = 440;

export const CONTENT_BLOCK_MAX_WIDTH = {
  _: 'auto',
  md: `calc(${pxToRem(MAX_CONTENT_WIDTH)} + 4rem)`
};

// TODO: Darken color when constrast falls below standards
const generateThemableColorValues = memoizeOne(
  (color?: string): DeepPartial<Colors> => {
    if (color) {
      const baseColorDarkened = darkenColor(color);
      const colorHover = getHoverColor(color);
      const colorActive = getActiveColor(color);

      return {
        linkVisited: chroma(baseColorDarkened).darken(0.3).hex(),
        linkText: baseColorDarkened,
        linkHover: colorHover,
        linkActive: colorActive,

        buttonPrimaryText: '#FFFFFF',
        buttonPrimaryFill: baseColorDarkened,
        buttonPrimaryHover: colorHover,
        buttonPrimaryActive: colorActive,

        scrollbarHandle: `rgba(${chroma(color).alpha(0.5).rgba().join(', ')})`,

        buttonOutlinePrimaryText: baseColorDarkened,
        buttonOutlinePrimaryTextHover: colorHover,
        buttonOutlinePrimaryTextPress: colorActive,
        buttonOutlinePrimaryFillHover: chroma(baseColorDarkened).alpha(0.1).css(),
        buttonOutlinePrimaryFillPress: chroma(baseColorDarkened).alpha(0.2).css()
      };
    }
    return {};
  }
);

export const getBrannanThemeOverrides = (overrides: LayoutThemeOverrides = {}): DeepPartial<Theme> => {
  const { eventFontFamily = googleFontFamilies.greatVibes, bodyFontFamily = googleFontFamilies.notoSerif, hedFontFamily = googleFontFamilies.notoSerif, textTransform = 'none' } =
    overrides.typography || {};
  const { primaryFillColor } = overrides.colors || {};

  const themableColors: DeepPartial<Colors> = generateThemableColorValues(primaryFillColor);

  const buildVariantBody = createTextStyleVariantBuilder(bodyFontFamily);
  const buildVariantHed = createTextStyleVariantBuilder(hedFontFamily);

  const typography = mergeTypograhyThemeOverrides({
    variants: {
      body4: buildVariantBody(400, 17, 1.5, 0),
      label2: buildVariantBody(400, 13, 1.4, 0),

      hed2: buildVariantHed(700, 17, 1.36, 0.02),
      hed3: buildVariantHed(500, 20, 1.36, 0),
      hed4: {
        ...buildVariantHed(400, 20, 1.4, 0, { casing: 'uppercase' }),
        letterSpacing: '0.16em'
      },
      // same font-family as aloha (default themeing.ts file)
      hed5: {
        fontSize: pxToRem(24),
        lineHeight: 1.3
      },
      // same font-family as aloha (default themeing.ts file)
      hed6: {
        fontSize: pxToRem(24),
        lineHeight: 1.3,
        letterSpacing: '-0.01em'
      },

      display1: {
        fontFamily: eventFontFamily,
        lineHeight: 1.23,
        textTransform
      },
      display2: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display3: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display4: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display5: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display6: {
        fontFamily: eventFontFamily,
        textTransform
      },
      display7: {
        fontFamily: eventFontFamily,
        textTransform,
        lineHeight: 1.25
      },
      display8: {
        fontFamily: eventFontFamily,
        textTransform,
        lineHeight: 1.25
      },
      display9: {
        fontFamily: eventFontFamily,
        textTransform,
        lineHeight: 1.25
      },
      display10: {
        fontFamily: eventFontFamily,
        textTransform,
        lineHeight: 1.25
      },
      display11: {
        fontFamily: eventFontFamily,
        textTransform,
        lineHeight: 1.25
      }
    }
  });

  return {
    typography,
    colors: {
      ...themableColors
    }
  };
};

export const brannanConfig: LayoutConfigBrannan = {
  applicatorHtmlProps: {
    layerMiddle: {
      prop: { 'data-layer': 'middle' },
      selector: '[data-layer="middle"]'
    },
    layerBack: {
      prop: { 'data-layer': 'back' },
      selector: '[data-layer="back"]'
    },
    componentTitleBanner: {
      prop: { 'data-component': 'title-banner' },
      selector: '[data-component="title-banner"]'
    }
  },
  theme: createTheme({ defaults: BRANNAN_THEME_DEFAULTS, overrides: getBrannanThemeOverrides() }), // extendTheme(getBrannanThemeOverrides()),
  configureThemeOverrides: (eventDesign, currentTheme) => {
    const { font, colorPalette } = eventDesign;
    const [primaryFill] = colorPalette;
    const overrides = getBrannanThemeOverrides({
      typography: { eventFontFamily: font.fontFamily, textTransform: font.textTransform },
      colors: { primaryFillColor: primaryFill.color.hex }
    });
    return createTheme({ defaults: BRANNAN_THEME_DEFAULTS, overrides });
  },
  fonts: {
    google: ['greatVibes', 'notoSerif']
  },
  previewEventHandlers: {
    onLayoutChange: ({ history, eventPageRoutes }) => {
      if (eventPageRoutes.welcome) {
        history.push({ pathname: eventPageRoutes.welcome.path });
      }
      globalWindow.scrollTo?.(0, 0);
    },
    onThemeChange: () => {
      globalWindow.scrollTo?.(0, 0);
    },
    onFontChange: history => {
      globalWindow.scrollTo?.(0, 0);
    },
    onGraphicAccentChange: () => {
      const el = globalWindow.document?.getElementById('graphic-accent');
      el?.scrollIntoView();
    },
    onPrimaryFillColorChange: ({ history, eventPageRoutes }) => {
      if (eventPageRoutes.schedule) {
        const historySub = history.listen(() => {
          globalWindow.setTimeout(() => {
            const el = globalWindow.document?.getElementById('schedule');
            el?.scrollIntoView();
          }, 500);
          historySub();
        });
        history.push({ pathname: eventPageRoutes.schedule.path });
        return [historySub];
      }
      return [];
    }
  }
};

////////////////////////////////////////////////////////////////

const MONO_1: Readonly<number[]> = [51, 51, 51, 1];
const WHITE: Readonly<number[]> = [255, 255, 255, 1];

const _isBackgroundTransparencyEnabled = (
  preference?: EventDesignPreferenceRangeFragment,
  websiteLayoutBrannanSettings?: EventDesignFragment['theme']['websiteLayoutBrannanSettings']
) => {
  const brannanBackgroundTransparency = websiteLayoutBrannanSettings?.brannanBackgroundTransparency;
  return brannanBackgroundTransparency?.supportsCustomization
    ? preference
      ? preference.rangeValue < 1
      : false
    : brannanBackgroundTransparency
    ? brannanBackgroundTransparency.rangeValue <= 0.7
    : false;
};

export const getBrannanApplicatorConfig = (
  designPreferences: EventDesignPreferences,
  colorPalette: ReadonlyArray<ColorSetFragment>,
  websiteLayoutBrannanSettings: EventDesignFragment['theme']['websiteLayoutBrannanSettings'],
  isAlohaGuestPage?: boolean,
  isGuestSiteCustomColorEnabled?: boolean,
  overrideDefaultColors?: boolean
): ApplicatorConfigVariantsBrannan => {
  const { brannanColorPreference, brannanBackgroundTransparency, brannanInnerFrameBorder, alohaColorPreference } = parseDesignPreferences(designPreferences);

  const { primaryFillColor, primaryTextColor, customBackgroundColor } = parseColorPalette(colorPalette);

  const useSuggestedBackgroundColor = colorPointerUtils.isThemePointer(brannanColorPreference?.colorPointerPosition ?? undefined);
  const useSuggestedBackgroundColorForAloha = colorPointerUtils.isThemePointer(alohaColorPreference?.colorPointerPosition ?? undefined);

  // Note that we check isAlohaGuestPage inside brannan because we use Brannan layout on the guest registry
  // page no matter which template is chosen. See https://withjoy.atlassian.net/browse/COMMERCE-284
  const applicatorConfig = {
    layerBack: {
      customBackgroundColor: {
        isEnabled: isAlohaGuestPage ? !useSuggestedBackgroundColorForAloha : !useSuggestedBackgroundColor,
        textColor: MONO_1,
        backgroundColor: isAlohaGuestPage && primaryFillColor ? chroma(primaryFillColor.color.hex).rgba() : WHITE
      }
    },
    layerMiddle: {
      border: {
        isEnabled: brannanInnerFrameBorder?.booleanValue ?? true
      },
      backgroundTranslucency: {
        isEnabled: _isBackgroundTransparencyEnabled(brannanBackgroundTransparency, websiteLayoutBrannanSettings)
      }
    },
    componentTitleBanner: {
      customBackgroundColor:
        brannanColorPreference && primaryFillColor && primaryTextColor
          ? {
              isEnabled: !useSuggestedBackgroundColor,
              // NOTE: `title-banner` component will have its own text color in the future
              textColor: MONO_1,
              backgroundColor: chroma(primaryFillColor.color.hex).rgba()
            }
          : undefined
    }
  };

  if (isGuestSiteCustomColorEnabled) {
    if (customBackgroundColor) {
      overrideDefaultColors && (applicatorConfig.layerBack.customBackgroundColor.isEnabled = true);
      applicatorConfig.layerBack.customBackgroundColor.backgroundColor = chroma(customBackgroundColor.color.hex).rgba();
      primaryTextColor && (applicatorConfig.layerBack.customBackgroundColor.textColor = chroma(primaryTextColor.color.hex).rgba());

      if (applicatorConfig.componentTitleBanner.customBackgroundColor) {
        overrideDefaultColors && (applicatorConfig.componentTitleBanner.customBackgroundColor.isEnabled = true);
        applicatorConfig.componentTitleBanner.customBackgroundColor.backgroundColor = chroma(customBackgroundColor.color.hex).rgba();
        primaryTextColor && (applicatorConfig.componentTitleBanner.customBackgroundColor.textColor = chroma(primaryTextColor.color.hex).rgba());
      }
    }
  }

  return applicatorConfig;
};
////////////////////////////////////////////////////////////////

export const useBrannanDesign = (eventDesign: GuestSiteEventDesign, isAlohaGuestPage?: boolean) => {
  // FeatureFlag => wait for StyleApplicator https://github.com/joylifeinc/joy-web/pull/3214
  const styledApplicatorLoading = useFeatureValue('enableLoadingStyleApplicator');
  const isGuestSiteColorsExperimentEnabled = useFeatureValue('guestSiteColorsExperiment').value === 'treatment';

  const { designPreferences, colorPalette, customCss } = eventDesign || {};
  const isSmallScreen = useMediaQuery(getBrannanMobileMediaQuery);
  const themeId = eventDesign?.theme.themeId;
  const overrideDefaultColors = areDefaultColorsOverridden(eventDesign);

  // Get applicator config based on user design preferences
  const applicatorConfig = useMemo(() => {
    return designPreferences && colorPalette
      ? getBrannanApplicatorConfig(
          designPreferences,
          colorPalette,
          eventDesign?.theme.websiteLayoutBrannanSettings,
          isAlohaGuestPage,
          isGuestSiteColorsExperimentEnabled,
          overrideDefaultColors
        )
      : undefined;
  }, [designPreferences, colorPalette, eventDesign?.theme.websiteLayoutBrannanSettings, isAlohaGuestPage, isGuestSiteColorsExperimentEnabled, overrideDefaultColors]);

  const { loading } = useStyleApplicator(
    styleApplicator => {
      if (themeId) {
        const { layerBack, layerMiddle, componentTitleBanner } = brannanConfig.applicatorHtmlProps;

        // On small screens, apply styles to header
        if (isSmallScreen) {
          return [styleApplicator.applyComponentSprites(componentTitleBanner.selector, themeId, 'title-banner', applicatorConfig?.componentTitleBanner)];
        }

        // Apply styles to frames
        return [
          styleApplicator.applyCompositionSprites(layerBack.selector, themeId, 'stacked', 'back', applicatorConfig?.layerBack),
          styleApplicator.applyCompositionSprites(layerMiddle.selector, themeId, 'stacked', 'middle', applicatorConfig?.layerMiddle)
        ];
      }
      return [];
    },
    [themeId, isSmallScreen, applicatorConfig]
  );

  return { isSmallScreen, applicatorConfig, loading: styledApplicatorLoading ? loading : false, CustomStyle: customCss ? createGlobalStyle`${customCss}` : null };
};
