import { RefObject, useLayoutEffect, useRef } from 'react';
import { Updater } from 'use-immer';
import { SurfaceState } from './useSurface';

interface SelectionStrategy {
  surfacePointerDown?: (update: Updater<SurfaceState>) => void;
  isEditing(editingLayerIndex: number | undefined, layerIndex: number): boolean;
  useInitTextCaret(isEditing: boolean): RefObject<HTMLDivElement>;
}

const ClickSelectionStrategy: SelectionStrategy = {
  surfacePointerDown: update => {
    update(draft => {
      draft.activeLayerIndex = undefined;
    });
  },
  isEditing(editingLayerIndex: number | undefined, layerIndex: number) {
    return editingLayerIndex === layerIndex;
  },
  useInitTextCaret(isEditing: boolean) {
    const contentEditableRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      if (!isEditing) return;
      if (!contentEditableRef.current) return;
      const range = document.createRange();
      range.selectNodeContents(contentEditableRef.current);
      range.collapse(false);
      const sel = window.getSelection();
      sel?.removeAllRanges();
      sel?.addRange(range);
    }, [isEditing]);

    return contentEditableRef;
  }
};

export const CurrentSelectionStrategy = ClickSelectionStrategy;
