import { createTelemetry, createTelemetryObject } from '@shared/core';
import { GuestSiteQueryParams } from '@apps/guest/packages/layout-engine/common/utils/useGuestSiteQueryParams';

const action = 'MediaCollecionButtonInteracted';
const category = 'mediaCollection';

const mediaCollectionTelemetry = createTelemetryObject({
  pages: {
    albums: (userRole: string, args: GuestSiteQueryParams) => ({
      category,
      pagePrefix: 'guest',
      page: 'Albums',
      action: 'ViewAlbums',
      extraPageInfo: {
        role: userRole,
        ...args
      }
    }),
    viewAlbum: (userRole: string, args: GuestSiteQueryParams) => ({
      category,
      pagePrefix: 'guest',
      page: 'AlbumPhotos',
      action: 'ViewAlbumPhotos',
      extraPageInfo: {
        role: userRole,
        ...args
      }
    })
  },
  actions: {
    albumCardClicked: (eventId: string, albumId: string) => ({
      action,
      category,
      extraInfo: {
        name: 'AlbumCardClicked',
        eventId,
        albumId
      }
    }),
    photoCardClicked: (eventId: string, albumId: string, mediaId: string, photoUrl: string) => ({
      action,
      category,
      extraInfo: {
        name: 'ViewPhotoClicked',
        eventId,
        albumId,
        mediaId,
        photoUrl
      }
    }),
    uploadAlbumPhotoCTAClicked: (eventId: string, albumId: string) => ({
      action,
      category,
      extraInfo: {
        name: 'uploadAlbumPhotoButtonClicked',
        eventId,
        albumId
      }
    }),
    uploadAlbumPhoto: (eventId: string, albumId: string, mediaId: string, photoId: string, photoUrl: string) => ({
      action,
      category,
      extraInfo: {
        name: 'uploadAlbumPhoto',
        eventId,
        albumId,
        mediaId,
        photoId,
        photoUrl
      }
    })
  }
});

const { TelemetryProvider: MediaCollectionTelemetryProvider, useTelemetry: useMediaCollectionTelemetry } = createTelemetry(mediaCollectionTelemetry);
export { MediaCollectionTelemetryProvider, useMediaCollectionTelemetry };
