import {
  EventDesignFragment,
  DesignFeatureType,
  EventDesignPreferenceBooleanFragment,
  EventDesignPreferenceColorPointerFragment,
  EventDesignPreferenceRangeFragment,
  ColorPointerLocation,
  ColorSetFragment
} from '@graphql/generated';

export type EventDesignPreferences = Readonly<EventDesignFragment['designPreferences']>;
export type RangeDesignFeature = DesignFeatureType.brannanBackgroundTransparency;
export type BooleanDesignFeature = DesignFeatureType.brannanInnerFrameBorder;
export type ColorPointerDesignFeature = DesignFeatureType.alohaColorPreference | DesignFeatureType.brannanColorPreference;
export type GetDesignPreferenceReturn = EventDesignPreferenceColorPointerFragment | EventDesignPreferenceBooleanFragment | EventDesignPreferenceRangeFragment;

export function getDesignPreference(type: BooleanDesignFeature, designPreferences: EventDesignPreferences): EventDesignPreferenceBooleanFragment | undefined;
export function getDesignPreference(type: RangeDesignFeature, designPreferences: EventDesignPreferences): EventDesignPreferenceRangeFragment | undefined;
export function getDesignPreference(type: ColorPointerDesignFeature, designPreferences: EventDesignPreferences): EventDesignPreferenceColorPointerFragment | undefined;
export function getDesignPreference<T extends DesignFeatureType>(type: T, designPreferences: EventDesignPreferences): GetDesignPreferenceReturn | undefined {
  return designPreferences.find(preference => preference.featureType === type);
}

type UnwrapReadonly<T> = T extends Readonly<infer U> ? U : T;

type ParseDesignPreferencesReturn = Readonly<{
  [DesignFeatureType.alohaColorPreference]: EventDesignPreferenceColorPointerFragment | undefined;
  [DesignFeatureType.alohaTextColorPreference]: EventDesignPreferenceColorPointerFragment | undefined;

  [DesignFeatureType.brannanColorPreference]: EventDesignPreferenceColorPointerFragment | undefined;
  [DesignFeatureType.brannanTextColorPreference]: EventDesignPreferenceColorPointerFragment | undefined;
  [DesignFeatureType.brannanBackgroundTransparency]: EventDesignPreferenceRangeFragment | undefined;
  [DesignFeatureType.brannanInnerFrameBorder]: EventDesignPreferenceBooleanFragment | undefined;
}>;

const BASE_PARSED_DESIGN_PREFERENCES: ParseDesignPreferencesReturn = (Object.keys(DesignFeatureType) as DesignFeatureType[]).reduce((acc, featureType: DesignFeatureType) => {
  acc[featureType] = undefined;
  return acc;
}, {} as UnwrapReadonly<ParseDesignPreferencesReturn>);

export const parseDesignPreferences = (designPreferences: EventDesignPreferences): ParseDesignPreferencesReturn => {
  return designPreferences.reduce(
    (acc, curr) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      acc[curr.featureType] = curr as any;
      curr;
      return acc;
    },
    { ...BASE_PARSED_DESIGN_PREFERENCES }
  );
};

////////////////////////////////////////////////////////
// Color

export const isOfColorPointerType = <T extends ColorPointerLocation>(target: T, source?: ColorPointerLocation): source is T => {
  return target === source;
};

export const colorPointerUtils = {
  isThemePointer: (pointerLocation?: ColorPointerLocation): pointerLocation is ColorPointerLocation.theme => isOfColorPointerType(ColorPointerLocation.theme, pointerLocation),
  isEventPointer: (pointerLocation?: ColorPointerLocation): pointerLocation is ColorPointerLocation.event => isOfColorPointerType(ColorPointerLocation.event, pointerLocation)
} as const;

type ParseColorPaletteReturn = Readonly<{
  primaryFillColor: ColorSetFragment | undefined;
  primaryTextColor: ColorSetFragment | undefined;
  customBackgroundColor: ColorSetFragment | undefined;
}>;

export const parseColorPalette = (colorPalette: ReadonlyArray<ColorSetFragment>): ParseColorPaletteReturn => {
  const [primaryFillColor, primaryTextColor, customBackgroundColor] = colorPalette;
  return {
    primaryFillColor,
    primaryTextColor,
    customBackgroundColor
  };
};
