import { Box, css, Flex, keyframes, styled, StyleSystemProps, TextV2 } from '@withjoy/joykit';
import { ReactComponent as WideArrowDownIcon } from '@assets/icons/wide-arrow-down.svg';

export const StyledRoot = styled(Flex)``;
const rootStyles: StyleSystemProps = {
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  rowGap: ['1.5rem', '3rem']
};

const fadeOut = keyframes`
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`;
const fadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const arrowBounce = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-23px);
  }
  60% {
    transform: translateY(-23px);
  }
`;

const fadeInMixin = () => css`
  ${fadeIn} 500ms forwards 1.5s;
`;

const fadeOutMixin = () => css`
  ${fadeOut} 500ms forwards 0.3s;
`;

export const StyledBoxViewDetails = styled(Box)<{ show: boolean }>`
  animation: ${({ show }) => (show ? fadeInMixin : fadeOutMixin)};
  ${({ show }) => {
    return show
      ? {
          opacity: 0
        }
      : {
          transition: `visibility 0s ease 1.5s`,
          visibility: 'hidden',
          opacity: 1
        };
  }}
`;

export const StyledBoxArrowIcon = styled(WideArrowDownIcon)`
  opacity: 0.8;
  height: 15px;
  line-height: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  overflow: hidden;
  animation: ${arrowBounce} 4s ease-out 1.5s infinite;
`;

export const StyledBoxText = styled(TextV2)`
  height: 35px;
  text-align: center;
  color: inherit;
  line-height: 35px;
`;

export const StyledBoxArrow = styled(Box)`
  height: 49px;
  text-align: center;
  position: relative;
  margin: 0 auto;
  color: inherit;
  text-align: center;
`;

export const styles = {
  root: rootStyles
};
