import React from 'react';
import { usePriceSummaryController, PriceUpTo, CardPromotion } from './PriceSummary.controller';
import { BoxProps, Flex, TextV2 } from '@withjoy/joykit';
import { PRICE_CONFIG } from '@apps/card/config';
import { CardEnvelopeReturnAddressLayout, ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { findFoilLayer } from '@apps/card/Card.utils';

export interface PriceSummaryProps extends Omit<BoxProps, 'as' | '__css'> {
  templateCategory: StationeryTemplateCategoryEnum;
  cardConfig: ThemeJson;
  title?: string;
  priceUpTo: PriceUpTo;
  salesTax?: number | null | undefined;
  /**
   * Promotion can come from the draft or the print order (depends on whether the user has
   * checked out or not)
   */
  promotion?: Maybe<CardPromotion>;
  children: (summary: ReturnType<typeof usePriceSummaryController>) => React.ReactElement;
  cardSize?: {
    width: number;
    height: number;
  };
}

export const deriveFullCustomizationsFromConfig = (config: ThemeJson) => {
  return {
    quantity: config.customizations.quantity,
    size: config.customizations.size,
    paperType: config.customizations.paperType,
    shape: config.customizations.shape,
    backLayout: config.card.back.layout,

    // In practice `layout` may be undefined contrary to the type declaration.
    // This is a patch to unblock customers. Later we will need to update the type (and the downstream effects).
    returnAddress: config.card.envelope.layout ?? CardEnvelopeReturnAddressLayout.none,

    recipientAddress: config.card.envelope.recipientAddressLayout,
    delivery: config.customizations.delivery,
    foilData: findFoilLayer(config.card.front.layers)?.foilData
  };
};

export const usePriceSummary = (args: Pick<PriceSummaryProps, 'templateCategory' | 'priceUpTo' | 'cardConfig' | 'promotion' | 'salesTax'>) => {
  const { cardConfig, templateCategory, priceUpTo, promotion, salesTax } = args;
  const summary = usePriceSummaryController(templateCategory, priceUpTo, PRICE_CONFIG, deriveFullCustomizationsFromConfig(cardConfig), promotion, salesTax);
  return summary;
};

const PriceSummary: React.FC<PriceSummaryProps> = ({ templateCategory, cardConfig, title, children, priceUpTo, promotion, salesTax, cardSize, ...restProps }) => {
  const summary = usePriceSummaryController(templateCategory, priceUpTo, PRICE_CONFIG, deriveFullCustomizationsFromConfig(cardConfig), promotion, salesTax, cardSize);

  const { t } = useTranslation('stationery');
  if (!summary) {
    return null;
  }

  const trans = t('cardCustomizer', 'priceSummary');
  const discountedTotal = summary.discountedTotal;
  const isDiscounted = !!discountedTotal;

  return (
    <Flex flexDirection="column" rowGap={6} {...restProps}>
      {title && <TextV2 typographyVariant="hed3">{title}</TextV2>}
      {children(summary)}
      <Flex flexDirection="column" paddingTop={3} borderTop="1px solid #EBEBEB">
        {promotion && (
          <Flex justifyContent="space-between" marginBottom={3}>
            <TextV2 typographyVariant="label2" color="positive7">
              {trans.discountSection.label({ code: promotion.code })}
            </TextV2>
            {summary.discountPercentage && (
              <TextV2 typographyVariant="label2" color="positive7">
                {trans.discountSection.value({ discount: summary.discountPercentage * 100 })}
              </TextV2>
            )}
          </Flex>
        )}

        <Flex justifyContent="space-between" alignItems="center">
          <TextV2 typographyVariant="hed3" fontSize="15px">
            {trans.totalLabel()}
          </TextV2>
          {isDiscounted ? (
            <Flex columnGap={4}>
              <TextV2 typographyVariant="hed3" textDecoration="line-through" fontWeight={400}>
                {summary.total}
              </TextV2>
              <TextV2 typographyVariant="hed3">{discountedTotal}</TextV2>
            </Flex>
          ) : (
            <TextV2 typographyVariant="hed3">{summary.total}</TextV2>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PriceSummary;
