import React, { useMemo, useState } from 'react';

import { addHours, addMinutes, format, parseISO } from 'date-fns';
import { Box, ButtonV2, Flex, LinkV2, TextV2 } from '@withjoy/joykit';
import { useIsMobileOrTablet } from '@shared/utils/media/useMediaScreens';
import { Link, useTranslation } from '@shared/core';
import OrderInfo from '@apps/card/components/Blocks/OrderInfo';
import { ProgressTracker } from './components/ProgressTracker';
import {
  StationeryPrintOrderDetailsFragment,
  StationeryPrintOrderStatusEnum,
  useCreateStationeryDraftFromPrintOrderMutation,
  StationeryPrintOrderIssueType,
  StationeryDraftFormat
} from '@graphql/generated';
import { PaymentSummary } from './components/PaymentSummary';
import { ConciergePaymentSummary } from './components/ConciergePaymentSummary';
import { InlineMessage } from './components/InlineMessage';
import { PRINT_ORDER_STATUS_TO_CONSOLIDATED_STATUS } from './PrintJobDetails.constants';
import { usePrintJobDetailsTelemetryProxyContext } from './PrintJobDetailsTelemetryProxyContext';
import { ThemeJson, CardEnvelopeRecipientAddressLayout, CardEnvelopeRecipientAddressSelection } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { animationTransition } from '@shared/utils/animationTransition';
import EditCardCountdown from './components/EditCardCountdown';
import EditRecipientAddress from './components/EditRecipientAddress';
import useEnvelopePreview from '@apps/card/hooks/useEnvelopePreview';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { BLANK_ENVELOPE_FOREGROUND, DraftPreview } from '../DraftPreview';

const OrderMetadata = ({ orderNumber, orderPlaced }: { orderNumber: string; orderPlaced?: string }) => {
  const orderPlacedFormatted = orderPlaced && format(parseISO(orderPlaced), 'PPP');
  return (
    <Flex rowGap={2} flexDirection="column" textAlign={{ md: 'right' }} paddingX={{ _: 6, sm2: 8, md: 0 }}>
      <TextV2 fontWeight="bold" typographyVariant="body1">{`Order Number: ${orderNumber}`}</TextV2>
      {orderPlacedFormatted && <TextV2 typographyVariant="body1">{`Order Placed: ${orderPlacedFormatted}`}</TextV2>}
    </Flex>
  );
};

interface PrintJobDetailsProps {
  order: StationeryPrintOrderDetailsFragment;
  variant: 'overview' | 'detailed';
  getToOrderDetailsPath?: (printOrderNumber: string) => string;
}

export const PrintJobDetails = (props: PrintJobDetailsProps) => {
  const telemetryContext = usePrintJobDetailsTelemetryProxyContext();
  const { t2 } = useTranslation('stationery');
  const courierErrorMessage = t2('cardCustomizer', 'courierErrorMessage');
  const envelopePreview = useEnvelopePreview({ order: props.order });
  const [createDraftFromOrder, { loading: isCreatingDraftFromOrder }] = useCreateStationeryDraftFromPrintOrderMutation();
  const { goToCustomizeDraft, goToDigitalCustomizeDraft, goToCustomizeOrderDetails } = useCardsRouterContext();

  const { variant, order, getToOrderDetailsPath } = props;
  const isMobileOrTablet = useIsMobileOrTablet();

  const [previewImage, setPreviewImage] = useState(0);

  const orderNumber = useMemo(() => order.orderNumber, [order.orderNumber]);
  const orderPlaced = useMemo(() => order.printPayment?.paidAt, [order.printPayment?.paidAt]);
  const editingEndsAt = useMemo(() => (orderPlaced ? addMinutes(addHours(new Date(orderPlaced), 24), -5).toISOString() : null), [orderPlaced]);

  const orderTrackingUrl = useMemo(() => order.trackingNumbers?.[0]?.trackingUrl, [order.trackingNumbers]);

  const shippingAddress = useMemo(() => {
    const { address1, address2, city, name, region, postalCode, countryCode } = order.shippingAddress;
    let addressLine = address1;
    if (address2) {
      addressLine += `, ${address2}`;
    }
    const cityLine = `${city}, ${region} ${postalCode} ${countryCode}`;
    return `${name}\n${addressLine}\n${cityLine}`;
  }, [order.shippingAddress]);

  const lastCanceledAtStatus = useMemo(() => {
    if (!order.statusHistory) {
      return null;
    }

    return [...order.statusHistory].sort((a, b) => new Date(b.date).valueOf() - new Date(a.date).valueOf()).find(entity => entity.status === 'canceled');
  }, [order.statusHistory]);
  const isErrorStatus = useMemo(() => order.currentStatus === StationeryPrintOrderStatusEnum.error, [order.currentStatus]);
  let consolidatedOrderStatus = PRINT_ORDER_STATUS_TO_CONSOLIDATED_STATUS[order.currentStatus] ?? 'orderPlaced';

  const isOrderCanceled = consolidatedOrderStatus === 'canceled' && !order.refunds.length;
  let isOrderRefunded = false;

  if (!!order.refunds.length) {
    isOrderRefunded = true;
    consolidatedOrderStatus = 'refunded';
  }

  const isOrderInNormalFlow = !isOrderCanceled && !isOrderRefunded;
  // If the order is paused, we want editing enabled.  Tracking this explicitly to avoid confusion.
  const isOrderPaused = order.currentStatus === StationeryPrintOrderStatusEnum.paused;
  const isOrderEditable = isOrderPaused === true || (editingEndsAt && [StationeryPrintOrderStatusEnum.paid, StationeryPrintOrderStatusEnum.paused].includes(order.currentStatus));

  const cardJSON = order.cardDraftJSONBlob as ThemeJson | null;
  const isNotConciergeOrder = !!cardJSON;

  const recipientAddressOrderPendingIssue = order.issues?.find(issue => issue.issueType === StationeryPrintOrderIssueType.recipient_address_incomplete && !issue.resolvedAt);

  const hasRecipientAddress = !!(
    cardJSON?.card.envelope.recipientAddressLayout === CardEnvelopeRecipientAddressLayout.front &&
    cardJSON.card.envelope.recipientAddressSelection !== CardEnvelopeRecipientAddressSelection.now &&
    recipientAddressOrderPendingIssue
  );

  const previewImages = [
    order.printCardFrontPreviewUrl,
    order.printCardBackPreviewUrl,
    ...(envelopePreview.isPreviewAvailable ? [envelopePreview.frontImageUrl, envelopePreview.backImageUrl] : [])
  ];

  const handleCreateDraftFromOrder = async (format: StationeryDraftFormat) => {
    telemetryContext?.onReorderClick();

    const { data } = await createDraftFromOrder({
      variables: {
        data: {
          orderId: props.order.id,
          format
        }
      }
    });

    const draftId = data?.createStationeryDraftFromPrintOrder?.id;

    if (draftId) {
      if (format === StationeryDraftFormat.print) {
        goToCustomizeDraft(draftId);
      } else {
        goToDigitalCustomizeDraft(draftId);
      }
    }
  };

  return (
    <Flex flexDirection="column" rowGap={10}>
      <Flex columnGap={{ md: 10 }} flexDirection={{ _: 'column', md: 'row' }} rowGap={10}>
        <Flex flexDirection="column" rowGap={8} flex={1}>
          {isMobileOrTablet && <OrderMetadata orderNumber={orderNumber} orderPlaced={orderPlaced} />}
          {!isNotConciergeOrder && (
            <Flex flexDirection="column" gap={5} paddingX={{ _: 6, md: 0 }} justifyContent="center">
              <DraftPreview images={{ cardFront: previewImages[0]! }} />
            </Flex>
          )}
          {isNotConciergeOrder && (
            <Flex flexDirection="column" gap={5} paddingX={{ _: 6, md: 0 }} justifyContent="center">
              {previewImage === 0 && <DraftPreview images={{ cardFront: previewImages[0]! }} diecut={cardJSON.customizations.shape} />}
              {previewImage === 1 && <DraftPreview images={{ cardBack: previewImages[1]! }} diecut={cardJSON.customizations.shape} />}
              {previewImage === 2 && <DraftPreview images={{ envelopFront: previewImages[2] ?? BLANK_ENVELOPE_FOREGROUND }} />}
              {previewImage === 3 && <DraftPreview images={{ envelopeBack: previewImages[3] ?? BLANK_ENVELOPE_FOREGROUND }} />}
              <Flex justifyContent="center" gap={1} display={{ _: 'flex', md: 'none' }}>
                {previewImages
                  .filter(p => p !== undefined)
                  .map((url, index) => (
                    <Flex
                      _after={{
                        position: 'absolute',
                        content: '" "',
                        width: '100%',
                        bottom: -12,
                        backgroundColor: 'mono10',
                        borderRadius: 2,
                        height: '2px',
                        transition: animationTransition('opacity'),
                        opacity: index === previewImage ? 1 : 0
                      }}
                      position="relative"
                      cursor="pointer"
                      onClick={() => setPreviewImage(index)}
                      zIndex={50}
                      width={pxToRem(52)}
                      height={pxToRem(52)}
                      key={index}
                      padding={1}
                      justifyContent="center"
                      alignItems="center"
                      backgroundColor="mono2"
                    >
                      {[0, 1].includes(index) && <Box as="img" src={url!} alt="Card Preview" width="100%" height="100%" objectFit="contain" objectPosition="center" />}
                      {index === 2 && <DraftPreview images={{ envelopFront: url ?? BLANK_ENVELOPE_FOREGROUND }} />}
                      {index === 3 && <DraftPreview images={{ envelopeBack: url ?? BLANK_ENVELOPE_FOREGROUND }} />}
                    </Flex>
                  ))}
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex flexDirection="column" paddingY={{ md: 8 }} paddingX={{ _: 6, md: 0 }} flex={1} rowGap={10}>
          {!isMobileOrTablet && <OrderMetadata orderNumber={orderNumber} orderPlaced={orderPlaced} />}

          <ProgressTracker status={consolidatedOrderStatus} />
          {isErrorStatus && (
            <InlineMessage
              icon="error"
              message={
                <>
                  {courierErrorMessage.text1}{' '}
                  <LinkV2
                    _activeLink={{ color: 'mono14' }}
                    _visited={{ color: 'mono14' }}
                    _active={{ color: 'mono14' }}
                    _pressed={{ color: 'mono14' }}
                    _hover={{ color: 'mono14', textDecoration: 'underline #333333' }}
                    textDecoration="underline #D6D6D6"
                    color="mono14"
                    fontSize="15px"
                    href={orderTrackingUrl}
                    isExternal
                    onClick={() => telemetryContext?.onVisitCourierPageToResolveIssueClick()}
                  >
                    {courierErrorMessage.link}
                  </LinkV2>{' '}
                  {courierErrorMessage.text2}
                </>
              }
            />
          )}
          {hasRecipientAddress && isOrderInNormalFlow && isNotConciergeOrder && isOrderEditable && (
            /* TODO: Add Telemetry */
            <EditRecipientAddress orderNumber={order.orderNumber} orderId={order.id} onEditClick={() => {}} pendingIssue={recipientAddressOrderPendingIssue} />
          )}
          {isOrderInNormalFlow && isNotConciergeOrder && isOrderEditable && (
            <EditCardCountdown
              editingEndsAt={editingEndsAt}
              isOrderPaused={isOrderPaused}
              onEditClick={() => {
                telemetryContext?.onEditOrderClick();
                goToCustomizeOrderDetails(order.orderNumber);
              }}
            />
          )}
          {isOrderCanceled && <InlineMessage icon="info" title="Refund is Being Processed" message="You will be refunded within 10 business days." />}
          <Flex columnGap={10} flexDirection="column" rowGap={10} width={{ _: '100%', sm2: 400, md: 'unset' }} alignSelf={{ _: 'center', md: 'unset' }}>
            {isOrderInNormalFlow && (
              <Flex flexDirection={{ _: 'column', md: 'row' }} gap={10} width="100%" justifyContent="space-between">
                <OrderInfo iconVariant="pin" title="Shipping To" description={shippingAddress} />
                <Flex flexDirection="column" gap={5}>
                  <ButtonV2
                    width={{ md: 250 }}
                    flex="none"
                    as="a"
                    disabled={!orderTrackingUrl}
                    href={orderTrackingUrl}
                    target="_blank"
                    intent="neutral"
                    shape="rounded"
                    onClick={() => telemetryContext?.onTrackOrderClick()}
                  >
                    Track Your Order
                  </ButtonV2>
                  {isNotConciergeOrder && (
                    <>
                      <ButtonV2
                        onClick={() => handleCreateDraftFromOrder(StationeryDraftFormat.print)}
                        loading={isCreatingDraftFromOrder}
                        width={{ md: 250 }}
                        flex="none"
                        intent="neutral"
                        shape="rounded"
                        variant="outline"
                      >
                        Order Again
                      </ButtonV2>
                      <ButtonV2
                        width={{ md: 250 }}
                        flex="none"
                        onClick={() => handleCreateDraftFromOrder(StationeryDraftFormat.digital)}
                        loading={isCreatingDraftFromOrder}
                        intent="neutral"
                        shape="rounded"
                        variant="outline"
                      >
                        Order Matching Digital Card
                      </ButtonV2>
                    </>
                  )}
                </Flex>
              </Flex>
            )}
            {isOrderCanceled && (
              <OrderInfo
                iconVariant="info"
                title="Order Cancelled"
                description="Your order has been successfully cancelled and your refund is being processed within 10 business days."
              />
            )}
            {isOrderRefunded && (
              <OrderInfo
                iconVariant="info"
                title={`Order Cancelled on ${format(new Date(lastCanceledAtStatus?.date || 0), 'MMMM do, yyyy')}`}
                description="Your order has been cancelled and your refund has been successfully processed."
              />
            )}
            {(!isOrderInNormalFlow || variant === 'detailed') && (
              <Flex flexDirection={{ _: 'column', md: 'row' }} gap={10} width="100%" justifyContent="space-between">
                <OrderInfo iconVariant="info" title="Need help with your order?" description="We’re here to get you sorted." />
                <ButtonV2
                  width={{ md: 250 }}
                  flex="none"
                  as="a"
                  href="mailto:printsupport@withjoy.com"
                  intent="neutral"
                  shape="rounded"
                  variant="outline"
                  onClick={() => telemetryContext?.onContactSupportClick()}
                >
                  Contact Support
                </ButtonV2>
              </Flex>
            )}

            {isOrderInNormalFlow && variant === 'overview' && getToOrderDetailsPath && (
              <ButtonV2
                width={{ _: '100%', md: 250 }}
                alignSelf="flex-end"
                as={Link}
                to={getToOrderDetailsPath(orderNumber)}
                intent="neutral"
                variant="outline"
                shape="rounded"
                onClick={() => telemetryContext?.onGoToOrderDetailsClick()}
              >
                Order Summary
              </ButtonV2>
            )}
          </Flex>
        </Flex>
      </Flex>
      {variant === 'detailed' &&
        (isNotConciergeOrder ? (
          <>
            <PaymentSummary order={props.order} />
          </>
        ) : (
          <>
            <ConciergePaymentSummary order={props.order} />
          </>
        ))}
    </Flex>
  );
};
