export const allowedDomains = new Set([
  'abnb.me',
  'account.secureserver.net',
  'airbnb.ca',
  'airbnb.co.uk',
  'airbnb.com.au',
  'airbnb.fr',
  'airbnb.com',
  'airbnb.ie',
  'accor.com',
  'accorhotels.com',
  'acehotel.com',
  'agoda.com',
  'alltrails.com',
  'amazon.ca',
  'amazon.co.uk',
  'amazon.com.mx',
  'amazon.com',
  'anantara.com',
  'anthropologie.com',
  'babylist.com',
  'bainbridgeisland.com',
  'bedbathandbeyond.ca',
  'bedbathandbeyond.com',
  'bestwestern.com',
  'bit.ly',
  'bloomingdales.com',
  'blueprintregistry.com',
  'book-directonline.com',
  'book.passkey.com',
  'booking.com',
  'booking.eu',
  'brides.com',
  'butcombe.com',
  'caesars.com',
  'cafe-turko.com',
  'cash.app',
  'castledurrow.com',
  'cb2.com',
  'chihulygardenandglass.com',
  'choicehotels.com',
  'cloudbeds.com',
  'collectionpot.com',
  'colshawhall.com',
  'crateandbarrel.ca',
  'crateandbarrel.com',
  'davidjones.com',
  'destinationweddings.com',
  'dillards.com',
  'disneyland.disney.go.com',
  'docs.google.com',
  'drive.google.com',
  'easyregistry.co.nz',
  'easyregistry.com.au',
  'edgewaterhotel.com',
  'en.wikipedia.org',
  'etsy.com',
  'expedia.com',
  'facebook.com',
  'fairmont.com',
  'finestresorts.com',
  'food52.com',
  'g.co',
  'gmail.com',
  'goo.gl',
  'google.com',
  'gov',
  'gov.uk',
  'hemanaire.com',
  'hertz.com',
  'hiexpress.com',
  'hilton.com',
  'hitchd.com',
  'holidayinn.com',
  'homedepot.com',
  'honeyfund.com',
  'hotels.com',
  'hotmail.co.uk',
  'hotmail.com',
  'hyatt.com',
  'ihg.com',
  'ikea.com',
  'instagram.com',
  'johnlewis.com',
  'justgiving.com',
  'kayak.com',
  'ktima-anthidon.gr',
  'lecreuset.co.za',
  'lekkeslaap.co.za',
  'loewshotels.com',
  'lonelyplanet.com',
  'macys.com',
  'mammothseattle.com',
  'maps.app.goo.gl',
  'marriagegiftlist.com',
  'marriott.com',
  'melia.com',
  'mews.com',
  'museumofflight.org',
  'myregistry.com',
  'nantucketresortcollection.com',
  'nationaltrust.org.uk',
  'niagaraparks.com',
  'omnihotels.com',
  'open.spotify.com',
  'outlook.com',
  'palaceresorts.com',
  'paradiseweddings.com',
  'patchworkit.com',
  'paypal.me',
  'pdfhost.io',
  'photos.app.goo.gl',
  'pikeplacemarket.org',
  'pin.it',
  'pinterest.com',
  'potterybarn.ca',
  'potterybarn.com',
  'premierinn.com',
  'prezola.com',
  'radissonhotels.com',
  'radissonhotelsamericas.com',
  'rei.com',
  'relayrestaurantgroup.com',
  'reservations.travelclick.com',
  'reservemygroup.com',
  'rome2rio.com',
  'shaadidestinations.com',
  'simon.com',
  'skyscanner.net',
  'sonesta.com',
  'soundtransit.org',
  'statuscake.com',
  'stripe.com',
  'surlatable.com',
  'swansea.com',
  'synxis.com',
  'target.com',
  'tgt.gifts',
  'thebay.com',
  'theclubatsolaz.com',
  'thedogpeover.co.uk',
  'theknot.com',
  'thetrainline.com',
  'thewalrusbar.com',
  'timeout.com',
  'topgolf.com',
  'travelclick.com',
  'travelersjoy.com',
  'travelodge.co.uk',
  'tripadvisor.ca',
  'tripadvisor.co.uk',
  'tripadvisor.com.au',
  'tripadvisor.com',
  'uber.com',
  'urbanoutfitters.com',
  'urldefense.com',
  'venmo.com',
  'viator.com',
  'vimeo.com',
  'visitphilly.com',
  'visitscotland.com',
  'vrbo.com',
  'walmart.ca',
  'walmart.com',
  'wayfair.com',
  'weddingpresentco.com',
  'weddingshop.com',
  'westcoastkids.ca',
  'westelm.com',
  'williams-sonoma.com',
  'withjoy.com',
  'wyndhamhotels.com',
  'yahoo.com',
  'yelp.com',
  'youtu.be',
  'youtube.com',
  'yuppiechef.com',
  'zola.co',
  'zola.com'
]);
