import { Skeleton } from '@shared/components/Skeleton';
import { Box, ButtonV2, Flex, FlexProps, TextV2 } from '@withjoy/joykit';
import type { Property } from 'csstype';
import React, { ReactNode } from 'react';
import { ResponsiveValue } from 'styled-system';
import { CheckFilled, Circle } from '@withjoy/joykit/icons';

interface ChoiceGroupProps<Value> {
  title?: string;
  subTitle?: string;
  value: Value;
  price?: string;
  choices?: Value[];
  columns?: number;
  minHeight?: number;
  setValue?: (value: Value) => void;
  getName?: (value: Value) => string;
  getOpacity?: (value: Value) => number;
  children?: ReactNode;
  hideValueFromTitle?: boolean;
  textTransform?: ResponsiveValue<Property.TextTransform>;
  icon?: ReactNode;
  direction?: 'row' | 'column';
}

const Root: React.FC = ({ children }) => {
  return (
    <Flex rowGap={3} flexDirection="column" width="100%">
      {children}
    </Flex>
  );
};

const Eyebrow: React.FC<Pick<FlexProps, 'height' | 'alignItems'>> = ({ children, height, alignItems }) => {
  return (
    <Flex columnGap={4} height={height} alignItems={alignItems}>
      {children}
    </Flex>
  );
};

const ChoicesContainer: React.FC<{ columns?: number; direction?: 'row' | 'column' }> = ({ children, columns, direction = 'row' }) => {
  return direction === 'column' ? (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" rowGap={5}>
      {children}
    </Flex>
  ) : (
    <Box display="grid" gridTemplateColumns={`repeat(${columns ?? 2}, 1fr)`} columnGap={3} rowGap={5} width="100%">
      {children}
    </Box>
  );
};

export function ChoiceGroup<Value>(props: ChoiceGroupProps<Value>) {
  const {
    title,
    subTitle,
    value,
    hideValueFromTitle,
    price,
    choices,
    columns,
    minHeight,
    setValue,
    getName,
    getOpacity,
    children: originalChildren,
    textTransform,
    icon,
    direction = 'row'
  } = props;

  const children = Array.isArray(originalChildren) ? originalChildren.filter(el => el) : originalChildren;

  return (
    <Root>
      <Eyebrow alignItems={!!icon ? 'center' : undefined}>
        {title && (
          <>
            <TextV2 typographyVariant="hed1">{title}</TextV2>
            {!hideValueFromTitle && (
              <TextV2 typographyVariant="body1" color="mono12" textTransform={textTransform ?? 'capitalize'}>
                {getName?.(value) ?? value}
              </TextV2>
            )}
            {icon}
          </>
        )}
        {price && (
          <TextV2 typographyVariant="hed1" color="mono12" marginLeft="auto">
            {price}
          </TextV2>
        )}
      </Eyebrow>
      {subTitle && (
        <TextV2 typographyVariant="body1" color="mono14" marginBottom={2}>
          {subTitle}
        </TextV2>
      )}
      {!choices ? (
        // In cases such as a Select/Dropdown.
        children
      ) : (
        // In cases where the choices are text or images.
        <ChoicesContainer columns={columns} direction={direction}>
          {choices.map((choice, i) => {
            const choiceVisual = Array.isArray(children) ? children[i] : undefined;
            return (
              <ButtonV2
                key={i}
                alignItems="center"
                justifyContent="center"
                borderColor={value === choice ? 'mono14' : 'mono5'}
                borderWidth={value === choice ? '2px' : '1px'}
                borderRadius={8}
                cursor="pointer"
                flex={0}
                height="unset"
                intent="neutral"
                minHeight={minHeight ?? 98}
                onClick={() => setValue?.(choice)}
                opacity={getOpacity?.(choice) ?? 1}
                padding={direction === 'column' ? 5 : 2}
                variant="outline"
                whiteSpace="normal" // We often have content that is multi-line, with limited width
                _active={{
                  backgroundColor: 'transparent'
                }}
                _hover={{
                  backgroundColor: 'transparent'
                }}
                fullWidth={direction === 'column'}
                startIcon={direction === 'column' ? value === choice ? <CheckFilled size="md" marginRight={2} /> : <Circle size="md" fill="white" marginRight={2} /> : undefined}
              >
                <Flex columnGap={3} flex={1} height="100%" alignItems="center" justifyContent={direction === 'column' ? 'flex-start' : 'center'}>
                  {choiceVisual ? (
                    choiceVisual
                  ) : (
                    <TextV2 key={i} typographyVariant="body1" color="mono12" textTransform="capitalize">
                      {choice}
                    </TextV2>
                  )}
                </Flex>
              </ButtonV2>
            );
          })}
        </ChoicesContainer>
      )}
    </Root>
  );
}

const ChoicesSkeleton = React.memo(({ minHeight = 98, choices }: { minHeight?: number; choices: number }) => {
  return (
    <>
      {Array.from({ length: choices }).map((_, index) => (
        <Skeleton borderRadius={8} key={index} minHeight={minHeight} />
      ))}
    </>
  );
});

ChoiceGroup.Skeleton = ({
  choices = 0,
  columns,
  disablePriceSkeleton,
  minHeight
}: Pick<ChoiceGroupProps<unknown>, 'columns' | 'minHeight'> & { disablePriceSkeleton?: boolean; choices?: number }) => {
  return (
    <Root>
      <Eyebrow height={20}>
        <Skeleton width={80} />
        <Skeleton width={60} />
        {!disablePriceSkeleton && <Skeleton width={70} marginLeft="auto" />}
      </Eyebrow>
      {choices > 0 && (
        <ChoicesContainer columns={columns}>
          <ChoicesSkeleton minHeight={minHeight} choices={choices} />
        </ChoicesContainer>
      )}
    </Root>
  );
};
