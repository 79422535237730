/* istanbul ignore file */
// FIXME: Add tests

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DesignLayoutType,
  EventDesignPurpose,
  EventWebsiteGuestQuery,
  useEventWebsiteGuestQuery,
  useEventHotelReservedRoomBlocksQuery,
  useEventWebsiteGuestTelemetryQuery
} from '@graphql/generated';
import { useImmer } from 'use-immer';
import { isInIframe } from '@shared/utils/isInIframe';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { shouldUseEventDesignDraft, useEventDesignTheming, useReloadForLegacyGuestsite, useGenerateGuestSiteLoadTelemData } from './GuestSite.utils';
import { useEventPageRouting } from './GuestSite.routes';
import { OnPreviewListenerUpdate } from './PreviewListener';
import { useGuestSiteTelemetry } from '../../GuestSite.telemetry';
import type { StaticContext } from '@react-router';
import { useQueryParamHelper } from '@shared/core/queryString';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useAppPage } from '@shared/utils/hooks/useAppPage';
import { useApolloClient } from '@apollo/client';
import { useGuestSiteHealth } from './GuestSite.health';
import useClient from '@shared/utils/hooks/useClient';
import { useSegmentGroupCallForEvent } from '@shared/core';
import { useComponentMountAndGraphqlQueryStatus } from '@shared/utils/hooks/useComponentMountAndGraphqlQueryStatus';
import { withWindow } from '@shared/utils/withWindow';
import { useHistory } from '@shared/core';
import { useGuestSiteState } from './GuestSite.state.provider';
import { usePagesWithPhotoAlignment } from '@shared/customPage/hooks/usePagesWithPhotoAlignment';
import { useLayoutAndThemeOverride } from '@apps/common/hooks/useLayoutAndThemeOverride';
import { useEventPlaceCacheUpdate } from '@shared/utils/eventPlace';

interface GuestSiteArgs
  extends Readonly<{
    eventHandle: string;
    staticContext: (StaticContext & { guestSiteEventHandleToVerify?: string }) | undefined;
  }> {}

const isPreviewing = isInIframe();

//Pages contained own additional lists of data.
const refetchPageQueries: Record<string, string[]> = {
  story: ['GetGuestsiteCoupleStoryAndQandas'],
  schedule: ['GetGuestSiteSchedule'],
  travel: ['GetGuestsiteTravelList'],
  faq: ['GetGuestsiteFaq'],
  vip: ['GetGuestsiteVips'],
  registry: ['GetGuestsiteRegistry'],
  accommodations: ['EventHotelReservedRoomBlocks', 'GuestEventHotelRoomBlocks', 'GuestPageContainerBySlug'],
  custom: ['GuestPageContainerBySlug']
};

export type GuestSitePage = keyof typeof refetchPageQueries;

export const useGuestSiteController = (args: GuestSiteArgs) => {
  const client = useApolloClient();
  const { onLoadComplete, onLoadFailure } = useGuestSiteHealth();
  const { isAdmin, hasIdentifiedUserOnce, role } = useEventUserRole();
  const isClient = useClient();
  const { eventHandle, staticContext } = args;
  const [loadingTheme, setLoadingTheme] = useState(true);
  const [isLastPageVisible, setIsLastPageVisible] = useState(false);
  const addAppPage = useAppPage();
  const history = useHistory();
  const [hasFiredGuestLoadTelem, setHasFiredGuestLoadTelem] = useState(false);
  const { isNativeAccommodations, hideAccomPreviewUntilFlagEnabled, view } = useGuestSiteState();

  // FeatureFlag => wait for StyleApplicator https://github.com/joylifeinc/joy-web/pull/3214
  const styledApplicatorLoading = useFeatureValue('enableLoadingStyleApplicator');
  const isHideGetTheAppPageExperimentEnabled = useFeatureValue('hideGetTheAppPageExperiment').value === 'treatment';

  const { executeSegmentGroupCallForEvent } = useSegmentGroupCallForEvent();

  //To define the previewed page on Admin
  const [currentAdminPage, setCurrentAdminPage] = useState('');

  const { themeData, loadingThemeData, getEventWithThemeAndLayout, hasOverrides } = useLayoutAndThemeOverride({ ssr: true });

  const { data, loading, error, refetch } = useEventWebsiteGuestQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: shouldUseEventDesignDraft() ? EventDesignPurpose.draft : EventDesignPurpose.live,
      isAdminDashboard: isPreviewing && isAdmin
    },
    batchMode: 'fast',
    ssr: true,
    fetchPolicy: 'cache-first',
    skip: isClient && !hasIdentifiedUserOnce
  });

  const { data: reservedRoomBlocksData, loading: reservedRoomBlocksLoading } = useEventHotelReservedRoomBlocksQuery({
    variables: {
      eventHandle
    },
    batchMode: 'fast'
  });

  const { data: guestSiteLoadTelemData, loading: guestSiteLoadTelemDataLoading } = useEventWebsiteGuestTelemetryQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: shouldUseEventDesignDraft() ? EventDesignPurpose.draft : EventDesignPurpose.live,
      isAdminDashboard: isPreviewing && isAdmin
    },
    batchMode: 'fast',
    skip: isClient && !hasIdentifiedUserOnce
  });

  useComponentMountAndGraphqlQueryStatus({ loading, error, onComplete: () => onLoadComplete(), onError: error => onLoadFailure('Failed to load GuestSite', error) });

  executeSegmentGroupCallForEvent(data?.eventByName?.firebaseId, {
    postgresEventId: data?.eventByName?.id,
    eventType: data?.eventByName?.info.eventType
  });

  const [{ eventPreview }, setState] = useImmer<{ eventPreview: EventWebsiteGuestQuery | undefined }>(() => ({
    eventPreview: data
  }));

  const [eventResponse, setEventResponse] = useState<EventWebsiteGuestQuery | undefined | null>(data);

  const [showBannerReminderChanges, setShowBannerReminderChanges] = useState(false);

  const [showAnnouncement, setShowAnnouncement] = useState(false);

  // If previewing, use the clone. Else, use the original.
  const event = isPreviewing ? eventPreview?.eventByName : eventResponse?.eventByName;
  const layout = isNativeAccommodations ? DesignLayoutType.brannan : event?.eventDesign?.websiteLayout.layoutType || DesignLayoutType.aloha;
  const hasUnpublishedChanges = event?.eventDesign?.hasUnpublishedChanges;

  if (staticContext) {
    staticContext.guestSiteEventHandleToVerify = eventHandle;
  }

  const { pages = [] } = event || {};

  const eventPageRoutes = useEventPageRouting(eventHandle, pages);

  // Update JoyKit theme object with event design preferences
  const { theme, loadingFonts } = useEventDesignTheming({ eventDesign: event?.eventDesign });

  const telemetry = useGuestSiteTelemetry();
  const updateContext = telemetry.updateContext;

  useEffect(() => {
    if (data) {
      if (!hasOverrides) {
        setEventResponse(data);
      } else {
        setEventResponse(getEventWithThemeAndLayout(data));
      }
    }
  }, [themeData, data, setEventResponse, getEventWithThemeAndLayout, hasOverrides]);

  useEffect(() => {
    if (event && layout) updateContext(prev => ({ ...prev, eventId: event.id, layout }));
  }, [event, layout, updateContext]);

  const extraInfo = useGenerateGuestSiteLoadTelemData(guestSiteLoadTelemData?.eventByName, role);

  useEffect(() => {
    if (!guestSiteLoadTelemDataLoading && guestSiteLoadTelemData && !hasFiredGuestLoadTelem) {
      telemetry.guestSiteLoaded(extraInfo);
      setHasFiredGuestLoadTelem(true);
    }
  }, [guestSiteLoadTelemDataLoading, guestSiteLoadTelemData, setHasFiredGuestLoadTelem, extraInfo, hasFiredGuestLoadTelem, telemetry]);

  // Preview

  // Initialize preview data when query resolves
  useEffect(() => {
    if (isPreviewing) {
      setState(draft => {
        draft.eventPreview = data;
      });
    }
  }, [setState, data]);

  // Respond to event preview updates if previewing
  const handleOnPreviewUpdate = useEventCallback<OnPreviewListenerUpdate>(cb => {
    if (isPreviewing) {
      setState(draft => {
        const eventDraft = draft.eventPreview?.eventByName;
        if (eventDraft) {
          cb(eventDraft, setCurrentAdminPage);
        }
      });
    }
  });

  //Refetch Preview data
  const { updateEventPlaceCache } = useEventPlaceCacheUpdate();
  const [refetchPreviewLoading, setRefetchPreviewLoading] = useState(false);
  const handleOnRefetchData = useEventCallback(message => {
    if (message.updateEventPlaceCache) {
      updateEventPlaceCache(message.updateEventPlaceCache);
      return;
    }
    setRefetchPreviewLoading(true);
    const query = refetchPageQueries[message.value as GuestSitePage];
    if (message.value !== 'custom') {
      //Wait until the new world DB is triggered to update
      //Timeout should be removed when all Admin edit pages transferred to JoyWeb
      setTimeout(() => {
        const promises = [];
        if (query) {
          promises.push(client.refetchQueries({ include: query }));
        }
        promises.push(refetch());
        Promise.all(promises).finally(() => {
          setRefetchPreviewLoading(false);
        });
      }, 2000);
    } else {
      const promises = [];
      if (query) {
        promises.push(client.refetchQueries({ include: query }));
      }
      promises.push(refetch());
      Promise.all(promises).finally(() => {
        setRefetchPreviewLoading(false);
      });
    }
  });

  // Show/Hide BannerUnPublishedChanges
  useEffect(() => {
    if (event && !isPreviewing && view !== 'draft' && isAdmin && hasUnpublishedChanges) {
      setShowBannerReminderChanges(true);
      telemetry.notPublishedBanner('Shown');
    } else {
      setShowBannerReminderChanges(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, layout, isAdmin, hasUnpublishedChanges]);

  const onDismissReminder = useCallback(() => {
    telemetry.notPublishedBanner('Dismissed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickReminder = useCallback(() => {
    telemetry.notPublishedBanner('Published');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Annoucement

  useEffect(() => {
    withWindow(({ document }) => {
      const comesFromRegistry = !!document?.referrer?.endsWith('/registry');
      if (event?.info?.announcementBanner?.active && !isPreviewing && !comesFromRegistry) {
        setShowAnnouncement(true);
        telemetry.announcementBannerChange(event?.info?.announcementBanner?.title, 'Shown');
      } else {
        setShowAnnouncement(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const onAnnouncementDismiss = useCallback((title: string) => {
    telemetry.announcementBannerChange(title, 'Dismissed');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useReloadForLegacyGuestsite(event?.featureFlags, event?.website, event?.useLegacyGuestSite);
  const justFonts = useQueryParamHelper().getValue('justFonts');
  const rsvpPage = event?.pages?.find(page => page.pageSlug === 'rsvp');
  const accommodationsPage = event?.pages?.find(page => page.pageSlug === 'accommodations');
  const isBrannan = layout === 'brannan';
  const graphicAccent = isBrannan ? event?.eventDesign?.accent ?? 'default' : undefined;
  const shouldRenderActionBar = (isBrannan || isLastPageVisible) && !isAdmin && !isPreviewing;
  const loadingGuestSite = loading || loadingThemeData || (styledApplicatorLoading ? loadingTheme || loadingFonts : false);
  const newPages = useMemo(() => (isHideGetTheAppPageExperimentEnabled ? event?.pages : addAppPage(event?.website, event)), [
    addAppPage,
    event,
    isHideGetTheAppPageExperimentEnabled
  ]);
  const customPages = usePagesWithPhotoAlignment(newPages, event?.pageContainers);
  const eventData = event
    ? {
        ...event,
        pages: customPages
      }
    : undefined;

  //Filtering the hidden pages for guest, but display hidden page when it in Admin
  let eventWithVisiblePages = eventData;
  if (eventData && isPreviewing) {
    // Welcome page should always be visible in both website preview and designer preview, even if webiste is hidden
    const visiblePages = eventData.pages.filter(
      page =>
        (currentAdminPage === 'story' && page.type === 'tidbits') ||
        currentAdminPage === page.type ||
        !page.disabled ||
        page.type === 'welcome' ||
        (page.type === 'custom' && (page.pageSlug !== 'accommodations' || !hideAccomPreviewUntilFlagEnabled))
    );
    eventWithVisiblePages = { ...eventData, pages: visiblePages };
  }

  // Accommodations Guestsite
  const accommodationsSlugPresence = eventWithVisiblePages?.pages.find(page => page.pageSlug === 'accommodations');
  const showAccommodationsToGuests = accommodationsSlugPresence !== undefined || eventWithVisiblePages === undefined;
  const accommPageIsHidden = accommodationsPage === undefined;

  // aloha - useScrollToPageWhenUrlChanges handles intercepting 'accommodations' to allow handling here on L216
  // brannan - no intercept needed, can check for 'accommodations' here
  const guestSiteAccommodationsShouldRedirect =
    /\/[^\/]+\/accommodations$/.test(history.location.pathname) && !loading && !showAccommodationsToGuests && !isPreviewing && accommPageIsHidden;

  return {
    event: eventWithVisiblePages,
    layout,
    loading: loadingGuestSite || refetchPreviewLoading || guestSiteLoadTelemDataLoading,
    hasUnpublishedChanges,
    isAdmin,
    isPreviewing,
    handleOnPreviewUpdate,
    handleOnRefetchData,
    eventPageRoutes,
    theme,
    justFonts,
    rsvpPage,
    graphicAccent,
    shouldRenderActionBar,
    loadingTheme,
    showBannerReminderChanges,
    setLoadingTheme,
    setIsLastPageVisible,
    onDismissReminder,
    onClickReminder,
    showAnnouncement,
    onAnnouncementDismiss,
    currentAdminPage,
    reservedRoomBlocksLoading,
    guestSiteAccommodationsShouldRedirect,
    reservedRoomBlocksData
  } as const;
};
