import { useGetStationeryEntitiesCountForNavigationByEventIdQuery } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { isValidStationeryDraft } from '../DraftsGallery/DraftsGallery';

export const useStationeryEntitiesCount = () => {
  const { eventInfo } = useEventInfo();
  const { data } = useGetStationeryEntitiesCountForNavigationByEventIdQuery({
    variables: {
      eventId: eventInfo?.eventId as string,
      input: {
        eventId: eventInfo?.eventId as string
      }
    },
    batchMode: 'off'
  });

  const validDrafts = data?.stationeryDraftsCount?.filter(isValidStationeryDraft);

  const draftsCount =
    (validDrafts?.length ?? 0) +
    (data?.digitalInvitationDraft?.id ? 1 : 0) +
    (data?.digitalSaveTheDateDraft?.id ? 1 : 0) +
    (data?.digitalPhotoSaveTheDateDraft?.id ? 1 : 0) +
    (data?.digitalCustomDraft?.id ? 1 : 0);
  const favoritesCount = data?.stationeryFavoritesCount?.pagedResults?.totalCount ?? 0;

  return { draftsCount, favoritesCount };
};
