import { useRef, useState, useEffect } from 'react';

// Typical range is -0.5 to 0.5.
// -0.5 is the leading edge of the bounds
//  0.5 is the trailing edge of the bounds
//  0   is the center
// If the number is beyond this range, it is out of bounds.
// This is useful for animations and effects that don't care about the specifics
// of the card width and coordinate system. Less data to pass around.
export type UnitPosition = { x: number; y: number };

// For effects such as foil.
// On mobile, since there's no hover, it responds to scroll movement.
// On desktop, it responds to pointer movement, in addition to scroll.
export function usePointerUnitPosition() {
  const elementRef = useRef<HTMLDivElement>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [unitPosition, setUnitPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const pointerMove = (e: MouseEvent) => {
      const bounds = element.getBoundingClientRect();
      const x = e.clientX - bounds.left - bounds.width / 2;
      const y = e.clientY - bounds.top - bounds.height / 2;

      // Reduce the effect as the pointer moves further from center
      const diagonal = Math.sqrt(bounds.width ** 2 + bounds.height ** 2);
      const distance = Math.sqrt(x ** 2 + y ** 2);
      const attenuation = Math.max(1 - distance / diagonal, 0);

      setMousePosition({
        x: (x / bounds.width) * attenuation,
        y: (y / bounds.height) * attenuation
      });
    };

    const scroll = () => {
      const HEIGHT = 1000; // A subjective full cycle of animation, set it to whatever feels right
      setScrollPosition(document.documentElement.scrollTop / HEIGHT - 0.5);
    };

    document.addEventListener('pointermove', pointerMove);
    document.addEventListener('scroll', scroll, { capture: true });
    return () => {
      document.removeEventListener('pointermove', pointerMove);
      document.removeEventListener('scroll', scroll, { capture: true });
    };
  }, []);

  useEffect(() => {
    setUnitPosition({
      x: mousePosition.x,
      y: mousePosition.y + scrollPosition
    });
  }, [mousePosition.x, mousePosition.y, scrollPosition]);

  return { unitPosition, elementRef };
}
