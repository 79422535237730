import { styled, StyleSystemProps } from '@withjoy/joykit';
import { ReactComponent as JoyLogo } from '@assets/joy-logo-footer.svg';
import { ParticleJoyLogo } from '@shared/components/ParticleJoyLogo';

export const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const getLinkStyles = (linkColor: string = 'mono10', linkHoverColor: string = 'mono14'): StyleSystemProps => ({
  typographyVariant: 'label3',
  textDecoration: 'none',
  color: linkColor,
  letterSpacing: '-0.03em',
  fontWeight: 'normal',
  _visited: {
    color: linkColor
  },
  _active: {
    color: linkColor
  },
  _hover: {
    color: linkHoverColor
  }
});

export const StyledJoyLogo = styled(JoyLogo)`
  margin: 0 32px 0 32px;
`;

export const alohaFooterContainerStyles: StyleSystemProps = {
  justifyContent: 'center',
  padding: 7,
  marginBottom: ['180px', '180px', '80px']
};

export const brannanFooterContainerStyles: StyleSystemProps = {
  justifyContent: 'center',
  marginTop: '158px',
  marginBottom: ['120px', '120px', '120px', '30px']
};

export const ParticleJoyLogoWithPadding = styled(ParticleJoyLogo)`
  padding: 3px 48px 0px 48px;
  & > div {
    & > svg {
      width: 40px;
    }
  }
`;
