import React, { FC, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from '@react-router';
import loadable from '@loadable/component';
import { Flex, JoyKitThemeProvider, TextV2 } from '@withjoy/joykit';
import { MediaCollectionProps } from './MediaCollection.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { EventDesignPurpose, useMediaCollectionEventInfoQuery } from '@graphql/generated';
import { useEventDesignTheming } from '../GuestSite/GuestSite.utils';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { MediaCollectionRouter, useRoutePaths } from './MediaCollection.routes';
import { Suspense } from '@shared/core/react/Suspense';
import { MediaCollectionHead } from './MediaCollectionHead';
import { MediaCollectionTelemetryProvider } from './MediaCollection.telemetry';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { display5ToEventTitleOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { FontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';

const AlbumsList = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest/mediacollection/albumlist" */
      './routes/AlbumsList'
    )
);

const ViewAlbum = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest/mediacollection/viewalbum" */
      './routes/ViewAlbum'
    )
);

const MediaCollectionLoader: FC<{ loadingSite: boolean }> = props => {
  const { loadingSite, children } = props;

  return <> {loadingSite ? <JoyLogoLoader loaderKey="media-collection-guest" /> : children} </>;
};

export const MediaCollection: FC<MediaCollectionProps> = props => {
  const { eventHandle } = props;

  const routePaths = useRoutePaths();

  const { data: eventData } = useMediaCollectionEventInfoQuery({
    variables: {
      eventHandle,
      eventDesignPurpose: EventDesignPurpose.live
    },
    batchMode: 'fast',
    ssr: true,
    fetchPolicy: 'cache-first'
  });

  // Update JoyKit theme object with event design preferences
  const { theme, loadingFonts } = useEventDesignTheming({ eventDesign: eventData?.eventByName?.eventDesign });

  const [isInitialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (loadingFonts && isInitialLoad) {
      setInitialLoad(false);
    }
  }, [isInitialLoad, loadingFonts]);

  return (
    <>
      {eventData?.eventByName && (
        <MediaCollectionHead
          eventDisplayName={eventData.eventByName.info.eventDisplayName}
          eventType={eventData.eventByName.info.eventType}
          ownerFirstName={eventData.eventByName.info.ownerFirstName}
          ownerLastName={eventData.eventByName.info.ownerLastName}
          fianceeFirstName={eventData.eventByName.info.fianceeFirstName}
          imageUrl={eventData.eventByName.photo?.url}
          handle={eventHandle}
        />
      )}
      <MediaCollectionLoader loadingSite={loadingFonts || isInitialLoad}>
        <JoyKitThemeProvider theme={theme}>
          <FontPackProvider eventDesign={eventData?.eventByName?.eventDesign}>
            <Flex width="100%" justifyContent="center">
              <Flex width="100%" flexDirection="column" gap={7} maxWidth={pxToRem(1280)} paddingTop={7} paddingBottom={pxToRem(80)} paddingX={{ _: 7, sm4: 10 }}>
                <Flex flexDirection="column" width="100%" alignItems="center" gap={3}>
                  <GuestSiteTypographyOverride override={display5ToEventTitleOverride}>
                    <TextV2 typographyVariant="display5" color="mono14" textAlign="center">
                      {eventData?.eventByName?.info.eventDisplayName}
                    </TextV2>
                  </GuestSiteTypographyOverride>
                </Flex>
                <Switch>
                  <Route
                    path={routePaths.viewAlbums.path}
                    exact={true}
                    render={({ match }) => (
                      <Suspense fallback={<></>}>
                        <ViewAlbum
                          magicToken={match.params.magicToken}
                          eventHandle={eventHandle}
                          containerId={eventData?.eventByName?.firebaseId || ''}
                          eventId={eventData?.eventByName?.id || ''}
                        />
                      </Suspense>
                    )}
                  />
                  <Route
                    path={routePaths.albums.path}
                    render={({ match }) => (
                      <Suspense fallback={<></>}>
                        <AlbumsList magicToken={match.params.magicToken} eventHandle={eventHandle} />
                      </Suspense>
                    )}
                  />
                  <Route path="*" render={() => <Redirect to="/notfound" />} />
                </Switch>
              </Flex>
            </Flex>
          </FontPackProvider>
        </JoyKitThemeProvider>
      </MediaCollectionLoader>
    </>
  );
};

export const MediaCollectionProvider: FC<MediaCollectionProps> = props => {
  return (
    <MediaCollectionTelemetryProvider>
      <MediaCollectionRouter eventHandle={props.eventHandle}>
        <MediaCollection {...props} />
      </MediaCollectionRouter>
    </MediaCollectionTelemetryProvider>
  );
};

MediaCollection.displayName = 'MediaCollection';
