import React from 'react';
import { ButtonV2, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { useUnlockController } from './UnlockForm.controller';
import { StyledFormStack, StyledTitleStack, StyledHeaderStack, StyledInputsStack, StyledLinksStack, StyledContent, styles } from './UnlockForm.styles';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body4ToSubHeadingOverride, display4ToHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

interface UnlockFormProps {
  eventId: string;
  onUnlockSuccess: () => void;
  onShowAskHost: () => void;
}

export const UnlockForm: React.FC<UnlockFormProps> = ({ eventId, onUnlockSuccess, onShowAskHost }) => {
  const {
    formik,
    loading,
    title,
    subtitle,
    passwordLabel,
    passwordPlaceholder,
    submitButtonText,
    contactButtonText,
    signInButtonText,
    inputError,
    onSignInHandler
  } = useUnlockController({ eventId, onUnlockSuccess });

  return (
    <StyledContent __css={styles.content} data-testid="unlock-form">
      <StyledHeaderStack>
        <StyledTitleStack>
          <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
            <TextV2 typographyVariant="display4">{title}</TextV2>
          </GuestSiteTypographyOverride>
          <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
            <TextV2 typographyVariant="body4">{subtitle}</TextV2>
          </GuestSiteTypographyOverride>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <form onSubmit={formik.handleSubmit}>
          <StyledInputsStack>
            <FormControl label={passwordLabel}>
              <InputV2
                placeholder={passwordPlaceholder}
                isInvalid={(inputError && formik.touched.password) || undefined}
                enableFocusOnMount
                isDisabled={loading}
                {...formik.getFieldProps('password')}
              />
            </FormControl>
          </StyledInputsStack>
          <ButtonV2 shape="rounded" width={'100%'} intent="neutral" type="submit" loading={loading}>
            {submitButtonText}
          </ButtonV2>
          <StyledLinksStack stack="horizontal">
            <ButtonV2 variant="ghost" intent="neutral" onClick={onSignInHandler}>
              {signInButtonText}
            </ButtonV2>
            <ButtonV2 variant="ghost" intent="neutral" onClick={onShowAskHost}>
              {contactButtonText}
            </ButtonV2>
          </StyledLinksStack>
        </form>
      </StyledFormStack>
    </StyledContent>
  );
};
