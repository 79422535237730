import React from 'react';
import { Flex, TextV2 } from '@withjoy/joykit';
import { HotelProduct } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { useFeatureValue } from '@shared/core/featureFlags';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body2ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { Category } from '@graphql/generated';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';

export const AccommodationTileEyebrow: React.FC<{ displayName: Maybe<string> }> = ({ displayName }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  return (
    <Flex columnGap={3} paddingBottom={3}>
      <HotelProduct size={'sm'} />
      <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
        <TextV2
          {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
          fontWeight={600}
          fontSize={scaleFontSize(pxToRem(14), Category.PARAGRAPH)}
          letterSpacing={pxToRem(-0.035)}
        >
          {displayName}
        </TextV2>
      </GuestSiteTypographyOverride>
    </Flex>
  );
};
