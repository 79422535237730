import { Box, BoxProps } from '@withjoy/joykit';
import React, { forwardRef, useState } from 'react';
import { VirtualScrollWrapperContext } from './context';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export interface VirtualScrollProviderProps extends BoxProps {}

export const VirtualScrollWrapper = forwardRef<HTMLElement, VirtualScrollProviderProps>(({ children, ...props }, ref) => {
  const [virtualScrollWrapperElement, setVirtualScrollWrapperElement] = useState<HTMLElement>();

  const onSetRef = useEventCallback((element: HTMLElement) => {
    setVirtualScrollWrapperElement(element);

    if (ref) {
      if (typeof ref === 'function') {
        ref(element);
      } else {
        ref.current = element;
      }
    }
  });

  return (
    <VirtualScrollWrapperContext.Provider value={{ virtualScrollWrapperElement }}>
      <Box ref={onSetRef} {...props}>
        {children}
      </Box>
    </VirtualScrollWrapperContext.Provider>
  );
});
