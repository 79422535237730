import { createDateFromUnformattedString, getLocaleDateString, calculateStayDates } from '@shared/utils/dateHelpers';
import { differenceInMonths } from 'date-fns';
import { HotelBookingPartnerSearchQueryParams } from '../HotelBooking.types';
import { useQueryParamHelper } from '@shared/core/queryString';

/**
 * Retrieves the Check-In and Check-Out dates based on the following priorities:
 * 1. URL query parameters (`checkIn` and `checkOut`)
 * 2. Event Date (if provided)
 * 3. Default date calculations if neither URL nor Event Date is available.
 *
 * It also checks if the calculated Check-In date is within 11 months from the current/provided date.
 *
 * @param {string | null} [eventDate] - Optional event date used if URL query parameters are not available.
 * @returns {Object} - Returns an object containing:
 *   - {string} checkIn - The localized Check-In date.
 *   - {string} checkOut - The localized Check-Out date.
 *   - {boolean} isCheckInOrCheckoutValid - `true` if the Check-In date is within 11 months from today, otherwise `false`.
 */
export const useCheckInCheckoutDates = ({ eventDate }: { eventDate?: string | null }) => {
  const queryParamHelper = useQueryParamHelper();
  const { checkIn: queryParamCheckIn, checkOut: queryParamCheckOut } = HotelBookingPartnerSearchQueryParams;

  // Retrieve query params
  const checkInQuery = queryParamHelper.getValueString(queryParamCheckIn);
  const checkOutQuery = queryParamHelper.getValueString(queryParamCheckOut);

  // Determine the origin of the dates and construct messages
  const checkInDateUrl = checkInQuery ? createDateFromUnformattedString(checkInQuery) : null;
  const checkOutDateUrl = checkOutQuery ? createDateFromUnformattedString(checkOutQuery) : null;

  let message = 'CheckIn Checkout Date: ';

  if (checkInDateUrl && checkOutDateUrl) {
    message += 'Used from URL: ';
  } else if (eventDate) {
    message += 'Not found in URL, used from event: ';
  } else {
    message += 'Not found in URL and event, used default: ';
  }

  // Use URL dates or event/fallback logic
  const { checkIn: checkInEventDate, checkOut: checkOutEventDate } = calculateStayDates(eventDate);
  const checkIn = checkInDateUrl || checkInEventDate.date;
  const checkOut = checkOutDateUrl || checkOutEventDate.date;

  // Get formatted YYYY-MM-DD date strings
  const checkInAsDate = getLocaleDateString(checkIn);
  const checkOutAsDate = getLocaleDateString(checkOut);

  message += `CheckIn: ${checkInAsDate}, CheckOut: ${checkOutAsDate}`;

  // Return if the check-in or check-out is valid (within 11 months) - Mostly used for zh, where zh shows empty results if date greater than 11 months.
  const isCheckInOrCheckoutValid = differenceInMonths(checkIn, new Date()) < 11;

  return {
    checkIn: checkInAsDate,
    checkOut: checkOutAsDate,
    isCheckInOrCheckoutValid,
    message
  };
};
