import { useMemo } from 'react';

import { EventPageType, EventPageFragment } from '@graphql/generated';
import { RoutesPaths } from '@shared/core';
import useQueryString from '@shared/utils/hooks/useQueryString';

const SUPPORTED_PAGES = [
  EventPageType.welcome,
  EventPageType.story,
  EventPageType.vip,
  EventPageType.faq,
  EventPageType.schedule,
  EventPageType.travel,
  EventPageType.moments,
  EventPageType.registry,
  EventPageType.rsvp,
  EventPageType.tidbits,
  EventPageType.custom
] as const;

const supportedPagesSet = new Set<EventPageType>(SUPPORTED_PAGES);

export type SupportedEventPages = typeof SUPPORTED_PAGES[number];

export type EventPageRoutePaths = Partial<RoutesPaths<SupportedEventPages>>;

export const useEventPageRouting = (eventHandle: string, pages: EventPageFragment[]): EventPageRoutePaths => {
  return useMemo(() => {
    if (!eventHandle || !eventHandle.trim().length) {
      return {};
    }
    return pages.reduce((acc, page) => {
      if (supportedPagesSet.has(page.type)) {
        acc[page.type as SupportedEventPages] = {
          path: `/${eventHandle}/${page.pageSlug}`,
          goToPath: () => page.pageSlug
        };
      }
      return acc;
    }, {} as EventPageRoutePaths);
  }, [eventHandle, pages]);
};

export const ACCOMMODATIONS_SLUG = 'accommodations';
export const CUSTOM_PAGE_PATH = 'page';

export const useEventPageRoute = (eventHandle: string) => {
  const queryString = useQueryString();
  const eventPageRoute = {
    path: `/:eventHandle`,
    goToPath: (type: EventPageType, pageSlug: string) => {
      let url = type === EventPageType.custom && pageSlug !== ACCOMMODATIONS_SLUG ? `/${eventHandle}/${CUSTOM_PAGE_PATH}/${pageSlug}` : `/${eventHandle}/${pageSlug}`;

      queryString && (url += `${queryString}`);

      return url;
    }
  };
  return eventPageRoute;
};
