import React from 'react';
import { Box, css, keyframes, pseudoSelectors, styled } from '@withjoy/joykit';
import { PhotoFragment } from '@graphql/generated';
import { roundToPrecision } from '@shared/utils/numbers';

const loading2 = keyframes`
to {
    background-position: -380% 0;
  }
`;

const getDimensionValue = (value: string | number | undefined) => {
  if (value === undefined) {
    return undefined;
  }

  return typeof value === 'string' ? value : `${value}px`;
};

const notLoadedStyles = css<{ index: number; height: string | number; width: string | number; loaded?: boolean }>`
  height: ${props => getDimensionValue(props.height)};
  width: ${props => getDimensionValue(props.width)};
  background: #f9f8f8;
  border-radius: 6px;
  position: relative;
  /* transform: translate3d(0px, 0px, 1px); */
  margin-left: auto;
  margin-right: auto;

  &:not(:first-child) {
    margin-top: 8px;
  }

  opacity: ${props => (10 - ((props.index * 1.4) % 10)) / 10};
  background-image: linear-gradient(90deg, rgba(211, 211, 211, 0) 0, rgba(211, 211, 211, 0.4) 50%, rgba(211, 211, 211, 0) 100%);
  background-size: 150px;
  background-repeat: no-repeat;
  background-position: 388% 0;

  animation: ${loading2} 2.5s infinite;
`;
export const SkeletonScreenWrapper = styled.div<{ index: number; height: string | number; width: string | number; loaded?: boolean; isFullBleed: boolean }>`
  max-width: ${props => (props.isFullBleed ? '100vw' : '100%')};
  left: ${props => (props.isFullBleed ? 'calc(-50vw + 50%)' : undefined)};
  ${props => {
    if (!props.loaded) {
      return notLoadedStyles;
    }
    return null;
  }}
`;

export interface SkeletonScreenProps {
  height: string | number;
  width?: string | number;
  index: number;
  loaded?: boolean;
  isFullBleed: boolean;
}

export const SkeletonScreen: React.SFC<SkeletonScreenProps> = ({ width = '100%', height, index, children, loaded, isFullBleed }) => {
  return (
    <SkeletonScreenWrapper width={width} height={height} index={index} loaded={loaded} isFullBleed={isFullBleed}>
      {children}
    </SkeletonScreenWrapper>
  );
};

export const StyledCoverPhoto = styled(Box)<{ backgroundOverlay?: boolean; url: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url('${props => props.url}');
  background-position: center;
  background-repeat: no-repeat;

  ${props => {
    if (props.backgroundOverlay) {
      return css`
        :before {
          content: ' ';
          display: block;
          height: 100%;
          width: 100%;
          background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, transparent 40%);
        }
      `;
    }
    return null;
  }}
`;

export const CoverPhotoContainer = styled(Box)<{ photo: PhotoFragment }>`
  ${({ theme, photo }) => {
    const aspectRatio = roundToPrecision((photo.height / photo.width) * 100);

    return {
      [theme.mediaQueries.down({ viewport: 'xl' })]: {
        [pseudoSelectors._after]: {
          content: '" "',
          display: 'block',
          paddingTop: `${aspectRatio}%`,
          pointerEvents: 'none'
        }
      }
    };
  }}
`;
