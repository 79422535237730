import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body2ToSubHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { Category } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import React from 'react';

export const AccommodationTileTitle: React.FC<{ displayName: Maybe<string> }> = ({ displayName }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  return (
    <GuestSiteTypographyOverride override={body2ToSubHeadingOverride} defaultFontFamily="Inter UI">
      <TextV2
        width={'100%'}
        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
        fontSize={scaleFontSize(pxToRem(18), Category.SUB_HEADING)}
        fontWeight={600}
        lineHeight={'23.4px'}
        letterSpacing={'-0.432px'}
      >
        {displayName}
      </TextV2>
    </GuestSiteTypographyOverride>
  );
};
