import { useFeatureValue } from '@shared/core/featureFlags';

// const shadow = [
//   '  0px  2px  8px  0px hsla(35, 10%, 50%, 0.15)',
//   ' -8px 12px 14px -8px hsla(35, 10%, 49%, 1.00)',
//   '-14px 12px  8px -8px hsla(33, 13%, 58%, 0.20)',
//   '-26px 20px 11px -5px hsla(33, 13%, 58%, 0.27)'
// ].join(',');

// Sample payload in comments
const defaultGalleryGridStyle = {
  galleryGap: '3px',
  diamondSize: 20,
  diamondColor: 'hsl(40, 60%, 60%)',
  valuePropSize: 'large',
  badgeContainer: 'frame',
  diamondTransform: 'translateY(1px)',
  valuePropCardBorder: '1px solid hsl(0, 0%, 90%)',
  cardTileConciergeVideo: 'frameless',
  aspectRatioFrameBoxShadow:
    '0px  2px  8px  0px hsla(35, 10%, 50%, 0.15), -8px 12px 14px -8px hsla(35, 10%, 49%, 1.00),-14px 12px  8px -8px hsla(33, 13%, 58%, 0.20),-26px 20px 11px -5px hsla(33, 13%, 58%, 0.27)',
  assetPreviewFrameBackground: 'hsla(45, 12%, 93%, 1)',
  assetPreviewAspectRatioMobile: '8 / 10',
  assetPreviewFramePaddingMobile: 4,
  assetPreviewFramePaddingDesktop: 8,
  premiumBadgeAssetPreviewDisplay: 'none',
  assetPreviewFrameGroupHoverBackground: 'hsla(45, 12%, 73%, 0.4)'
};

export const useGalleryGridStyle = (): Record<string, string | number | undefined> => {
  const { payload } = useFeatureValue('printGalleryGridStyle');

  if (!payload || typeof payload !== 'object') {
    return defaultGalleryGridStyle;
  }

  return { ...defaultGalleryGridStyle, ...payload };
};
