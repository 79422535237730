import { useMemo } from 'react';

import { monorepoRoutePaths, routePaths as adminRoutePaths } from '@apps/admin/route.paths';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { getProductVisibility } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { useRouterHelper, useTranslation } from '@shared/core';
import { ExternalRegistry, Gift, Shipping, Shop } from '@withjoy/joykit/icons';

import { useFeatureValue } from '@shared/core/featureFlags';
import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { PageInfo } from './useProductRows';
import { useValuePropositionAdvertisement } from '../useValuePropositionAdvertisement/useValuePropositionAdvertisement';
import { useMergeAddGiftsAndOverviewFeature } from '@apps/registry/admin/routes/AddGiftsAndOverview';

function getRegistryEnablementAndVisibility(eventPages: PageInfo[], productVerticals: ProductVerticals) {
  const registryPage = eventPages.find(({ pageSlug }) => pageSlug === EventPageSlugs.Registry);
  const visibility = getProductVisibility(registryPage?.visibility);
  const isEnabled = !productVerticals.optOutOfRegistry;

  return {
    isEnabled,
    visibility,
    pageId: registryPage?.id
  };
}

export const useRegistryProductRow = (eventPages: PageInfo[], productVerticals: ProductVerticals, eventId?: string, pendingRefundOrdersCount?: number): ProductRow => {
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  const { t2 } = useTranslation('adminDashboardV2');
  const visibilityTranslations = t2('visibility');
  const { registry: registryTranslations } = t2('sections');
  const valueProposition = useValuePropositionAdvertisement();
  const registryTitlesExperimentEnabled = useFeatureValue('registryDashboardTitlesExperiment').value === 'treatment';
  const { isEnabled: mergedAddGiftsAndOverviewEnabled } = useMergeAddGiftsAndOverviewFeature();

  const registryProductRow = useMemo<ProductRow>(() => {
    const registryStrings = registryTranslations;
    const visibilityStrings = visibilityTranslations;
    const { isEnabled, visibility, pageId } = getRegistryEnablementAndVisibility(eventPages, productVerticals);

    return {
      title: registryStrings.title,
      stringOn: visibilityStrings.on,
      stringOff: visibilityStrings.off,
      settingsUrl: routeHelpers.buildPath(adminRoutePaths.registryManage.path),
      showVisibilityDialog: true,
      isEnabled,
      visibility,
      onDialogOpen: () => telemetry.visibilityDialog.enter('registryVisibilityDialog'),
      onDialogUpdate: ({ visibility, visibilityChanged, eventPasswordChanged }) =>
        telemetry.visibilityUpdated({ label: 'registry', visibility, visibilityChanged, eventPasswordChanged }),
      pageId,
      children: [
        ...(mergedAddGiftsAndOverviewEnabled
          ? []
          : [
              {
                title: registryTitlesExperimentEnabled ? registryStrings.children.overview.experimentTitle : registryStrings.children.overview.title,
                icon: ExternalRegistry,
                path: routeHelpers.buildPath(monorepoRoutePaths.registryOverview),
                pathIsInJoyWeb: true,
                onClick: (categoryArg?: string) => telemetry.openPage({ label: 'overview', section: 'Registry', categoryArg }),
                telemetryName: monorepoRoutePaths.registryOverview
              }
            ]),
        {
          title: registryTitlesExperimentEnabled ? registryStrings.children.shop.experimentTitle : registryStrings.children.shop.title,
          icon: Shop,
          path: routeHelpers.buildPath(monorepoRoutePaths.registryShop),
          pathIsInJoyWeb: true,
          badge: 'New',
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'shop', section: 'Registry', categoryArg }),
          telemetryName: monorepoRoutePaths.registryShop
        },
        {
          title: registryStrings.children.manage.title,
          icon: Gift,
          path: routeHelpers.buildPath(monorepoRoutePaths.registryManage),
          pathIsInJoyWeb: true,
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'manage', section: 'Registry', categoryArg }),
          telemetryName: monorepoRoutePaths.registryManage
        },
        {
          title: registryStrings.children.track.title,
          icon: Shipping,
          path: routeHelpers.buildPath(monorepoRoutePaths.registryTrack),
          pathIsInJoyWeb: true,
          onClick: (categoryArg?: string) => telemetry.openPage({ label: 'track', section: 'Registry', categoryArg }),
          telemetryName: monorepoRoutePaths.registryTrack,
          notificationPill: pendingRefundOrdersCount ? String(pendingRefundOrdersCount) : undefined
        }
      ],
      valueProposition: {
        title: valueProposition?.title,
        description: valueProposition?.description,
        ctaText: valueProposition?.ctaText,
        path: routeHelpers.buildPath(monorepoRoutePaths.registryManage),
        label: valueProposition?.label,
        trackTelemetry: () => telemetry.valuePropositionClicked(valueProposition?.label),
        icon: valueProposition?.icon
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventPages, registryTranslations, routeHelpers, telemetry, visibilityTranslations, productVerticals, valueProposition, mergedAddGiftsAndOverviewEnabled]);

  return registryProductRow;
};
