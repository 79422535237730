import { get } from 'lodash-es';
import { ConciergeServiceCategory, formatter, PRICE_CONFIG } from '../config';
import { useCallback, useMemo } from 'react';
import { PromoCode } from '../components/Review/components/PromoCodeInput/PromoCodeInput';
import { isPromoValid, ProductInfo, usePromotionList } from '../../CardCustomizer/usePromotionConfig';
import { convertPriceTotalWithRounding } from '@apps/card/hooks/convertPriceTotalWithRounding';

interface Input {
  designSetName: string;
  quantity: number;
  category: ConciergeServiceCategory;
  customizations: {
    magnet: boolean;
  };
  promoCode: PromoCode;
  product: ProductInfo;
}

export const useGetDiscount = (product: ProductInfo) => {
  const { promotions, isEnabled } = usePromotionList(product.category);

  const getDiscount = useCallback(
    (promoCode: PromoCode) => {
      const firstMatch = promotions.find(p => {
        return p.code.toLowerCase() === promoCode.code.toLowerCase() && isPromoValid(p, product);
      });

      if (firstMatch && isEnabled) {
        return {
          isEnabled: true,
          discountPercent: firstMatch.discountPercentage
        };
      }

      return { isEnabled: false };
    },
    [isEnabled, product, promotions]
  );

  return { getDiscount };
};

const useCalculateConciergeOrderPrice = (input: Input) => {
  const { designSetName, quantity, category, customizations, promoCode, product } = input;

  const quantityPriceConfig = get(PRICE_CONFIG, [category, 'quantity', designSetName, quantity], {
    quantity: 0,
    totalPriceInMinorUnits: 0,
    individualPriceString: '$0.00',
    totalPriceString: '$0.00'
  });
  const magneticUpgradeConfig = useMemo(() => get(PRICE_CONFIG, [category, 'upgrades', 'magnetic', quantity], null), [category, quantity]);
  const isMagneticUpgradeAvailable = useMemo(() => Boolean(magneticUpgradeConfig), [magneticUpgradeConfig]);

  let totalPriceInMinorUnits = quantityPriceConfig.totalPriceInMinorUnits;

  if (customizations.magnet) {
    totalPriceInMinorUnits += magneticUpgradeConfig.totalPriceInMinorUnits;
  }

  const { getDiscount } = useGetDiscount(product);

  let discountCoeff = 1;
  let discountInfo = undefined;
  if (promoCode.code !== '' && !promoCode.error) {
    const { isEnabled, discountPercent } = getDiscount(promoCode);
    if (isEnabled) {
      discountCoeff = 1 - discountPercent!;
      const savingsInMinorUnits = convertPriceTotalWithRounding(Math.round(totalPriceInMinorUnits * discountPercent!) * -1, quantity).resultWithFloor;
      const previousPrice = totalPriceInMinorUnits;
      discountInfo = {
        applied: true,
        code: promoCode.code,
        discountPercent: discountPercent!,
        discountSavings: {
          minorUnits: savingsInMinorUnits,
          formatted: formatter.formatCurrency(savingsInMinorUnits / 100, { form: 'auto' })
        },
        priceBeforeDiscount: {
          minorUnits: previousPrice,
          formatted: formatter.formatCurrency(previousPrice / 100, { form: 'auto' })
        }
      };

      totalPriceInMinorUnits *= discountCoeff;
    }
  }
  totalPriceInMinorUnits = convertPriceTotalWithRounding(totalPriceInMinorUnits, quantity).resultWithFloor;
  return {
    availableCustomizations: {
      magnet: isMagneticUpgradeAvailable
    },
    totalPrice: {
      minorUnits: totalPriceInMinorUnits,
      formatted: formatter.formatCurrency(totalPriceInMinorUnits / 100, { form: 'auto' })
    },
    promotions: discountInfo,
    lineItems: [
      [`Quantity: ${quantityPriceConfig.quantity} cards ${quantityPriceConfig.individualPriceString} each`, quantityPriceConfig.totalPriceString],
      customizations.magnet && [`Magnet Upgrade: ${magneticUpgradeConfig?.individualPriceString} each`, magneticUpgradeConfig?.totalPriceString]
    ].filter(Boolean) as [string, string][],
    configs: {
      quantity: quantityPriceConfig,
      magneticUpgrade: magneticUpgradeConfig
    }
  };
};

export default useCalculateConciergeOrderPrice;
