import { StationeryPrintOrderDetailsFragment } from '@graphql/generated';
import { ThemeJson } from '../routes/CardCustomizer';
import { CardEnvelopeReturnAddressLayout } from '../routes/CardCustomizer/CardCustomizer.types';

interface EnvelopePreviewArgs {
  order: StationeryPrintOrderDetailsFragment;
}

const useEnvelopePreview = (args: EnvelopePreviewArgs) => {
  const { order } = args;

  const cardDraft = order.cardDraftJSONBlob as ThemeJson | undefined;

  if (!cardDraft) {
    return {};
  }

  const isNoneLayout = cardDraft.card.envelope.layout === CardEnvelopeReturnAddressLayout.none;
  const isFrontLayout = cardDraft.card.envelope.layout === CardEnvelopeReturnAddressLayout.front;
  const isBackLayout = cardDraft.card.envelope.layout === CardEnvelopeReturnAddressLayout.back;

  const isPreviewAvailable = isNoneLayout || Boolean(isFrontLayout && order.envelopeFrontImageUrl) || Boolean(isBackLayout && order.envelopeBackImageUrl);

  return {
    isPreviewAvailable,
    frontImageUrl: order.envelopeFrontImageUrl,
    backImageUrl: order.envelopeBackImageUrl
  };
};

export default useEnvelopePreview;
