import { createTelemetry, createTelemetryObject } from '@shared/core/analytics';

const action = 'PhotoPickerButtonInteracted';

const telemObject = createTelemetryObject({
  actions: {
    uploadPhotoClicked: (category: string, extraInfo: Object = {}) => ({
      action,
      category,
      extraInfo: {
        name: 'uploadPhotoClicked',
        ...extraInfo
      }
    }),
    savePhotoClicked: (category: string, selectedPhotoId: string) => ({
      action,
      category,
      extraInfo: {
        name: 'savePhotoClicked',
        selectedPhotoId
      }
    }),
    closePhotoPicker: (category: string) => ({
      action,
      category,
      extraInfo: {
        name: 'closePhotoPicker'
      }
    })
  }
});

const { TelemetryProvider: PhotoPickerTelemetryProvider, useTelemetry: usePhotoPickerTelemetry } = createTelemetry(telemObject);

export { PhotoPickerTelemetryProvider, usePhotoPickerTelemetry };
