import { Box } from '@withjoy/joykit';
import React from 'react';
import { PageDimensions } from '../CardCustomizer.types';
import { MEDIA_SERVICE, PPI } from '../steps/CardDesign/config';
import { FoilLayerData } from './Layer.types';
import { absolutePhotoUrl } from './ImageLayer';
import { UnitPosition } from './usePointerUnitPosition';

// colorId: [dark, light]
// prettier-ignore
const FOIL_COLORS_STOPS: Record<string, [string, string]> = {
  GOLD:     ['hsl( 40,  75%, 40%)', 'hsl( 55, 100%,  80%)'],
  ROSEGOLD: ['hsl( 15,  75%, 40%)', 'hsl( 20, 100%,  80%)'],
  SILVER:   ['hsl(  0,   0%, 80%)', 'hsl(  0,   0%, 100%)']
};

const FOIL_TEXTURE = 'https://withjoy.com/media/raw/paper/foilTexture.png';

function createFoilFill(unitPosition: UnitPosition, colorId: string) {
  const degrees = 145 - (unitPosition.x * 5 + -unitPosition.y * 10);
  const [dark, light] = FOIL_COLORS_STOPS[colorId] ?? FOIL_COLORS_STOPS['GOLD'];
  const roll = unitPosition.x * 60 + -unitPosition.y * 100;
  return `
    url(${FOIL_TEXTURE}),
    linear-gradient(${degrees}deg,
      ${dark}  ${roll + 0}%,
      ${light} ${roll + 25}%,
      ${dark}  ${roll + 50}%,
      ${light} ${roll + 75}%,
      ${dark}  ${roll + 100}%)
  `;
}

export const FoilLayer = (props: { layer: FoilLayerData; pageDimensions: Pick<PageDimensions, 'width' | 'height' | 'cutMargin'>; pointerUnitPosition: UnitPosition }) => {
  const { layer, pageDimensions, pointerUnitPosition } = props;
  const { src, colorId } = layer.foilData;
  const cutMargin = pageDimensions.cutMargin * PPI;
  const url = absolutePhotoUrl(src);

  return (
    <Box
      style={{
        position: 'absolute',
        left: -cutMargin,
        top: -cutMargin,
        width: pageDimensions.width * PPI + cutMargin * 2,
        height: pageDimensions.height * PPI + cutMargin * 2,
        overflow: 'hidden', // Safari not responding to 'clip'

        // See ImageLayer for file size comments.
        maskImage: `url(${url.startsWith(MEDIA_SERVICE) ? `${url}?rendition=medium` : url})`,
        maskSize: 'cover',

        backgroundImage: createFoilFill(pointerUnitPosition, colorId),
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }}
    />
  );
};
