import { Box, Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { QRCodeLayerData } from './Layer.types';
import { layerLayoutDataToCSS } from './ImageLayer';
import { textLayerDataToCSS } from './TextLayer';
import { useFont } from '../../../hooks/useFont';
import { useCreateQRCodeMutation } from '@graphql/generated';
import { StationeryDraftFormat } from '@graphql/generated';
import { PageEditor } from './Page';

export const QRCodeLayer = (props: { editor?: PageEditor; layer: QRCodeLayerData; isActive?: boolean; format?: StationeryDraftFormat }) => {
  const { editor, layer, isActive, format } = props;
  const { link, password, fill, font, text, hideImage } = layer.qrcodeData;

  const [qrCodeImage, setQrCodeImage] = useState<string | undefined>(undefined);
  const [createQRCode] = useCreateQRCodeMutation();
  const hideQRCodeImage = format === StationeryDraftFormat.digital && hideImage;
  useEffect(() => {
    void (async () => {
      if (!hideQRCodeImage) {
        try {
          const url = new URL(link);

          if (url) {
            url.searchParams.set('utm_source', 'qr');
            url.searchParams.set('utm_medium', 'paper');
            url.searchParams.set('src', 'print');

            const { data } = await createQRCode({ variables: { data: { link: url.toString(), fillColor: fill } } });

            if (data) {
              setQrCodeImage(data.createQRCode);
            }
          }
        } catch {}
      }
    })();
  }, [createQRCode, link, hideQRCodeImage, fill]);

  useFont(`${font.family}:${font.weight}${font.style ?? ''}`);

  const alignItems = (() => {
    if (text.alignHorizontal === 'left') return 'flex-start';
    if (text.alignHorizontal === 'right') return 'flex-end';
    return 'center';
  })();

  const isInEditor = !!editor;
  const formattedLink = link.replace(/^https?:\/\//, '');
  const shouldHighlightLink = format === StationeryDraftFormat.digital;

  return (
    <Flex
      className="introable"
      cursor={isActive ? 'move' : 'default'}
      userSelect="none"
      alignItems={alignItems}
      flexDirection="column"
      gap="5px"
      style={{
        ...textLayerDataToCSS(layer.qrcodeData),
        ...layerLayoutDataToCSS(layer.layout),
        height: 'unset',
        wordBreak: 'break-word'
      }}
    >
      {qrCodeImage && !hideQRCodeImage ? (
        <Box as="img" src={qrCodeImage} draggable={false} style={{ width: 72, height: 72 }} />
      ) : (
        <Box draggable={false} style={{ width: 72, height: 72 }} />
      )}
      <Box>
        {shouldHighlightLink ? (
          <Box
            fontWeight="bold"
            style={{ textDecoration: 'underline 0.5px #D9D9D9', textUnderlineOffset: '4px', display: 'inline' }}
            {...(!isInEditor ? { as: 'a', href: link, cursor: 'pointer', target: '_blank' } : {})}
          >
            {formattedLink}
          </Box>
        ) : (
          formattedLink
        )}
        {password ? `\nPassword: ${password}` : ''}
      </Box>
    </Flex>
  );
};
