import { defaultTheme, Flex, styled, StyleSystemProps } from '@withjoy/joykit';

export const StyledVirtualEventContainer = styled(Flex)``;
const virtualEventContainerStyles: StyleSystemProps = {
  backgroundColor: 'mono2',
  borderRadius: '1rem',
  padding: `${defaultTheme.space[7]} ${defaultTheme.space[5]}`,
  width: '100%',
  maxWidth: '434px',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '1rem'
};

const columnReverseContainerStyles: StyleSystemProps = {
  flexDirection: ['column', 'column', 'column-reverse'],
  alignItems: 'center',
  rowGap: '1rem'
};

export const styles = {
  virtualEventContainer: virtualEventContainerStyles,
  columnReverseContainer: columnReverseContainerStyles
};
