import React, { useContext, useMemo } from 'react';
import { ButtonV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { Search } from '@withjoy/joykit/icons';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { AccommodationCombined } from '../Accommodations/Accommodation.types';
import { Category } from '@graphql/generated';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { button2ToButtonsOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { useEventInfo } from '@shared/utils/eventInfo';
import { Link } from '@shared/core';
import { EventVenue } from '@shared/utils/eventPlace';
import { useColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';
import { getButtonStylesWithAccentColor } from '@apps/guest/packages/layout-engine/layouts/layout.utils';

interface ExploreOtherOptionsProps {
  pageSlug: string;
  eventTimezone: Maybe<string>;
  venue: Maybe<EventVenue>;
  accommodations: Maybe<AccommodationCombined[]>;
}

export const ExploreOtherOptions: React.FC<ExploreOtherOptionsProps> = ({ pageSlug, eventTimezone: timezone, venue, accommodations }) => {
  const analytics = useContext(AnalyticsContext);

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const { accentColor } = useColorPaletteProvider();
  const buttonStyles = useMemo(() => (accentColor ? getButtonStylesWithAccentColor(accentColor) : {}), [accentColor]);

  const { t2 } = useTranslation('guestSiteCustom');
  const bookingAssistantTrans = t2('bookingAssistant');

  const { eventHandle } = useEventInfo();
  const params = new URLSearchParams({
    utm_source: 'website_accommodations_page',
    utm_medium: 'web',
    utm_campaign: 'explore_other_options'
  });
  const url = `/${eventHandle}/hotel/?${params.toString()}`;

  const handleOnClick = useEventCallback(() => {
    analytics.track({
      action: 'LinkInteracted',
      category: 'linkify',
      label: 'accommodations',
      extraInfo: {
        url,
        actionType: 'click',
        source: 'accommodations'
      }
    });
    analytics.track({
      action: 'BookingAssistantClicked',
      actionType: 'click',
      category: 'wedding',
      pagePrefix: 'guest',
      page: pageSlug
    });
  });

  return (
    <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
      <ButtonV2
        width={pxToRem(244)}
        startIcon={<Search size="md" />}
        typographyVariant="button2"
        intent="neutral"
        shape="rounded"
        variant="outline"
        fontWeight={600}
        fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
        onClick={handleOnClick}
        as={Link}
        to={url}
        {...buttonStyles}
      >
        {bookingAssistantTrans.cta}
      </ButtonV2>
    </GuestSiteTypographyOverride>
  );
};
