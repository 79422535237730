import React from 'react';
import { EmailInputFields, EmailType } from '@shared/components/EmailsAndEcards/Emails.types';
import { FormikProps } from 'formik';
import { Box, ButtonV2, SpacingStack, TextV2, CancelButton, IconV2, Flex } from '@withjoy/joykit';
import { DesignEmail, Close } from '@withjoy/joykit/icons';
import { StyledEmailEditPanelDrawer, DrawerButtonsWrapper, FormWrapper } from './EmailEditPanel.styles';
import { useEmailEditPanelController } from './EmailEditPanel.controller';
import { EcardType, EventPageFragment } from '@graphql/generated';
import { useEmailEditorContext } from '../../EmailEditor.context';
import { darkButtonOverride } from '@apps/postOffice/components/Shared.styles';
import { RemoveScroll } from 'react-remove-scroll';

export interface EmailEditPanelProps
  extends Readonly<{
    emailType: EmailType;
    formik: FormikProps<EmailInputFields>;
    handleCancelClick: () => void;
    isEmailEditPanelShown: boolean;
    toggleEmailEditPanel: () => void;
    pages?: ReadonlyArray<EventPageFragment>;
    isPasswordToggleShown?: boolean;
    eventDate?: string;
    eventHandle?: string;
    eventDisplayName?: string;
    ecardDraftType?: EcardType;
  }> {}

export const EmailEditPanel: React.FC<EmailEditPanelProps> = ({
  emailType,
  formik,
  handleCancelClick,
  isEmailEditPanelShown,
  pages,
  toggleEmailEditPanel,
  isPasswordToggleShown,
  eventDate,
  eventHandle,
  eventDisplayName,
  ecardDraftType
}) => {
  const { allowSidePannedOutsideClickHandle } = useEmailEditorContext();
  const { tEmailEditor, onSaveClick, getEmailSpecificComponent, isMobile, headlineIsNotValid, tEmailEditorHeadlineError } = useEmailEditPanelController({
    formik,
    toggleEmailEditPanel,
    emailType,
    isPasswordToggleShown,
    eventDate,
    ecardDraftType,
    eventHandle,
    eventDisplayName,
    pages
  });

  return (
    <RemoveScroll enabled={isMobile && isEmailEditPanelShown}>
      <StyledEmailEditPanelDrawer
        isOpen={isEmailEditPanelShown}
        position={isMobile ? 'bottom' : 'right'}
        canCloseOnOutsideClick={allowSidePannedOutsideClickHandle}
        useBackdrop={false}
        onClose={handleCancelClick}
      >
        <Box style={{ position: 'relative', height: '100%' }}>
          <FormWrapper padding={8}>
            <Flex justifyContent="space-between">
              <TextV2 typographyVariant="hed6" style={{ fontWeight: 700 }} marginBottom={8}>
                <IconV2 size="md" marginRight="12px" display={['block', 'block', 'none !important']}>
                  <DesignEmail />
                </IconV2>{' '}
                {tEmailEditor.header}
              </TextV2>
              <IconV2 size="md" onClick={handleCancelClick} cursor="pointer">
                <Close />
              </IconV2>
            </Flex>
            <TextV2 typographyVariant="label2" color="negative6" marginBottom={6}>
              {headlineIsNotValid && tEmailEditorHeadlineError({ headline: formik.values.headline })}
            </TextV2>
            <SpacingStack spacing={6} marginBottom={8}>
              {getEmailSpecificComponent()}
            </SpacingStack>
          </FormWrapper>
          <DrawerButtonsWrapper alignItems="center" justifyContent="center" flexDirection="column" padding="24px">
            <ButtonV2 overrides={darkButtonOverride} variant="solid" intent="primary" onClick={onSaveClick} disabled={!formik.isValid} width="100%" marginBottom="16px">
              {tEmailEditor.doneButtonText}
            </ButtonV2>
            <CancelButton onClick={handleCancelClick}>{tEmailEditor.cancelButtonText}</CancelButton>
          </DrawerButtonsWrapper>
        </Box>
      </StyledEmailEditPanelDrawer>
    </RemoveScroll>
  );
};
