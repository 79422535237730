import chroma from 'chroma-js';

export const parseColor = (color?: string) => {
  if (!color) {
    return undefined;
  }
  try {
    return chroma(color);
  } catch (e) {
    return undefined;
  }
};

export const isWhite = (color?: string) => {
  if (!color) return false;
  try {
    return chroma(color).hex().toLowerCase() === '#ffffff';
  } catch (e) {
    return false;
  }
};

/**
 * Determines if a color is light or dark based on the HSP color model.
 * @param r red channel value 0-255
 * @param g green channel value 0-255
 * @param g blue channel value 0-255
 * @returns boolean true if the color is in the upper half of the HSP color range.
 */
export const isLightColor = (r: number, g: number, b: number): boolean => {
  const HALF_HSP_RANGE = 127.5;

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Determine whether the color is dark
  if (hsp < HALF_HSP_RANGE) {
    return false;
  }

  return true;
};

export const darkenColor = (color: string) => {
  // taken from monorepo
  return chroma(
    chroma(color)
      .rgb()
      .map(x => x * 0.8)
  ).hex();
};

export const toRgbArray = (color: string): [number, number, number] => {
  return chroma(color).rgb();
};
