import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

const ROOMBLOCK = 'roomblock';
const HOTEL_BOOKING = 'hotelbooking';

const HOTEL_BOOKING_ROUTE = `/:eventHandle/${HOTEL_BOOKING}`;
const HOTEL_BOOKING_ROUTES = {
  hotelbooking: HOTEL_BOOKING_ROUTE,
  roomblock: `${HOTEL_BOOKING_ROUTE}/${ROOMBLOCK}/:id`,
  partnerhotels: `${HOTEL_BOOKING_ROUTE}/partnerhotels/:hotelId`
} as const;

export const useHotelBookingRoutePaths = () => {
  const buildPath = useEventCallback((eventHandle: string, routePath: string, id?: string) => `/${eventHandle}/${HOTEL_BOOKING}/${routePath}${id ? `/${id}` : ''}`);
  return {
    buildPath,
    roomblock: ROOMBLOCK,
    hotelBooking: HOTEL_BOOKING,
    hotelBookingRoute: HOTEL_BOOKING_ROUTE,
    hotelBookingRoutes: HOTEL_BOOKING_ROUTES
  };
};

export const useHotelRoutePaths = () => {
  return {
    hotelSearch: {
      path: '/hotel/search',
      gotToPath: (eventId?: Maybe<string>, queryString?: Maybe<string>) => `/hotel/search?eventId=${eventId}${queryString ? '&' + queryString : ''}`
    },
    hotelDetails: {
      path: '/hotel/details',
      goToPath: (eventId?: Maybe<string>, joyPlaceId?: Maybe<string>, customUrl?: Maybe<string>, queryString?: Maybe<string>) =>
        `/hotel/details?eventId=${eventId}&joyPlaceId=${joyPlaceId}${customUrl ? '&customUrl=' + encodeURIComponent(customUrl) : ''}${queryString ? '&' + queryString : ''}`
    }
  };
};
