import { useMemo } from 'react';

import { monorepoRoutePaths, routePaths as adminRoutePaths } from '@apps/admin/route.paths';
import { useDashboardTelemetry } from '@apps/admin/routes/Dashboard/Dashboard.telemetry';
import { getProductVisibility } from '@apps/admin/routes/Dashboard/common/enums/ProductVisibilityEnum';
import { EventPageSlugs } from '@apps/admin/routes/Dashboard/common/enums/EventPageSlugs';
import { useRouterHelper, useTranslation } from '@shared/core';
import { Bed, EditPages, GuestList, MailingAddress, RsvpAdmin, Venue } from '@withjoy/joykit/icons';
import { useFeatureValue } from '@shared/core/featureFlags';
import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { PageInfo } from './useProductRows';
import { ProductRowItemProps } from '../../PrimaryDashboardContent/ProductRowItem';
import { usePlanningRoutes } from '@apps/admin/routes/Planning/hooks/usePlanningRoutes';
import { PlanningRoutes } from '@apps/admin/routes/Planning/Planning.routes';
import { useEventInfo } from '@shared/utils/eventInfo';

function getGuestListRsvpEnablementAndVisibility(eventPages: PageInfo[], productVerticals: ProductVerticals) {
  const rsvpPage = eventPages.find(({ pageSlug }) => pageSlug === EventPageSlugs.Rsvp);
  const visibility = getProductVisibility(rsvpPage?.visibility);
  const isEnabled: boolean = !productVerticals.optOutOfGuestList;

  return {
    isEnabled,
    visibility,
    pageId: rsvpPage?.id
  };
}

const useWeddingShopFeature = (): ProductRowItemProps | null => {
  const { value, payload } = useFeatureValue('weddingShopFeatureEnabled');
  const { t2 } = useTranslation('adminDashboardV2');
  const accommodationsAdminAddStayUpdatesEnabled = useFeatureValue('accommodationsAdminAddStayUpdatesEnabled').value === 'on';

  const isFeatureEnabled = value !== 'off' && value !== undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const maybeTileTitle = (payload as any)?.tileTitle;

  return useMemo(() => {
    if (isFeatureEnabled) {
      return {
        title: maybeTileTitle || t2('sections').guestList.children.weddingShop.title,
        icon: Venue,
        path: 'https://weddingshop.withjoy.com',
        pathIsInJoyWeb: false,
        hasNewPill: !accommodationsAdminAddStayUpdatesEnabled,
        telemetryName: 'weddingShop'
      };
    }

    return null;
  }, [isFeatureEnabled, t2, maybeTileTitle, accommodationsAdminAddStayUpdatesEnabled]);
};

export const useGuestListRsvpProductRow = (eventPages: PageInfo[], productVerticals: ProductVerticals, eventId?: string): ProductRow => {
  const routeHelpers = useRouterHelper();
  const telemetry = useDashboardTelemetry();
  const { t2 } = useTranslation('adminDashboardV2');
  const visibilityTranslations = t2('visibility');
  const { guestList: guestListTranslations } = t2('sections');
  const { value: contactCollectionEnabled } = useFeatureValue('contactCollectionEnabled');
  const accommodationsAdminAddStayUpdatesEnabled = useFeatureValue('accommodationsAdminAddStayUpdatesEnabled').value === 'on';
  const adminDashboardHoneymoonEnabled = useFeatureValue('adminDashboardHoneymoonEnabled').value === 'on';
  const weddingShopTile = useWeddingShopFeature();
  const { hotelBlockPath } = usePlanningRoutes();
  const { eventHandle } = useEventInfo();

  const guestListRsvpProductRow = useMemo<ProductRow>(() => {
    const guestListStrings = guestListTranslations;
    const visibilityStrings = visibilityTranslations;
    const { isEnabled, visibility, pageId } = getGuestListRsvpEnablementAndVisibility(eventPages, productVerticals);

    const children: Array<ProductRowItemProps> = [
      {
        title: guestListStrings.children.guestList.title,
        icon: GuestList,
        path: routeHelpers.buildPath(monorepoRoutePaths.guests),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'guestList', section: 'Guest List', categoryArg }),
        telemetryName: monorepoRoutePaths.guests
      },
      {
        title: guestListStrings.children.contactCollector.title,
        icon: MailingAddress,
        path: routeHelpers.buildPath(adminRoutePaths.contact.path),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'contactCollector', section: 'Contact Collector', categoryArg }),
        hasNewPill: !accommodationsAdminAddStayUpdatesEnabled,
        pathIsInJoyWeb: true,
        telemetryName: adminRoutePaths.contact.path
      },
      {
        title: guestListStrings.children.rsvp.title,
        icon: RsvpAdmin,
        path: routeHelpers.buildPath(monorepoRoutePaths.rsvp),
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'rsvp', section: 'Guest List', categoryArg }),
        telemetryName: monorepoRoutePaths.rsvp
      }
    ];

    if (weddingShopTile) {
      children.push(weddingShopTile);
    }

    if (accommodationsAdminAddStayUpdatesEnabled && !adminDashboardHoneymoonEnabled) {
      children.push({
        title: guestListStrings.children.hotels.children.roomBlocks.title,
        hideDropdownLabel: true,
        icon: Bed,
        path: hotelBlockPath,
        onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminRoomBlockTile', section: 'Hotel Room Block', categoryArg }),
        telemetryName: PlanningRoutes.hotelblock,
        pathIsInJoyWeb: true
      });
    }

    if (accommodationsAdminAddStayUpdatesEnabled && adminDashboardHoneymoonEnabled) {
      children.push({
        title: guestListStrings.children.hotels.title,
        hideDropdownLabel: true,
        icon: EditPages,
        subItems: [
          {
            title: guestListStrings.children.hotels.children.roomBlocks.title,
            path: hotelBlockPath,
            onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminRoomBlockTile', section: 'Hotel Room Block', categoryArg }),
            telemetryName: PlanningRoutes.hotelblock
          },
          {
            title: guestListStrings.children.hotels.children.honeymoon.title,
            path: `/${eventHandle}/${adminRoutePaths.hotelSearch.path}`,
            onClick: (categoryArg?: string) => telemetry.openPage({ label: 'adminHoneymoonTile', section: 'Honeymoon', categoryArg }),
            telemetryName: PlanningRoutes.honeymoon
          }
        ]
      });
    }

    return {
      title: guestListStrings.title,
      stringOn: visibilityStrings.rsvpOn,
      stringOff: visibilityStrings.rsvpOff,
      settingsUrl: routeHelpers.buildPath(monorepoRoutePaths.rsvpSettings),
      showVisibilityDialog: true,
      isEnabled,
      visibility,
      onDialogOpen: () => telemetry.visibilityDialog.enter('rsvpVisibilityDialog'),
      onDialogUpdate: ({ visibility, visibilityChanged, eventPasswordChanged }) =>
        telemetry.visibilityUpdated({ label: 'rsvp', visibility, visibilityChanged, eventPasswordChanged }),
      pageId,
      children: !!contactCollectionEnabled ? children : children.filter(({ title }) => title !== guestListStrings.children.contactCollector.title)
    };
  }, [
    guestListTranslations,
    visibilityTranslations,
    eventPages,
    productVerticals,
    routeHelpers,
    accommodationsAdminAddStayUpdatesEnabled,
    weddingShopTile,
    adminDashboardHoneymoonEnabled,
    contactCollectionEnabled,
    telemetry,
    hotelBlockPath,
    eventHandle
  ]);

  return guestListRsvpProductRow;
};
