import React from 'react';
import { Route, Switch } from '@react-router';
import loadable from '@loadable/component';

import { useHotelBookingRoutePaths } from './HotelBooking.routes';
import { TelemetryProvider as HotelBookingTelemetryProvider } from './HotelBooking.telemetry';

const HotelBooking = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest/hotelbooking/routes/hotelbooking" */
      '@apps/guest/routes/HotelBooking/routes/HotelBooking'
    )
);

const RoomBlockHotel = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest/hotelbooking/routes/roomblockhotel" */
      '@apps/guest/routes/HotelBooking/routes/RoomBlockHotel'
    )
);

const HotelBookingPartner = loadable(
  () =>
    import(
      /* webpackChunkName: "content/guest/hotelbooking/routes/hotelbookingpartner" */
      '@apps/guest/routes/HotelBooking/routes/HotelBookingPartner'
    )
);

export const HotelBookingApp: React.FC<{ eventHandle: string }> = ({ eventHandle }) => {
  const { hotelBookingRoutes } = useHotelBookingRoutePaths();
  return (
    <HotelBookingTelemetryProvider context={{ eventName: eventHandle }}>
      <Switch>
        <Route
          path={hotelBookingRoutes.partnerhotels}
          render={({ match }) => {
            return <HotelBookingPartner hotelId={match.params.hotelId} />;
          }}
        />
        <Route
          path={hotelBookingRoutes.roomblock}
          render={({ match }) => {
            return <RoomBlockHotel roomBlockHotelId={match.params.id || null} />;
          }}
        />
        <Route path={hotelBookingRoutes.hotelbooking} render={() => <HotelBooking eventHandle={eventHandle} />} />
      </Switch>
    </HotelBookingTelemetryProvider>
  );
};
