import { Flex, TextV2, LinkV2 } from '@withjoy/joykit';
import React from 'react';
import { Phone } from '@withjoy/joykit/icons';
import { AccommodationCombinedType } from '../../Accommodation.types';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { useFeatureValue } from '@shared/core/featureFlags';
import { body2ToParagraphOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

export const AccommodationTilePhone: React.FC<{ type: AccommodationCombinedType; phone?: string | null }> = ({ phone, type }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  if (type === AccommodationCombinedType.Roomblock || !phone) return null;

  return (
    <Flex flexDirection={'row'} columnGap={5}>
      <Flex>
        <Phone size={32} />
      </Flex>
      <Flex flexDirection={'column'} justifyContent={'center'}>
        <LinkV2 fontSize={15} lineHeight={'22.5px'} letterSpacing={'-0.15px'} textDecoration={'none'} wordBreak="break-word" href={`tel:${phone}`}>
          <GuestSiteTypographyOverride override={body2ToParagraphOverride} defaultFontFamily="Inter UI">
            <TextV2 {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} paddingBottom={1}>
              {phone}
            </TextV2>
          </GuestSiteTypographyOverride>
        </LinkV2>
      </Flex>
    </Flex>
  );
};
