import { ResponsiveMarginProps } from '@withjoy/joykit/styled-system';
import React from 'react';
import { PrettyUrlOptions } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { LinkType } from '@shared/components/Linkify/Linkify.types';
import { StyledRoot } from './PrettyLink.styles';

export interface PrettyLinkProps extends ResponsiveMarginProps {
  readonly tagName?: 'span' | 'p' | 'div';

  readonly allowedTypes?: LinkType[];

  readonly children: Maybe<string> | React.ReactNode;

  readonly prettyUrlOptions?: PrettyUrlOptions;

  readonly source?: string;
}

const PrettyLink: React.FC<PrettyLinkProps> = ({ children, allowedTypes, tagName = 'p', ...restProps }) => {
  return (
    <StyledRoot tagName={tagName} allowedTypes={allowedTypes} {...restProps}>
      {children}
    </StyledRoot>
  );
};

PrettyLink.displayName = 'PrettyLink';

export { PrettyLink };
