import { SafetyRating, useEventSafetyRatingQuery } from '@graphql/generated';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useToast } from '@withjoy/joykit';

type UseEventSafetyRatingQueryArgs = {
  eventHandle: Maybe<string>;
};

export const useVerifyEventSafetyAndSendMessage = (args: UseEventSafetyRatingQueryArgs) => {
  const { eventHandle } = args;
  const { toast } = useToast();
  const { data, loading: isFetchingEventSafetyRating } = useEventSafetyRatingQuery({
    variables: {
      name: eventHandle!
    },
    skip: !eventHandle,
    batchMode: 'fast'
  });

  const verifyEventSafetyAndSendMessage = useEventCallback((onSuccess: () => void, onFail?: () => void) => {
    const eventSafetyRating = data?.eventByName?.info.safetyRating;
    if (isFetchingEventSafetyRating) {
      return;
    }

    if (eventSafetyRating === SafetyRating.unsafeRequiresEscalation) {
      onFail?.();
      toast(`Error: Unable to send messages. Verify your account with support@withjoy.com or via the help chat.`);
      return;
    }

    onSuccess();
  });

  return {
    isFetchingEventSafetyRating,
    verifyEventSafetyAndSendMessage
  };
};
