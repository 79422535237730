import { StationeryTemplateCategoryEnum } from '@graphql/generated';
import type { StationeryTemplateCategoryTranslations } from '@locales/generated';
import { useTranslation } from '@shared/core';
import { TOptions } from 'i18next';
import { useCallback } from 'react';

type Key = keyof StationeryTemplateCategoryTranslations;

type TranslateFunction = (templateCategory: StationeryTemplateCategoryEnum, options?: TOptions) => string;

export const useStationeryTemplateCategoryTranslations = (key: Key): TranslateFunction => {
  const { t } = useTranslation('stationeryTemplateCategory');

  return useCallback(
    (templateCategory: StationeryTemplateCategoryEnum, options?: TOptions) => {
      const translationMap = t(key);

      if (templateCategory in translationMap) {
        // have to explicitly ignore this error despite checking in the line above
        // @ts-ignore
        return translationMap[templateCategory](options);
      }

      return translationMap.default(options);
    },
    [t, key]
  );
};
