import React, { useState, useCallback } from 'react';
import { StationeryPrintOrderByOrderNumberQuery } from '@graphql/generated';
import { ButtonV2, TextV2, Flex } from '@withjoy/joykit';
import Dialog from './components/Dialog/Dialog';
import { Table } from './Issues.styles';

interface IssuesProps {
  issues: NonNullable<StationeryPrintOrderByOrderNumberQuery['stationeryPrintOrderByOrderNumber']>['issues'];
  orderId: string;
}

const Issues: React.FC<IssuesProps> = ({ issues, orderId }) => {
  const [issueId, setIssueId] = useState<string | null>(null);
  const handleOnResolveClick = useCallback((issueId: string) => {
    setIssueId(issueId);
  }, []);

  return (
    <Flex flexDirection="column" rowGap={{ _: 8, md: 10 }} width={{ _: '100%', md2: 1080 }} maxWidth="100%" marginBottom={{ _: 8, md: 10 }}>
      <Dialog onClose={() => setIssueId(null)} orderId={orderId} issueId={issueId} />
      <TextV2 typographyVariant={{ _: 'HedMobile32', md: 'Hed56' }} paddingX={{ _: 6, sm2: 8, md: 0 }}>
        Order Issues
      </TextV2>
      <Table>
        <thead>
          <tr>
            <th scope="col">
              <TextV2 typographyVariant="label3">ID</TextV2>
            </th>
            <th scope="col">
              <TextV2 typographyVariant="label3">Type</TextV2>
            </th>
            <th scope="col">
              <TextV2 typographyVariant="label3">Target</TextV2>
            </th>
            <th scope="col">
              <TextV2 typographyVariant="label3">Description</TextV2>
            </th>
            <th scope="col">
              <TextV2 typographyVariant="label3">Resolved At</TextV2>
            </th>
            <th scope="col">
              <TextV2 typographyVariant="label3">Action</TextV2>
            </th>
          </tr>
        </thead>
        <tbody>
          {issues.map(({ id, issueType, issueTarget, additionalUserFacingDescription, resolvedAt }) => (
            <tr key={id}>
              <td>
                <TextV2 typographyVariant="label2">{id}</TextV2>
              </td>
              <td>
                <TextV2 typographyVariant="body1">{issueType}</TextV2>
              </td>
              <td>
                <TextV2 typographyVariant="body1">{issueTarget}</TextV2>
              </td>
              <td>
                <TextV2 typographyVariant="body1">{additionalUserFacingDescription}</TextV2>
              </td>
              <td>
                <TextV2 typographyVariant="body1">{resolvedAt ?? 'Pending'}</TextV2>
              </td>
              <td>
                <ButtonV2 disabled={resolvedAt || !orderId || !id} onClick={() => handleOnResolveClick(id)}>
                  Resolve
                </ButtonV2>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Flex>
  );
};

export default Issues;
