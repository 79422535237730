import { styled, Box, Flex, SpacingStack, css, ModalProps, StyleSystemProps, TextV2 } from '@withjoy/joykit';
import Carousel from 'react-elastic-carousel';
import { animated } from 'react-spring';

type DotProps = {
  $isActive: boolean;
};

export const getDialogOverrides = (isMobile: boolean | undefined) => {
  const overrides: NonNullable<ModalProps['overrides']> = {
    ContentContainer: {
      props: {
        paddingTop: 'unset',
        paddingBottom: 'unset'
      }
    },
    Content: {
      props: isMobile
        ? {
            minHeight: `calc(var(--vh, 1vh) * 100 - 24px)`,
            maxHeight: `calc(var(--vh, 1vh) * 100 - 24px)`,
            width: 'calc(100% - 24px)',
            marginTop: 4,
            marginBottom: 4,
            borderRadius: '8px',
            overflow: 'hidden'
          }
        : {
            marginTop: 5,
            marginBottom: 5,
            borderRadius: '12px'
          }
    }
  };

  return overrides;
};

const dialogBodyStyles: StyleSystemProps = {
  paddingX: [0],
  paddingY: [0, '20px']
};

// react-elastic-carousel type doesn't include `children` as a prop by default
// https://github.com/sag1v/react-elastic-carousel/pull/231
export const StyledCarousel = styled(Carousel)<{ children: React.ReactNode }>`
  .rec-swipable {
    * {
      &:focus-visible {
        outline: none;
      }
    }
  }

  .rec-slider-container {
    margin: 0;
  }

  .rec-item-wrapper > div {
    width: 100%;
  }

  ${props => props.theme.mediaQueries.md} {
    margin-top: ${({ theme }) => theme.space[9]};

    .rec-slider-container {
      margin: 0 10px;
    }
  }
`;

export const Slide = styled(animated(Flex))`
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${props => props.theme.mediaQueries.md} {
    gap: ${({ theme }) => theme.space[7]};
    margin: 0 auto;
    height: calc(100vh - 140px);
    flex-direction: row-reverse;
    justify-content: center;
    max-width: 1024px;
    padding-bottom: 30px;
  }
`;

export const GraphicAccent = styled(Flex)``;
const graphicAccentStyles: StyleSystemProps = {
  alignItems: 'center',
  justifyContent: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'left',
  height: '44px',
  width: 'auto',
  maxWidth: '100%',
  marginBottom: 0
};

export const PhotoPlaceholder = styled(Box)``;
const placeholderStyles: StyleSystemProps = {
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundColor: 'mono5',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: [0, 2],
  textTransform: 'uppercase',
  minHeight: '350px',
  minWidth: '350px'
};

export const StyledContentSection = styled(SpacingStack)``;
const contentSectionStyles: StyleSystemProps = {
  paddingX: [6],
  marginTop: [7, 7, 7, 0],
  paddingBottom: [90, 90, 90, 0],
  maxHeight: '100%',
  width: '100%',
  maxWidth: ['100%', '456px'],
  overflowY: 'auto',
  overflowX: 'hidden',
  rowGap: 5
};

const aliasStyles: StyleSystemProps = {
  overflow: ['hidden', 'hidden', 'initial'],
  textOverflow: 'ellipsis'
};

export const StyledDescription = styled(TextV2)`
  white-space: pre-line;
`;

export const StyledImageSection = styled(animated(SpacingStack))``;
const imageSectionStyles: StyleSystemProps = {
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '350px',
  minWidth: '350px'
};

export const StyledImage = styled(Box)``;
const imageStyles: StyleSystemProps = {
  borderRadius: ['inherit', '4px'],
  width: ['unset', 'unset', 'unset', '100%'],
  maxWidth: ['100%', '456px', '456px', '640px'],
  maxHeight: ['100%', '456px', '456px', '640px']
};

export const StyledPaginationContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 32px;
  height: 24px;
  gap: 16px;

  > * {
    &:first-child {
      margin-right: -4px;
    }

    &:last-child {
      margin-left: -4px;
    }
  }
`;

export const DotsContainer = styled(Flex)``;
const dotsContainerStyles: StyleSystemProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'sticky',
  bottom: ['32px', '12px'],
  backgroundColor: 'white',
  paddingY: 1,
  paddingX: 4,
  boxShadow: '0px 7px 27px rgb(44 41 37 / 6%), 0px 4px 16px rgb(0 0 0 / 7%)',
  borderRadius: '12px',
  height: '24px'
};

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.black : theme.colors.gray6)};
  cursor: pointer;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      box-shadow: 0 0 0 4px white, 0 0 0 5.5px ${({ theme }) => theme.colors.linkActive};
    `}
`;

export const StyledIcon = styled(Box)``;
const iconNavButtonStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
  color: 'black',
  backgroundColor: 'mono1',
  _hover: {
    backgroundColor: 'mono2',
    color: 'black'
  },
  _active: {
    backgroundColor: 'mono3',
    color: 'black'
  },
  _disabled: {
    backgroundColor: 'white',
    color: 'mono6',
    cursor: 'not-allowed'
  }
};

export const StyledCloseIcon = styled(Box)``;
const iconCloseButtonStyles: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  background: ['rgba(0, 0, 0, 0.5)', 'inherit'],
  borderRadius: ['50%'],
  color: ['white', 'black'],
  width: ['40px', '48px'],
  height: ['40px', '48px'],
  padding: [3, 'auto']
};

export const StyledAnimatedDiv = styled(animated.div)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    isMobile
      ? css`
          height: 100vh;
          height: 100dvh;
          overflow: scroll;
        `
      : ''}
`;

export const styles = {
  dialogBody: dialogBodyStyles,
  contentSection: contentSectionStyles,
  dotsContainer: dotsContainerStyles,
  graphicAccent: graphicAccentStyles,
  iconCloseButton: iconCloseButtonStyles,
  iconNavButton: iconNavButtonStyles,
  imageSection: imageSectionStyles,
  image: imageStyles,
  alias: aliasStyles,
  placeholder: placeholderStyles
};
