import React, { useCallback, useMemo, useState } from 'react';
import { GetDigitalAndPaperDraftsByEventIdQuery, StationeryDraftFormat, useGetDigitalAndPaperDraftsByEventIdQuery } from '@graphql/generated';
import { Link, useTranslation, useRouterHelper, useLocation } from '@shared/core';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { templateToTitle } from '@apps/card/Card.utils';
import { GalleryTile } from '../GalleryTile';
import { PageSection } from '../PageSection';
import { Box, Flex, TextV2, ButtonV2, LinkV2, BoxProps } from '@withjoy/joykit';
import { Edit } from '@withjoy/joykit/icons';
import { ThemeJson } from '@apps/card/routes/CardCustomizer/CardCustomizer.types';
import { formatDistanceToNowStrict } from 'date-fns';
import { useCardTelemetry } from '@apps/card/Card.telemetry';
import { FitScaler } from '@apps/card/routes/CardCustomizer/components/FitScaler';
import { Shadow } from '@apps/card/routes/CardCustomizer/steps/CardDesign/CardPagePreview';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { useIsMobileOrTablet, useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { SavetheDateEcard, PhotoSavetheDateEcard, InvitationsEcard, CustomEcard } from '@assets/icons';
import { ecardRoutePaths } from '@apps/ecard/Ecard.route.paths';
import { useFeatureFlagsContext, useFeatureValue } from '@shared/core/featureFlags';
import { DeleteDraftButton } from './DeleteDraftButton';
import { DeleteDraftDialog } from './DeleteDraftDialog';
import { pxToRem } from '@withjoy/joykit/theme';
import EmptyState from '../EmptyState';
import { useStationeryTemplateCategoryTranslations } from '@apps/card/hooks/useStationeryTemplateCategoryTranslations';

type StationeryDraft = GetDigitalAndPaperDraftsByEventIdQuery['stationeryDrafts'][number];

interface DraftsGalleryProps {
  eventId: string;
}

interface DigitalDraft {
  title: string;
  icon: JSX.Element;
  show: boolean;
  url: URL;
}

interface StationeryCardsSectionProps {
  format: StationeryDraftFormat;
  drafts: Maybe<GetDigitalAndPaperDraftsByEventIdQuery['stationeryDrafts']>;
  onDeleteDraftClick: (draft: StationeryDraft) => void;
}

interface DigitalCardsProps {
  noDigitalDrafts: boolean;
  digitalDrafts: DigitalDraft[];
}

const FormattedUpdateAtLabel = React.memo(({ updatedAt }: { updatedAt: string }) => {
  const { t } = useTranslation('stationery');
  return (
    <TextV2 as="span" typographyVariant="label2" color="mono8">
      {t('dashboard', 'draftsGallery', 'editedAtLabel')({ timeAgo: formatDistanceToNowStrict(new Date(updatedAt), { addSuffix: true }) })}
    </TextV2>
  );
});

const GalleryGrid = (props: { children: React.ReactNode; columns: BoxProps['gridTemplateColumns'] }) => {
  const { children, columns } = props;
  return (
    <Box
      display="grid"
      maxWidth="100%"
      gridTemplateColumns={columns}
      columnGap={{
        _: 5,
        sm2: 6,
        md3: 8
      }}
      rowGap={{
        _: 6,
        sm2: 8,
        md3: 10
      }}
      paddingBottom={8}
    >
      {children}
    </Box>
  );
};

const StationeryCards: React.FC<StationeryCardsSectionProps> = ({ format, drafts, onDeleteDraftClick }) => {
  const { value: printEnableDraftsV2Value } = useFeatureValue('printEnableDraftsV2');
  const isPrintDraftsV2Enabled = printEnableDraftsV2Value === 'on';
  const isMobileOrTablet = useIsMobileOrTablet();
  const [isOpened, setIsOpened] = useState(!isPrintDraftsV2Enabled);
  const { getCustomizeDraftPath, getDigitalCustomizeDraftPath } = useCardsRouterContext();
  const { draftsGalleryTileInteracted, deleteDraftButtonInteracted } = useCardTelemetry({ page: 'draftsRoute' });
  const { t } = useTranslation('stationery');
  const getCategoryName = useStationeryTemplateCategoryTranslations('stationeryTemplateCategoryToTile');
  const draftsGalleryPaperSectionTranslations = t('dashboard', 'draftsGallery', 'stationerySection');
  const isMobile = useIsMobileScreen();

  const customizeDraftPath = (draftId: string) => {
    if (format === StationeryDraftFormat.print) {
      return getCustomizeDraftPath(draftId);
    }
    if (format === StationeryDraftFormat.digital) {
      return getDigitalCustomizeDraftPath(draftId);
    }

    return '';
  };

  const tilesToShow = useMemo(() => (isMobileOrTablet ? 2 : 3), [isMobileOrTablet]);
  const isEmpty = drafts?.length === 0;
  const hasMinimumQuantityOfDraftsToShowButton = (drafts?.length || 0) > tilesToShow;

  const draftsToDisplay = useMemo(() => {
    if (isOpened || !isPrintDraftsV2Enabled) {
      return drafts;
    }

    return drafts?.slice(0, tilesToShow);
  }, [isOpened, drafts, tilesToShow, isPrintDraftsV2Enabled]);

  const handleDeleteDraftClick = useCallback(
    (draft: StationeryDraft) => {
      deleteDraftButtonInteracted({
        themeId: draft.stationeryTemplate.themeId,
        format: draft.format,
        isPremiumDesign: draft.stationeryTemplate.premium,
        stationeryTemplateCategory: draft.stationeryTemplate.category
      });
      onDeleteDraftClick(draft);
    },
    [deleteDraftButtonInteracted, onDeleteDraftClick]
  );

  const emptyText = draftsGalleryPaperSectionTranslations.emptyStateText[isMobile ? 'mobile' : 'desktop'][format]();
  const emptyStateImage = `https://withjoy.com/media/print/drafts-${format}-empty-state.png`;

  return (
    <>
      <TextV2 typographyVariant={{ _: 'hed2', md: 'hed3' }} fontSize={{ _: '20px', md: '26px' }} fontWeight={600}>
        {draftsGalleryPaperSectionTranslations.title[format]()}
      </TextV2>
      {isEmpty ? (
        <EmptyState imageUrl={emptyStateImage} title="You don’t have any drafts just yet!" message={emptyText} />
      ) : (
        <>
          <GalleryGrid
            columns={{
              _: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)'
            }}
          >
            {draftsToDisplay?.map(draft => {
              const cardJSON = draft.cardJSON as ThemeJson;
              const { themeId, category } = draft.stationeryTemplate;
              return (
                <Link
                  to={customizeDraftPath(draft.id)}
                  onClick={() => {
                    draftsGalleryTileInteracted({ draftId: draft.id, themeId, templateCategory: category });
                  }}
                  key={draft.id}
                >
                  <GalleryTile rowGap={5}>
                    <Box aspectRatio="1/1" borderRadius={12} backgroundColor="mono2" overflow="hidden">
                      <FitScaler padding={16}>
                        <Shadow shape={cardJSON.customizations.shape}>
                          <Page width={5} height={7} page={cardJSON.card.front} shape={cardJSON.customizations.shape} />
                        </Shadow>
                      </FitScaler>
                    </Box>
                    <Flex columnGap={3} flexDirection={['column', null, 'row']} rowGap={4} justifyContent="space-between">
                      <GalleryTile.Content
                        alignment="left"
                        title={templateToTitle(draft.stationeryTemplate)}
                        label={
                          <Flex flexDirection={['column', null, 'row']} gap={[2, null, 4]} alignItems="baseline">
                            <span>{getCategoryName(category)}</span>
                            {draft.updatedAt && <FormattedUpdateAtLabel updatedAt={draft.updatedAt} />}
                          </Flex>
                        }
                      />
                      <Flex gap={3}>
                        <DeleteDraftButton onClick={() => handleDeleteDraftClick(draft)} />
                        <Flex
                          height={[pxToRem(40), null, pxToRem(36)]}
                          width={[null, null, pxToRem(36)]}
                          flex={[1, null, 'initial']}
                          display="inline-flex"
                          alignItems="center"
                          justifyContent="center"
                          borderRadius="full"
                          backgroundColor={['white', null, 'mono14']}
                          color={['mono14', null, 'white']}
                          gap={3}
                          border="2px solid"
                          borderColor="mono14"
                        >
                          <Edit size="sm" />
                          <Box as="span" fontWeight={600} display={['block', null, 'none']}>
                            Edit
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </GalleryTile>
                </Link>
              );
            })}
          </GalleryGrid>
          {isPrintDraftsV2Enabled && hasMinimumQuantityOfDraftsToShowButton && !isOpened && (
            <Flex alignItems="center" justifyContent="center">
              <ButtonV2 variant="outline" shape="rounded" intent="neutral" typographyVariant="button2" width={{ _: '100%', md: '262px' }} onClick={() => setIsOpened(true)}>
                {draftsGalleryPaperSectionTranslations.buttonTitle()}
              </ButtonV2>
            </Flex>
          )}
        </>
      )}
    </>
  );
};

const NoDigitalCardsText = ({ emptyStateTitle }: { emptyStateTitle: string }) => {
  return (
    <TextV2 typographyVariant={{ _: 'hed4', md: 'hed5' }} marginBottom={5} width="100%" textAlign="left">
      {emptyStateTitle}
    </TextV2>
  );
};

const DigitalCards: React.FC<DigitalCardsProps> = ({ noDigitalDrafts, digitalDrafts }) => {
  const location = useLocation();
  const { formatToUrl } = useFeatureFlagsContext();
  const { t } = useTranslation('stationery');
  const draftsGalleryDigitalSectionTranslations = t('dashboard', 'draftsGallery', 'digitalSection');

  const getUrlWithReferrer = (url: URL) => {
    const formattedUrl = formatToUrl(url.toString());
    const urlWithReferrer = new URL(formattedUrl);
    urlWithReferrer.searchParams.set('ref', encodeURIComponent(location.pathname));
    return urlWithReferrer.toString();
  };

  return (
    <>
      <TextV2 typographyVariant={{ _: 'hed2', md: 'hed3' }} fontSize={{ _: '20px', md: '26px' }} fontWeight={600}>
        {draftsGalleryDigitalSectionTranslations.title()}
      </TextV2>
      {noDigitalDrafts ? (
        <NoDigitalCardsText emptyStateTitle={draftsGalleryDigitalSectionTranslations.emptyStateTitle()} />
      ) : (
        <GalleryGrid
          columns={{
            _: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)'
          }}
        >
          {digitalDrafts?.map(
            draft =>
              draft.show && (
                <LinkV2
                  href={getUrlWithReferrer(draft.url)}
                  key={draft.title}
                  _activeLink={{ color: 'mono14' }}
                  _visited={{ color: 'mono14' }}
                  _active={{ color: 'mono14' }}
                  _pressed={{ color: 'mono14' }}
                  color={'mono14'}
                  textDecoration={'none'}
                  _hover={{ textDecoration: 'none' }}
                >
                  <GalleryTile rowGap={5}>
                    <Flex alignItems="center" justifyContent="center" aspectRatio="16/9" borderRadius={12} backgroundColor="mono2" overflow="hidden">
                      {draft.icon}
                    </Flex>
                    <Flex alignItems="center" justifyContent="center">
                      <TextV2 typographyVariant={{ _: 'label3', md: 'hed2' }} fontWeight={600}>
                        {draft.title}
                      </TextV2>
                    </Flex>
                  </GalleryTile>
                </LinkV2>
              )
          )}
        </GalleryGrid>
      )}
    </>
  );
};

export const isValidStationeryDraft = (draft: { cardJSON: unknown }) => {
  const maybeConfig = draft.cardJSON as DeepPartial<ThemeJson>;
  return !!maybeConfig?.card?.front?.layers && !!maybeConfig.card.back?.layers && !!maybeConfig.card.envelope?.layers;
};

const getSortedStationeryDraftsByFormat = (drafts: Maybe<GetDigitalAndPaperDraftsByEventIdQuery['stationeryDrafts']>, format: StationeryDraftFormat) => {
  const validDrafts = drafts?.filter(draft => {
    const isMatchingFormat = draft.format === format;
    return isMatchingFormat && isValidStationeryDraft(draft);
  });

  validDrafts?.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  return validDrafts;
};

export const DraftsGallery = (props: DraftsGalleryProps) => {
  const { eventId } = props;
  const { data, loading } = useGetDigitalAndPaperDraftsByEventIdQuery({ variables: { input: { eventId }, eventId }, batchMode: 'fast' });
  const [draftToDelete, setDraftToDelete] = useState<StationeryDraft | null>(null);
  const { buildPath } = useRouterHelper();
  const { value: printEnableDraftsV2Value } = useFeatureValue('printEnableDraftsV2');
  const { value: printEnableDigitalEditor } = useFeatureValue('printEnableDigitalEditor');
  const isPrintDraftsV2Enabled = printEnableDraftsV2Value === 'on';
  const isPrintDigitalEditorEnabled = printEnableDigitalEditor === 'on';
  const { t } = useTranslation('stationery');
  const draftsGalleryTranslations = t('dashboard', 'draftsGallery');
  const draftsGalleryDigitalSectionTranslations = t('dashboard', 'draftsGallery', 'digitalSection');

  const paperDrafts = useMemo(() => getSortedStationeryDraftsByFormat(data?.stationeryDrafts, StationeryDraftFormat.print), [data?.stationeryDrafts]);
  const premiumDigitalDrafts = useMemo(() => getSortedStationeryDraftsByFormat(data?.stationeryDrafts, StationeryDraftFormat.digital), [data?.stationeryDrafts]);

  const digitalInvitationDraft = data?.digitalInvitationDraft;
  const digitalSaveTheDateDraft = data?.digitalSaveTheDateDraft;
  const digitalPhotoSaveTheDateDraft = data?.digitalPhotoSaveTheDateDraft;
  const digitalCustomDraft = data?.digitalCustomDraft;

  const noDigitalDrafts = !digitalInvitationDraft && !digitalSaveTheDateDraft && !digitalPhotoSaveTheDateDraft && !digitalCustomDraft;

  const digitalDrafts: DigitalDraft[] = [
    {
      title: draftsGalleryDigitalSectionTranslations.photoSaveTheDate(),
      icon: <PhotoSavetheDateEcard />,
      show: !!digitalPhotoSaveTheDateDraft,
      url: new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath('savethedate')))
    },
    {
      title: draftsGalleryDigitalSectionTranslations.saveTheDate(),
      icon: <SavetheDateEcard />,
      show: !!digitalSaveTheDateDraft,
      url: new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath('ecard/savethedate')))
    },
    {
      title: draftsGalleryDigitalSectionTranslations.invitation(),
      icon: <InvitationsEcard />,
      show: !!digitalInvitationDraft,
      url: new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath('ecard/invitation')))
    },
    {
      title: draftsGalleryDigitalSectionTranslations.custom(),
      icon: <CustomEcard />,
      show: !!digitalCustomDraft,
      url: new URL(window.location.origin + buildPath(ecardRoutePaths.design.goToPath('ecard/custom')))
    }
  ];

  const handleDeleteDraftClick = useCallback((draft: StationeryDraft) => {
    setDraftToDelete(draft);
  }, []);

  if (loading) {
    return <JoyLogoLoader loaderKey="admin-customize-drafts" />;
  }

  return (
    <PageSection id="drafts-gallery" title={draftsGalleryTranslations.sectionTitle()}>
      <>
        <DeleteDraftDialog draft={draftToDelete} onClose={() => setDraftToDelete(null)} />
        <StationeryCards format={StationeryDraftFormat.print} drafts={paperDrafts} onDeleteDraftClick={handleDeleteDraftClick} />
        {isPrintDigitalEditorEnabled && <StationeryCards format={StationeryDraftFormat.digital} drafts={premiumDigitalDrafts} onDeleteDraftClick={handleDeleteDraftClick} />}
        {isPrintDraftsV2Enabled && <DigitalCards noDigitalDrafts={noDigitalDrafts} digitalDrafts={digitalDrafts} />}
      </>
    </PageSection>
  );
};
