import React from 'react';
import { VideoAsset } from './video.types';
import { useWelcomeVideoController } from './WelcomeVideo.controller';
import {
  StyledCloseButton,
  thumbnailContainerProps,
  ThumbnailPlayButtonContainer,
  videoPlayerPortalBodyProps,
  WelcomeVideoIframe,
  WelcomeVideoThumbnail
} from './WelcomeVideo.styles';
import { Box, TextV2 } from '@withjoy/joykit';
import { AspectRatioContainer } from '@shared/components/ApectRatio';
import { ReactComponent as PlayButton } from '@assets/icons/button-play.svg';
import { ReactComponent as Close } from '@assets/icons/close.svg';
import { SimplePortal } from '@shared/components/SimplePortal';
import { addRendition } from '@shared/utils/photoRendition';

interface Props
  extends Readonly<{
    video: VideoAsset;
    thumbnailUrl?: Maybe<string>;
  }> {}

export const WelcomeVideo: React.FC<Props> = props => {
  const { video, thumbnailUrl } = props;
  const { handleOpenClick, handleCloseClick, viewerUrl, open } = useWelcomeVideoController({ url: video.url, mimeType: video.mimeType });
  return (
    <>
      <Box {...thumbnailContainerProps} paddingX={[7]} paddingY={[10]}>
        <button style={{ position: 'relative', width: '100%' }} onClick={handleOpenClick}>
          {thumbnailUrl ? <WelcomeVideoThumbnail src={addRendition({ url: thumbnailUrl, renditionSize: 'medium' })} /> : null}
          <ThumbnailPlayButtonContainer>
            <PlayButton style={{ width: '80px' }} />
          </ThumbnailPlayButtonContainer>
        </button>
      </Box>
      {open ? (
        <SimplePortal onEscapeKeyDown={handleCloseClick}>
          <Box {...videoPlayerPortalBodyProps}>
            <Box height="100%" width="100%" display="flex" flexDirection="column">
              <AspectRatioContainer aspectRatio={9 / 5} minHeight={'400px'}>
                <WelcomeVideoIframe title={video.title || 'video'} id="videoFrame" src={viewerUrl} style={{ margin: 'auto' }} />
              </AspectRatioContainer>
              {video.caption ? (
                <TextV2
                  marginTop={6}
                  paddingX={{
                    _: 7,
                    lg: 8
                  }}
                >
                  {video.caption}
                </TextV2>
              ) : null}
            </Box>
          </Box>
          <StyledCloseButton onClick={handleCloseClick} variant="ghost">
            <Close />
          </StyledCloseButton>
        </SimplePortal>
      ) : null}
    </>
  );
};
