import { useHistory } from '@shared/core';
import { Flex } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';
import { useEventInfo } from '@shared/utils/eventInfo';
import PageNotFound from '@apps/pageNotFound/PageNotFound';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { calculateStayDates } from '@shared/utils/dateHelpers';
import { useEventAccommodationsCheck } from '@shared/hooks/useEventAccommodationsCheck';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useGuestSiteQueryParams } from '@apps/guest/packages/layout-engine/common/utils/useGuestSiteQueryParams';
import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { useMount } from '@shared/utils/hooks/useMount';
import { useHotelRoutePaths } from '../HotelBooking/HotelBooking.routes';
import { withWindow } from '@shared/utils/withWindow';

export const BookYourStay: React.FC<{ eventHandle: string }> = ({ eventHandle }) => {
  const history = useHistory();
  const { eventInfo } = useEventInfo();
  const { checkIn: eventStartDate, checkOut: eventEndDate } = calculateStayDates(eventInfo?.finalizedDate?.dateString);
  const queryParams = useGuestSiteQueryParams();
  const telemetry = useGuestSiteTelemetry();
  const hotelRoute = useHotelRoutePaths();

  const { hasVisibleAccommodationsEventPage, error, hasVisibleTravelEventPage, hasTravelAccommodationListings, isResolved } = useEventAccommodationsCheck({ eventHandle });
  const { value: accommodationsGuestHotelProvider, loading: accommodationsGuestHotelProviderLoading } = useFeatureValue('accommodationsGuestHotelProviderEnabled');
  const { loading: hotelNewRoutesLoading, value: hotelNewRoutes } = useFeatureValue('hotelNewRoutesEnabled');

  const [showNotFound, setShowNotFound] = useState<boolean>(false);

  useMount(() => {
    telemetry.bookYourStayPageViewed({
      ...queryParams
    });
  });

  useEffect(() => {
    if (accommodationsGuestHotelProviderLoading || !isResolved || hotelNewRoutesLoading) return;

    if (error) {
      setShowNotFound(true);
      return;
    }

    const utmQueryParams = 'utm_medium=web&utm_source=confirmation_registry_purchase&utm_campaign=next_steps';

    if (hasVisibleAccommodationsEventPage) {
      history.push(`/${eventHandle}/accommodations?` + utmQueryParams);
    } else if (hasVisibleTravelEventPage && hasTravelAccommodationListings) {
      history.push(`/${eventHandle}/travel?` + utmQueryParams);
    } else {
      if (hotelNewRoutes === 'on' && eventInfo?.eventId) {
        withWindow(global => (global.location.href = hotelRoute.hotelSearch.gotToPath(eventInfo.eventId, utmQueryParams)));
      } else {
        history.push(`/${eventHandle}/hotel/?` + utmQueryParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    accommodationsGuestHotelProviderLoading,
    eventInfo,
    eventEndDate,
    error,
    eventStartDate,
    eventHandle,
    hasVisibleAccommodationsEventPage,
    hasVisibleTravelEventPage,
    hasTravelAccommodationListings,
    accommodationsGuestHotelProvider,
    isResolved
  ]);

  if (showNotFound) {
    return <PageNotFound />;
  }

  return (
    <Flex justifyContent="center" alignItems="center" height="100vh" width="100vw">
      <JoyLogoLoader loaderKey="guest-hotel-booking" />
    </Flex>
  );
};
