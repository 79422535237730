import { animationTransition } from '@shared/utils/animationTransition';
import { Box, css, styled } from '@withjoy/joykit';

const hideOnLoading = () => css`
  visibility: hidden;
  opacity: 0;
`;

const showOnLoading = () => css`
  visibility: initial;
  transition: ${animationTransition('opacity')};
`;

export const StyledBoxBrannan = styled(Box)<{ loading: boolean; isPreviewing: boolean; customBackgroundColor?: string; customTextColor?: string }>`
  pointer-events: ${({ isPreviewing }) => (isPreviewing ? 'none' : 'all')};
  ${({ loading }) => (loading ? hideOnLoading : showOnLoading)};
  ${props =>
    props.customBackgroundColor &&
    css`
      background-color: ${props.customBackgroundColor} !important;
    `}
  ${props =>
    props.customTextColor &&
    css`
      color: ${props.customTextColor} !important;
    `}
`;
