import { zonedTimeToUtc } from 'date-fns-tz';
import { addDays, subDays, isBefore, isValid, isSameMonth, addMonths, differenceInCalendarDays } from 'date-fns';
import { addError } from './logger';

const DEFAULT_MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const DEFAULT_DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const getTimeAmPm = (date: Date) => {
  return date.toLocaleString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' });
};

export const getMonthName = (date: Date, translatedMonthNames = DEFAULT_MONTH_NAMES) => {
  return translatedMonthNames[date.getMonth()];
};

export const getDayName = (date: Date, translatedDaysOfWeek = DEFAULT_DAYS_OF_WEEK) => {
  return translatedDaysOfWeek[date.getDay()];
};

export const getLocaleDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getUrlDateString = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
};

export const getExtractDateFields = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0');
  return { year, month: parseInt(month), day: parseInt(day) };
};

export const getLocaleTimeString = (date: Date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const getDaysPassed = (startTime: Date, endTime: Date): number => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const startDay = Math.floor(startTime.getTime() / millisecondsPerDay);
  const currentDay = Math.floor(endTime.getTime() / millisecondsPerDay);
  return currentDay - startDay;
};

export const getDateInSpecificTimezone = (date: Date, timezone: string) => {
  return zonedTimeToUtc(`${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`, timezone);
};

/**
 * Validates the provided check-in/check-out date.
 * - If the date is in the past or invalid (null or undefined), it returns `null`.
 * - If the date is in the future, it returns the original date.
 *
 * @param {Date | null} [dateCheckInCheckOut] - The check-in/check-out date to validate.
 * @returns {Date | null} - The original date if it's valid (in the future), or `null` if it's invalid or in the past.
 */
export const validateCheckInCheckOut = (dateCheckInCheckOut?: Date | null) => {
  const today = new Date();
  if (!dateCheckInCheckOut || isBefore(dateCheckInCheckOut, today)) {
    return null;
  }
  return dateCheckInCheckOut;
};

const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/;
/**
 * Creates a Date object from an unformatted date string.
 *
 * @param {string} [dateString] - The unformatted date string to convert into a Date object.
 * @param {'checkin' | 'checkout'} [type] - Specifies whether the date is for check-in or check-out.
 *   - If `checkin` is specified and the date string is invalid, the function returns a check-in date that is 1 month ahead of today, minus 1 day.
 *   - If `checkout` is specified and the date string is invalid, the function returns a check-out date that is 1 month ahead of today, plus 1 day.
 *   - If neither `checkin` nor `checkout` is specified, the function returns the current date as a fallback.
 * @returns {Date} - The converted Date object.
 */
export const createDateFromUnformattedString = (dateString?: string | null): Date | null => {
  if ((dateString && dateOnlyRegex.test(dateString)) || isValid(new Date(`${dateString}T00:00:00`))) {
    return new Date(`${dateString}T00:00:00`);
  }

  addError(`createDateFromUnformattedString: Invalid Date String - ${dateString}`);

  return null;
};

/**
 * Calculates the check-in and check-out dates based on the provided date string.
 * - If the date string is invalid or in the past, it calculates the dates based on the current date:
 *   - Check-in: 1 month ahead and 1 day before.
 *   - Check-out: 1 month ahead and 1 day after.
 * - If the date string is valid and in the future, it calculates the dates relative to the given date:
 *   - Check-in: 1 day before the given date.
 *   - Check-out: 1 day after the given date.
 *
 * @param {string | null} [dateString] - The date string to calculate the stay dates from.
 * @returns {Object} - An object containing the `checkIn` and `checkOut` Date objects.
 */
export const calculateStayDates = (dateString?: string | null) => {
  const currentDate = new Date();
  let checkIn: Date, checkOut: Date;

  if (!dateString || isBefore(new Date(dateString), currentDate)) {
    // If dateString is invalid or in the past, calculate based on current date
    checkIn = subDays(addMonths(currentDate, 1), 1); // Check-in: 1 month ahead, 1 day before
    checkOut = addDays(addMonths(currentDate, 1), 1); // Check-out: 1 month ahead, 1 day after
  } else {
    // If dateString is valid and in the future, calculate relative to the given date
    checkIn = subDays(new Date(dateString), 1); // Check-in: 1 day before the given date
    checkOut = addDays(new Date(dateString), 1); // Check-out: 1 day after the given date
  }

  return {
    checkIn: {
      date: checkIn,
      dateString: getLocaleDateString(checkIn),
      extractedDateFields: getExtractDateFields(checkIn),
      urlDateString: getUrlDateString(checkIn)
    },
    checkOut: {
      date: checkOut,
      dateString: getLocaleDateString(checkOut),
      extractedDateFields: getExtractDateFields(checkOut),
      urlDateString: getUrlDateString(checkOut)
    }
  };
};

/**
 * Determines check in and check out have same month.
 *
 * @param {string} [checkIn] - The check in date string to check.
 * @param {string} [checkOut] - The checkout date string to check.
 * @returns {boolean} - The Check in and Check out dates have same month.
 */
export const isSameMonthCheckInCheckOut = (checkIn: string, checkOut: string) => {
  if (isSameMonth(new Date(checkIn), new Date(checkOut))) {
    return true;
  }
  return false;
};

/**
 * Determine if given Date is 90 or fewer days away.
 *
 * @param {string} [dateStringCutOffDate] - The date object to transform in date string.
 * @returns {boolean} - The date is 90 days or fewer days away.
 */
export const isCutOffDateNinetyOrFewerDaysAway = (dateStringCutOffDate: string) => {
  const today = new Date();
  if (differenceInCalendarDays(new Date(dateStringCutOffDate), today) <= 90) {
    return true;
  }
  return false;
};
