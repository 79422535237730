import React, { useMemo } from 'react';
import { Category, GuestTextComponentFragment } from '@graphql/generated';
import { Flex } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { RichTextEditorGlobalStyle, RichTextEditorOverrideProps } from '@withjoy/joykit/components/RichTextEditor/RichTextEditor.styles';
import wrapHtmlWithSafeLink from '@shared/components/SafeLink/wrapHtmlWithSafeLink';
import { RichTextGlobalStyle } from './RichText.styles';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { OverrideTypography } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.types';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';

interface RichTextProps {
  isFirstOfType?: boolean;
  isBrannanLayout?: boolean;
  richTextComponentData: GuestTextComponentFragment;
}

const RichTextWithSafeLinkBase = ({ id, text }: { id: string; text: string }) => {
  return <Flex width="100%" className={`jodit-editor richtext-${id}`} flexDirection="column" dangerouslySetInnerHTML={{ __html: text }} />;
};

const RichTextWithSafeLink = wrapHtmlWithSafeLink(RichTextWithSafeLinkBase);

const typographyOverride: OverrideTypography = [
  { variant: 'display4', category: Category.HEADING },
  { variant: 'body4', category: Category.PARAGRAPH }
];

export const RichText: React.FC<RichTextProps> = ({ isFirstOfType, richTextComponentData }) => {
  const { scaleFontSize } = useScaleGuestSiteFontSize();

  const overrides: RichTextEditorOverrideProps = useMemo(
    () => ({
      Paragraph: { props: { typographyVariant: 'body4' } },
      Heading: {
        h1: { props: { fontSize: scaleFontSize(pxToRem(32), Category.HEADING) } },
        h2: { props: { fontSize: scaleFontSize(pxToRem(24), Category.HEADING) } },
        h3: { props: { fontSize: scaleFontSize(pxToRem(18.72), Category.HEADING) } },
        h4: { props: { fontSize: scaleFontSize(pxToRem(16), Category.HEADING) } },
        h5: { props: { fontSize: scaleFontSize(pxToRem(13.28), Category.HEADING) } },
        h6: { props: { fontSize: scaleFontSize(pxToRem(10.72), Category.HEADING) } }
      }
    }),
    [scaleFontSize]
  );

  return (
    <GuestSiteTypographyOverride override={typographyOverride}>
      <Flex textAlign="left" width="100%">
        {isFirstOfType && (
          <>
            <RichTextGlobalStyle />
            <RichTextEditorGlobalStyle overrides={overrides} />
          </>
        )}
        <RichTextWithSafeLink id={richTextComponentData.id} text={richTextComponentData.text || ''} />
      </Flex>
    </GuestSiteTypographyOverride>
  );
};
