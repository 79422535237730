import React, { useState, useEffect, useRef } from 'react';
import { differenceInDays, intervalToDuration, isPast } from 'date-fns';
import { useEventCountdownTranslations } from './EventCountdown.i18n';
import { TextV2, TextV2Props } from '@withjoy/joykit';
import globalWindow from '@shared/core/globals';
import { zonedTimeToUtc } from 'date-fns-tz';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { label4ToCaptionOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

interface InternalEventCountdownProps {
  timezone?: string;
  milliseconds: number;
}

interface CountdownState
  extends Readonly<{
    days?: number;
    hrs?: number;
    mins?: number;
  }> {}

const CountdownDefaultState: CountdownState = {
  days: 0,
  hrs: 0,
  mins: 0
} as const;

/**
 * create event count down object
 * if event in the past then time will increase
 * if event in the future then time will decrease
 */
function _createTimerDuration(startDate: Date | number, timeZone: string): CountdownState {
  const now = Date.now();

  const duration =
    intervalToDuration({
      start: startDate,
      end: zonedTimeToUtc(now, timeZone)
    }) || CountdownDefaultState;

  const days = Math.abs(differenceInDays(startDate, now));
  return {
    days,
    hrs: duration.hours,
    mins: duration.minutes
  };
}

const COUNTDOWN_KEYS: ReadonlyArray<keyof CountdownState> = ['days', 'hrs', 'mins'];
/**
 * join the event count down object keys
 */
function _formatDuration(duration: CountdownState) {
  return COUNTDOWN_KEYS.reduce((acc, key) => {
    if (duration[key]) {
      acc.push(`${duration[key]} ${key}`);
    }
    return acc;
  }, [] as string[]).join(' ');
}

interface EventCountdownProps extends Readonly<InternalEventCountdownProps>, TextV2Props {}

const EventCountdown: React.FC<EventCountdownProps> = ({ milliseconds, timezone = 'America/Los_Angeles', ...restProps }) => {
  const { getEventDateWithAgoText } = useEventCountdownTranslations();
  const hasPassedRef = useRef<boolean>(isPast(milliseconds));
  const [countDown, setCountdown] = useState<CountdownState>(_createTimerDuration(milliseconds, timezone) || CountdownDefaultState);
  const durationToDisplay = _formatDuration(countDown);
  const content = hasPassedRef.current ? getEventDateWithAgoText(durationToDisplay) : durationToDisplay;

  useEffect(() => {
    const interval = globalWindow.setInterval(() => {
      hasPassedRef.current = isPast(milliseconds);
      setCountdown(_createTimerDuration(milliseconds, timezone));
    }, 1000);
    return function cleanup() {
      globalWindow.clearInterval(interval);
    };
  }, [milliseconds, timezone]);
  return (
    <GuestSiteTypographyOverride override={label4ToCaptionOverride}>
      <TextV2 typographyVariant="label4" {...restProps}>
        {content}
      </TextV2>
    </GuestSiteTypographyOverride>
  );
};

EventCountdown.displayName = 'EventCountdown';

export { EventCountdown };
