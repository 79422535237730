import React, { FC, useEffect, useRef, useState } from 'react';
import { PickerResponse } from 'filestack-js';
import { Flex, useDisclosure } from '@withjoy/joykit';
import { PagePhotoSettingsProps, UploadedImage } from './FocusPointEditor.types';
import { pxToRem } from '@withjoy/joykit/theme';
import { EventPageType, useGetPageIdsQuery, useSetEventPageAssetMutation } from '@graphql/generated';
import { useEventInfo } from '@shared/utils/eventInfo';
import { TempSpinner } from '../TempSpinner';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useFocusPointEditorTelemetry } from './FocusPointEditor.telemetry';
import { findMatchingPageType } from './utils';
import { EditorConsole } from './EditorConsole';
import { PhotoPicker } from '../PhotoPicker';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useFilestack } from '@shared/utils/filestack';
import { MediaCollectionPhoto } from '../PhotoPicker/PhotoPicker.types';

export const PagePhotoSettings: FC<PagePhotoSettingsProps> = props => {
  const [isLoadingPhoto, setLoadingPhoto] = useState(false);
  const [imageUrl, setImageUrl] = useState(props.imageData?.url);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage>({
    photoId: undefined,
    imageData: undefined
  });
  const { isOpen: isOpenPhotoPicker, onOpen: openPhotoPicker, onClose: closePhotoPicker } = useDisclosure();

  const isPhotoUploadFromCollectionEnabled = useFeatureValue('photoUploadFromCollectionEnabled').value === 'on';

  const isOpenFileStack = useRef(false);
  const isImageUploaded = useRef(!!props.imageData?.url);

  const { eventInfo, eventHandle } = useEventInfo();
  const telemetry = useFocusPointEditorTelemetry();

  const { data: pageIds } = useGetPageIdsQuery({ variables: { name: eventHandle || '' }, batchMode: 'fast', skip: !eventHandle });

  const [setEventPageAssetMutation, { loading: isUpdatingEventPhoto }] = useSetEventPageAssetMutation();

  const { open: openFilePicker, getPhotoPreviewUrl } = useFilestack({
    containerId: eventInfo?.eventFirebaseId || '',
    accept: 'image/*',
    onUploadDone: async (res): Promise<void> => {
      setLoadingPhoto(true);
      const { filename, mimetype, size, url } = res.filesUploaded[0];
      const previewUrl = await getPhotoPreviewUrl({ url });
      isImageUploaded.current = !!previewUrl;
      const loadImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> =>
        new Promise(resolve => {
          const image = new Image();

          image.onload = () => {
            const height = image.height;
            const width = image.width;
            resolve({ width, height });
          };

          image.src = imageUrl;
        });

      const dimensions = await loadImageDimensions(previewUrl);
      setImageUrl(previewUrl);
      setUploadedImage(currState => ({
        photoId: currState.photoId,
        imageData: { assetId: filename, mimeType: mimetype, fileSize: size, width: dimensions.width, height: dimensions.height }
      }));
      setLoadingPhoto(false);
    },
    onClose: () => {
      if (!isLoadingPhoto) isOpenFileStack.current = false;
      setTimeout(() => {
        props.onFileStackClose && props.onFileStackClose(!!isImageUploaded.current);
      }, 500);
    },
    maxFiles: 1
  });

  const onUploadDoneStart = useEventCallback((res: PickerResponse) => {
    setLoadingPhoto(true);
    isImageUploaded.current = !!res.filesUploaded[0].url;
  });

  const onUploadDone = useEventCallback(async (res: PickerResponse, previewUrl: string) => {
    isImageUploaded.current = !!previewUrl;
  });

  const onUploadDoneEnd = useEventCallback(() => {
    setLoadingPhoto(false);
  });

  const onCloseFileStack = useEventCallback(() => {
    if (!isLoadingPhoto) isOpenFileStack.current = false;
  });

  useEffect(() => {
    if (props.isOpen) {
      telemetry.photoEditorOpened(props.telemetryCategory, 'opened');
    }
    if (props.isOpen && !props.imageData?.url && !isOpenFileStack.current) {
      isPhotoUploadFromCollectionEnabled ? openPhotoPicker() : openFilePicker();
      isOpenFileStack.current = true;
    } else if (!props.isOpen) {
      isOpenFileStack.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const handleSaveUploadedPhoto = async () => {
    if (uploadedImage.imageData) {
      // Custom and Normal page photo have different end points, we have to maintain both in global level
      if (props.pageType !== EventPageType.custom) {
        const pageTypeToUpdate = findMatchingPageType(props.pageType);
        // TODO: https://withjoy.atlassian.net/browse/PRNT-2050
        const pageIdToUse = pageIds?.eventByName?.pages.find(page => page.type === pageTypeToUpdate)?.id || props.pageId;
        telemetry.pagePhotoUpdated(props.telemetryCategory, 'Update', 'succeeded', { pageType: pageTypeToUpdate, actualPageType: props.pageType });
        await setEventPageAssetMutation({
          variables: {
            id: pageIdToUse || '',
            payload: uploadedImage.imageData
          },
          refetchQueries: ['WebsiteInfo'],
          awaitRefetchQueries: true
        });
      }
    }
  };

  const handleSavePhoto = useEventCallback((imageData: MediaCollectionPhoto) => {
    setImageUrl(imageData.photo.url);
    setUploadedImage({
      photoId: imageData.photo.id,
      imageData: {
        assetId: imageData.photo.assetId,
        mimeType: imageData.photo.mimeType,
        fileSize: imageData.photo.fileSize || 0,
        width: imageData.photo.width,
        height: imageData.photo.height
      }
    });

    closePhotoPicker();
  });

  const onCancelPhotoFocus = useEventCallback(() => {
    setImageUrl('');
    setUploadedImage({
      photoId: undefined,
      imageData: undefined
    });
    props.handleOnCancel();
  });

  const onClosePhotoPicker = useEventCallback(() => {
    closePhotoPicker();
    if (!imageData) {
      // need to close the dialog if no image is selected
      props.handleOnCancel();
    }
  });

  const imageData =
    imageUrl && uploadedImage.imageData
      ? {
          id: 'id',
          height: uploadedImage.imageData.height,
          width: uploadedImage.imageData.width,
          url: imageUrl,
          layout: {
            alignX: props.imageData?.layout.alignX || 'center',
            alignY: props.imageData?.layout.alignY || 'center'
          }
        }
      : props.imageData;

  const shouldRender = !!imageData && !!eventInfo?.eventId;

  return (
    <>
      {shouldRender ? (
        <EditorConsole
          {...props}
          imageData={imageData}
          handleOnCancel={onCancelPhotoFocus}
          openFilePicker={() => {
            isPhotoUploadFromCollectionEnabled ? openPhotoPicker() : openFilePicker();
          }}
          eventId={eventInfo.eventId}
          isLoadingPhoto={isLoadingPhoto}
          handleSaveUploadedPhoto={handleSaveUploadedPhoto}
          isUpdatingEventPhoto={isUpdatingEventPhoto}
          uploadedImageData={uploadedImage.imageData}
          pageIdsData={pageIds?.eventByName?.pages || []}
          photoPresentation={props.photoPresentation}
          uploadedImagePhotoId={uploadedImage.photoId}
        />
      ) : (
        <Flex height={pxToRem(300)}>
          <TempSpinner />
        </Flex>
      )}
      <PhotoPicker
        isOpen={isOpenPhotoPicker}
        onClose={onClosePhotoPicker}
        containerId={eventInfo?.eventFirebaseId || ''}
        acceptedImageFormat="image/*"
        maxFiles={1}
        onUploadDoneStart={onUploadDoneStart}
        onUploadDone={onUploadDone}
        onUploadDoneEnd={onUploadDoneEnd}
        onCloseFileStack={onCloseFileStack}
        onSavePhoto={handleSavePhoto}
        isSavingPhoto={isUpdatingEventPhoto}
        telemetryCategory={props.telemetryCategory}
      />
    </>
  );
};
