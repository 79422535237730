import React, { FC, memo, useMemo } from 'react';
import { areEqual } from 'react-window';
import memoize from 'memoize-one';
import { Box, Flex, useTheme } from '@withjoy/joykit';
import { RotatingRingLoader } from '@shared/components/RotatingRingLoader';
import { VirtualFixedGrid } from '@apps/admin/common/VirtualScroll';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { backgroundImageForUrl } from '@shared/utils/style/backgroundImage';
import { addRendition } from '@shared/utils/photoRendition';
import { GridItemProps, GridRendererProps, MediaPhoto, PhotoItemProps, PhotoRendererProps } from './PhotoRenderer.types';
import { CARD_ASPECT_RATIO, GUTTER_SIZE, MEDIA_SKELETON } from '../../PhotoPicker.constants';
import { parseInputToInt } from '@shared/utils/parseInputToInt';

const createItemData = memoize((mediaCollections: MediaPhoto[], selectedId: string | null, onSelect: (id: string) => void) => {
  return {
    mediaCollections,
    selectedId,
    onSelect
  };
});

const PhotoItem: FC<PhotoItemProps> = props => {
  const { src, isSelected, onSelect } = props;

  return (
    <Flex
      width="100%"
      aspectRatio={{ _: CARD_ASPECT_RATIO._, sm: CARD_ASPECT_RATIO.sm }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius={3}
      border="3px solid"
      borderColor={isSelected ? 'mono14' : 'transparent'}
      background={backgroundImageForUrl(src)}
      backgroundColor="mono2"
      backgroundSize="contain"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      overflow="hidden"
      cursor="pointer"
      _active={{
        borderColor: 'mono14'
      }}
      onClick={onSelect}
    ></Flex>
  );
};

const PhotoUploadingPlaceholder: FC = () => {
  const { colors } = useTheme();

  return (
    <Flex width="100%" aspectRatio={{ _: '1.27', sm: '1.11' }} flexDirection="column" justifyContent="center" alignItems="center" borderRadius={3} backgroundColor="mono2">
      <Box position="relative" zIndex={1}>
        <RotatingRingLoader width="62px" height="62px" borderWidth="10px" borderColor="#759CFF" thumbColor={colors.newBrandPurple2} />
      </Box>
    </Flex>
  );
};

export const PhotoRenderer: FC<PhotoRendererProps> = props => {
  const { mediaCollection, selectedId, onSelect, showPhotoUploadingPlaceholder } = props;

  const isSmOrLarge = useMediaQuery(theme => theme.mediaQueries.up({ breakpointAlias: 'sm' }));
  const columns = isSmOrLarge ? 4 : 2;
  const gutterSize = isSmOrLarge ? GUTTER_SIZE.sm : GUTTER_SIZE._;
  const aspectRatio = isSmOrLarge ? CARD_ASPECT_RATIO.sm : CARD_ASPECT_RATIO._;

  const photosCollection = useMemo(
    () =>
      mediaCollection?.map(m => {
        return {
          id: m.id,
          photo: { ...m.photo, url: addRendition({ url: m.photo.url, renditionSize: 'medium' }) }
        };
      }) || [],
    [mediaCollection]
  );

  const itemData = createItemData(
    [...(showPhotoUploadingPlaceholder ? [{ id: MEDIA_SKELETON, photo: { id: `${MEDIA_SKELETON}_photo`, url: '' } }] : []), ...photosCollection],
    selectedId,
    onSelect
  );

  return (
    <VirtualFixedGrid
      itemData={itemData}
      columnCount={columns}
      overscanRowCount={4}
      rowCount={Math.ceil(itemData.mediaCollections.length / columns)}
      rowHeight={({ containerWidth }) => {
        // Calculate total space taken up by gutters between columns
        const totalGutterSize = gutterSize * (columns - 1);
        // Calculate width of each item by dividing remaining space evenly
        const itemWidth = (containerWidth - totalGutterSize) / columns;
        // Calculate row height based on item width and aspect ratio
        // Add gutter size to maintain vertical spacing between rows
        return itemWidth / Number(aspectRatio) + gutterSize;
      }}
    >
      {GridItem}
    </VirtualFixedGrid>
  );
};

const GridItem: React.FC<GridItemProps> = memo(({ data, style, rowIndex, columnIndex }) => {
  const { mediaCollections, selectedId, onSelect } = data;

  const isSmOrLarge = useMediaQuery(theme => theme.mediaQueries.up({ breakpointAlias: 'sm' }));
  const columns = isSmOrLarge ? 4 : 2;
  const gutterSize = isSmOrLarge ? GUTTER_SIZE.sm : GUTTER_SIZE._;
  const shiftedIndex = rowIndex * columns + columnIndex;

  const media = mediaCollections[shiftedIndex];

  if (!media) {
    return null;
  }

  return (
    <Box
      key={media.photo.id}
      style={{
        ...style,
        // Adjust left position to account for gutters between columns
        // Formula: Original left - (total gutter space per column * current column) + (gutter size * current column)
        left: Number(style.left) - ((gutterSize * (columns - 1)) / columns) * columnIndex + columnIndex * gutterSize,
        // Adjust width to account for gutters between columns
        // Formula: Original width - (total gutter space per column)
        width: parseInputToInt(style.width) - (gutterSize * (columns - 1)) / columns
      }}
      data-row={rowIndex}
      data-col={columnIndex}
    >
      <GridChildRenderer mediaId={media.id} photoUrl={media.photo.url} selectedId={selectedId} onSelect={onSelect} />
    </Box>
  );
}, areEqual);

const GridChildRenderer: FC<GridRendererProps> = memo(props => {
  const { mediaId, photoUrl, selectedId, onSelect } = props;

  if (mediaId === MEDIA_SKELETON) {
    return <PhotoUploadingPlaceholder key={mediaId} />;
  }

  return <PhotoItem key={mediaId} src={photoUrl} isSelected={mediaId === selectedId} onSelect={() => onSelect(mediaId)} />;
});

PhotoRenderer.displayName = 'PhotoRenderer';
