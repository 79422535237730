export const GOOGLE_HOSTED: string[] = [
  'Antic Didone',
  'Cinzel',
  'Cormorant Garamond',
  'Crimson Text',
  'DM Serif Display',
  'EB Garamond',
  'Gilda Display',
  'Homemade Apple',
  'Hurricane',
  'Jost',
  'Literata',
  'Lora',
  'Monsieur La Doulaise',
  'Montserrat',
  'Mr De Haviland',
  'Parisienne',
  'Pinyon Script',
  'Playfair Display',
  'Playfair Display SC',
  'Reem Kufi',
  'Seaweed Script',
  'Spectral'
];

// prettier-ignore
export const SELF_HOSTED: Record<string, string> = {
  'Adelicia Script Clean':            'Adelicia Script Clean.woff2',
  'Adorable':                         'Adorable-Line.woff', // Only supporting the style "line"
  'Allenoire':                        'Allenoire.woff2',
  'Bodoni 72':                        'Bodoni 72.woff',
  'Bon Vivant':                       'BonVivant.woff2',
  'Bon Vivant Serif':                 'BonVivantSerif.woff2',
  'Bon Vivant Serif Bold':            'BonVivantSerifBold.woff2',
  'Brand':                            'Brand.woff',
  'California Palms Script Thicker':  'CaliforniaPalms_Script_thicker.woff2',
  'Casablanca':                       'Casablanca.woff2',
  'Dream Avenue':                     'Dream Avenue.woff',
  'Hopeless Romantic Society':        'Hopeless Romantic Society.woff2',
  'Losta Masta':                      'Losta Masta.woff',
  'Lunaria Celesttial':               'Lunaria Celesttial.woff',
  'Mozart Script':                    'MozartScript-Regular.woff',
  'Nostalgia':                        'Nostalgia.woff2',
  'Paris Script':                     'Paris Script.woff2',
  'Slight':                           'slight-regular_2-webfont.woff2',
  'Titania':                          'Titania-Regular.woff'
};

// Not user selectable, but used behind the scenes.
// prettier-ignore
export const SELF_HOSTED_SYSTEM: Record<string, string> = {
  'Adobe NotDef': 'Adobe NotDef.otf'
}

export const FONT_FAMILIES = [...GOOGLE_HOSTED, ...Object.keys(SELF_HOSTED)].sort();
