import { useContext } from 'react';
import { useHotelBookingPartnerSearchJoyPlaceByIdQuery } from '@graphql/generated';
import { AnalyticsContext } from '@shared/core';
import { useEventInfo } from '@shared/utils/eventInfo';
import { HotelPartnerSearchParamsType, HotelPartnerSearchPlaceIdType, HotelPartnerSearchQueryParams } from './HotelBookingPartnerSearch.types';
import { useQueryParamHelper } from '@shared/core/queryString';
import { addError } from '@shared/utils/logger';
import { useCheckInCheckoutDates } from '../../hooks/useCheckInCheckoutDates';

export const useHotelBookingPartnerSearchParam = () => {
  const { anonymousId } = useContext(AnalyticsContext);

  const { eventInfo, loading: eventInfoLoading } = useEventInfo();
  const eventDate = eventInfo?.finalizedDate?.dateString;

  const { checkIn: checkin, checkOut: checkout, message: checkInCheckOutMessage } = useCheckInCheckoutDates({ eventDate });

  const queryParamHelper = useQueryParamHelper();

  // Get all query param
  const queryParamPlaceId = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.placeId);
  const queryParamPlaceIdType = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.placeIdType) || '';
  const queryParamName = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.name);
  // Get all query param - UTM Params
  const utm_source = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.utm_source);
  const utm_medium = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.utm_medium);
  const utm_campaign = queryParamHelper.getValueString(HotelPartnerSearchQueryParams.utm_campaign);

  const isValidHotelIdType = Object.keys(HotelPartnerSearchPlaceIdType).includes(queryParamPlaceIdType); // Validate is valid placeIdType
  const placeIdType: HotelPartnerSearchPlaceIdType = isValidHotelIdType
    ? ((queryParamPlaceIdType as unknown) as HotelPartnerSearchPlaceIdType)
    : HotelPartnerSearchPlaceIdType.google; // convert value to HotelPartnerSearchPlaceIdType

  const { data, loading: joyPlaceLoading, error: joyPlaceError } = useHotelBookingPartnerSearchJoyPlaceByIdQuery({
    variables: {
      joyPlaceByIdId: queryParamPlaceId ?? ''
    },
    batchMode: 'off',
    skip: !(queryParamPlaceId && placeIdType === HotelPartnerSearchPlaceIdType.joy)
  });
  const loading = joyPlaceLoading || eventInfoLoading;

  // Get build required params from query param, event and joyPlace
  const eventPlaceId = eventInfo?.locationInfo?.placeId;
  const eventLocationName = eventInfo?.locationInfo?.name;
  const clientReference = `${eventInfo?.eventId}_${anonymousId ?? ''}`;
  const placeId = data?.joyPlaceById?.googlePlaceId || queryParamPlaceId || eventPlaceId;
  const name = data?.joyPlaceById?.name || queryParamName || eventLocationName;

  const params: HotelPartnerSearchParamsType = {
    clientReference,
    placeId,
    name,
    checkin,
    checkout,
    hasCheckInOut: !!(checkin || checkout),
    utm_source,
    utm_medium,
    utm_campaign
  };

  const buildRedirectUrl = ({ url, fields }: { url: string; fields: (keyof HotelPartnerSearchParamsType)[] }) => {
    // eslint-disable-next-line no-console
    console.log(checkInCheckOutMessage);
    if (joyPlaceError) {
      addError({
        message: 'HotelBookingPartnerSearch - GraphQL query failed to fetch JoyPlace data',
        context: 'HotelBookingPartnerSearch',
        errorDetails: joyPlaceError.message
      });
    }

    if (!params.placeId) {
      addError({ message: 'HotelBookingPartnerSearch - PlaceId Missing', context: 'HotelBookingPartnerSearch', errorDetails: `PlaceId: ${params.placeId}` });
    }

    if (!params.name) {
      addError({ message: 'HotelBookingPartnerSearch - Name Missing', context: 'HotelBookingPartnerSearch', errorDetails: `Name: ${params.name}` });
    }

    if (!params.utm_source) {
      addError({ message: 'HotelBookingPartnerSearch - Source Missing', context: 'HotelBookingPartnerSearch', errorDetails: `Source: ${params.utm_source}` });
    }

    const redirectUrl = new URL(url);
    fields.forEach(field => {
      const fieldValue = params[field];
      if (fieldValue !== undefined && fieldValue !== null) {
        redirectUrl.searchParams.append(field, fieldValue.toString());
      }
    });
    return redirectUrl;
  };

  return { ...params, loading, buildRedirectUrl };
};
