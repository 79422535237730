import { useEffect } from 'react';
import { debounce } from 'lodash-es';
import { PagesRefs } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/LayoutAloha.types';
import getElementPosition from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/utils/getElementPosition';

type KeepPageScrollPositionProps = {
  pagesRef: PagesRefs[];
  container: HTMLDivElement | null;
  targetPage?: HTMLDivElement | null;
};

export const useKeepPageScrollPosition = ({ pagesRef, container, targetPage }: KeepPageScrollPositionProps) => {
  useEffect(() => {
    if (!pagesRef?.length || !targetPage || !container) {
      return;
    }

    let observer: MutationObserver;

    const keepPageScrollPositionWhenDOMChanges = () => {
      const initialPagePositionY = targetPage?.getBoundingClientRect().top ?? 0;
      const pages = pagesRef.map(pageRef => ({ ...pageRef, initialPageHeight: pageRef?.ref?.offsetHeight || 0 }));

      const updatePageScroll = () => {
        pages.forEach(({ ref: pageRef, page, initialPageHeight }, index) => {
          if (!pageRef || page.pageSlug === 'accommodations') return;

          const newPageHeight = pageRef.offsetHeight;
          const heightDifference = newPageHeight - initialPageHeight;
          const hasPageSizeChanged = heightDifference > 0;

          if (!hasPageSizeChanged) return;

          const finalPagePositionY = targetPage?.getBoundingClientRect().top ?? 0;
          const minContainerPositionY = container.scrollTop;
          const pagePosition = getElementPosition(pageRef, minContainerPositionY);
          const isPageBeforeVisibleArea = pagePosition.max <= minContainerPositionY;
          const isPagePartiallyVisibleArea = pagePosition.min <= minContainerPositionY && pagePosition.max >= minContainerPositionY;

          // Update the scroll position if the changed page is before the visible screen
          if (isPageBeforeVisibleArea || isPagePartiallyVisibleArea) {
            const newPageScrollPosition = minContainerPositionY + finalPagePositionY - initialPagePositionY;

            // Move the scrollTop the page size difference to keep the page in the original position
            container.scrollTop = newPageScrollPosition;
          }

          pages[index].initialPageHeight = newPageHeight;
        });
      };

      // Listen for container's DOM changes to update the page scroll position base on pages height changes
      observer = new MutationObserver(debounce(() => updatePageScroll(), 20, { maxWait: 40 }));
      observer.observe(container, { attributes: true, childList: true, subtree: true });
    };

    keepPageScrollPositionWhenDOMChanges();

    return () => {
      observer.disconnect();
    };
  }, [pagesRef, container, targetPage]);
};
