import { PromoCode } from './PromoCodeInput';
import { isPromoValid, ProductInfo, usePromotionList } from '@apps/card/routes/CardCustomizer/usePromotionConfig';
import { useCallback } from 'react';

const useValidatePromoCode = (product: ProductInfo) => {
  const { isEnabled, promotions } = usePromotionList(product.category);

  const validatePromoCode = useCallback(
    (promo: PromoCode) => {
      const returnCode = {
        code: '',
        applied: false,
        error: 'Invalid code'
      } as PromoCode;

      if (isEnabled) {
        const { code } = promo;

        const firstMatch = promotions.find(p => {
          return p.code.toLowerCase() === code.toLowerCase() && isPromoValid(p, product);
        });

        if (firstMatch) {
          returnCode.code = firstMatch.code;
          returnCode.applied = true;
          returnCode.error = undefined;
        }
      }

      return returnCode;
    },
    [isEnabled, product, promotions]
  );

  return { validatePromoCode };
};

export default useValidatePromoCode;
