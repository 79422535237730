import { useFeatureValue } from '@shared/core/featureFlags';
import { ProductRow } from '../../PrimaryDashboardContent/PrimaryDashboardContent.types';
import { useGuestListRsvpProductRow } from './useGuestListRsvpProductRow';
import { useInvitationsProductRow } from './useInvitationsProductRow';
import { useRegistryProductRow } from './useRegistryProductRow';
import { useWebsiteProductRow } from './useWebsiteProductRow';
import { ProductVerticals } from '@shared/core/productVerticals/productVerticals.types';
import { EventPageType } from '@graphql/generated';

export type PageVisibility = 'hidden' | 'passwordProtected' | 'public';
export interface PageInfo {
  id: string;
  pageSlug: string;
  visibility: PageVisibility;
  type?: EventPageType;
  pageTitle?: string;
}
export function useProductRows(
  eventPages: PageInfo[],
  productVerticals: ProductVerticals,
  eventId?: string,
  shouldShowOnboardingSurvey?: boolean,
  pendingRefundOrdersCount?: number
) {
  const { value: newPrintExperienceEnabledValue } = useFeatureValue('enableNewPrintExperience');
  const { value: printAdminDashboardIconUpdateValue } = useFeatureValue('printAdminDashboardIconUpdate');
  const { value: messagingEnableHotelBlockReminder } = useFeatureValue('messagingEnableHotelBlockReminder');
  const { value: enableRsvpReminderMessage } = useFeatureValue('enableRsvpReminderMessage');

  const websiteProductRow = useWebsiteProductRow(eventPages, productVerticals, eventId, shouldShowOnboardingSurvey);
  const registryProductRow = useRegistryProductRow(eventPages, productVerticals, eventId, pendingRefundOrdersCount);
  const invitationsProductRow = useInvitationsProductRow(
    productVerticals,
    newPrintExperienceEnabledValue === 'on',
    true,
    messagingEnableHotelBlockReminder === 'on',
    printAdminDashboardIconUpdateValue === 'treatment',
    enableRsvpReminderMessage === 'on',
    eventId
  );
  const guestListRsvpProductRow = useGuestListRsvpProductRow(eventPages, productVerticals, eventId);

  const productRows: ProductRow[] = [websiteProductRow, registryProductRow, invitationsProductRow, guestListRsvpProductRow].sort((a, b) => {
    return (b.isEnabled ? 1 : 0) - (a.isEnabled ? 1 : 0);
  });

  return productRows;
}
