import { useCurrencyContext } from '@shared/utils/currency';
import { CurrencyFormat } from '@shared/utils/currency/types';
import { useCallback } from 'react';

export const useMinorUnitsToPriceString = () => {
  const currency = useCurrencyContext();
  return useCallback(
    (
      price: number,
      { canShowFreeLabel, freeLabel = 'FREE', form = 'auto', precision }: { canShowFreeLabel?: boolean; freeLabel?: string; form?: CurrencyFormat; precision?: number }
    ) => {
      const priceInMajorUnits = price / 100;

      if (canShowFreeLabel && priceInMajorUnits === 0) {
        return freeLabel;
      }

      return currency.formatCurrency(priceInMajorUnits, {
        form,
        precision
      });
    },
    [currency]
  );
};
