/* istanbul ignore file */

import React from 'react';
import { Switch, Route } from '@react-router';
import { JoyKitThemeProvider, createGlobalStyle, Box, GridOverlay, NoSsr } from '@withjoy/joykit';
import { GuestSite } from './routes/GuestSite';

import '@assets/charm.css';
import { TelemetryProvider } from './GuestSite.telemetry';

import { withWindow } from '@shared/utils/withWindow';
import { HotelBooking } from './routes/HotelBooking';
import { MediaCollection } from './routes/MediaCollection';
import HotelBookingPartner from './routes/HotelBooking/routes/HotelBookingPartner';
import HotelBookingPartnerSearch from './routes/HotelBooking/routes/HotelBookingPartnerSearch';
import AccommodationPlacePartner from './routes/HotelBooking/routes/AccommodationPlacePartner';
import { useAccommodationPlaceRoutePaths } from './routes/HotelBooking/routes/AccommodationPlacePartner/AccommodationPlacePartner.utils';
import loadable from '@loadable/component';
import { StandAloneAlohaMenu } from './packages/layout-engine/components/StandAloneAlohaMenu';
import { BookYourStay } from './routes/BookYourStay';

const EventAccommodationsApp = loadable(
  () =>
    import(
      /* webpackChunkName: "content/accommodations" */
      '@apps/stays/EventAccommodations'
    )
);

const EventAccommodationsWrapper = ({ eventHandle }: { eventHandle: string }) => {
  return (
    <>
      <EventAccommodationsApp eventHandle={eventHandle} />
      <StandAloneAlohaMenu eventHandle={eventHandle} />
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Gotham SSm';
  }
`;

export interface GuestProps {}

const Guest: React.FC<GuestProps> = ({ children }) => {
  const { accommodationPlace } = useAccommodationPlaceRoutePaths();
  return (
    <JoyKitThemeProvider>
      <GlobalStyle />
      <Box as="main" overflowX={['hidden', null, 'initial']}>
        <NoSsr>
          <GridOverlay />
        </NoSsr>
        <Switch>
          <Route
            path="/:eventHandle/moments"
            render={() => {
              // moments is an app-browser page, need to reload to let charm-server 307
              withWindow(() => window.location.reload());
              return null;
            }}
          />
          <Route
            path="/:eventHandle/registry"
            render={() => {
              withWindow(() => window.location.reload());
              return null;
            }}
          />
          <Route
            path="/:eventHandle/accommodations/:accommodationId"
            render={({ match }) => {
              return <EventAccommodationsWrapper eventHandle={match.params.eventHandle} />;
            }}
          />
          <Route
            path={accommodationPlace.path}
            render={({ match }) => {
              return <AccommodationPlacePartner accommodationPlaceV2ByIdId={match.params.accommodationPlaceId} />;
            }}
          />
          <Route path={'/:eventHandle/hotel'} exact render={() => <HotelBookingPartnerSearch />} />
          <Route
            path={'/:eventHandle/hotel/:hotelId?'}
            render={({ match }) => {
              return <HotelBookingPartner hotelId={match.params.hotelId} />;
            }}
          />
          <Route
            path="/:eventHandle/hotelbooking"
            render={({ match }) => {
              const eventHandle = match.params.eventHandle;
              return <HotelBooking eventHandle={eventHandle || null} />;
            }}
          />
          <Route
            path="/:eventHandle/book-your-stay"
            render={({ match }) => {
              const eventHandle = match.params.eventHandle;
              return <BookYourStay eventHandle={eventHandle || null} />;
            }}
          />
          <Route
            path="/:eventHandle/page/:pageSlug"
            render={({ match, staticContext }) => {
              const eventHandle = match.params.eventHandle;
              return (
                <>
                  {match.params.pageSlug && (
                    <TelemetryProvider context={{ eventName: eventHandle }}>
                      <GuestSite eventHandle={eventHandle || null} staticContext={staticContext} />
                    </TelemetryProvider>
                  )}
                </>
              );
            }}
          />
          <Route
            path="/:eventHandle/albums"
            render={({ match }) => {
              const eventHandle = match.params.eventHandle;
              return (
                <>
                  <TelemetryProvider context={{ eventName: eventHandle }}>
                    <MediaCollection eventHandle={eventHandle || null} />
                  </TelemetryProvider>
                </>
              );
            }}
          />
          <Route
            path="/:eventHandle"
            render={({ match, staticContext }) => {
              const eventHandle = match.params.eventHandle;
              return (
                <TelemetryProvider context={{ eventName: eventHandle }}>
                  <GuestSite eventHandle={eventHandle || null} staticContext={staticContext} />
                </TelemetryProvider>
              );
            }}
          />
        </Switch>
      </Box>
    </JoyKitThemeProvider>
  );
};

Guest.displayName = 'Guest';

export default Guest;
