import { UpdatedData } from '@apps/registry/admin/routes/YourRegistry/components/PageSettingsDialog/PageSettingsDialog.provider';
import { createTelemetry, createTelemetryObject } from '@shared/core/analytics';
import { PageFunctionType } from '@shared/core/analytics/telemetry/types';
import { ReEngagementPopupItemTypes } from './components/DashboardContentV2/ReEngagementPopup/constants/ReEngagementPopup.const';

const category = 'AdminDashboard';
const pagePrefix = 'admin';

export type PagesForTelemetry = 'dashboard' | 'productSelector' | 'visibilityDialog' | 'multiProductPromoDialog' | 'reEngagementPopup';

const pages: { [tab in PagesForTelemetry]: PageFunctionType } = {
  dashboard: ({ dashboardVersion }: { dashboardVersion: string }) => ({
    category,
    pagePrefix,
    page: 'Dashboard',
    action: 'AdminVisited',
    extraPageInfo: {
      dashboardVersion
    }
  }),
  productSelector: () => ({
    category,
    pagePrefix,
    page: 'EventProductSettingsDialog',
    action: 'ViewProductSelector'
  }),
  visibilityDialog: (pageName: string) => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: pageName,
    action: 'VisibilityDialog'
  }),
  multiProductPromoDialog: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'multiProductPromoDialog',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.multiProductPromoDialog'
    }
  }),
  reEngagementPopup: () => ({
    category: 'dashboard',
    pagePrefix: 'admin',
    page: 'engagement_popup',
    action: 'PageViewed',
    extraInfo: {
      name: 'admin.dashboard.engagement_popup'
    }
  })
};

export const dashboardTelemetry = createTelemetryObject({
  actions: {
    openPage: ({ label, section, categoryArg, pageSlug }: { label: string; section: string; categoryArg?: string; pageSlug?: string }) => ({
      action: 'OpenPage',
      category: categoryArg ? categoryArg : category,
      actionType: 'click',
      label,
      extraInfo: {
        section,
        pageSlug
      }
    }),
    visibilityUpdated: ({ visibility, visibilityChanged, eventPasswordChanged, label }: UpdatedData & { label: string }) => ({
      action: 'Updated',
      page: 'visibilityDialog',
      category,
      label,
      extraInfo: {
        visibility,
        visibilityChanged,
        eventPasswordChanged
      }
    }),
    shareClicked: () => ({
      action: 'Share',
      category,
      actionType: 'click',
      extraInfo: {
        section: 'EventSettings'
      },
      label: 'event'
    }),
    productSelectionSave: (selectedOptions: string[], removedOptions: string[], addedOptions: string[]) => ({
      action: 'EventProductSettingsUpdated',
      category,
      extraInfo: {
        selectedOptions,
        removedOptions,
        addedOptions
      },
      label: 'saved'
    }),
    valuePropositionClicked: (label?: string) => ({
      category: 'AdminDashboard',
      action: 'OpenPage',
      section: 'Registry',
      actionType: 'click',
      label
    }),
    retargetDialogCTAClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'Learn More',
      extraInfo: {
        name: 'adminRoomBlockCTA_retargetpopup'
      }
    }),
    retargetDialogDismissClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'adminRoomBlockDismiss',
      extraInfo: {
        name: 'adminRoomBlockDismiss_retargetpopup'
      }
    }),
    addShippingAddressButtonBannerClicked: () => ({
      category: 'AdminDashboard',
      action: 'ButtonInteracted',
      label: 'addShippingAddressButtonBanner'
    }),

    // Re-engagement Popup
    reEngagementPopupCTAClicked: (popupType: ReEngagementPopupItemTypes, action: 'clicked' | 'dismissed') => ({
      category: 'hotelBlockConfirmationAbandonment',
      action: 'engagementPopupInteracted',
      label: 'engagement_popup',
      extraInfo: {
        name: action,
        popupType: popupType
      }
    }),
    printReEngagementPopupCTAClicked: (name: 'viewDrafts' | 'editDraft', action: 'clicked' | 'dismissed') => ({
      category: 'AdminDashboard',
      action: 'engagementPopupInteracted',
      label: 'engagement_popup',
      extraInfo: {
        name: `${name}_${action}`,
        popupType: 'print'
      }
    }),
    hotelReminderReEngagementPopupViewed: (args: {
      eventDate: string;
      title: string;
      subtitle: string;
      variant: ReEngagementPopupItemTypes.HOTEL_REMINDER_1_MONTH | ReEngagementPopupItemTypes.HOTEL_REMINDER_3_MONTH | ReEngagementPopupItemTypes.HOTEL_REMINDER_6_MONTH;
    }) => ({
      category: 'AdminDashboard',
      action: 'engagementPopupViewed',
      label: 'engagement_popup',
      extraInfo: {
        popupType: 'Guest Hotel Reminder',
        eventDate: args.eventDate,
        title: args.title,
        subtitle: args.subtitle,
        variant: args.variant
      }
    }),
    hotelReminderReEngagementPopupCTAClicked: (args: {
      eventDate: string;
      title: string;
      subtitle: string;
      variant: ReEngagementPopupItemTypes.HOTEL_REMINDER_1_MONTH | ReEngagementPopupItemTypes.HOTEL_REMINDER_3_MONTH | ReEngagementPopupItemTypes.HOTEL_REMINDER_6_MONTH;
      action: 'clicked' | 'dismissed';
    }) => ({
      category: 'AdminDashboard',
      action: 'engagementPopupInteracted',
      label: 'engagement_popup',
      extraInfo: {
        popupType: 'Guest Hotel Reminder',
        eventDate: args.eventDate,
        title: args.title,
        subtitle: args.subtitle,
        variant: args.variant,
        action: args.action
      }
    }),
    addNewPageOpen: () => ({
      action: 'WebsiteAdminButtonInteracted',
      category: 'website',
      pagePrefix: 'admin',
      label: 'Open Add New Page Dialog',
      extraInfo: {
        name: 'openAddNewPageDialog'
      }
    })
  },
  pages
});

const {
  TelemetryProvider: DashboardTelemetryProvider,
  useTelemetry: useDashboardTelemetry,
  enrichTelemetryExtraInfo: enrichDashboardTelemetryExtraInfo
} = createTelemetry(dashboardTelemetry, { eventId: '' });

type DashboardTelemetry = ReturnType<typeof useDashboardTelemetry>;

export { DashboardTelemetryProvider, useDashboardTelemetry, DashboardTelemetry, enrichDashboardTelemetryExtraInfo };
