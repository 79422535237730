import { EmailInputFields } from '@shared/components/EmailsAndEcards/Emails.types';
import { useFeatureValue } from '@shared/core/featureFlags';
import { InputV2, OptionsType, OptionType, SelectV1 } from '@withjoy/joykit';
import { FormikProps } from 'formik';
import React from 'react';

type ReplyToSelectorProps = {
  allowedEmails: string[];
  onSelect: (newValue: string) => void;
  value: string;
  formik: FormikProps<EmailInputFields>;
};

export const ReplyToSelector = (props: ReplyToSelectorProps) => {
  const { value, onSelect, allowedEmails, formik } = props;
  const { value: useSelector } = useFeatureValue('emailContentEnableReplyToSelector');

  const options: OptionsType<OptionType> = allowedEmails.map(email => {
    return {
      value: email,
      label: email
    };
  });

  if (useSelector === 'on') {
    return (
      <SelectV1
        value={{
          label: value,
          value
        }}
        searchable={false}
        onChange={option => {
          onSelect(option?.value ?? '');
        }}
        options={options}
      />
    );
  } else {
    return <InputV2 {...formik.getFieldProps('replyToEmail')} />;
  }
};
