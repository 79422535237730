import React, { FC, useContext, useEffect, useState } from 'react';
import { useFeatureValue } from '@shared/core/featureFlags';
import { LinkConfirmationDialog } from '@shared/components/SafeLink/LinkConfirmationDialog';
import { Flex, TextV2, useDisclosure } from '@withjoy/joykit';
import { AnalyticsContext, useTranslation } from '@shared/core';
import { ensureHttpsPrefix, getIsAllowListedUrl } from '@shared/components/SafeLink/SafeLink.utils';
import { formatRedirectURL } from '@shared/utils/formatting/redirectURL.formatter';
import { withWindow } from '@shared/utils/withWindow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export type RedirectWithSafeLinkProps = {
  href?: string;
  onClose?: () => void;
  target?: '_self' | '_blank' | '_parent' | '_top';
  ensureProtocol?: boolean;
  skipTelemetry?: boolean;
  showEmptyTabMessage?: boolean;
};

export const RedirectWithSafeLink: FC<RedirectWithSafeLinkProps> = ({ href, ensureProtocol, skipTelemetry, target = '_blank', showEmptyTabMessage, onClose: onCloseProps }) => {
  const analytics = useContext(AnalyticsContext);
  const safeLinksEnabled = useFeatureValue('guestSiteSafeLinks');
  const { loading: loadingFlag, value } = useFeatureValue('guestLinkUseRedirectService');
  const guestLinkUseRedirectService = value === 'on';
  const { t } = useTranslation('sharedComponents');
  const translations = t('safeLink');

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  const handleOnCloseDialog = useEventCallback(() => {
    onClose();
    setUrl('');
    onCloseProps?.();
    setLoading(false);
  });

  useEffect(() => {
    if (loadingFlag) return;
    if (href) {
      const isAllowListedUrl = getIsAllowListedUrl(href);
      const type = href.startsWith('mailto:') ? 'email' : 'url';
      if (!skipTelemetry) {
        analytics.track({
          action: 'LinkInteracted',
          category: 'safeLink',
          extraInfo: { actionType: 'click', url: href, isAllowListedUrl, source: href, type }
        });
      }

      if (isAllowListedUrl || !safeLinksEnabled) {
        const safeLink = guestLinkUseRedirectService
          ? formatRedirectURL({ url: ensureProtocol ? ensureHttpsPrefix(href) : href })
          : ensureProtocol
          ? ensureHttpsPrefix(href)
          : href;
        withWindow(global => global.open(safeLink, target));
        setUrl('');
        onCloseProps?.();
      } else {
        setUrl(ensureProtocol ? ensureHttpsPrefix(href) : href);
        onOpen();
      }
    }
  }, [analytics, ensureProtocol, guestLinkUseRedirectService, onOpen, safeLinksEnabled, href, skipTelemetry, onCloseProps, target, loadingFlag]);

  return (
    <>
      {showEmptyTabMessage && !loading && (
        <Flex flexDirection={'column'} width="100%" justifyContent="center" alignItems="center" paddingX={6} height={'90vh'} gap={6}>
          <Flex paddingY={32} paddingX={24} gap={32} borderRadius={12} border={'1px solid'} borderColor={'mono3'} flexDirection={'column'}>
            <Flex gap={16} flexDirection={'column'}>
              <TextV2 typographyVariant={'hed2'} color={'mono14'} textAlign="center">
                {translations.emptyTabTitle()}
              </TextV2>
              <TextV2 typographyVariant={'body2'} textAlign="center">
                {translations.emptyTabMessage()}
              </TextV2>
            </Flex>
          </Flex>
        </Flex>
      )}
      {url && <LinkConfirmationDialog href={url} isOpen={isOpen} onClose={handleOnCloseDialog} target={target} />}
    </>
  );
};
