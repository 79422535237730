import { EventPageType, WebsitePagePhotoPresentationFragment } from '@graphql/generated';
import { PhotoPresentationLayout } from './FocusPointEditor.types';

const PHOTO_TYPE_SUPPORT_MAPPER = {
  [EventPageType.tidbits]: EventPageType.story,
  [EventPageType.app]: EventPageType.welcome,
  [EventPageType.custom]: EventPageType.welcome
};

export const findMatchingPageType = (pageType: EventPageType | undefined) => {
  const pageTypeToUpdate =
    pageType && PHOTO_TYPE_SUPPORT_MAPPER.hasOwnProperty(pageType) ? PHOTO_TYPE_SUPPORT_MAPPER[pageType as keyof typeof PHOTO_TYPE_SUPPORT_MAPPER] : pageType || '';
  return pageTypeToUpdate;
};

const KNOWN_PHOTO_PRESENTATION_LAYOUT_MAP: Record<string, PhotoPresentationLayout> = {
  fullWidth: 'fullWidth',
  center: 'center'
};

export const derivePhotoPresentationLayout = (photoPresentation: Maybe<WebsitePagePhotoPresentationFragment>): PhotoPresentationLayout => {
  const maybeLayout = photoPresentation?.presentationLayout;
  return maybeLayout && maybeLayout in KNOWN_PHOTO_PRESENTATION_LAYOUT_MAP ? KNOWN_PHOTO_PRESENTATION_LAYOUT_MAP[maybeLayout] : 'center';
};
