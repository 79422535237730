import React from 'react';
import { ButtonV2 } from '@withjoy/joykit/components/ButtonV2';
import { Trash } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';

interface DeleteDraftButtonProps {
  onClick: () => void;
}

export const DeleteDraftButton: React.FC<DeleteDraftButtonProps> = ({ onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClick();
  };

  return (
    <ButtonV2
      paddingX={0}
      paddingY={0}
      minWidth={0}
      width={[pxToRem(40), null, pxToRem(36)]}
      height={[pxToRem(40), null, pxToRem(36)]}
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      shape="rounded"
      intent="neutral"
      variant="outline"
      borderColor="mono3"
      onClick={handleClick}
    >
      <Trash size="sm" />
    </ButtonV2>
  );
};
