import { Box, ButtonV2 } from '@withjoy/joykit';
import React, { useCallback } from 'react';
import { AppsWrapperStyles } from './Apps.styles';
import { ReactComponent as AppleIcon } from '@assets/icons/apple-icon.svg';
import { ReactComponent as GooglePlayIcon } from '@assets/icons/google-icon.svg';

import { useGuestSiteTelemetry } from '@apps/guest/GuestSite.telemetry';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { button2ToButtonsOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

interface Props extends Readonly<{ onAppClicked?: (app: string) => void }> {}

export const Apps: React.FC<Props> = () => {
  const telemetry = useGuestSiteTelemetry();
  const appStoreLink = 'https://apps.apple.com/us/app/joy-wedding-app-website/id994411720';
  const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.withjoy.joy';

  const handleAppButtonClicked = useCallback(
    (platform: string) => {
      telemetry.weHaveAnAppClicked(platform);
    },
    [telemetry]
  );

  const appleButtonClick = useCallback(() => {
    window.open(appStoreLink, '_blank');
    handleAppButtonClicked('AppStore');
  }, [handleAppButtonClicked]);

  const googleButtonClick = useCallback(() => {
    window.open(googlePlayLink, '_blank');
    handleAppButtonClicked('GooglePlay');
  }, [handleAppButtonClicked]);

  return (
    <GuestSiteTypographyOverride override={button2ToButtonsOverride}>
      <Box {...AppsWrapperStyles}>
        <ButtonV2
          startIcon={<AppleIcon />}
          shape="rounded"
          intent="primary"
          variant="outline"
          padding={3}
          width="170px"
          marginRight={[0, 4]}
          marginBottom={[4, 0]}
          onClick={appleButtonClick}
        >
          App Store
        </ButtonV2>
        <ButtonV2 startIcon={<GooglePlayIcon />} shape="rounded" intent="primary" variant="outline" padding={3} width="170px" onClick={googleButtonClick}>
          Google Play
        </ButtonV2>
      </Box>
    </GuestSiteTypographyOverride>
  );
};
