import { DigitalNavigationStep, NavigationContext, PrintNavigationStep } from '../CardCustomizer.types';
import { createContext } from '@shared/utils/createContext';

const [NavigationProvider, useNavigationContext] = createContext<NavigationContext<PrintNavigationStep>>({ name: 'Navigation' });
const [DigitalNavigationProvider, useDigitalNavigationContext] = createContext<NavigationContext<DigitalNavigationStep>>({ name: 'Navigation' });

export { NavigationProvider, useNavigationContext, DigitalNavigationProvider, useDigitalNavigationContext };

export const printNavigationStepToLabel: Record<PrintNavigationStep, string> = {
  cardFront: 'Font of Card',
  cardBack: 'Back of Card',
  envelope: 'Envelope',
  recipients: 'Recipients',
  review: 'Review',
  postCheckout: 'Post Checkout'
};
