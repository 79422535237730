import { ANIMATION_TYPES } from '@apps/admin/routes/WebsiteEditor/context/NavigationPaneContext/NavigationPane.constants';
import { Category } from '@graphql/generated';

export enum SCREENS {
  fontList = 'fontList',
  categoryList = 'categoryList',
  fontEdit = 'fontEdit'
}

export const SCREEN_ARRAY = [SCREENS.fontList, SCREENS.categoryList, SCREENS.fontEdit];

export const FONT_CONTAINER_HEIGHT = 65;

export const FONT_PACK_CONTAINER_HEIGHT = 100;

export const FONT_PACK_SCREEN_ANIMATION_CONFIG = {
  [SCREENS.fontList]: [
    {
      navigatedFrom: SCREENS.categoryList,
      animationType: ANIMATION_TYPES.CHILD_VIEW_RIGHT_SLIDE
    }
  ],
  [SCREENS.categoryList]: [
    {
      navigatedFrom: SCREENS.fontList,
      animationType: ANIMATION_TYPES.CHILD_VIEW_LEFT_SLIDE
    },
    {
      navigatedFrom: SCREENS.fontEdit,
      animationType: ANIMATION_TYPES.CHILD_VIEW_RIGHT_SLIDE
    }
  ],
  [SCREENS.fontEdit]: [
    {
      navigatedFrom: SCREENS.categoryList,
      animationType: ANIMATION_TYPES.CHILD_VIEW_LEFT_SLIDE
    }
  ]
};

export const MIN_FONT_SIZE_PERCENTAGE = 50;
export const MAX_FONT_SIZE_PERCENTAGE = 150;
export const DEFAULT_FONT_SIZE_PERCENTAGE = 100;

export const QUERY_PARAM_CATEGORY_MAPPER = {
  buttons: Category.BUTTONS,
  date: Category.DATE,
  event_title: Category.EVENT_TITLE,
  heading: Category.HEADING,
  location: Category.LOCATION,
  navigation: Category.NAVIGATION,
  paragraph: Category.PARAGRAPH,
  sub_heading: Category.SUB_HEADING
};
