import React from 'react';
import { Box, Flex } from '@withjoy/joykit';
import { Photos } from '@withjoy/joykit/icons';
import { CARD_ASPECT_RATIO } from '../../PhotoPicker.constants';

const ZeroStateItem = () => {
  return (
    <Flex
      width="100%"
      aspectRatio={{ _: CARD_ASPECT_RATIO._, sm: CARD_ASPECT_RATIO.sm }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderRadius={3}
      border="2px dashed"
      borderColor="mono4"
      backgroundColor="mono1"
    >
      <Photos size={48} />
    </Flex>
  );
};

export const ZeroState = () => {
  return (
    <Box display="grid" gridTemplateColumns={{ _: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' }} width="100%" gap={{ _: 5, sm: 4 }}>
      {[...Array(8)].map((_, index) => (
        <ZeroStateItem key={index} />
      ))}
    </Box>
  );
};

ZeroState.displayName = 'ZeroState';
