import { EventPlaceType, PointOnMapType, useEventPlacesQuery } from '@graphql/generated';
import { useEventInfo } from '../eventInfo/useEventInfo';
import { EventVenue } from './EventPlace.types';

/**
 * Returns venue about the currently loaded event.
 * @param {boolean} [isAdmin=true] - A flag indicating whether the current user is an admin.
 */
export function useEventPlaces(isAdmin: boolean = true) {
  const { eventHandle } = useEventInfo();

  const { data, ...rest } = useEventPlacesQuery({
    variables: {
      name: eventHandle!,
      payload: {
        eventName: eventHandle!,
        pageSlug: 'accommodations'
      },
      isAdmin
    },
    batchMode: 'fast',
    skip: !eventHandle
  });

  // We have to use existing venue id b/c event place don't have id for existing venue.
  const findTravelMap = data?.pageContainerBySlug?.components?.find(component => component.__typename === 'TravelMapComponent');
  const travelMapComponent = findTravelMap?.__typename === 'TravelMapComponent' ? findTravelMap : undefined;
  const pointOnMapId = travelMapComponent?.pointsOnMap?.find(pointsOnMap => pointsOnMap?.type === PointOnMapType.venue)?.id;

  const eventPlaces = data?.eventByName?.places ?? [];

  const venue = eventPlaces.find(place => place.type === EventPlaceType.venue);
  const displayName = venue?.name ? `${venue.name}${venue?.displayAddress ? ', ' + venue?.displayAddress : ''}` : null;
  const venueInfo: EventVenue = {
    id: venue?.id,
    googlePlaceId: venue?.googlePlaceId,
    name: venue?.name,
    displayName,
    address: venue?.displayAddress,
    latitude: venue?.latitude,
    longitude: venue?.longitude,
    pointOnMapId,
    hasVenue: Boolean(displayName)
  };

  return { eventPlaces, venueInfo, ...rest };
}
