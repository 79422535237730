import { useMemo } from 'react';
import {
  AccommodationPlaceType,
  EventAccommodationPublicDataFragment,
  EventAccommodationsCheckQuery,
  EventPageVisibility,
  EventPlaceType,
  EventReservedRoomBlocksPublicDataFragment,
  TravelItemIdsFragment,
  useEventAccommodationsCheckQuery
} from '@graphql/generated';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { isNumber } from 'lodash-es';
import { sortByOrderList } from '@shared/utils/array';

const CUSTOM_ACCOMMODATIONS_PAGE_SLUG = 'accommodations';
const TRAVEL_EVENT_PAGE_SLUG = 'travel';
const ACCOMMODATION_TRAVEL_TYPE_SET = new Set<TravelItemIdsFragment['__typename']>(['Hotel', 'RentalHome', 'Tent']);

type UseEventAccommodationsCheckArgs = {
  eventHandle: string;
};

export type UseEventAccommodationsCheckReturn = {
  /**
   * Has the query for event data resolved.
   */
  isResolved: boolean;

  eventLocationPlaceId: Maybe<string>;
  eventLocationCityName: Maybe<string>;
  eventDisplayName: Maybe<string>;
  eventFinalizedDate: Maybe<{ milliseconds: number; timezone: string }>;

  ////////////////////////////////////////////////////////////
  // Accommodations page related data
  ////////////////////////////////////////////////////////////
  accommodationListings: EventAccommodationData[];
  /**
   * Whether the event has accommodation listings (eventByName.accommodationPlacesV2).
   */
  hasAccommodationListings: boolean;
  /**
   * Whether the event has a visible accommodations event page.
   *
   * - **visible** = the page is either public or password protected.
   */
  hasVisibleAccommodationsEventPage: boolean;

  ////////////////////////////////////////////////////////////
  // Travel page related data
  ////////////////////////////////////////////////////////////

  /**
   * Whether the event has travel accommodation listings (eventByName.info.travel).
   *
   * - **visible** = the page is either public or password protected.
   * - an accommodation travel listing is a travel item that is either a Hotel, RentalHome, or Tent.
   */
  hasTravelAccommodationListings: boolean;
  /**
   * Whether the event has a visible travel event page.
   */
  hasVisibleTravelEventPage: boolean;

  error: Error | undefined;
};

const isEventPageVisible = (pageInfo?: { visibility: EventPageVisibility }) => {
  if (!pageInfo) return false;
  return [EventPageVisibility.public, EventPageVisibility.passwordProtected].includes(pageInfo.visibility);
};

const isAnAccommodationLikeTravelListing = (travelListing: NonNullable<EventAccommodationsCheckQuery['eventByName']>['info']['travel'][number]) => {
  return travelListing.__typename ? ACCOMMODATION_TRAVEL_TYPE_SET.has(travelListing.__typename) : false;
};

const isValidAccommodationPlace = (accommodation: EventAccommodationPublicDataFragment): accommodation is ExtendedEventAccommodationPublicData => {
  return !!accommodation.type;
};

const isValidRoomBlock = (accommodation: EventReservedRoomBlocksPublicDataFragment): accommodation is ExtendedEventReservedRoomBlocksPublicData => {
  return !!accommodation.bookingUrl;
};

export interface ExtendedEventAccommodationPublicData extends Omit<EventAccommodationPublicDataFragment, 'type'> {
  type: AccommodationPlaceType;
  distanceFromVenue?: {
    value: string | number;
    format: 'mi' | 'km';
  };
}

export interface ExtendedEventReservedRoomBlocksPublicData extends Omit<EventReservedRoomBlocksPublicDataFragment, 'bookingUrl'> {
  type: 'roomBlock';
  bookingUrl: string;
  distanceFromVenue?: {
    value: string | number;
    format: 'mi' | 'km';
  };
}

export type EventAccommodationData = ExtendedEventAccommodationPublicData | ExtendedEventReservedRoomBlocksPublicData;

export const useEventAccommodationsCheck = (args: UseEventAccommodationsCheckArgs): UseEventAccommodationsCheckReturn => {
  const { eventHandle } = args;
  const { data, loading, error } = useEventAccommodationsCheckQuery({
    variables: {
      name: eventHandle,
      pageContainerBySlugPayload: {
        eventName: eventHandle,
        pageSlug: 'accommodations'
      }
    },
    batchMode: 'off',
    fetchPolicy: 'cache-first'
  });

  const processedAccommodationsData = useMemo(() => {
    const maybeEventVenue = data?.eventByName?.places?.find(place => place.type === EventPlaceType.venue);
    const venueLocation = maybeEventVenue || data?.eventByName?.info?.locationInfo;

    const accommodationListings = (data?.eventByName?.accommodationPlacesV2 || []).filter(isValidAccommodationPlace);
    const reservedRoomBlocks = (data?.eventByName?.hotelRoomBlocks?.reservedRoomBlocks || [])
      .filter(isValidRoomBlock)
      .map<ExtendedEventReservedRoomBlocksPublicData>(x => ({ ...x, type: 'roomBlock' }));
    let combinedAccommodationListings = [...reservedRoomBlocks, ...accommodationListings];
    const maybeAccommodationsTravelMapComponent = data?.pageContainerBySlug?.components?.find(comp => comp.__typename === 'TravelMapComponent');

    if (maybeAccommodationsTravelMapComponent?.__typename === 'TravelMapComponent' && maybeAccommodationsTravelMapComponent.blockOrdering?.length) {
      combinedAccommodationListings = sortByOrderList(combinedAccommodationListings, maybeAccommodationsTravelMapComponent.blockOrdering);
    }

    const accommodationsEventPageInfo = data?.eventByName?.pagesInfo?.find(pageInfo => pageInfo.page.pageSlug === CUSTOM_ACCOMMODATIONS_PAGE_SLUG);

    return {
      accommodationListings: combinedAccommodationListings.map<EventAccommodationData>(accommodation => {
        const canCalculateVenueDistance = [venueLocation?.latitude, venueLocation?.longitude, accommodation.latitude, accommodation.longitude].every(isNumber);
        return {
          ...accommodation,
          distanceFromVenue: canCalculateVenueDistance
            ? {
                value: distanceInMiles(venueLocation?.latitude || 0, venueLocation?.longitude || 0, accommodation.latitude || 0, accommodation.longitude || 0).toFixed(1),
                format: 'mi'
              }
            : undefined
        };
      }),
      hasAccommodationListings: combinedAccommodationListings.length > 0,
      hasVisibleAccommodationsEventPage: isEventPageVisible(accommodationsEventPageInfo)
    };
  }, [data]);

  const processedTravelData = useMemo(() => {
    const hasTravelAccommodationListings = (data?.eventByName?.info.travel?.filter(isAnAccommodationLikeTravelListing).length ?? 0) > 0;
    const travelEventPageInfo = data?.eventByName?.pagesInfo?.find(pageInfo => pageInfo.page.pageSlug === TRAVEL_EVENT_PAGE_SLUG);

    return {
      hasTravelAccommodationListings,
      hasVisibleTravelEventPage: isEventPageVisible(travelEventPageInfo)
    };
  }, [data]);
  const eventInfo = data?.eventByName?.info;

  return {
    isResolved: !loading || !!data,
    eventDisplayName: eventInfo?.eventDisplayName,
    eventLocationPlaceId: eventInfo?.placeId,
    eventFinalizedDate: eventInfo?.finalizedDate,
    eventLocationCityName: eventInfo?.locationInfo?.city,
    error,
    ...processedAccommodationsData,
    ...processedTravelData
  };
};
