export const ACCOMMODATIONS_PAGE_SLUG = 'accommodations';

export const ACCOMMODATIONS_MAX_LENGTH = 160;

export const SELECTED_HOTEL_LOCAL_STORAGE_KEY = (eventId: string) => `${eventId}_selectedHotel`;

export const REQUEST_GROUP_RATE_INFO_LOCAL_STORAGE_KEY = (eventId: string) => `${eventId}_requestGroupRateInfo`;

export const CERTIFIED_HOTEL_TEXT_IMAGE = 'https://withjoy.com/assets/public/firstpartyhotels/certified-hotel-text.png';

// @todo - https://withjoy.atlassian.net/browse/CORE-6360
export const COMPARE_HOTELS = {
  expedia: 'https://withjoy.com/assets/public/firstpartyhotels/compare/expedia.png',
  priceline: 'https://withjoy.com/assets/public/firstpartyhotels/compare/priceline.png'
};
