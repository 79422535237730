import React from 'react';
import { useGuestUnlockInfoController } from '../UnlockDialog/UnlockDialog.controller';
import { DialogStyled } from '../DialogStyled/DialogStyled';
import { UnlockDialogContextValue, useUnlockDialogContext } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { ButtonV2, ButtonV2Props } from '@withjoy/joykit';
import { ManageUnlockDialogForms } from '@apps/guest/packages/layout-engine/components/UnlockDialog/ManageUnlockDialogForms/ManageUnlockDialogForms';

export interface GuestTypes {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  personIdTag?: string;
}

export interface ButtonV2PropsExt extends ButtonV2Props {
  shouldDisplayUnlockButton: Maybe<boolean>;
  unlockButtonText: string;
}

const UnlockDialog: React.FC<UnlockDialogContextValue> & { TriggerButton: typeof TriggerButton } = ({ isOpen, onClose, shouldDisplayFindInvite }) => {
  const unlockData = useGuestUnlockInfoController();
  const { showBackButton } = unlockData;

  return (
    <DialogStyled isOpen={isOpen} onClose={onClose}>
      <DialogStyled.Header>
        <DialogStyled.BackButton hidden={!showBackButton} />
        <DialogStyled.CloseButton onClick={onClose} />
      </DialogStyled.Header>
      <DialogStyled.Body>
        <ManageUnlockDialogForms unlockData={{ ...unlockData, shouldDisplayFindInvite }} onDialogClose={onClose} />
      </DialogStyled.Body>
      <DialogStyled.Footer />
    </DialogStyled>
  );
};

const TriggerButton: React.FC<ButtonV2PropsExt> = props => {
  const { handleUnlockDialogOpen } = useUnlockDialogContext();
  const { shouldDisplayUnlockButton, unlockButtonText } = props;

  if (!shouldDisplayUnlockButton) return null;

  return (
    <ButtonV2 shape="rounded" onClick={() => handleUnlockDialogOpen()} {...props}>
      {unlockButtonText}
    </ButtonV2>
  );
};

UnlockDialog.TriggerButton = TriggerButton;

export { UnlockDialog };
