import React from 'react';
import { Box } from '@withjoy/joykit/components/Box';
import { pxToRem } from '@withjoy/joykit/theme';

export const FoilBadge = () => {
  return (
    <Box
      background="linear-gradient(90deg, #EBD77E 10%, #C39E45 100%), linear-gradient(90deg, #D1DADA 0.25%, #8D9195 100%)"
      borderRadius={pxToRem(4)}
      paddingX={4}
      paddingY={pxToRem(2)}
      color="white"
      typographyVariant="label3"
      width="fit-content"
    >
      FOIL
    </Box>
  );
};
