import { useCallback, useMemo } from 'react';
import { Category } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { toUnitless } from '@shared/joykit/packages/core/common/themes/shared/typography';
import { useFontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';

export const useScaleGuestSiteFontSize = () => {
  const { eventDesign } = useFontPackProvider();

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment', { skip: !eventDesign?.fonts?.length }).value === 'treatment';

  const categoryToFontSizePercent = useMemo(
    () =>
      eventDesign?.fonts?.reduce(
        (acc, font) => ({
          ...acc,
          [font.category]: font.sizePercent
        }),
        {} as Record<Category, number>
      ),
    [eventDesign?.fonts]
  );

  /**
   * Scales the font size based on the given category.
   *
   * @param {string} fontSize - The font size to scale, in rem. eg: 2rem.
   * @param {Category} category - The font category.
   * @returns {string} - The scaled font size in rem.
   */
  const scaleFontSize = useCallback(
    (fontSize: string, category: Category) => {
      const fontSizePercent = categoryToFontSizePercent?.[category];
      if (!fontSizePercent || !fontPackExperimentEnabled) return fontSize;
      const currentFontSizeInRem = toUnitless(fontSize);

      return `${currentFontSizeInRem * (fontSizePercent / 100)}rem`;
    },
    [categoryToFontSizePercent, fontPackExperimentEnabled]
  );

  return { scaleFontSize };
};
