import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from '@withjoy/joykit';
import { CSSProperties } from 'react';
import { LayerLayoutData, ImageLayerData } from './Layer.types';
import { MEDIA_SERVICE } from '../steps/CardDesign/config';
import { PageEditor } from './Page';
import { loadImageDimensions } from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/PhotoSelector/PhotoSelector.controller';
import { EditableCroppedImageLayer, CroppedImageLayer } from './CroppedImageLayer';
// import CardLoader from '../steps/CardDesign/components/CardLoader';

const MINIMUM_PPI = 150;
export const SCALE_MAX = 72 / MINIMUM_PPI; // Prevent blurry prints

// A fallback URL to use while we're waiting the media service URL to be read.
// key:   layer.imageData.src
// value: filestack image "preview" URL
export const previewUrls = new Map<string, string>();

export function layerLayoutDataToCSS(layout: LayerLayoutData): CSSProperties {
  return {
    position: 'absolute',
    left: layout.x,
    top: layout.y,
    width: layout.width,
    height: layout.height
  };
}

// ImageLayerData.imageData.src is defined as one of two forms:
// a) container/asset - if event-specific
// b) asset           - if event-agnostic
// This function simply prepends the event-agonstic container ID as needed.
// Then prepends the media service URL.

// Hacking this to allow passing through URLs that are already absolute paths.
// Not a permenant addition. My assumption is that we will add the images I'm
// using to mediaservice or some other form of storage we control.

export function absolutePhotoUrl(imageLayerSrc: string) {
  if (imageLayerSrc.startsWith('https://')) {
    return imageLayerSrc; // Passthrough.
  }

  // Event-agnostic (slash-less) srcs are always served from production media service,
  // so in that case we do not use the MEDIA_SERVICE constant which is env-specific.
  return imageLayerSrc.includes('/') ? `${MEDIA_SERVICE}/${imageLayerSrc}` : `https://withjoy.com/media/paper/${imageLayerSrc}`;
}

export type Size = { width: number; height: number };

// Could merge with CroppedImageLayer, but would need to wait for the image natural w/h to load before render, which is not ideal.
export const ImageLayer = (props: { layerIndex: number; layer: ImageLayerData; editor?: PageEditor; loader?: ReactElement; loading?: boolean }) => {
  const { layer, editor, layerIndex, loader, loading } = props;
  const isActive = editor?.isActive(layerIndex) ?? false;

  const url = previewUrls.get(layer.imageData.src) ?? absolutePhotoUrl(layer.imageData.src);

  const [photoSize, setPhotoSize] = useState<Size>(); // Only used if $layer.editable
  useEffect(() => {
    if (!layer.editable) return;
    void (async () => setPhotoSize(await loadImageDimensions(url)))();
  }, [layer.editable, url]);

  if (layer.editable) {
    if (!photoSize) {
      return (
        <Box
          style={{
            ...layerLayoutDataToCSS(layer.layout),
            overflow: 'hidden' // Safari not responding to 'clip'
          }}
        />
      ); // Wait for the image to load
    }

    return isActive && editor ? (
      <EditableCroppedImageLayer {...props} editor={editor} photoSize={photoSize} />
    ) : (
      <CroppedImageLayer loader={loader} loading={loading} layer={layer} photoSize={photoSize} />
    );
  }

  return (
    <>
      {/* If supplied with a Loader, draw it. */}
      {loader ? loader : null}
      <Box
        style={{
          ...layerLayoutDataToCSS(layer.layout),
          overflow: 'hidden', // Safari not responding to 'clip'

          // Images sizes served via Media Service (withjoy.com/media) vs directly from Azure Blob:
          //                          Azure     Media Service
          // Examples invitations:    Blob      Default  Medium
          //   cherie                 12.7MB    1.6MB    408KB
          //   bohemian_eucalyptus     3.1MB    556KB    119KB
          backgroundImage: `url(${url.startsWith(MEDIA_SERVICE) ? `${url}?rendition=medium` : url})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // Opacity to fade in and out of Loader
          transition: 'opacity 1s linear',
          opacity: loading ? '0%' : '100%'
        }}
      />
    </>
  );
};
