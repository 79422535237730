import { ComponentType, GuestVideoComponentFragment } from '@graphql/generated';
import React from 'react';
import { Box, DialogV2, Flex, useDisclosure } from '@withjoy/joykit';
import { AspectRatioContainer } from '@shared/components/ApectRatio';
import { pxToRem } from '@withjoy/joykit/theme';
import { RichText } from '../RichText';
import { ReactComponent as PlayButton } from '@assets/icons/button-play.svg';
import { getVideoDataFromUrl } from '@apps/admin/routes/WebsiteEditor/routes/WebsiteEditorCustomPageV2/components/CustomPageLeftPanel/components/CustomComponents/Video/Video.utils';

type VideoProps = {
  videoComponentData: GuestVideoComponentFragment;
};

const DialogContent: React.FC<VideoProps & { videoEmbedUrl: string }> = ({ videoEmbedUrl, videoComponentData: { id, sortOrder, caption } }) => {
  return (
    <>
      <DialogV2.Header>
        <DialogV2.CloseButton />
      </DialogV2.Header>
      <DialogV2.Body>
        <Flex maxWidth={pxToRem(900)} marginX={'auto'} flexDirection={'column'} rowGap={6}>
          <AspectRatioContainer aspectRatio={9 / 5} minHeight={'400px'}>
            <Box as={'iframe'} title={''} src={videoEmbedUrl} border={'none'} height={'100%'} width={'100%'} />
          </AspectRatioContainer>
          <RichText
            isFirstOfType
            richTextComponentData={{
              id,
              text: caption || '',
              sortOrder,
              type: ComponentType.text
            }}
          />
        </Flex>
      </DialogV2.Body>
    </>
  );
};

export const Video: React.FC<VideoProps> = props => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const videoData = getVideoDataFromUrl(props.videoComponentData.videoUrl);

  if (!videoData?.embedUrl) {
    return null;
  }

  return (
    <>
      <Box onClick={onOpen} cursor={'pointer'} position={'relative'} overflow={'hidden'} borderRadius={3}>
        <Box as="img" src={videoData.thumbnailUrl} alt={''} width={'100%'} display={'block'} />
        <Box as={PlayButton} width={pxToRem(80)} position={'absolute'} top={'50%'} left={'50%'} transform={'translate(-50%, -50%)'} />
      </Box>
      <DialogV2 isOpen={isOpen} onClose={onClose} size="full">
        <DialogContent videoEmbedUrl={videoData.embedUrl} {...props} />
      </DialogV2>
    </>
  );
};
