import React from 'react';
import { GuestPhotoComponentFragment } from '@graphql/generated';
import { Box, DialogV2, Flex, useDisclosure } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';

interface PhotoProps {
  photoComponentData: GuestPhotoComponentFragment;
}

const DialogContent: React.FC<PhotoProps & { image: React.ReactNode }> = ({ image }) => {
  return (
    <>
      <DialogV2.Header>
        <DialogV2.CloseButton />
      </DialogV2.Header>
      <DialogV2.Body>
        <Flex maxWidth={pxToRem(900)} marginX={'auto'} flexDirection={'column'} rowGap={6}>
          {image}
        </Flex>
      </DialogV2.Body>
    </>
  );
};

export const Photo: React.FC<PhotoProps> = props => {
  const { photoComponentData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const image = <Box as={'img'} src={photoComponentData.photo.url} alt={''} width="100%" display="block" />;

  return (
    <>
      <Flex onClick={onOpen} cursor={'pointer'} width="100%" overflow="hidden" borderRadius={3}>
        {photoComponentData.photo.url && image}
      </Flex>
      <DialogV2 isOpen={isOpen} onClose={onClose} size="full">
        <DialogContent image={image} {...props} />
      </DialogV2>
    </>
  );
};
