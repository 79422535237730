import React from 'react';
import { Route, RoutePath, RoutesProvider, Switch, useRouterHelper } from '@shared/core';
import { ServiceCenter } from '@apps/serviceCenter/ServiceCenter';
import { Messages } from '@apps/serviceCenter/routes/Messages';
import { Events } from './routes/Events';
import { PrintOrderManagement } from './routes/PrintOrderManagement';
import { PrintOrderDetails } from './routes/PrintOrderDetails';
import { PrintOrderSearchByOrderNumber } from './routes/PrintOrderSearchByOrderNumber';
import { SuperAdminRoute } from '@apps/examples/registry-shop-manage/components/SuperAdminRoute';
import { JoyLogo } from '@assets/index';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { PrintTemplates } from './routes/PrintTemplates';

export const routePaths = {
  servicecenter: {
    path: '',
    goToPath: () => ``
  },
  messages: {
    path: 'messages',
    goToPath: () => 'messages'
  },
  eventSafetyRating: {
    path: 'event-safety-rating',
    goToPath: () => 'event-safety-rating'
  },
  print: {
    path: 'print',
    goToPath: () => 'print'
  },
  printTemplates: {
    path: 'print/templates',
    goToPath: () => 'print/templates'
  },
  printOrder: {
    path: 'print/:printOrderNumber',
    goToPath: (printOrderNumber: string) => `print/${printOrderNumber}`
  },
  printOrderSearchByOrderNumber: {
    path: 'print/search/:printOrderNumber',
    goToPath: (printOrderNumber: string) => `print/search/${printOrderNumber}`
  }
};

const RoutesServiceCenter = () => {
  const routeHelpers = useRouterHelper();
  const routes: Array<RoutePath<{ printOrderNumber?: string }>> = [
    {
      path: routeHelpers.buildPath(routePaths.messages.path),
      component: () => <Messages />
    },
    {
      path: routeHelpers.buildPath(routePaths.eventSafetyRating.path),
      component: () => <Events />
    },
    {
      path: routeHelpers.buildPath(routePaths.printTemplates.path),
      component: () => <PrintTemplates />
    },
    {
      path: routeHelpers.buildPath(routePaths.printOrderSearchByOrderNumber.path),
      component: ({ match }) => (match.params.printOrderNumber ? <PrintOrderSearchByOrderNumber printOrderNumber={match.params.printOrderNumber} /> : <PrintOrderManagement />)
    },
    {
      path: routeHelpers.buildPath(routePaths.printOrder.path),
      component: ({ match }) => (match.params.printOrderNumber ? <PrintOrderDetails printOrderNumber={match.params.printOrderNumber} /> : <PrintOrderManagement />)
    },
    {
      path: routeHelpers.buildPath(routePaths.print.path),
      component: () => <PrintOrderManagement />
    }
  ];

  const isPrintTemplatesRoute = routeHelpers.location.pathname === routeHelpers.buildPath(routePaths.printTemplates.path);

  return (
    <Box>
      {/* Print Template route is dark background, so we don't want this light header */}
      {!isPrintTemplatesRoute && (
        <Flex justifyContent="center" alignItems={'center'} height={'5.5rem'}>
          <JoyLogo />
          <TextV2 typographyVariant="Hed40" marginLeft={4}>
            Service Center
          </TextV2>
        </Flex>
      )}
      <Switch>
        <Route exact path={routeHelpers.buildPath(routePaths.servicecenter.path)} render={() => <ServiceCenter />} />
        {routes.map(route => {
          return <Route exact key={route.path} path={route.path} render={route.component} />;
        })}
      </Switch>
    </Box>
  );
};

export const RouterServiceCenter: React.FC<{ appUrl: string }> = ({ appUrl }) => {
  return (
    <SuperAdminRoute
      path="/servicecenter"
      render={() => {
        return (
          <RoutesProvider appUrl={appUrl}>
            <RoutesServiceCenter />
          </RoutesProvider>
        );
      }}
    />
  );
};
