import React, { FC } from 'react';
import { createContext } from '@shared/utils/createContext';
import { GuestSiteEventDesign } from '../layout.types';
import { colorPointerUtils, parseDesignPreferences } from '@shared/utils/websiteDesign';
import { DesignLayoutType } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { areDefaultColorsOverridden } from '../layout.utils';

type ColorPaletteProviderContext = {
  accentColor?: string;
  backgroundColor?: string;
  textColor?: string;
};

type ColorPaletteProviderProps = {
  eventDesign: GuestSiteEventDesign;
};

const [Provider, useColorPaletteProvider] = createContext<ColorPaletteProviderContext>({
  name: 'ColorPaletteProvider'
});

const ColorPaletteProvider: FC<ColorPaletteProviderProps> = ({ children, eventDesign }) => {
  const [accentColor, textColor, customBackgroundColor] = eventDesign?.colorPalette || [];
  const { brannanColorPreference, alohaColorPreference } = parseDesignPreferences(eventDesign?.designPreferences || []);
  const isGuestSiteColorsExperimentEnabled = useFeatureValue('guestSiteColorsExperiment').value === 'treatment';

  const shouldUseDefaultBackgroundColorBrannan = colorPointerUtils.isThemePointer(brannanColorPreference?.colorPointerPosition ?? undefined);
  const shouldUseDefaultBackgroundColorAloha = colorPointerUtils.isThemePointer(alohaColorPreference?.colorPointerPosition ?? undefined);

  const isCustomColorEnabledByLayout =
    eventDesign?.websiteLayout?.layoutType === DesignLayoutType.aloha ? !shouldUseDefaultBackgroundColorAloha : !shouldUseDefaultBackgroundColorBrannan;
  const isCustomColorEnabled = isGuestSiteColorsExperimentEnabled && (isCustomColorEnabledByLayout || areDefaultColorsOverridden(eventDesign));

  return (
    <Provider
      value={{
        accentColor: isCustomColorEnabled ? accentColor?.color.hex : undefined,
        backgroundColor: isCustomColorEnabled ? customBackgroundColor?.color.hex : undefined,
        textColor: isCustomColorEnabled ? textColor?.color.hex : undefined
      }}
    >
      {children}
    </Provider>
  );
};

export { ColorPaletteProvider, useColorPaletteProvider };
