import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { Category } from '@graphql/generated';
import { TextV2, TextV2Props } from '@withjoy/joykit';
import { useIsMounted } from '@withjoy/joykit/hooks';
import { pxToRem } from '@withjoy/joykit/theme';
import React, { useEffect, useRef } from 'react';

interface ResponsiveText extends Omit<TextV2Props, 'fontSize'> {
  text: Maybe<string>;
  skipResize?: boolean;
  defaultFontSize?: number;
  fontCategory: Category;
}

export const ResponsiveText: React.FC<ResponsiveText> = ({ text, defaultFontSize = 48, skipResize, fontCategory, ...textV1Props }) => {
  const isMounted = useIsMounted();
  const ref = useRef<HTMLDivElement>(null);

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  useEffect(() => {
    const updateFontSize = () => {
      if (ref.current && isMounted && !skipResize) {
        let newFontSize = 0;
        while (newFontSize <= defaultFontSize) {
          ref.current.style.fontSize = scaleFontSize(`${pxToRem(newFontSize)}`, fontCategory);
          const textWidth = ref.current.scrollWidth;
          if (textWidth > ref.current.clientWidth) {
            break;
          }
          newFontSize++;
        }
        ref.current.style.fontSize = scaleFontSize(`${pxToRem(newFontSize - 1)}`, fontCategory);
      }
    };

    const timeout = setTimeout(() => updateFontSize(), 200);

    window.addEventListener('resize', updateFontSize);
    return () => {
      window.removeEventListener('resize', updateFontSize);
      clearTimeout(timeout);
    };
  }, [text, isMounted, defaultFontSize, skipResize, scaleFontSize, fontCategory]);

  return (
    <TextV2 ref={ref} {...textV1Props} {...(!skipResize && { whiteSpace: 'nowrap' })}>
      {text}
    </TextV2>
  );
};
