import React, { useEffect, useRef } from 'react';
import { useMount } from '@shared/utils/hooks/useMount';
import { useHotelBookingGuestSiteTelemetry } from '../../HotelBooking.telemetry';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';
import { withWindow } from '@shared/utils/withWindow';
import { useHotelBookingPartnerSearchParam } from './useHotelBookingPartnerSearchParam';
import { config } from '@static/js/env.config';
import { addError } from '@shared/utils/logger';

export const HotelBookingPartnerSearch = () => {
  const redirectInProgress = useRef(false);
  const telemetry = useHotelBookingGuestSiteTelemetry();

  const { loading, buildRedirectUrl, ...restParams } = useHotelBookingPartnerSearchParam();

  useMount(() => {
    telemetry.hotelbookingPartnerSearch.enter();
  });

  useEffect(() => {
    if (redirectInProgress.current || loading) {
      return;
    }

    try {
      /**
       * Try redirect to Nuitee
       */
      const nuiteeRedirectUrl = buildRedirectUrl({
        url: config.nuiteeWhitelableBaseUrl,
        fields: ['clientReference', 'placeId', 'name', 'checkin', 'checkout']
      });

      telemetry.partnerHotelSearchRedirect({
        destinationName: 'stays',
        destinationUrl: nuiteeRedirectUrl,
        clientReference: restParams.clientReference,
        locationPlaceId: restParams.placeId,
        locationName: restParams.name,
        checkin: restParams.checkin,
        checkout: restParams.checkout,
        utm_source: restParams.utm_source,
        utm_medium: restParams.utm_medium,
        utm_campaign: restParams.utm_campaign,
        onTelemetrySend: () => {
          withWindow(() => window.location.replace(nuiteeRedirectUrl.toString()));
        }
      });

      redirectInProgress.current = true;
    } catch (error) {
      addError({
        message: 'HotelBookingPartnerSearch: Failed to build redirect URL',
        context: 'HotelBookingPartnerSearch',
        errorDetails: error instanceof Error ? error.message : String(error)
      });
      withWindow(() => window.location.replace(config.nuiteeWhitelableBaseUrl));
      redirectInProgress.current = true;
    }
  }, [buildRedirectUrl, loading, restParams, telemetry]);

  return <>{!redirectInProgress.current && <JoyLogoLoader loaderKey="hotel-booking-partner-search" />}</>;
};
