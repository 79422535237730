import React from 'react';
import { StyledContent, StyledFormStack, StyledHeaderStack, StyledInputsStack, StyledTitleStack, styles } from '../UnlockForm/UnlockForm.styles';
import { ButtonV2, FormControl, InputV2, TextV2 } from '@withjoy/joykit';
import { VerifyEmailFormController } from './VerifyEmailForm.controller';
import { GuestTypes } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialog';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body4ToSubHeadingOverride, display4ToHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';

export interface VerifyEmailFormProps {
  eventId: string;
  guest: GuestTypes;
  setGuest: (p: GuestTypes) => void;
}

export const VerifyEmailForm: React.FC<VerifyEmailFormProps> = ({ eventId, guest, setGuest }) => {
  const { formik, loading, inputError, title, subtitle, emailLabel, submitButtonText, inputPlaceholder } = VerifyEmailFormController({
    eventId,
    setGuest,
    guest
  });

  return (
    <StyledContent __css={styles.content}>
      <StyledHeaderStack>
        <StyledTitleStack>
          <GuestSiteTypographyOverride override={display4ToHeadingOverride}>
            <TextV2 typographyVariant="display4">{title}</TextV2>
          </GuestSiteTypographyOverride>
          <GuestSiteTypographyOverride override={body4ToSubHeadingOverride}>
            <TextV2 typographyVariant="body4">{subtitle}</TextV2>
          </GuestSiteTypographyOverride>
        </StyledTitleStack>
      </StyledHeaderStack>
      <StyledFormStack spacing={6} __css={styles.formStack}>
        <form onSubmit={formik.handleSubmit}>
          <StyledInputsStack>
            <FormControl label={emailLabel}>
              <InputV2
                isInvalid={(inputError && formik.touched.email) || undefined}
                enableFocusOnMount
                isDisabled={loading}
                placeholder={inputPlaceholder}
                {...formik.getFieldProps('email')}
              />
            </FormControl>
          </StyledInputsStack>
          <ButtonV2 width={'100%'} shape="rounded" intent="neutral" type="submit" loading={loading}>
            {submitButtonText}
          </ButtonV2>
        </form>
      </StyledFormStack>
    </StyledContent>
  );
};
