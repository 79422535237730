import React, { FC, useEffect } from 'react';
import { createContext } from '@shared/utils/createContext';
import { GuestSiteEvent } from '../layout.types';
import useSet from '@shared/utils/hooks/useSet';
import globalWindow from '@shared/core/globals';
import { useFeatureValue } from '@shared/core/featureFlags';

type FontPackProviderContext = {
  eventDesign: GuestSiteEvent['eventDesign'] | undefined;
  addFonts: (fonts: string[]) => void;
  hasFont: (font: string) => boolean;
  loadedFonts: Set<string>;
};

type FontPackProviderProps = {
  eventDesign?: GuestSiteEvent['eventDesign'] | undefined;
};

const [Provider, useFontPackProvider] = createContext<FontPackProviderContext>({
  name: 'FontPackProvider'
});

const FontPackProvider: FC<FontPackProviderProps> = ({ eventDesign, children }) => {
  const [loadedFonts, { addMultiple: addFonts, has: hasFont }] = useSet<string>();

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  useEffect(() => {
    if (globalWindow.WebFont && eventDesign?.fonts && fontPackExperimentEnabled) {
      const fontPackFontFamilies = eventDesign.fonts.map(fontPack => fontPack?.font?.fontFamily) || [];
      const arrFonts = [...fontPackFontFamilies].filter(x => x) as string[];
      const getFontsUnloaded = arrFonts.filter((font: string) => !hasFont(font));

      if (!getFontsUnloaded.length) {
        return;
      }

      // attempt to load fonts
      globalWindow.WebFont?.load({
        google: {
          families: getFontsUnloaded
        },
        fontactive: () => {
          addFonts(getFontsUnloaded);
        }
      });
    }
  }, [addFonts, eventDesign?.fonts, hasFont, fontPackExperimentEnabled]);

  return <Provider value={{ eventDesign, addFonts, hasFont, loadedFonts }}>{children}</Provider>;
};

export { FontPackProvider, useFontPackProvider };
