import { PointerEventHandler } from 'react';

export type XY = { x: number; y: number };
export type Rect = XY & { width: number; height: number };
export type Matrix = [number, number, number, number];
export type Step<T> = (delta: XY, state: T) => void;

function magnitude(x: number, y: number) {
  return Math.sqrt(x * x + y * y);
}

export function startDrag<T>(step: Step<T>, state: T, onEnd?: (didDrag: boolean) => void): PointerEventHandler<HTMLElement> {
  let origin: XY;
  let didDrag = false;

  const onMove = (e: PointerEvent) => {
    if (!e.isPrimary) return;
    const x = e.clientX - origin.x;
    const y = e.clientY - origin.y;
    didDrag = didDrag || magnitude(x, y) >= 1; // If the mouse didn't move, then it's not a drag.
    if (didDrag) step({ x, y }, state);
  };

  const onUp = (e: PointerEvent) => {
    if (!e.isPrimary) return;
    removeEventListener('pointermove', onMove);
    removeEventListener('pointerup', onUp);
    onEnd?.(didDrag);
  };

  return e => {
    if (!e.isPrimary) return;
    e.stopPropagation(); // Prevent deselection (should be handled by caller?)
    origin = { x: e.clientX, y: e.clientY };
    didDrag = false;
    addEventListener('pointermove', onMove);
    addEventListener('pointerup', onUp);
  };
}
