import React, { useCallback } from 'react';
import { useTranslation } from '@shared/core';
import { FormikProps } from 'formik';
import { EmailInputFields, EmailType, EmailEditPanelOption } from '@shared/components/EmailsAndEcards/Emails.types';
import GeneralMessageEmailForm from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/GeneralMessageEmailForm';
import EcardEmailForm from '@shared/components/EmailsAndEcards/components/EmailEditor/components/EmailEditPanel/components/EcardEmailForm';
import { isValidDate, isCustomECardType } from '@apps/ecard/Ecard.utils';
import { useEcardsTelemetry } from '@apps/ecard/Ecard.telemetry';
import { usePostOfficeTelemetry } from '@apps/postOffice/PostOffice.telemetry';
import { EcardType, EventPageFragment } from '@graphql/generated';
import { withWindow } from '@shared/utils/withWindow';
import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { useToast } from '@withjoy/joykit';
import ThankYouEmailForm from './components/ThankYouEmailForm';
import { useQueryParamHelper } from '@shared/core/queryString';
import { notEmailOrUrlRegex } from '@shared/components/EmailsAndEcards/Emails.utils';
import { useGetValidReplyToAddresses } from './hooks/getValidReplyToAddresses';

interface UseEmailEditPanelControllerArgs
  extends Readonly<{
    emailType: EmailType;
    formik: FormikProps<EmailInputFields>;
    toggleEmailEditPanel: () => void;
    isPasswordToggleShown?: boolean;
    eventDate?: string;
    ecardDraftType?: EcardType;
    eventHandle?: string;
    eventDisplayName?: string;
    pages?: ReadonlyArray<EventPageFragment>;
  }> {}

export const useEmailEditPanelController = (args: UseEmailEditPanelControllerArgs) => {
  const { toast } = useToast();
  const isMobile = useIsMobileScreen();
  const { t, t2 } = useTranslation('emailsAndEcards');
  const tEmailEditor = t2('emailEditor');
  const tEmailEditorHeadlineError = t('emailEditor', 'headlineError');
  const tGeneralMessageEmailOptions = tEmailEditor.generalMessageEmailOptions;
  const tEcardEmailOptions = tEmailEditor.ecardEmailOptions;
  const { emailType, formik, toggleEmailEditPanel, isPasswordToggleShown, eventDate, ecardDraftType, eventHandle, eventDisplayName, pages } = args;
  const telemetry = useEcardsTelemetry();
  const { modifyThankYouNote } = usePostOfficeTelemetry();
  const { getValueString } = useQueryParamHelper();
  const thankYouPropmt = `Write a ${getValueString('tone') || 'friendly'} ${getValueString('context') || ''} thank you note to ${getValueString('name') || 'my friend'} from ${
    eventDisplayName || ''
  } for the ${getValueString('items') || 'gift'}.`;
  const headlineIsNotValid = !notEmailOrUrlRegex.test(formik.values.headline);
  const validReplyToAddresses = useGetValidReplyToAddresses(eventHandle ?? '');

  const generalMessageEmailOptions: Array<EmailEditPanelOption> = [
    {
      id: 'includeWebsiteDetails-toggle',
      name: 'website information toggle',
      hed: tGeneralMessageEmailOptions[0].hed,
      dek: tGeneralMessageEmailOptions[0].dek,
      isOn: !!formik.values.includeWebsiteDetails,
      formikName: formik.getFieldProps('includeWebsiteDetails').name,
      onCange: () => {
        watchToggleChanges('includeWebsiteDetails', !formik.values.includeWebsiteDetails);
        formik.setTouched({ ...formik.touched, ['includeWebsiteDetails']: true });
      }
    },
    {
      id: 'includeEventPassword-toggle',
      name: 'password toggle',
      hed: tGeneralMessageEmailOptions[1].hed,
      dek: tGeneralMessageEmailOptions[1].dek,
      isOn: !!formik.values.includeEventPassword,
      formikName: formik.getFieldProps('includeEventPassword').name,
      isDisabled: !!formik.values.includeAppInstructions || (!formik.values.includeAppInstructions && !formik.values.includeWebsiteDetails),
      onCange: () => {
        watchToggleChanges('includeEventPassword', !formik.values.includeEventPassword);
        formik.setTouched({ ...formik.touched, ['includeEventPassword']: true });
      }
    },
    {
      id: 'includeAppInstructions-toggle',
      name: 'app instruction toggle',
      hed: tGeneralMessageEmailOptions[2].hed,
      dek: tGeneralMessageEmailOptions[2].dek,
      isOn: !!formik.values.includeAppInstructions,
      formikName: formik.getFieldProps('includeAppInstructions').name,
      onCange: () => {
        watchToggleChanges('includeAppInstructions', !formik.values.includeAppInstructions);
        formik.setTouched({ ...formik.touched, ['includeAppInstructions']: true });
      }
    }
  ];

  const ecardEmailOptions: Array<EmailEditPanelOption> = [
    {
      id: 'includeEventDate-toggle',
      name: 'event date toggle',
      hed: tEcardEmailOptions[0].hed,
      dek: eventDate || tEmailEditor.dateHidden,
      isOn: !!formik.values.includeEventDate,
      formikName: formik.getFieldProps('includeEventDate').name,
      isDisabled: !eventDate,
      onCange: () => {
        formik.setFieldValue('includeEventDate', !formik.values.includeEventDate);
        formik.setTouched({ ...formik.touched, ['includeEventDate']: true });
      }
    },
    {
      id: 'includeAddToCalendarLink-toggle',
      name: 'add to calendar link toggle',
      hed: tEcardEmailOptions[1].hed,
      dek: tEcardEmailOptions[1].dek,
      isOn: !!formik.values.includeAddToCalendarLink,
      formikName: formik.getFieldProps('includeAddToCalendarLink').name,
      isDisabled: !isValidDate(eventDate),
      onCange: () => {
        formik.setFieldValue('includeAddToCalendarLink', !formik.values.includeAddToCalendarLink);
        formik.setTouched({ ...formik.touched, ['includeAddToCalendarLink']: true });
      },
      isHidden: ecardDraftType && isCustomECardType(ecardDraftType)
    }
  ];

  const handleIncludeGuestNamesToggle = () => {
    const includeGuestsNamesValue = formik.getFieldMeta('includeGuestNames').value;
    formik.setFieldValue('includeGuestNames', !includeGuestsNamesValue);
  };

  const handlePhotoSelect = (photoUrl: string, containerId?: string, assetId?: string) => {
    formik.setFieldValue('imageUrl', photoUrl);
    formik.setFieldValue('containerId', containerId);
    formik.setFieldValue('assetId', assetId);
  };

  const onSaveClick = () => {
    if (formik.dirty) {
      if (formik.touched.message && emailType === 'thankyou') {
        modifyThankYouNote(thankYouPropmt, formik.initialValues?.message || '', formik.values?.message || '');
      }
      formik.submitForm();
    }
    toast(tEmailEditor.doneTooltipText, {
      icon: '✅'
    });
    telemetry.editEmailDoneClick();
    toggleEmailEditPanel();
  };

  const watchToggleChanges = (formikField: string, newValue: boolean) => {
    if (formikField === 'includeAppInstructions') {
      if (!!newValue) {
        formik.setFieldValue('includeEventPassword', true);
      }
      if (!newValue && !formik.values.includeWebsiteDetails) {
        formik.setFieldValue('includeEventPassword', false);
      }
    } else if (formikField === 'includeWebsiteDetails') {
      if (!newValue && !formik.values.includeAppInstructions) {
        formik.setFieldValue('includeEventPassword', false);
      }
    }

    formik.setFieldValue(formikField, newValue);
  };

  // post office v2 future functionality
  // const handleLinkSelect = (selectedLink: GeneralMessageLinkType) => {
  //   formik.setFieldValue('selectedLink', selectedLink);
  // };

  const onGoToPhotosClick = useCallback(() => {
    withWindow(() => {
      window.open(`${window.location.origin}/${eventHandle}/edit/photos`, '_blank');
    });
  }, [eventHandle]);

  const getEmailSpecificComponent = () => {
    switch (emailType) {
      case 'generic':
        return (
          <GeneralMessageEmailForm
            formik={formik}
            tEmailEditor={tEmailEditor}
            generalMessageEmailOptions={generalMessageEmailOptions}
            isPasswordToggleShown={isPasswordToggleShown}
            handleIncludeGuestNamesToggle={handleIncludeGuestNamesToggle}
            handlePhotoSelect={handlePhotoSelect}
            pages={pages}
            validReplyToAddresses={validReplyToAddresses}
            // post office v2 future functionality:
            // handleLinkSelect={handleLinkSelect}
            // initialSelectedLink={formik.values.selectedLink!}
          />
        );
      case 'ecard':
        return (
          <EcardEmailForm
            formik={formik}
            tEmailEditor={tEmailEditor}
            ecardEmailOptions={ecardEmailOptions}
            handleIncludeGuestNamesToggle={handleIncludeGuestNamesToggle}
            onGoToPhotosClick={onGoToPhotosClick}
            handlePhotoSelect={handlePhotoSelect}
            pages={pages}
          />
        );

      case 'thankyou':
        return <ThankYouEmailForm formik={formik} tEmailEditor={tEmailEditor} handlePhotoSelect={handlePhotoSelect} pages={pages} />;
      default:
        return null;
    }
  };

  return {
    tEmailEditor,
    generalMessageEmailOptions,
    onSaveClick,
    handleIncludeGuestNamesToggle,
    handlePhotoSelect,
    getEmailSpecificComponent,
    isMobile,
    headlineIsNotValid,
    tEmailEditorHeadlineError
  };
};
