import { EventQandaFragment, useGetGuestsiteFaqQuery } from '@graphql/generated';
import { Box, BoxProps, Flex, SpacingStack, TextV2 } from '@withjoy/joykit';
import React from 'react';
import { PrettyLink } from '../../components/PrettyLink';
import { useEventUserRole } from '@shared/components/AuthProvider';
import { isInIframe } from '@shared/utils/isInIframe';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { body4ToParagraphOverride, hed2ToSubHeadingOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { pxToRem } from '@withjoy/joykit/theme';

export interface FaqListProps
  extends Readonly<{
      // Add props here.
      stackByDefault?: boolean;
      nested?: boolean;
      eventHandle: string;
      tidbits?: ReadonlyArray<EventQandaFragment>;
    }>,
    BoxProps {}

const FaqList = React.forwardRef<HTMLDivElement, FaqListProps>(({ children, nested, stackByDefault, ...restProps }, ref) => {
  const { eventHandle, tidbits } = restProps;
  const isPreviewing = isInIframe();
  const { isAdmin } = useEventUserRole();
  // prepare faq query, since can't use hooks conditionally
  const { data, loading } = useGetGuestsiteFaqQuery({
    variables: { eventHandle: eventHandle, isAdminDashboard: isPreviewing && isAdmin },
    batchMode: 'fast',
    fetchPolicy: 'cache-and-network',
    ssr: false
  });

  if (nested) {
    // this means that FaqList is being rendered as a nested component w/in either Tidbits or CoupleStory, so should use the tidbits (qanda) passed down
    if (!tidbits || tidbits.length === 0) {
      return null;
    }
    return (
      <Box ref={ref} {...restProps}>
        <SpacingStack flexDirection="column" spacing={9}>
          {tidbits.map(({ id, question, answer }) => {
            if (!answer) {
              return null;
            }
            return (
              <Flex key={id} data-testid={`qanda-${id}`} flexDirection="column">
                <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride}>
                  <TextV2 wordBreak="break-word" typographyVariant="hed2">
                    {question}
                  </TextV2>
                </GuestSiteTypographyOverride>
                <GuestSiteTypographyOverride override={body4ToParagraphOverride}>
                  <TextV2 wordBreak="break-word" typographyVariant="body4" marginTop={pxToRem(10)} tagName="div">
                    <PrettyLink>{answer}</PrettyLink>
                  </TextV2>
                </GuestSiteTypographyOverride>
              </Flex>
            );
          })}
        </SpacingStack>
      </Box>
    );
  } else {
    // otherwise, we are rendering the standalone Faqlist page, and should use the data from faq query
    if (loading) return <div style={{ height: '300px' }}></div>; // height set to prevent divs from jumping around while page loads

    const items = data?.eventByName?.info.faq || [];
    return (
      <Box ref={ref} {...restProps}>
        <SpacingStack flexDirection="column" spacing={10}>
          {items.map(({ id, question, answer }) => {
            if (!answer) {
              return null;
            }
            return (
              <Flex key={id} data-testid={`qanda-${id}`} flexDirection="column">
                <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride}>
                  <TextV2 typographyVariant="hed2">{question}</TextV2>
                </GuestSiteTypographyOverride>
                <GuestSiteTypographyOverride override={body4ToParagraphOverride}>
                  <TextV2 wordBreak="break-word" typographyVariant="body4" marginTop={5} tagName="div">
                    <PrettyLink>{answer}</PrettyLink>
                  </TextV2>
                </GuestSiteTypographyOverride>
              </Flex>
            );
          })}
        </SpacingStack>
      </Box>
    );
  }
});

FaqList.displayName = 'FaqList';

export { FaqList };
