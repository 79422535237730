import { styled, Theme } from '@withjoy/joykit';
import { css } from '@withjoy/joykit';
import { InGroupPosition } from '@shared/components/PeopleListItem/PeopleListItem';
import { mediaScreenMobile, mediaScreenNotMobile } from '@shared/utils/media/mediaQueriesUtils';

export interface StylingProps {
  isSmall?: boolean;
  disabled?: boolean;
  inGroupPosition?: InGroupPosition;
  isNoNamePlusOne?: boolean;
}

const borderWidth = '1px';
const borderPadding = ({ isSmall }: StylingProps) => (isSmall ? '1px' : '2px');
const avatarSize = ({ isSmall }: StylingProps) => (isSmall ? '24px' : '40px');
const itemInfoVPadding = ({ isSmall }: StylingProps) => (isSmall ? '0.25rem' : '0.375rem');

export const AvatarWrapper = styled.span``;

export const TextContainer = styled.span``;
export const ItemText = styled.span``;
export const ItemSubtext = styled.span``;
export const AvatarName = styled.span``;

export const AvatarBorder = styled.span``;

const groupedItemBorderCss = ({ inGroupPosition = '', theme }: StylingProps & { theme: Theme }) => {
  const borderColor = theme.colors.mono6;
  const borderRadius = '50%';
  let borderCss = null;
  // hide top/bottom border depending on position in group, also extend border to connect previous border with next one
  if (inGroupPosition === 'first') {
    borderCss = css`
      border-radius: ${borderRadius} ${borderRadius} 0 0;
      border-color: ${borderColor} ${borderColor} transparent;
    `;
  } else if (inGroupPosition === 'last') {
    borderCss = css`
      border-radius: 0 0 ${borderRadius} ${borderRadius};
      border-color: transparent ${borderColor} ${borderColor};
    `;
  } else if (inGroupPosition === 'middle') {
    borderCss = css`
      border-radius: 0 0 0 0;
      border-color: transparent ${borderColor};
    `;
  }
  const beforeSideBorder = `${inGroupPosition ? `${borderWidth} solid ${borderColor}` : 'none'}`;
  return css`
    ::before {
      height: ${['first', 'last'].includes(inGroupPosition) ? '33%' : 'unset'};
      bottom: ${['first', 'middle'].includes(inGroupPosition) ? '-33%' : 'unset'};
      top: ${['last', 'middle'].includes(inGroupPosition) ? '-33%' : 'unset'};
      border-left: ${beforeSideBorder};
      border-right: ${beforeSideBorder};
    }
    ${borderCss}
  `;
};

const avatarBorderPositionCss = (props: StylingProps) => {
  // extend border to certain amount of pixel outside of avatar component
  const extPoint = `calc(-1*(${borderWidth} + ${borderPadding(props)}))`;
  return css`
    top: ${extPoint};
    right: ${extPoint};
    bottom: ${extPoint};
    left: ${extPoint};
  `;
};

export const StyledItemInfo = styled.span<StylingProps & { theme: Theme }>`
  display: flex;
  flex: 1;
  margin-right: 1rem;
  padding-top: ${itemInfoVPadding};
  padding-bottom: ${itemInfoVPadding};
  align-items: center;
  ${TextContainer} {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  ${ItemText}, ${ItemSubtext} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
    ${({ disabled, theme }) =>
      mediaScreenNotMobile`${css`
        color: ${disabled ? theme.colors.mono5 : theme.colors.mono14};
      `}`}
    ${({ disabled, theme }) =>
      mediaScreenMobile`${css`
        color: ${disabled ? theme.colors.mono8 : theme.colors.mono14};
      `}`}
  }
  ${ItemText} {
    font-size: 13px;
  }
  ${ItemSubtext} {
    font-size: 11px;
  }
  ${AvatarWrapper} {
    position: relative;
    width: ${avatarSize};
    height: ${avatarSize};
    margin-right: ${({ isSmall }) => (isSmall ? '0.75rem' : '1rem')};
    background-color: ${({ theme, isNoNamePlusOne }) => (isNoNamePlusOne ? theme.colors.mono6 : theme.colors.primary8)};
    color: white;
    text-align: center;
    border-radius: 50%;
    img,
    svg {
      opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
    ${AvatarName} {
      /* makes the text slightly more centered */
      position: relative;
      left: 0.5px;
      font-style: normal;
      font-weight: 500;
      font-size: ${({ isSmall }) => (isSmall ? '12px' : '16px')};
      line-height: ${avatarSize};
    }
  }
  ${AvatarBorder} {
    position: absolute;
    ${avatarBorderPositionCss};
    border: ${borderWidth} solid transparent;
    ::before {
      content: ' ';
      position: absolute;
      left: -${borderWidth};
      right: -${borderWidth};
    }
    ${groupedItemBorderCss}
  }
`;
