import { templateToTitle } from '@apps/card/Card.utils';
import { Page } from '@apps/card/routes/CardCustomizer/components/Page';
import { useServiceCenterStationeryTemplatesContainerQuery } from '@graphql/generated';
import { Flex, styled, TextV2 } from '@withjoy/joykit';
import React, { useEffect, useState } from 'react';

const Container = styled.div`
  padding: 2rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: white;
  background-color: hsl(0, 0%, 25%);
  min-height: 100vh;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 160px;
  font-size: 0.9rem;
  padding: 1rem;
  line-height: 1.5;
  color: white;
  background-color: hsla(0, 0%, 100%, 0.1);
  border: 0;

  &:focus {
    outline: none;
  }
`;

function camelCaseToWords(word: string) {
  const result = word.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

const FILTER_PARAM_KEY = 'filter';
const FILTER_DEFAULT = ['felicity_accommodations', 'emilio_hotels', 'tiffany_lodging', 'minimalist_hotel', 'doodle_accommodations', 'the_hotel'].join('\n');

export const PrintTemplates = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [filter, setFilter] = useState(urlParams.get(FILTER_PARAM_KEY) ?? FILTER_DEFAULT);

  useEffect(() => {
    const url = new URL(window.location.href);
    const filterValue = filter.trim();
    if (filterValue == FILTER_DEFAULT) {
      url.searchParams.delete(FILTER_PARAM_KEY);
    } else {
      url.searchParams.set(FILTER_PARAM_KEY, filterValue);
    }
    history.pushState(null, '', url);
  }, [filter]);

  const { data, loading } = useServiceCenterStationeryTemplatesContainerQuery({
    variables: {
      filter: {}
    },
    batchMode: 'fast',
    fetchPolicy: 'cache-first'
  });

  if (loading) return <Container>Loading...</Container>;

  const templates = data?.stationeryTemplatesContainer.pagedResults.edges?.map(edge => edge.node) ?? [];
  const filterTerms = filter.split(/\s+/).filter(term => term.length > 3);
  const filteredTemplates = templates.filter(template => {
    if (template.themeId === 'test03') return false; // Invalid themeJSON payload
    return filterTerms.some(value => template.themeId.includes(value));
  });
  filteredTemplates.sort((a, b) => a.themeId.localeCompare(b.themeId));

  return (
    <Container>
      <TextArea value={filter} onChange={e => setFilter(e.target.value)} spellCheck={false} />
      {filteredTemplates.length} Results
      <Flex flexDirection="row" flexWrap="wrap" gap={7}>
        {filteredTemplates.slice(0, 100).map(template => {
          const { themeId, category, side, widthInches, heightInches } = template;
          return (
            <Flex key={template.id} flexDirection="column" gap={4} style={{ scale: 2 }}>
              <TextV2 typographyVariant="label2" title={themeId}>
                {templateToTitle(template)}
                <br />
                {[camelCaseToWords(category), `${widthInches}×${heightInches}`, side === 'back' ? 'Back' : ''].filter(x => x).join(' ')}
              </TextV2>
              <Page page={template.themeJSON.card.front} width={5} height={7} />
            </Flex>
          );
        })}
      </Flex>
    </Container>
  );
};
