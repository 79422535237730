import React from 'react';
import { Box, Flex, TextV2, ButtonV2, useDisclosure } from '@withjoy/joykit';
import { Info } from '@withjoy/joykit/icons';
import { useCardsRouterContext } from '@apps/card/Card.routes';
import { useResolveStationeryPrintOrderIssueMutation, GetStationaryPrintOrderByOrderNumberQuery, StationeryPrintOrderIssueTarget } from '@graphql/generated';
import WarningDialog from './components';

interface EditRecipientAddressProps {
  orderNumber: string;
  orderId: string;
  onEditClick: () => void;
  pendingIssue?: NonNullable<GetStationaryPrintOrderByOrderNumberQuery['stationeryPrintOrderByOrderNumber']>['issues'][0];
}

const EditRecipientAddress: React.FC<EditRecipientAddressProps> = ({ orderNumber, orderId, onEditClick, pendingIssue }) => {
  const warningDisclosure = useDisclosure();
  const { goToCustomizeOrderDetailsAtRecipientsStep } = useCardsRouterContext();
  const [resolveStationeryPrintOrderIssue, { loading: isResolvingStationeryPrintOrderIssues }] = useResolveStationeryPrintOrderIssueMutation({
    refetchQueries: ['StationeryPrintOrderByOrderNumber']
  });
  const hasUserIssue = pendingIssue?.issueTarget === StationeryPrintOrderIssueTarget.user;

  const handleOnResolveOrderIssueClick = async () => {
    try {
      if (hasUserIssue) {
        await resolveStationeryPrintOrderIssue({
          variables: { stationeryPrintOrderId: orderId, issueId: pendingIssue.id }
        });
        warningDisclosure.onClose();
      }
    } catch {
      /* TODO: Add Telemetry */
    }
  };

  const openEditCard = () => {
    onEditClick?.();
    goToCustomizeOrderDetailsAtRecipientsStep(orderNumber);
  };

  return (
    <>
      <WarningDialog
        isOpen={warningDisclosure.isOpen}
        onClose={warningDisclosure.onClose}
        onResolve={handleOnResolveOrderIssueClick}
        isResolveLoading={isResolvingStationeryPrintOrderIssues}
      />
      <Flex padding={6} border="1px solid #FFD8BB" borderRadius={3} gap={6}>
        <Info flex="none" color="#E0946D" size={32} />
        <Flex flexDirection="column" gap={3}>
          <TextV2 color="mono12" fontWeight={600}>
            Print Recipient Addresses
          </TextV2>
          <TextV2 color="mono12">
            Your order will be paused until we receive your list of recipient addresses. When you’re ready, add your recipients by clicking the link below.
          </TextV2>
          <Flex alignItems={{ _: 'flex-start', md: 'center' }} justifyContent={{ _: 'center', md: 'space-between' }} flexDirection={{ _: 'column', md: 'row' }} rowGap="15px">
            <Box
              alignSelf="flex-start"
              cursor="pointer"
              typographyVariant="body2"
              fontWeight={600}
              borderBottom="1px solid"
              borderColor="mono3"
              borderRadius={0}
              lineHeight={1}
              paddingY={1}
              onClick={openEditCard}
              __css={{ _hover: { borderBottomColor: 'transparent' } }}
            >
              Add/Edit Recipient addresses
            </Box>
            {hasUserIssue && (
              <ButtonV2 width={{ md: 150 }} flex="none" intent="neutral" shape="rounded" variant="outline" onClick={warningDisclosure.onOpen}>
                Send to Print
              </ButtonV2>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default EditRecipientAddress;
