import React, { useState } from 'react';
import { useTranslation } from '@shared/core';
import { Category } from '@graphql/generated';
import { Box, BoxProps, ButtonV2, Flex, TextV2 } from '@withjoy/joykit';
import { AccommodationCombined, AccommodationCombinedType } from '../Accommodations/Accommodation.types';
import { distanceInMiles } from '@shared/utils/distanceInMiles';
import { createDateFromUnformattedString } from '@shared/utils/dateHelpers';
import { pxToRem } from '@withjoy/joykit/theme';
import { useMediaQuery } from '@withjoy/joykit/utils';
import { useLayout } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { SkeletonText, SkeletonThumbnail } from '@shared/components/Skeleton';
import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body1ToParagraphOverride,
  button2ToButtonsOverride,
  hed2ToSubHeadingOverride,
  label3ToParagraphOverride,
  label4ToParagraphOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { useTextLineClampOverflow } from '@shared/hooks/useTextLineClampOverflow';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useAccommodationsCta } from '../../hooks/useAccommodationsCta';
import { DEFAULT_HOTEL_IMAGE, DEFAULT_HOTEL_IMAGE_WIDE } from '@shared/utils/constants';
import { JoyPlacePricing } from './components/JoyPlacePricing';
import { EventVenue } from '@shared/utils/eventPlace';
import { useAccommodationsAnalyticsCompare } from '../../hooks/useAccommodationsAnalyticsCompare';

interface AccommodationsTileV2Props {
  idx: number;
  locationInfo?: EventVenue;
  eventHandle: string;
  showDefaultData?: boolean;
  accommodation: AccommodationCombined;
  setLocationBeingHovered?: (id: string | undefined) => void;
}

const AccommodationsTileV2: React.FC<AccommodationsTileV2Props> = props => {
  const { idx, showDefaultData, locationInfo, eventHandle, setLocationBeingHovered, accommodation } = props;
  const { displayName, description, photo, id, type, interestedGuestCount, latitude, longitude } = accommodation;
  const { layout } = useLayout();
  const isBrannan = layout === 'brannan';
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isMobileOrTablet = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'md' }));
  const isGridView = isBrannan && !isMobile;
  const haveDistanceToVenue = locationInfo?.latitude && locationInfo?.longitude && latitude && longitude;
  const milesToVenue = distanceInMiles(locationInfo?.latitude || 0, locationInfo?.longitude || 0, latitude || 0, longitude || 0).toFixed(1);

  const { ctaTitle, ctaUrl, handleOnSelectAccommodation, enabledAdminGuestSiteBannerDialog } = useAccommodationsCta({ eventHandle, accommodation, locationInfo });
  const { value: showPricingValue } = useFeatureValue('accommodationsItemLowestPriceDisplayed');
  const showPricingEnabled = showPricingValue === 'treatment';
  const showRegularHotelPricing = !accommodation.customUrl && accommodation.isFromAccommodationCatalog && showPricingEnabled;
  const { value: showNuiteePriceFFValue, loading: loadingFeatureFlag } = useFeatureValue('accommodationsGuestShowNuiteePricing');
  const showNuiteePrice = showNuiteePriceFFValue === 'treatment';

  useAccommodationsAnalyticsCompare({ accommodationPlaceId: id, shouldSkip: !!accommodation.customUrl || type === AccommodationCombinedType.Roomblock });

  return (
    <Flex
      flex="1 1 calc(33.33% - 10px)"
      padding={6}
      borderRadius={pxToRem(12)}
      onClick={handleOnSelectAccommodation}
      {...(!(enabledAdminGuestSiteBannerDialog && type === AccommodationCombinedType.Roomblock) && { as: 'a', href: ctaUrl })}
      __css={{ _hover: { cursor: 'pointer', boxShadow: '0px 7px 27px 0px rgba(44, 41, 37, 0.06), 0px 4px 16px 0px rgba(0, 0, 0, 0.07)' } }}
      onMouseEnter={() => !isMobileOrTablet && setLocationBeingHovered?.(id)}
      onMouseLeave={() => !isMobileOrTablet && setLocationBeingHovered?.(undefined)}
      gap={6}
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box width={'100%'} position="relative">
        <Flex justifyContent="space-between" position="absolute" top={4} left={4} right={4} zIndex={1} flexWrap="wrap" gap={4}>
          <Box
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            columnGap={2}
            width={32}
            height={32}
            borderRadius="50%"
            backgroundColor={'rgba(255, 255, 255, 0.5)'}
            backdropFilter={'blur(4px)'}
          >
            <GuestSiteTypographyOverride override={label3ToParagraphOverride} defaultFontFamily="Inter UI">
              <TextV2 typographyVariant="label3" fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)} fontWeight={500} fontFamily="Inter UI" color="mono14">
                {+idx + 1}
              </TextV2>
            </GuestSiteTypographyOverride>
          </Box>
          {interestedGuestCount ? <InterestedGuestsLabel interestedGuestCount={interestedGuestCount} /> : null}
        </Flex>
        <Image photoUrl={photo?.url || (isBrannan || isMobile ? DEFAULT_HOTEL_IMAGE : DEFAULT_HOTEL_IMAGE_WIDE)} verticalAlign={showDefaultData ? 'bottom' : 'center'} />
        {displayName ? (
          <Box marginTop={5}>
            <VenueDisplayName displayName={displayName} />
          </Box>
        ) : null}
        {haveDistanceToVenue && (
          <Box marginTop={1}>
            <VenueDistance milesToVenue={milesToVenue} />
          </Box>
        )}
        {description ? (
          <Box marginTop={5}>
            <Description description={description} />
          </Box>
        ) : null}
        {/* ROOM BLOCK */}
        {type === AccommodationCombinedType.Roomblock && <RoomBlockPricing accommodation={accommodation} marginTop={7} />}
        {/* HOTEL: only enabled by experiment under treatment condition */}
        {type === AccommodationCombinedType.Hotel && showRegularHotelPricing && !loadingFeatureFlag ? (
          <JoyPlacePricing accommodation={accommodation} showNuiteePrice={showNuiteePrice} marginTop={7} />
        ) : null}
      </Box>
      {!isGridView && (
        <GuestSiteTypographyOverride override={button2ToButtonsOverride} defaultFontFamily="Inter UI">
          <ButtonV2
            width={{ _: '100%', sm2: isBrannan ? pxToRem(278) : '100%' }}
            intent="neutral"
            shape="rounded"
            fontWeight={600}
            backgroundColor="black"
            fontSize={scaleFontSize(pxToRem(16), Category.BUTTONS)}
            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
          >
            {ctaTitle}
          </ButtonV2>
        </GuestSiteTypographyOverride>
      )}
    </Flex>
  );
};

AccommodationsTileV2.displayName = 'AccommodationsTileV2';

const AccommodationsTileV2Skeleton: React.FC = () => {
  return (
    <Flex flex="1 1 calc(33.33% - 10px)" padding={6} borderRadius={pxToRem(12)}>
      <Flex flexDirection="column" width="100%">
        <SkeletonThumbnail
          height={pxToRem(206)}
          overrides={{
            PlaceholderRoot: {
              props: {
                width: '100%',
                backgroundColor: 'white',
                borderRadius: '12px !important'
              }
            }
          }}
        />
        <Box width="100%" paddingTop={6}>
          <SkeletonText rows={1} width={pxToRem(136)} skeletonHeight={pxToRem(22)} paddingBottom={2} />
          <SkeletonText rows={1} width={pxToRem(206)} skeletonHeight={pxToRem(23)} paddingBottom={5} />
          <SkeletonText width={pxToRem(200)} marginLeft="auto" rows={1} skeletonHeight={pxToRem(25)} />
        </Box>
      </Flex>
    </Flex>
  );
};

const InterestedGuestsLabel = ({ interestedGuestCount }: { interestedGuestCount: number }) => {
  const { t } = useTranslation('guestSiteCustom');
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const accTrans = t('accommodations');
  return (
    <Flex borderRadius={pxToRem(20.632)} height={pxToRem(32)} alignItems="center" paddingX={4} backgroundColor="rgba(255, 255, 255, 0.5)" backdropFilter="blur(4px)">
      <GuestSiteTypographyOverride override={label3ToParagraphOverride} defaultFontFamily="Inter UI">
        <TextV2
          typographyVariant="label3"
          fontFamily={'Inter UI'}
          color={'black'}
          fontSize={scaleFontSize(pxToRem(13), Category.PARAGRAPH)}
          letterSpacing={pxToRem(-0.032)}
          fontWeight={600}
          alignSelf={'center'}
        >
          {accTrans.guestsInterested({ interestedGuestCount })}
        </TextV2>
      </GuestSiteTypographyOverride>
    </Flex>
  );
};

const Image = ({ photoUrl, verticalAlign = 'center' }: { photoUrl: string; verticalAlign?: 'top' | 'bottom' | 'center' }) => {
  return (
    <Box
      width="100%"
      height={pxToRem(206)}
      borderRadius={pxToRem(12)}
      objectFit={'cover'}
      backgroundSize={'cover'}
      backgroundPosition={`center ${verticalAlign}`}
      backgroundRepeat={'no-repeat'}
      backgroundImage={`url(${photoUrl})`}
      display="flex"
      position={'relative'}
    />
  );
};

const VenueDisplayName = ({ displayName }: { displayName: string }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  return (
    <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
      <TextEllipsisWrapper
        typographyVariant="hed2"
        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
        fontSize={scaleFontSize(pxToRem(17), Category.SUB_HEADING)}
        fontWeight={600}
        color="mono14"
        title={displayName || ''}
      >
        {displayName}
      </TextEllipsisWrapper>
    </GuestSiteTypographyOverride>
  );
};

const VenueDistance = ({ milesToVenue }: { milesToVenue: string }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  return (
    <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
      <TextV2
        typographyVariant="body1"
        {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
        fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
        fontWeight={400}
        color="mono10"
      >
        {accTrans.milesFromVenue({ miles: milesToVenue })}
      </TextV2>
    </GuestSiteTypographyOverride>
  );
};

const Description = ({ description }: { description: string }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { textRef, isOverflowing } = useTextLineClampOverflow(1);
  const [isShowingMoreDescription, setShowMoreDescription] = useState(false);

  const handleCoupleNoteOpen = useEventCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setShowMoreDescription(isOpen => !isOpen);
  });
  return (
    <Flex width="100%" alignItems="flex-end">
      {description && (
        <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextEllipsisWrapper ref={textRef} lineClamp={isShowingMoreDescription ? 'none' : 1} lineHeight={1.5}>
            <TextV2
              typographyVariant="body1"
              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
              fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
              fontWeight={400}
              color="mono10"
              title={description}
              lineHeight={1.5}
            >
              {description}
            </TextV2>
          </TextEllipsisWrapper>
        </GuestSiteTypographyOverride>
      )}
      {isOverflowing && (
        <GuestSiteTypographyOverride override={body1ToParagraphOverride} defaultFontFamily="Inter UI">
          <TextV2
            {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
            fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
            fontWeight={700}
            _hover={{ cursor: 'pointer' }}
            color="mono12"
            onClick={handleCoupleNoteOpen}
            typographyVariant="body1"
            lineHeight={1.5}
          >
            {isShowingMoreDescription ? `${accTrans.less()}` : `${accTrans.more()}`}
          </TextV2>
        </GuestSiteTypographyOverride>
      )}
    </Flex>
  );
};

// Room blocks come from a different data source and have different pricing information
const RoomBlockPricing = ({ accommodation, ...boxProps }: { accommodation: AccommodationCombined } & BoxProps) => {
  const { pricePerNight, strikeoutPricePerNight, cutoffDate } = accommodation;
  const { t } = useTranslation('guestSiteCustom');
  const accTrans = t('accommodations');
  const { scaleFontSize } = useScaleGuestSiteFontSize();
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';
  const isMobile = useMediaQuery(theme => theme.mediaQueries.between(0, { breakpointAlias: 'sm2' }));
  const isBrannan = useLayout().layout === 'brannan';
  return cutoffDate ? (
    <Box {...boxProps}>
      <Flex justifyContent="space-between">
        <Flex flexDirection="column">
          <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
            <TextV2
              typographyVariant="label4"
              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
              fontSize={scaleFontSize(pxToRem(12), Category.PARAGRAPH)}
              fontWeight={600}
              color="mono10"
              textTransform="uppercase"
            >
              {accTrans.bookBefore()}
            </TextV2>
          </GuestSiteTypographyOverride>
          <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
            <TextV2
              typographyVariant="label4"
              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
              fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
              fontWeight={600}
              color="mono14"
            >
              {createDateFromUnformattedString(cutoffDate)?.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
            </TextV2>
          </GuestSiteTypographyOverride>
        </Flex>
        {pricePerNight && (
          <Flex flexDirection="column">
            <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
              <TextV2
                typographyVariant="label4"
                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                fontSize={scaleFontSize(pxToRem(12), Category.PARAGRAPH)}
                fontWeight={600}
                color="mono10"
                textTransform="uppercase"
                textAlign="right"
              >
                {accTrans.groupRate()}
              </TextV2>
            </GuestSiteTypographyOverride>
            <Flex gap={1} justifyContent="flex-end" alignItems="center">
              <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                {strikeoutPricePerNight && (
                  <TextV2
                    typographyVariant="label4"
                    backgroundSize={'100% 1.5px'}
                    width={'auto'}
                    backgroundImage={'linear-gradient(currentColor, currentColor)'}
                    backgroundPosition={'0% 50%'}
                    backgroundRepeat={'no-repeat'}
                    {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                    fontSize={scaleFontSize(pxToRem(13), Category.PARAGRAPH)}
                    fontWeight={600}
                    color={'mono10'}
                  >
                    ${strikeoutPricePerNight.toString()}
                  </TextV2>
                )}
                <TextV2
                  typographyVariant="label4"
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                  fontWeight={600}
                  color="mono14"
                  textAlign="right"
                >
                  ${pricePerNight}
                </TextV2>
              </GuestSiteTypographyOverride>
              <GuestSiteTypographyOverride override={label4ToParagraphOverride} defaultFontFamily="Inter UI">
                <TextV2
                  typographyVariant="label4"
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontSize={scaleFontSize(pxToRem(15), Category.PARAGRAPH)}
                  fontWeight={400}
                  color="mono14"
                  textAlign="right"
                  textTransform="lowercase"
                >
                  {accTrans.night()}
                </TextV2>
              </GuestSiteTypographyOverride>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Box>
  ) : (
    <Box {...boxProps}>
      {(pricePerNight || (!isMobile && isBrannan)) && (
        <Flex gap={2} justifyContent="flex-end">
          {strikeoutPricePerNight && (
            <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
              <TextV2
                typographyVariant="hed2"
                {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                fontSize={scaleFontSize(pxToRem(16), Category.SUB_HEADING)}
                fontWeight={600}
                color="mono8"
                backgroundSize={'100% 1.5px'}
                width={'auto'}
                backgroundImage={'linear-gradient(currentColor, currentColor)'}
                backgroundPosition={'0% 50%'}
                backgroundRepeat={'no-repeat'}
                textAlign="right"
              >
                ${strikeoutPricePerNight.toString()}
              </TextV2>
            </GuestSiteTypographyOverride>
          )}
          {pricePerNight ? (
            <Flex gap={1}>
              <GuestSiteTypographyOverride override={hed2ToSubHeadingOverride} defaultFontFamily="Inter UI">
                <TextV2
                  typographyVariant="hed2"
                  {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
                  fontSize={scaleFontSize(pxToRem(16), Category.SUB_HEADING)}
                  fontWeight={600}
                  color="mono14"
                  textAlign="right"
                >
                  ${pricePerNight}
                </TextV2>
              </GuestSiteTypographyOverride>
            </Flex>
          ) : (
            <Flex height={pxToRem(21.75)} />
          )}
        </Flex>
      )}
    </Box>
  );
};

export { AccommodationsTileV2, AccommodationsTileV2Skeleton };
