import { OverviewLayoutConfig } from './AddGiftsAndOverview.types';

export const OVERVIEW_LAYOUT_CONFIG: OverviewLayoutConfig = {
  variants: [
    {
      name: 'minFiveGifts',
      condition: {
        minGiftCount: 5
      },
      rows: [
        'giftCountAndGiftAdvisor',
        'heroPromo',
        'departmentsCarousel',
        'brandCarousel',
        'brandsMarquee',
        'thankYouNotesHighlight',
        'valueProps',
        'etsyPromo',
        'recommendedCategories',
        'overviewFaq'
      ]
    }
  ],
  default: {
    name: 'default',
    rows: [
      'giftCountAndExploreRegistry',
      'mainPromo',
      'registryHighlights',
      'doYouHaveRegistry',
      'departmentsCarousel',
      'giftAdvisor',
      'heroPromo',
      'brandCarousel',
      'brandsMarquee',
      'delayShippingPromo',
      'curationAndGroupGifting',
      'cashFundPromo',
      'thankYouNotesHighlight',
      'universalSearchAndCustomerSupport',
      'completionDiscountPromo',
      'valueProps',
      'etsyPromo',
      'recommendedCategories',
      'overviewFaq'
    ]
  }
};
