import React from 'react';
import { GuestSiteEventDesign } from '../../../layout.types';
import { brannanConfig, useBrannanDesign } from '../../LayoutBrannan.theme';
import { FrameInner, FrameOuter } from '../Frames';
import { Page } from '../Page';
import chroma from 'chroma-js';
import { useFeatureValue } from '@shared/core/featureFlags';
import { useColorPaletteProvider } from '../../../ColorPaletteProvider/ColorPaletteProvider';

const { layerBack, layerMiddle } = brannanConfig.applicatorHtmlProps;

export interface ShellProps {
  eventDesign?: GuestSiteEventDesign;
  noTranslucency?: boolean;
  isAlohaGuestPage?: boolean;
  isCustomPage?: boolean;
}

export const Shell: React.FC<ShellProps> = ({ eventDesign, noTranslucency, isAlohaGuestPage, isCustomPage, children }) => {
  const { applicatorConfig, loading, CustomStyle } = useBrannanDesign(eventDesign, isAlohaGuestPage);
  // Add feature flag here
  const isCustomColorsEnabled = useFeatureValue('guestSiteColorsExperiment').value === 'treatment';
  const { backgroundColor: backgroundColorToOverride } = useColorPaletteProvider();

  // For some themes (e.g. Rustic Chic) custom background color is not being applied even if enabled
  // This workaround allows to override the default theme color
  const customBackgroundColorConfig = applicatorConfig?.layerBack.customBackgroundColor;
  const customBackgroundColor =
    customBackgroundColorConfig?.isEnabled && customBackgroundColorConfig?.backgroundColor
      ? chroma(customBackgroundColorConfig?.backgroundColor as Array<number>).hex()
      : undefined;

  const customTextColor =
    customBackgroundColorConfig?.isEnabled && customBackgroundColorConfig?.textColor ? chroma(customBackgroundColorConfig?.textColor as Array<number>).hex() : undefined;

  return (
    <FrameOuter
      loading={loading}
      applicatorProp={layerBack.prop}
      customBackgroundColor={customBackgroundColor}
      customTextColor={isCustomColorsEnabled ? customTextColor : undefined}
      isCustomPage={isCustomPage}
    >
      {CustomStyle && <CustomStyle />}
      <FrameInner>
        <Page
          customBackgroundColor={isCustomColorsEnabled && backgroundColorToOverride ? customBackgroundColor : undefined}
          applicatorProp={layerMiddle.prop}
          showBorder={applicatorConfig?.layerMiddle.border?.isEnabled}
          noTranslucency={noTranslucency}
        >
          {children}
        </Page>
      </FrameInner>
    </FrameOuter>
  );
};
