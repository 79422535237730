import React, { useCallback } from 'react';
import { Box, ButtonV2, Flex, InputV2, LinkV2, Radio, TextV2, useToast } from '@withjoy/joykit';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  GetEventByIdServiceCenterQueryResult,
  GetEventByNameServiceCenterQueryResult,
  useGetEventByIdServiceCenterLazyQuery,
  useGetEventByNameServiceCenterLazyQuery
} from '@graphql/generated';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { Link } from '@shared/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy } from '@withjoy/joykit/icons';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

enum FilterBy {
  EVENT_NAME = 'EVENT_NAME',
  EVENT_ID = 'EVENT_ID'
}

interface FormikEventsForm {
  eventKey: string;
  filterBy: FilterBy;
}

export type EventFound = NonNullable<GetEventByIdServiceCenterQueryResult['data']>['eventById'] | NonNullable<GetEventByNameServiceCenterQueryResult['data']>['eventByName'] | null;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchForEvent: React.FC<{ setEventFound: React.Dispatch<EventFound> }> = ({ setEventFound }) => {
  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } });

  const formik = useFormik<FormikEventsForm>({
    initialValues: {
      eventKey: '',
      filterBy: FilterBy.EVENT_NAME
    },
    validationSchema: Yup.object<FormikEventsForm>({
      eventKey: Yup.string().required(),
      filterBy: Yup.mixed().oneOf([FilterBy.EVENT_NAME, FilterBy.EVENT_ID])
    }),
    onSubmit: (values, actions) => {
      if (values.filterBy === FilterBy.EVENT_NAME) {
        findEventByName({ variables: { handle: values.eventKey } });
      } else {
        findEventById({ variables: { id: values.eventKey } });
      }
    },
    validateOnMount: true
  });

  const [findEventById, { loading: loadingEventById, error: errorFindingEventById }] = useGetEventByIdServiceCenterLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    variables: { id: formik.values.eventKey },
    onCompleted: async data => {
      const event = data?.eventById;
      if (event) {
        setEventFound(event);
      }
    }
  });

  const [findEventByName, { loading: loadingEventByName, error: errorFindingEventByName }] = useGetEventByNameServiceCenterLazyQuery({
    batchMode: 'fast',
    fetchPolicy: 'no-cache',
    variables: { handle: formik.values.eventKey },
    onCompleted: async data => {
      const event = data?.eventByName;
      setEventFound(event);
    }
  });

  const onSearchClick = useCallback(() => {
    setEventFound(null);
    formik.submitForm();
  }, [formik, setEventFound]);

  return (
    <Box width="100%">
      <Flex flexDirection={'row'} columnGap={4}>
        <Box width={300}>
          <InputV2 placeholder="Event Name or Event ID" {...formik.getFieldProps('eventKey')} />
        </Box>
        <ButtonV2 variant="solid" intent="primary" onClick={onSearchClick} disabled={!formik.isValid} loading={loadingEventById || loadingEventByName}>
          Search
        </ButtonV2>
      </Flex>
      {(errorFindingEventById || errorFindingEventByName) && (
        <Box marginTop={2} background={'red2'} borderRadius={'4px'}>
          <Box color="red7">{errorFindingEventById?.message || errorFindingEventByName?.message}</Box>
        </Box>
      )}
      <Flex paddingRight={4} paddingY={4} columnGap={10} flexDirection={isMobile ? 'column' : 'row'}>
        <Radio
          onChange={formik.handleChange}
          id="filterByName"
          checked={formik.values.filterBy === FilterBy.EVENT_NAME}
          tabIndex={0}
          value={FilterBy.EVENT_NAME}
          label="Search by Event Name"
          name="filterBy"
        />
        <Radio
          onChange={formik.handleChange}
          id="filterById"
          checked={formik.values.filterBy === FilterBy.EVENT_ID}
          tabIndex={0}
          value={FilterBy.EVENT_ID}
          label="Search by EventID"
          name="filterBy"
        />
      </Flex>
    </Box>
  );
};

export const SearchForEventWithResult: React.FC = () => {
  const [eventFound, setEventFound] = React.useState<EventFound>(null);
  return (
    <Box>
      <SearchForEvent setEventFound={setEventFound} />
      {eventFound && (
        <Box display={'grid'} gridTemplateColumns={'min-content 1fr'} columnGap={'1.5rem'} rowGap={'1rem'} alignItems={'center'}>
          <TextV2 typographyVariant="hed3">EventName:</TextV2>
          <LinkV2 as={Link} to={`/${eventFound.website}`} typographyVariant="hed2">
            {eventFound.website}
          </LinkV2>
          <TextV2 typographyVariant="hed3">Event ID:</TextV2>
          <CopyText text={eventFound.id}>
            <TextV2 typographyVariant="hed2">{eventFound.id}</TextV2>
          </CopyText>

          <TextV2 typographyVariant="hed3">Firebase ID:</TextV2>
          <CopyText text={eventFound.firebaseId}>
            <TextV2 typographyVariant="hed2">{eventFound.firebaseId}</TextV2>
          </CopyText>
        </Box>
      )}
    </Box>
  );
};

const CopyText = ({ text, children }: { text: string; children: React.ReactNode }) => {
  const { toast } = useToast();
  const publishToast = useEventCallback(() => {
    return toast('Copied to clipboard');
  });
  return (
    <CopyToClipboard text={text} onCopy={publishToast}>
      <Flex alignItems={'center'} cursor={'pointer'}>
        {children}
        <Copy marginLeft={'0.5rem'} />
      </Flex>
    </CopyToClipboard>
  );
};
