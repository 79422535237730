import React from 'react';
import { LinkifyPhoneNumbers } from './components/LinkifyPhoneNumbers';
import { LinkifyUrlsAndEmails } from './components/LinkifyUrlsAndEmails';
import { LinkifyProps, LinkType } from './Linkify.types';

/**
 * Wrap this component around plain-text, and it will find any URL, email, or phone numbers in
 * the text (including in any descendent children) and convert them to clickable links.
 *
 * [PrettyUrl](https://github.com/joylifeinc/joy-web/blob/develop/src/shared/utils/formatting/prettyUrl/prettyUrl.formatter.ts) is applied to urls by default
 */
const DEFAULT_ALLOWED_TYPES: LinkType[] = ['URL', 'EMAIL', 'PHONE'];

export const Linkify: React.FC<LinkifyProps> = ({ className, children, allowedTypes = DEFAULT_ALLOWED_TYPES, prettyUrlFormat = true, tagName = 'p', prettyUrlOptions, source }) => {
  return React.createElement(
    tagName,
    { className },
    <LinkifyUrlsAndEmails prettyUrlFormat={prettyUrlFormat} allowedTypes={allowedTypes} prettyUrlOptions={prettyUrlOptions} source={source}>
      <LinkifyPhoneNumbers source={source} enabled={allowedTypes.includes('PHONE')}>
        {children}
      </LinkifyPhoneNumbers>
    </LinkifyUrlsAndEmails>
  );
};
