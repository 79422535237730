import { getDeviceResolution } from './deviceResolution';

const PUBLIC_ASSETS_FOLDER = `https://withjoy.com/assets/public`;

const assetScale = {
  1: '',
  2: '@2x',
  3: '@3x'
} as const;

const DEVICE_RESOLUTION = getDeviceResolution() as keyof typeof assetScale;

const ASSET_SCALE: string = assetScale[DEVICE_RESOLUTION] || assetScale[1];

export const URLS = {
  graphicAccent: (accentId: string, scale?: 1 | 2 | 3) => {
    let affix: string = ASSET_SCALE;
    if (scale) {
      affix = assetScale[scale];
    }
    return `${PUBLIC_ASSETS_FOLDER}/graphicAccents/accent_${accentId}${affix}.png`;
  },
  styleApplicatorLib: `${PUBLIC_ASSETS_FOLDER}/styles/applicator.js`,
  webfontLib: 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js'
} as const;

export const DOMAIN_NAME = 'withjoy.com/';
