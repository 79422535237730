export enum HotelPartnerSearchQueryParams {
  placeId = 'placeId',
  placeIdType = 'placeIdType',
  name = 'name',
  checkIn = 'checkIn',
  checkOut = 'checkOut',
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign'
}

export enum HotelPartnerSearchPlaceIdType {
  google = 'google',
  joy = 'joy'
}

export type HotelPartnerSearchParamsType = {
  clientReference: string;
  placeId?: string | null;
  name?: string | null;
  checkin?: string | null;
  checkout?: string | null;
  hasCheckInOut?: boolean;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
};

export interface TelPartnerHotelSearchRedirectParams {
  destinationName: 'joy' | 'stays' | 'hotels';
  destinationUrl: URL;
  onTelemetrySend?: () => void;
  clientReference: string;
  locationPlaceId?: string | null;
  locationName?: string | null;
  checkin?: string | null;
  checkout?: string | null;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
}
