import { pxToRem } from '@withjoy/joykit/theme';
import { StyleSystemProps, createGlobalStyle } from '@withjoy/joykit';

export const RemoveScroll = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const mapSizeVariants = (isBrannanLayout?: boolean): { fullscreen: StyleSystemProps; normalscreen: StyleSystemProps } => ({
  fullscreen: {
    top: 0,
    right: 0,
    zIndex: 1101,
    width: '100vw',
    position: 'fixed',
    height: 'var(--full-screen-height)'
  },
  normalscreen: {
    width: '100%',
    position: 'relative',
    height: isBrannanLayout ? { _: pxToRem(284), sm2: '100%' } : { _: pxToRem(273), sm2: pxToRem(396), sm4: pxToRem(329) }
  }
});
