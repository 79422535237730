/**
 * This file was automatically generated by `IconCodeGenerator`.
 * DO NOT MODIFY IT BY HAND. Instead, follow the instructions at `src/shared/joykit/packages/core/icons/README.md`.
 */

import * as React from 'react';

import createSvgIcon from '../createSvgIcon';

import { SvgIconConfig } from '../types';

const config: SvgIconConfig = {
  title: 'Circle',
  definitions: {
    '24': {
      viewBox: '0 0 24 24',
      path: (
        <>
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
          <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#D9D9D9" />
        </>
      )
    }
  }
};

const icon = createSvgIcon(config);
export default icon;
