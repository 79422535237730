import { createTelemetry, createTelemetryObject } from '@shared/core/analytics';

const smartAppBannerTelemetry = createTelemetryObject({
  actions: {
    // APP Section
    weHaveAnAppClicked: (app: string, category: string) => ({
      action: 'WeHaveAnAppClicked',
      actionType: 'click',
      category,
      label: app
    }),
    stayOnWebClicked: (category: string) => ({
      action: 'StayOnWebClicked',
      actionType: 'click',
      category
    })
  }
});

const { TelemetryProvider: SmartAppBannerTelemetryProvider, useTelemetry: useSmartAppBannerTelemetry } = createTelemetry(smartAppBannerTelemetry);
export { SmartAppBannerTelemetryProvider, useSmartAppBannerTelemetry };
