import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { DesignLayoutType, EventPageType } from '@graphql/generated';
import { Flex, NoSsr } from '@withjoy/joykit';
import { useHistory } from '@react-router';
import { Footer } from '../../components/Footer';
import { LayoutBaseProps } from '../layout.types';
import { Header } from './components/Header';
import { Body } from './components/Body';
import { brannanConfig, useBrannanDesign } from './LayoutBrannan.theme';
import { findEventPageByPageSlug } from '../layout.utils';
import WelcomeVideo from '../../widgets/WelcomeVideo';
import CoverPhoto from '../../components/CoverPhoto';
import { brannanFooterContainerStyles } from '@apps/guest/packages/layout-engine/components/Footer/Footer.styles';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { useLayoutBrannanController } from './LayoutBrannan.controller';
import { getEventMenuPropsFromEvent } from '@apps/guest/packages/layout-engine/layouts/layout.utils';
import { Shell } from './components/Shell';
import { useGuestSiteState } from '@apps/guest/routes/GuestSite/GuestSite.state.provider';
import { isInIframe } from '@shared/utils/isInIframe';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { useCurrentPageSlug } from '@shared/utils/hooks/useCurrentPageSlug';

export interface LayoutBrannanProps extends Readonly<LayoutBaseProps> {
  setLoadingTheme?: Maybe<Dispatch<SetStateAction<boolean>>>;
}

const { componentTitleBanner } = brannanConfig.applicatorHtmlProps;

const LayoutBrannan: React.FC<LayoutBrannanProps> = ({ eventHandle, event, setLoadingTheme }) => {
  const { info, pages, eventDesign } = event;
  const history = useHistory();

  const isPreviewing = isInIframe();
  const { currentPageSlug, isCustomPageSlug } = useCurrentPageSlug(eventHandle);

  const { handleMenuClicked, handleFooterButtonClicked, handleEditEventClicked } = useLayoutBrannanController(pages);
  const { isNativeAccommodations } = useGuestSiteState();

  const { currentPage, welcomePage, isCustomPage } = useMemo(() => {
    const currentPage = currentPageSlug ? findEventPageByPageSlug(pages, currentPageSlug, isCustomPageSlug) : null;
    const welcomePage = pages.find(item => item.type === EventPageType.welcome);
    const isCustomPage = currentPage?.type === EventPageType.custom;
    return {
      currentPage,
      welcomePage,
      isCustomPage
    };
  }, [currentPageSlug, isCustomPageSlug, pages]);

  const queryParams = useQueryParams();

  useEffect(() => {
    if (currentPageSlug === 'registrylinks' || currentPageSlug === 'registry') {
      const path = queryString.stringifyUrl({
        url: `/${eventHandle}/registry`,
        query: queryParams
      });
      window.location.href = path;
      return;
    }
    if (!currentPage) {
      if (currentPageSlug !== 'welcome' && welcomePage) {
        history.replace(`/${eventHandle}/${welcomePage.pageSlug}`);
      }
    }
  }, [history, eventHandle, currentPage, currentPageSlug, queryParams, welcomePage]);

  const { isSmallScreen, loading } = useBrannanDesign(eventDesign);

  useEffect(() => {
    setLoadingTheme && setLoadingTheme(loading);
  }, [loading, setLoadingTheme]);

  useEffect(() => {
    if (isPreviewing) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [isPreviewing, currentPage]);

  return (
    <Shell eventDesign={event.eventDesign} isCustomPage={isCustomPage}>
      {/*
            TODO: Remove this NoSsr (legacy decision)
          */}
      <NoSsr>
        {!isNativeAccommodations && (
          <Header
            data-testid={'layout-header'}
            applicatorProp={componentTitleBanner.prop}
            navBarVariant={isSmallScreen ? 'menu' : 'inline'}
            eventHandle={eventHandle}
            pages={pages}
            info={info}
            handleMenuClicked={handleMenuClicked}
            handleEditEventClicked={handleEditEventClicked}
            eventProps={getEventMenuPropsFromEvent(event)}
            page={currentPage?.type}
            pageSlug={currentPage?.pageSlug}
          />
        )}
        <Body
          data-testid={`layout-body-${currentPageSlug}`}
          marginTop={[0, null, 9]}
          page={currentPage}
          event={event}
          eventHandle={eventHandle}
          photo={
            currentPage?.pageSlug === 'welcome' && event.video ? (
              <WelcomeVideo video={event.video} thumbnailUrl={currentPage.photo?.url || event.video.thumbnailUrl} />
            ) : (
              <CoverPhoto
                websiteLayoutType={DesignLayoutType.brannan}
                photo={currentPage?.photo}
                pageId={currentPage?.id}
                pageType={currentPage?.type}
                pageSlug={currentPage?.pageSlug}
                photoPresentation={currentPage?.photoPresentation}
              />
            )
          }
        />
        <Flex __css={brannanFooterContainerStyles}>
          <Footer onButtonClicked={handleFooterButtonClicked} data-testid={'layout-footer'} />
        </Flex>
      </NoSsr>
    </Shell>
  );
};

LayoutBrannan.displayName = 'LayoutBrannan';

export { LayoutBrannan };
