import React from 'react';
import { AccommodationPlacePartner as AccommodationPlacePartnerV1 } from './AccommodationPlacePartner';
import { AccommodationPlacePartnerV2 } from './AccommodationPlacePartnerV2';
import { useFeatureValue } from '@shared/core/featureFlags';
import { JoyLogoLoader } from '@shared/components/JoyLogoLoader';

const AccommodationPlacePartner = (props: { accommodationPlaceV2ByIdId: string }) => {
  const { value, loading } = useFeatureValue('accommodationsPlaceRedirectV2Enabled');
  return (
    <>
      {loading && <JoyLogoLoader loaderKey="guest-hotel-booking-accommodations-place-root" />}
      {value === 'on' ? <AccommodationPlacePartnerV2 {...props} /> : <AccommodationPlacePartnerV1 {...props} />}
    </>
  );
};

export default AccommodationPlacePartner;
