// import { RoutesProvider } from '@shared/core';
import React, { Dispatch, SetStateAction } from 'react';
import { LayoutAloha } from '../../packages/layout-engine/layouts/LayoutAloha';
import { LayoutBrannan } from '../../packages/layout-engine/layouts/LayoutBrannan';
import { useGuestSiteController } from './GuestSite.controller';
import { PreviewListener } from './PreviewListener';
import { DesignLayoutType } from '@graphql/generated';
import { ScrollToProvider } from '@apps/guest/packages/layout-engine/layouts/LayoutAloha/providers/ScrollToProvider/ScrollToProvider';
import { GuestSiteEvent } from '@apps/guest/packages/layout-engine/layouts/layout.types';
import { StaticContext } from '@react-router';
import { Box, JoyKitThemeProvider } from '@withjoy/joykit';
import JustFonts from '@apps/guest/packages/layout-engine/components/JustFonts';
import { GuestSiteHead } from './GuestSiteHead';
import { GuestSiteDebugger } from '@apps/guest/components/GuestSiteDebugger';
import { ActionBar } from '@apps/guest/packages/layout-engine/components/ActionBar';
import { UnlockDialogProvider } from '@apps/guest/packages/layout-engine/components/UnlockDialog/UnlockDialogProvider';
import { LayoutProvider } from '@apps/guest/packages/layout-engine/layouts/LayoutProvider';
import { Announcement } from '@apps/guest/packages/layout-engine/components/Announcement';
import { BannerPublishReminder } from '@apps/guest/packages/layout-engine/components/BannerPublishReminder';
import { wrapWithGuestSiteHealthProvider } from './GuestSite.health';
import { GuestSiteFeatureSet } from '@apps/guest/packages/layout-engine/components/GuestSiteFeatureSet/GuestSiteFeatureSet';
import { TelemetryProvider as HotelBookingTelemetryProvider } from '../HotelBooking/HotelBooking.telemetry';
import HotelBooking from '../HotelBooking/routes/HotelBooking';
import { useNativeState } from '@shared/core/native';
import { GuestSiteStateProvider, useGuestSiteState } from './GuestSite.state.provider';
import { AdminLeadGenDialog } from './components/AdminLeadGenDialog';
import { useInformParentOnMousedown } from './hooks/useInformParentOnMousedown';
import { FontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';
import { StatusBanner } from './components/StatusBanner/StatusBanner';
import { ColorPaletteProvider } from '@apps/guest/packages/layout-engine/layouts/ColorPaletteProvider/ColorPaletteProvider';
import { SmartAppBanner, SmartAppBannerProvider, useSmartAppBannerProvider } from '@shared/components';

export interface GuestSiteLayoutProps
  extends Readonly<{
    eventHandle: string;
    event: GuestSiteEvent;
    layout: DesignLayoutType;
    setLoadingTheme?: Maybe<Dispatch<SetStateAction<boolean>>>;
    setIsLastPageVisible?: (value: boolean) => void;
  }> {}

const GuestSiteLayout: React.FC<GuestSiteLayoutProps> = ({ eventHandle, event, layout, setLoadingTheme, setIsLastPageVisible }) => {
  const layoutSwitch = () => {
    const layoutTypes = {
      [DesignLayoutType.aloha]: LayoutAloha,
      [DesignLayoutType.brannan]: LayoutBrannan
    };
    const Layout = layoutTypes[layout];

    return (event && <Layout setLoadingTheme={setLoadingTheme} setIsLastPageVisible={setIsLastPageVisible} eventHandle={eventHandle} event={event} />) || null;
  };

  return layoutSwitch();
};

export interface GuestSiteProps
  extends Readonly<{
    eventHandle: string;
    staticContext: StaticContext | undefined;
  }> {}

const GuestSiteBase: React.FC<GuestSiteProps> = wrapWithGuestSiteHealthProvider(({ eventHandle, staticContext }) => {
  const {
    event,
    layout,
    eventPageRoutes,
    isPreviewing,
    handleOnPreviewUpdate,
    handleOnRefetchData,
    theme,
    loading,
    justFonts,
    graphicAccent,
    shouldRenderActionBar,
    setLoadingTheme,
    setIsLastPageVisible,
    showBannerReminderChanges,
    onDismissReminder,
    onClickReminder,
    showAnnouncement,
    onAnnouncementDismiss,
    reservedRoomBlocksLoading,
    guestSiteAccommodationsShouldRedirect
  } = useGuestSiteController({
    eventHandle,
    staticContext
  });
  const { isNative } = useNativeState();
  const { showNoLeadDialog, enabledAdminGuestSiteBannerDialog, setShowNoLeadDialog } = useGuestSiteState();

  const { smartAppBannerHeight } = useSmartAppBannerProvider();

  // hook to inform irame parent on mousedown
  useInformParentOnMousedown(isPreviewing);

  if (reservedRoomBlocksLoading) {
    return null;
  } else if (guestSiteAccommodationsShouldRedirect) {
    return (
      <HotelBookingTelemetryProvider context={{ eventName: eventHandle }}>
        <HotelBooking eventHandle={eventHandle || ''} />;
      </HotelBookingTelemetryProvider>
    );
  }

  return (
    <>
      <Box {...(smartAppBannerHeight ? { paddingTop: smartAppBannerHeight } : {})}>
        {event && (
          <GuestSiteHead
            hideFromSearchEngines={event.settings.hideFromSearchEngines}
            eventDisplayName={event.info?.eventDisplayName}
            imageUrl={event.photo?.url}
            handle={event.website}
            ownerFirstName={event.info.ownerFirstName}
            ownerLastName={event.info.ownerLastName}
            fianceeFirstName={event.info.fianceeFirstName}
            pageData={event.pages}
            eventType={event.info.eventType}
          />
        )}
        {!loading && <StatusBanner />}
        <GuestSiteFeatureSet loadingGuestSite={loading}>
          {event && layout ? (
            <LayoutProvider layout={layout}>
              <FontPackProvider eventDesign={event.eventDesign}>
                <UnlockDialogProvider>
                  <ColorPaletteProvider eventDesign={event.eventDesign}>
                    <JoyKitThemeProvider theme={theme}>
                      {showAnnouncement && !isNative && (
                        <Announcement
                          onAnnouncementClosed={() => onAnnouncementDismiss(event.info.announcementBanner.title)}
                          content={<>{event.info.announcementBanner.message}</>}
                          title={event.info.announcementBanner.title}
                          graphicAccent={graphicAccent}
                        />
                      )}
                      <AdminLeadGenDialog showDialog={enabledAdminGuestSiteBannerDialog && showNoLeadDialog} eventHandle={eventHandle} openCloseDialog={setShowNoLeadDialog} />
                      {justFonts ? (
                        <JustFonts />
                      ) : (
                        <ScrollToProvider>
                          {isPreviewing && (
                            <PreviewListener
                              eventPageRoutes={eventPageRoutes}
                              eventDesign={event?.eventDesign}
                              onRefetchData={handleOnRefetchData}
                              onUpdate={handleOnPreviewUpdate}
                            />
                          )}
                          <GuestSiteLayout setLoadingTheme={setLoadingTheme} eventHandle={eventHandle} event={event} layout={layout} setIsLastPageVisible={setIsLastPageVisible} />
                        </ScrollToProvider>
                      )}
                      {showBannerReminderChanges && <BannerPublishReminder handleDismiss={onDismissReminder} handleClickLink={onClickReminder} eventHandle={eventHandle} />}
                    </JoyKitThemeProvider>
                    <GuestSiteDebugger event={event} />
                  </ColorPaletteProvider>
                  {shouldRenderActionBar && <ActionBar eventId={event.id} />}
                </UnlockDialogProvider>
              </FontPackProvider>
            </LayoutProvider>
          ) : null}
        </GuestSiteFeatureSet>
      </Box>
      <SmartAppBanner telemetryCategory="wedding" />
    </>
  );
});

const GuestSite: React.FC<GuestSiteProps> = props => {
  return (
    <GuestSiteStateProvider eventHandle={props.eventHandle}>
      <SmartAppBannerProvider>
        <GuestSiteBase {...props} />
      </SmartAppBannerProvider>
    </GuestSiteStateProvider>
  );
};

GuestSite.displayName = 'GuestSite';

export { GuestSite };
