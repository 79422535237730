import { useIsMobileScreen } from '@shared/utils/media/useMediaScreens';
import { DialogV2, DrawerV2, ModalSizes } from '@withjoy/joykit';
import React from 'react';

/**
 * The props will expand over time as we consolidate all of the various modal<>sheet implementations
 */
interface ResponsiveDialogProps {
  isOpen: boolean;
  onClose: () => void;
  content?: () => React.ReactNode;
  header?: () => React.ReactNode;
  desktopSize?: ModalSizes;
}

export const ResponsiveDialog = (props: ResponsiveDialogProps) => {
  const { content: contentProp, header: headerProp, isOpen, onClose, desktopSize = 'md' } = props;
  const isMobile = useIsMobileScreen();

  const content = contentProp?.();
  const header = headerProp?.();

  if (isMobile) {
    return (
      <DrawerV2
        isOpen={isOpen}
        onClose={onClose}
        overrides={{
          Content: {
            props: {
              borderTopRightRadius: 3,
              borderTopLeftRadius: 3
            }
          }
        }}
        anchor="bottom"
        size="auto"
        disableAutoFocus
      >
        <DrawerV2.CloseButton zIndex="banner" />
        {header && <DrawerV2.Header>{header}</DrawerV2.Header>}
        {content && <DrawerV2.Body borderTopRightRadius={3}>{content}</DrawerV2.Body>}
      </DrawerV2>
    );
  }

  return (
    <DialogV2
      isOpen={isOpen}
      onClose={onClose}
      overrides={{
        Header: {
          props: {
            paddingX: [8, 8]
          }
        },
        CloseButton: {
          props: {
            top: [4, 4],
            right: [5, 5],
            height: 7,
            width: 7,
            minWidth: 'unset',
            padding: 0,
            borderRadius: 'full',
            border: '1px solid transparent'
          }
        },
        Body: {
          props: {
            padding: [8, 8]
          }
        }
      }}
      size={desktopSize}
    >
      <DialogV2.CloseButton zIndex="banner" />
      {header && <DialogV2.Header>{header}</DialogV2.Header>}
      {content && <DialogV2.Body>{content}</DialogV2.Body>}
    </DialogV2>
  );
};
