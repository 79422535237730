import { DesignLayoutType, EventPageType, PhotoFragment, WebsitePagePhotoPresentationFragment } from '@graphql/generated';
import { getNodeEnv } from '@shared/utils/getEnvironmentVariables';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SkeletonScreen, StyledCoverPhoto, CoverPhotoContainer } from './CoverPhoto.styles';
import { InlineEditor } from '@shared/components';
import { sendMessageToParentWindow } from '@shared/utils/previewMessageBus';
import { isInIframe } from '@shared/utils/isInIframe';
import { useFeatureValue } from '@shared/core/featureFlags';
import { Box, BoxProps, Flex } from '@withjoy/joykit';
import { useResponsive } from '@shared/utils/hooks/useResponsive';
import { useQueryParamHelper } from '@shared/core/queryString';
import { withWindow } from '@shared/utils/withWindow';
import { animationTransition } from '@shared/utils/animationTransition';
import { CropAndRenderImage } from '../CropAndRenderImage/CropAndRenderImage';

const _getImageRendition = (url: string) => {
  const imageUrl = new URL(url);
  imageUrl.searchParams.append('rendition', 'medium');
  return imageUrl.toString();
};

const { isTest } = getNodeEnv();

const LazyScreen: React.FC<{
  src?: string;
  height: string | number;
  width?: string | number;
  isFullBleed: boolean;
  isChildEagerLoadRequire?: boolean;
  children: (loaded: boolean) => JSX.Element;
}> = ({ height, width, children, src, isFullBleed, isChildEagerLoadRequire = false }) => {
  const [loaded, setLoaded] = useState(src === undefined || isTest || __SERVER__);

  const isPreviewing = isInIframe();

  const handeOnLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded && src) {
      const img = new Image();
      img.onload = handeOnLoad;
      img.src = _getImageRendition(src);
    }
  }, [loaded, src]);

  useEffect(() => {
    if (isPreviewing && loaded) {
      sendMessageToParentWindow({ action: 'requestIframeScaleValue', source: 'joyPreview' });
    }
  }, [isPreviewing, loaded]);

  // const memoizedImage = src ? <img alt="" src={src} style={{ display: 'none' }} onLoad={handeOnLoad} /> : null;

  return (
    <SkeletonScreen height={height} width={width} index={0} loaded={loaded} isFullBleed={isFullBleed}>
      {loaded || isChildEagerLoadRequire ? children(loaded) : null}
    </SkeletonScreen>
  );
};

interface Props
  extends Readonly<{
    photo?: Maybe<PhotoFragment>;
    pageId?: string;
    pageType?: EventPageType;
    pageSlug?: string;
    websiteLayoutType: DesignLayoutType;

    // `CoverPhoto` is rendered in `LayoutBrannan` and `LayoutAloha` but only `LayoutBrannan`
    // passes the `photoPresentation` prop. No need to check if the feature flag is enabled.
    photoPresentation?: Maybe<WebsitePagePhotoPresentationFragment>;
  }> {}

const FULL_BLEED_IMAGE_PROPERTIES: Record<'parent', BoxProps> = {
  parent: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '600px',
    position: 'relative',
    width: '100vw',
    overflow: 'hidden',
    // All CSS units are converted to values before doing the operation.
    // -50vw: is 50% of the viewport width
    // +50%: is 50% of the *element's* width
    //
    // it's effectively the same as setting `left: -50%; transform: translateX(50%);`
    // but we need to calculate based off the viewport, not the element's width
    left: 'calc(-50vw + 50%)'
  }
};

const checkIfShouldRenderFullBleedImage = (websiteLayout: DesignLayoutType, photoPresentation?: Maybe<WebsitePagePhotoPresentationFragment>) => {
  return websiteLayout === DesignLayoutType.brannan && photoPresentation?.presentationLayout === 'fullWidth';
};

export const CoverPhoto: React.FC<Props> = props => {
  const { photo, pageId, pageType, pageSlug, websiteLayoutType, photoPresentation } = props;

  const enablePhotoLayout = websiteLayoutType === DesignLayoutType.brannan;

  const queryParamHelper = useQueryParamHelper();
  const inlineEditingEnabled = useMemo(() => queryParamHelper.getValue('feature.enableInlineEditing') === 'true', [queryParamHelper]);

  const guestBrannanCoverPhotoV2Enabled = useFeatureValue('guestBrannanCoverPhotoV2Enabled').value === 'on';
  const isBrannanFullBleedFeatureEnabled = useFeatureValue('websiteBrannanFullBleedImageEnabled', { skip: !enablePhotoLayout || pageType === 'custom' }).value === 'on';

  const handleEditPhotoClick = () => {
    // send the message to the parent window to open the photo editor
    sendMessageToParentWindow({
      action: 'inlineEditingInteraction',
      source: 'joyPreview',
      value: {
        action: 'editPhoto',
        inlineEditData: {
          enablePhotoLayout,
          photoPresentation,
          photo: photo,
          pageId: pageId || '',
          page: pageType,
          pageSlug
        }
      }
    });
  };

  const shouldRenderFullBleedImage = isBrannanFullBleedFeatureEnabled && checkIfShouldRenderFullBleedImage(websiteLayoutType, photoPresentation);

  const imageRef = useRef<HTMLImageElement>(null);

  const [imageHeight, setImageHeight] = useState(imageRef.current?.clientHeight || 600);

  const [isMobile] = useResponsive({ values: { mobile: true, tablet: false } }, false);

  useEffect(() => {
    return () => {
      setImageHeight(600);
    };
  }, [photo?.url, isMobile]);

  const innerWidth = withWindow(window => window.innerWidth, (600 * (photo?.width || 1)) / (photo?.height || 1));

  const computeAspectRatio = useMemo(() => {
    if (photoPresentation?.cropInfo && photo) {
      let cropWidth = photoPresentation.cropInfo.width;
      let cropHeight = photoPresentation.cropInfo.height;
      if (photoPresentation?.cropInfo?.unit === 'percent') {
        cropWidth = (photoPresentation.cropInfo.width / 100) * photo.width;
        cropHeight = (photoPresentation.cropInfo.height / 100) * photo.height;
      }
      const aspectRaio = cropWidth / cropHeight;
      return aspectRaio;
    }
    // retuns default 16/9 if photoPresentation cropInfo is not available
    return 16 / 9;
  }, [photo, photoPresentation?.cropInfo]);

  return (
    <>
      {photo ? (
        <LazyScreen
          key={photo.id}
          src={photo.url}
          width={shouldRenderFullBleedImage ? innerWidth : (600 * photo.width) / photo.height}
          height={shouldRenderFullBleedImage ? Math.min(innerWidth / computeAspectRatio, 600) : 600}
          isFullBleed={shouldRenderFullBleedImage}
          // need to render the child eagerly if the full bleed image is enabled to do some canvas calculations
          isChildEagerLoadRequire={shouldRenderFullBleedImage}
        >
          {isLoaded => (
            <>
              {!guestBrannanCoverPhotoV2Enabled ? (
                <InlineEditor
                  key={`inline-edit-photo-${pageId || ''}-${photo?.id || ''}`}
                  elementLabel="Photo"
                  wrapperType="actionInside"
                  actionData={{
                    editPhoto: handleEditPhotoClick
                  }}
                  componentName="pagePhoto"
                  pageName={pageType}
                  pageSlug={pageSlug || ''}
                  wrapperCSS={shouldRenderFullBleedImage ? { width: '100vw', left: 'calc(-50vw + 50%)' } : {}}
                >
                  {shouldRenderFullBleedImage ? (
                    <>
                      <Box
                        // make the item invisible until the image is loaded
                        visibility={isLoaded ? 'visible' : 'hidden'}
                        opacity={isLoaded ? 1 : 0}
                        transition={animationTransition('opacity')}
                        {...FULL_BLEED_IMAGE_PROPERTIES.parent}
                      >
                        {photoPresentation?.cropInfo && (
                          <CropAndRenderImage
                            imageUrl={_getImageRendition(photo.url)}
                            cropInfo={{
                              x: photoPresentation.cropInfo.x,
                              y: photoPresentation.cropInfo.y,
                              height: photoPresentation.cropInfo.height,
                              width: photoPresentation.cropInfo.width,
                              unit: photoPresentation.cropInfo.unit as 'percent' | 'px'
                            }}
                          />
                        )}
                      </Box>
                    </>
                  ) : (
                    <CoverPhotoContainer
                      data-width={photo.width}
                      data-height={photo.height}
                      data-testid={'body-coverphoto'}
                      position={'relative'}
                      photo={photo}
                      maxHeight={[null, null, null, 600]}
                      width={'100%'}
                    >
                      <StyledCoverPhoto backgroundSize={'contain'} url={_getImageRendition(photo.url)} />
                    </CoverPhotoContainer>
                  )}
                </InlineEditor>
              ) : (
                <CoverPhotoContainer
                  data-width={photo.width}
                  data-height={photo.height}
                  data-testid={'body-coverphoto'}
                  position={'relative'}
                  photo={photo}
                  maxHeight={[null, null, null, 600]}
                  width={'100%'}
                >
                  <Flex position="absolute" alignItems="center" justifyContent="center" width="100%" height="100%">
                    <InlineEditor
                      key={`inline-edit-photo-${pageId || ''}-${photo?.id || ''}`}
                      elementLabel="Photo"
                      wrapperType="actionInside"
                      actionData={{
                        editPhoto: handleEditPhotoClick
                      }}
                      minHeight="unset"
                      wrapperCSS={{
                        maxHeight: imageHeight || '600px',
                        height: '100%'
                      }}
                      containerCSS={{
                        height: '100%',
                        maxHeight: '600px'
                      }}
                      componentName="pagePhoto"
                      pageName={pageType}
                      pageSlug={pageSlug || ''}
                    >
                      <img
                        key={`${_getImageRendition(photo.url)}_${isMobile ? 'mobile' : 'desktop'}`}
                        ref={imageRef}
                        src={_getImageRendition(photo.url)}
                        onLoad={() => setImageHeight(imageRef.current?.clientHeight || 600)}
                        alt=""
                        style={{ height: 'auto', width: 'auto', maxHeight: '100%', maxWidth: '100%', aspectRatio: `auto ${photo.width / photo.height}` }}
                      />
                    </InlineEditor>
                  </Flex>
                </CoverPhotoContainer>
              )}
            </>
          )}
        </LazyScreen>
      ) : (
        inlineEditingEnabled && (
          <InlineEditor
            key={`inline-edit-photo-${pageId || ''}-add-new-photo`}
            elementLabel="Photo"
            wrapperType="emptyChild"
            actionData={{
              addPhoto: handleEditPhotoClick
            }}
            componentName="pagePhoto"
            pageName={pageType}
            pageSlug={pageSlug || ''}
          >
            <Box height="100%" width="100%" />
          </InlineEditor>
        )
      )}
    </>
  );
};
