import React from 'react';
import { Flex } from '@withjoy/joykit';

import { RichText } from './components/RichText';
import { TravelMap } from './components/TravelMap';
import { useCustomData } from './hooks/useCustomData';
import { sortBySortOrder } from '@shared/utils/array';
import { GuestPhotoComponentFragment, GuestTextComponentFragment, GuestTravelMapComponentFragment, GuestVideoComponentFragment } from '@graphql/generated';
import { useTranslation } from '@shared/core';
import { CustomComponentEmptyState } from './components/CustomComponentEmptyState';
import { pxToRem } from '@withjoy/joykit/theme';
import { Photo } from './components/Photo';
import { Video } from './components/Video';

interface Props {
  pageId: string;
  eventId: string;
  pageSlug: string;
  eventHandle: string;
  pageTitle?: string;
  subTitle?: string | null;
  isBrannanLayout?: boolean;
}

export const Custom: React.FC<Props> = props => {
  const { pageSlug, eventId, eventHandle, isBrannanLayout } = props;
  const { loading, pageContainerBySlug } = useCustomData(eventId, eventHandle, pageSlug);

  const { t2 } = useTranslation('guestSiteCustom');
  const emptyStateTrans = t2('emptyState');

  const hasCustomComponents = pageContainerBySlug?.components ? pageContainerBySlug.components.length > 0 : false;

  const sortedComponents = sortBySortOrder(pageContainerBySlug?.components ?? []);
  const firstOfTypeTextIndex = sortedComponents.findIndex(component => component.__typename === 'TextComponent');

  const customComponents = sortedComponents
    .map((componentData, index) => {
      switch (componentData.__typename) {
        case 'TravelMapComponent':
          return <TravelMap key={componentData.__typename} {...props} travelMapComponentData={componentData as GuestTravelMapComponentFragment} />;
        case 'TextComponent':
          return (
            <RichText
              key={componentData.__typename}
              isBrannanLayout={isBrannanLayout}
              isFirstOfType={index === firstOfTypeTextIndex}
              richTextComponentData={componentData as GuestTextComponentFragment}
            />
          );
        case 'PhotoComponent':
          return <Photo key={componentData.__typename} photoComponentData={componentData as GuestPhotoComponentFragment} />;
        case 'VideoComponent':
          return <Video key={componentData.__typename} videoComponentData={componentData as GuestVideoComponentFragment} />;
        default:
          return null;
      }
    })
    .filter((element): element is JSX.Element => element !== null);

  if (loading) {
    return <></>;
  }
  return (
    <Flex flexDirection={'column'} width={'100%'} rowGap={pxToRem(45)} alignItems="center">
      {hasCustomComponents ? (
        <>
          {customComponents.map((component, index) => (
            <React.Fragment key={`custom-components-guest-${index}`}>{component}</React.Fragment>
          ))}
        </>
      ) : (
        <CustomComponentEmptyState title={emptyStateTrans.title} subTitle={emptyStateTrans.content} isBrannanLayout={props.isBrannanLayout} />
      )}
    </Flex>
  );
};
