import { getConfig } from '@config';
import { CardPaperType } from '../../CardCustomizer.types';

const isDev = getConfig().environment === 'dev';
export const MEDIA_SERVICE = isDev ? 'https://dev.withjoy.com/media' : 'https://withjoy.com/media';
export const PPI = 72;
export const DEFAULT_CUT_MARGIN_INCHES = 0.125;

export enum StationeryPaperSize {
  A2 = 'A2',
  A7 = 'A7'
}

const A2_ENVELOPE_SKU = 'WJYOEA2W';
const A7_ENVELOPE_SKU = 'WJYOEA7W';

interface CardTypeInfo {
  label: string;
  description: string;
  weight: string;
  thickness?: string;
  SKUs: Record<StationeryPaperSize, { cardType: string; cardTypeSku: string; envelopeSku: string }>;
  imageUrl: string;
}

export const CARD_TYPES: Record<CardPaperType, CardTypeInfo> = {
  [CardPaperType.signature]: {
    label: 'Signature',
    description: 'Our luxurious signature card stock has a velvety cotton texture and elegant eggshell finish.',
    weight: '120lb. / 324gsm',
    imageUrl: 'https://withjoy.com/media/paper-type/signature.jpg',
    SKUs: {
      [StationeryPaperSize.A2]: { cardTypeSku: 'WJYA2SFW', cardType: 'FlatNoteCard', envelopeSku: A2_ENVELOPE_SKU },
      [StationeryPaperSize.A7]: { cardTypeSku: 'WJYA7SFW', cardType: 'FlatNoteCard', envelopeSku: A7_ENVELOPE_SKU }
    }
  },
  [CardPaperType.smooth]: {
    label: 'Smooth',
    description: 'Similar in feel to our signature stock, but with a smooth, matte finish. Recommended for photo cards.',
    weight: '120lb. / 324gsm',
    imageUrl: 'https://withjoy.com/media/paper-type/signature.jpg',
    SKUs: {
      [StationeryPaperSize.A2]: { cardTypeSku: 'WJYA2WSS', cardType: 'FlatNoteCard', envelopeSku: A2_ENVELOPE_SKU },
      [StationeryPaperSize.A7]: { cardTypeSku: 'WJYA7WSS', cardType: 'FlatNoteCard', envelopeSku: A7_ENVELOPE_SKU }
    }
  },
  [CardPaperType.pearlescent]: {
    label: 'Pearlescent',
    description: 'Add an elegant iridescence to printed cards and a soft glow to photographs with Pearlescent paper.',
    weight: '110lb. / 300gsm',
    imageUrl: 'https://withjoy.com/media/paper-type/pearlescent.jpg',
    SKUs: {
      [StationeryPaperSize.A2]: { cardTypeSku: 'WJYA2WP', cardType: 'FlatNoteCard', envelopeSku: A2_ENVELOPE_SKU },
      [StationeryPaperSize.A7]: { cardTypeSku: 'WJYA7WP', cardType: 'FlatNoteCard', envelopeSku: A7_ENVELOPE_SKU }
    }
  },
  [CardPaperType.doubleThick]: {
    label: 'Double Thick',
    description: 'Twice as thick as our signature paper, with the same eggshell finish and velvety cotton texture.',
    weight: '240lb. / 648gsm',
    thickness: '30pt',
    imageUrl: 'https://withjoy.com/media/paper-type/double.jpg',
    SKUs: {
      [StationeryPaperSize.A2]: { cardTypeSku: 'WJYA2SFWDT', cardType: 'FlatNoteCard', envelopeSku: A2_ENVELOPE_SKU },
      [StationeryPaperSize.A7]: { cardTypeSku: 'WJYA7SFWDT', cardType: 'FlatNoteCard', envelopeSku: A7_ENVELOPE_SKU }
    }
  },
  [CardPaperType.tripleThick]: {
    label: 'Triple Thick',
    description: 'Extra thick and luxurious, with the same eggshell finish and cottony texture as our signature paper.',
    weight: '360lb. / 972gsm',
    thickness: '60pt',
    imageUrl: 'https://withjoy.com/media/paper-type/triple.jpg',
    SKUs: {
      [StationeryPaperSize.A2]: { cardTypeSku: 'WJYA2SFWTT', cardType: 'FlatNoteCard', envelopeSku: A2_ENVELOPE_SKU },
      [StationeryPaperSize.A7]: { cardTypeSku: 'WJYA7SFWTT', cardType: 'FlatNoteCard', envelopeSku: A7_ENVELOPE_SKU }
    }
  }
};
