import { useCallback } from 'react';
import produce from 'immer';
import { DesignLayoutType, EventDesignFragment, EventWebsiteGuestQuery, GetThemeByThemeIdQuery, useGetThemeByThemeIdQuery } from '@graphql/generated';
import { useQueryParams } from '@shared/utils/hooks/useQueryParams';
import { parseColor, isLightColor } from '@shared/utils/color';
import chroma from 'chroma-js';
import { useFeatureValue } from '@shared/core/featureFlags/featureFlags.provider';

type ApplyThemeAndLayoutArgs = {
  draft: EventDesignFragment;
  selectedLayout?: DesignLayoutType;
  themeData?: GetThemeByThemeIdQuery;
  font?: string;
  textColor?: string;
  linkColor?: string;
  backgroundColor?: string;
  isCustomColorsEnabled: boolean;
};

type QueryParamsValues = {
  layout?: DesignLayoutType;
  theme?: string;
  font?: string;
  tc?: string;
  lc?: string;
  bc?: string;
};

const applyColorPaletteOverrides = (draft: EventDesignFragment, linkColorHex?: string, textColorHex?: string, backgroundColorHex?: string) => {
  if (linkColorHex && draft.colorPalette[0]) {
    draft.colorPalette[0].color.hex = linkColorHex;
  }
  if (textColorHex && draft.colorPalette[1]) {
    draft.colorPalette[1].color.hex = textColorHex;
  }
  if (backgroundColorHex) {
    if (draft.colorPalette[2]) {
      draft.colorPalette[2].color.hex = backgroundColorHex;
    } else {
      const [r, g, b] = chroma(backgroundColorHex).rgb();
      draft.colorPalette[2] = {
        id: 'background',
        color: {
          hex: backgroundColorHex,
          isLight: isLightColor(r, g, b)
        }
      };
    }
  }
};

const applyThemeAndLayout = ({ draft, selectedLayout, themeData, font, textColor, linkColor, backgroundColor, isCustomColorsEnabled }: ApplyThemeAndLayoutArgs) => {
  const textColorHex = parseColor(textColor)?.hex();
  const linkColorHex = parseColor(linkColor)?.hex();
  const backgroundColorHex = parseColor(backgroundColor)?.hex();

  if (selectedLayout && draft.websiteLayout && Object.values(DesignLayoutType).includes(selectedLayout)) {
    draft.websiteLayout.layoutType = selectedLayout;
  }
  if (themeData?.themeByThemeId) {
    draft.theme = themeData.themeByThemeId;
  }
  if (font) {
    draft.font.fontFamily = font;
  }
  if (isCustomColorsEnabled) {
    applyColorPaletteOverrides(draft, linkColorHex, textColorHex, backgroundColorHex);
  }
  return draft;
};

type UseLayoutAndThemeOverrideOptions = Readonly<{
  themeId?: string;
  layout?: DesignLayoutType;
  font?: string;
  ssr?: boolean;
}>;
/**
 * Custom hook to override layout and theme.
 *
 * @param {Object} options - Options for layout and theme override.
 * @param {boolean} [options.ssr=true] - Server-side rendering flag.
 *
 * @returns {Object} - Returns an object containing theme data, loading state, and functions to get event design and event with theme and layout applied.
 * @returns {Object} return.themeData - The theme data fetched by the query.
 * @returns {boolean} return.loadingThemeData - The loading state of the theme data query.
 * @returns {Function} return.getEventDesignWithThemeAndLayout - Function to get event design with theme and layout applied.
 * @returns {Function} return.getEventWithThemeAndLayout - Function to get event with theme and layout applied.
 */
export const useLayoutAndThemeOverride = ({ ssr = true }: UseLayoutAndThemeOverrideOptions) => {
  const { layout, theme: themeId, font, tc: textColor, bc: backgroundColor, lc: linkColor }: QueryParamsValues = useQueryParams();
  const { data: themeData, loading: loadingThemeData } = useGetThemeByThemeIdQuery({
    variables: {
      themeId: (themeId as string) ?? ''
    },
    ssr,
    skip: !themeId,
    batchMode: 'fast'
  });
  const isCustomColorsEnabled = useFeatureValue('guestSiteColorsExperiment').value === 'treatment';

  const getEventDesignWithThemeAndLayout = useCallback(
    (eventDesign?: EventDesignFragment) => {
      return produce(eventDesign, draft => {
        if (draft) {
          applyThemeAndLayout({
            draft,
            selectedLayout: layout,
            themeData,
            font,
            textColor,
            linkColor,
            backgroundColor,
            isCustomColorsEnabled
          });
        }
      });
    },
    [layout, themeData, font, textColor, linkColor, backgroundColor, isCustomColorsEnabled]
  );

  const getEventWithThemeAndLayout = useCallback(
    (event?: EventWebsiteGuestQuery) => {
      return produce(event, draft => {
        if (draft?.eventByName?.eventDesign) {
          applyThemeAndLayout({
            draft: draft.eventByName.eventDesign,
            selectedLayout: layout,
            themeData,
            font,
            textColor,
            linkColor,
            backgroundColor,
            isCustomColorsEnabled
          });
        }
      });
    },
    [layout, themeData, font, textColor, linkColor, backgroundColor, isCustomColorsEnabled]
  );

  return {
    themeData,
    loadingThemeData,
    getEventDesignWithThemeAndLayout,
    getEventWithThemeAndLayout,
    layout,
    themeId,
    font,
    hasOverrides: layout || themeId || font || textColor || linkColor || backgroundColor
  };
};
