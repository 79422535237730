import React, { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from '@shared/core';
import { Box, ButtonV2, CloseButton, Flex, TextV2, useToast } from '@withjoy/joykit';
import { useFilestack } from '@shared/utils/filestack';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { useEventInfo } from '@shared/utils/eventInfo';
import { Import, WarningTriangle } from '@withjoy/joykit/icons';
import { pxToRem } from '@withjoy/joykit/theme';
import { PhotoRenderer, ZeroState } from './components';
import { useCreateMediaPhotoMutation, useGetEventMediaQuery, useUploadPhotoFromUrlMutation } from '@graphql/generated';
import { ResponsiveDialog } from '../ResponsiveDialog';
import { closeButtonOverrides, drawerOverrides } from '../ResponsiveDialog/ResponsiveDialog.styles';
import { ScrollWrapper } from './PhotoPicker.styles';
import { MediaCollectionPhoto, PhotoPickerProps } from './PhotoPicker.types';
import { PhotoPickerTelemetryProvider, usePhotoPickerTelemetry } from './PhotoPicker.telemetry';

export const PhotoPickerBase: FC<PhotoPickerProps> = props => {
  const {
    isOpen,
    onClose,
    containerId,
    acceptedImageFormat,
    maxFiles,
    onCloseFileStack,
    onUploadDoneStart,
    onUploadDone,
    onUploadDoneEnd,
    onSavePhoto,
    isSavingPhoto,
    telemetryCategory
  } = props;

  const [selectedPhotoId, setSelectedPhotoId] = useState<string | null>(null);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState(false);
  const [uploadedPhotoIds, setUploadedPhotoIds] = useState<string[]>([]);

  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation('joykit');
  const photoPickerTrans = t('photoPicker');
  const { toast } = useToast();

  const { eventInfo, eventHandle = '' } = useEventInfo();
  const telemetry = usePhotoPickerTelemetry();

  const { data: eventMediaResponse, loading } = useGetEventMediaQuery({ variables: { eventHandle }, batchMode: 'fast' });
  const [uploadPhotoFromUrl] = useUploadPhotoFromUrlMutation();
  const [createMediaPhoto] = useCreateMediaPhotoMutation();

  const { open: openFilePicker, getPhotoPreviewUrl } = useFilestack({
    containerId,
    accept: acceptedImageFormat,
    onUploadDone: async (res): Promise<void> => {
      onUploadDoneStart?.(res);
      setIsUploadingPhoto(true);
      const { filename, url } = res.filesUploaded[0];
      const previewUrl = await getPhotoPreviewUrl({ url });
      try {
        const { data } = await uploadPhotoFromUrl({
          variables: {
            payload: {
              assetId: filename,
              containerId,
              url: previewUrl
            }
          }
        });

        const photoId = data?.uploadPhotoFromUrl?.id;
        if (photoId) {
          const { data } = await createMediaPhoto({
            variables: {
              payload: {
                eventId: eventInfo?.eventId || '',
                photoId
              }
            },
            refetchQueries: ['GetEventMedia'],
            awaitRefetchQueries: true
          });
          const uploadedPhotoId = data?.createMediaPhoto?.id;
          if (uploadedPhotoId) {
            setSelectedPhotoId(uploadedPhotoId);
            setUploadedPhotoIds(prev => [uploadedPhotoId, ...prev]);
            setIsUploadingPhoto(false);
          }
        }
        onUploadDone && (await onUploadDone(res, previewUrl));
      } catch (error) {
        toast(`${photoPickerTrans.savePhotoError()}`, { icon: <WarningTriangle /> });
      } finally {
        onUploadDoneEnd?.();
        setIsUploadingPhoto(false);
      }
    },
    onClose: onCloseFileStack,
    maxFiles
  });

  const handleFilePickerOpen = useEventCallback(() => {
    telemetry.uploadPhotoClicked(telemetryCategory);
    openFilePicker();
  });

  // Memoize the sorted media collection
  const sortedMediaCollection = useMemo(() => {
    const mediaCollection = eventMediaResponse?.eventByName?.media;
    const mediaPhotoCollection: MediaCollectionPhoto[] = (mediaCollection?.filter(m => m.__typename === 'MediaPhoto') || []) as MediaCollectionPhoto[];
    if (!uploadedPhotoIds.length) {
      return mediaPhotoCollection;
    }
    return [...mediaPhotoCollection].sort((a, b) => {
      const aIndex = uploadedPhotoIds.indexOf(a.id);
      const bIndex = uploadedPhotoIds.indexOf(b.id);

      // If both are in uploadedPhotoIds, sort by their order in uploadedPhotoIds
      if (aIndex !== -1 && bIndex !== -1) {
        return aIndex - bIndex;
      }

      // If only one is in uploadedPhotoIds, prioritize it
      if (aIndex !== -1) return -1;
      if (bIndex !== -1) return 1;

      // If neither is in uploadedPhotoIds, maintain original order
      return 0;
    });
  }, [eventMediaResponse?.eventByName?.media, uploadedPhotoIds]);

  const handleClose = useEventCallback(() => {
    telemetry.closePhotoPicker(telemetryCategory);
    onClose();
    setTimeout(() => {
      setSelectedPhotoId(null);
      setUploadedPhotoIds([]);
    }, 500);
  });

  const handleSave = useEventCallback(() => {
    if (sortedMediaCollection.length === 0) {
      handleFilePickerOpen();
    } else {
      const selectedPhoto = sortedMediaCollection.find(m => m.id === selectedPhotoId);
      if (selectedPhoto) {
        telemetry.savePhotoClicked(telemetryCategory, selectedPhoto.photo.id);
        onSavePhoto(selectedPhoto);
        setTimeout(() => {
          setSelectedPhotoId(null);
          setUploadedPhotoIds([]);
        }, 500);
      }
    }
  });

  const onPhotoSelect = useEventCallback((id: string) => {
    setSelectedPhotoId(id);
  });

  return (
    <ResponsiveDialog
      isOpen={isOpen}
      onClose={handleClose}
      hideDivider={true}
      removeBodyPadding
      hasCloseButton={false}
      disableCloseOnOutsideClick={true}
      drawerOverrides={{
        ...drawerOverrides,
        Content: {
          props: {
            ...drawerOverrides.Content?.props,
            boxShadow: '0px -7px 16px -8px rgba(0, 0, 0, 0.3), 0px -10px 27px -5px rgba(44, 41, 37, 0.25)'
          }
        },
        Body: { props: { ...drawerOverrides.Body?.props, paddingY: 5, paddingX: 0, overflow: 'auto', maxHeight: '100vh' } },
        Footer: { props: { paddingX: 6, paddingY: 5 } }
      }}
      dialogOverrides={{
        Body: { props: { paddingY: 5, paddingX: 0, overflow: 'auto', maxHeight: 'calc(100vh - 160px)' } },
        Footer: { props: { paddingY: 5 } }
      }}
      disableFocusLock={true}
      renderFooter={() => (
        <Flex width="100%" alignItems="center" justifyContent="center">
          <ButtonV2
            size="lg"
            variant="solid"
            shape="rounded"
            intent="neutral"
            outline="none"
            minWidth={{ _: '100%', sm: pxToRem(231) }}
            onClick={handleSave}
            loading={isSavingPhoto}
            disabled={isSavingPhoto || isUploadingPhoto || (!selectedPhotoId && sortedMediaCollection.length > 0)}
          >
            {!loading && sortedMediaCollection.length === 0 ? photoPickerTrans.uploadPhotos() : photoPickerTrans.savePhoto()}
          </ButtonV2>
        </Flex>
      )}
    >
      <ScrollWrapper ref={scrollWrapperRef}>
        <Box width="100%" borderBottom="1px solid" borderColor="mono3" paddingBottom={5} paddingX={{ _: 6, sm: 7 }}>
          <Flex alignItems="center" height={8}>
            <TextV2 as="h3" typographyVariant="hed3" textAlign="left">
              {photoPickerTrans.chooseYourPhoto()}
            </TextV2>
            <CloseButton
              overrides={{
                Root: { props: { ...closeButtonOverrides.Root?.props, position: 'absolute', top: 5, right: 7, backgroundColor: 'white', zIndex: 10 } },
                Icon: { props: { ...closeButtonOverrides.Icon?.props } }
              }}
              onClick={handleClose}
              aria-label={'close dialog'}
            />
          </Flex>
        </Box>
        <Flex flexDirection="column" padding={{ _: 6, sm: 7 }} width="100%" gap={{ _: 6, sm: 7 }}>
          <Flex
            width="100%"
            height={pxToRem(82)}
            justifyContent="center"
            alignItems="center"
            alignSelf="stretch"
            borderRadius={3}
            border="2px dashed"
            borderColor="mono4"
            backgroundColor="mono1"
          >
            <ButtonV2 size="lg" variant="outline" shape="rounded" startIcon={<Import size={24} />} intent="neutral" outline="none" onClick={handleFilePickerOpen}>
              {photoPickerTrans.uploadPhotos()}
            </ButtonV2>
          </Flex>

          <Flex flexDirection="column" gap={{ _: 5, sm: 4 }} alignSelf="stretch">
            <Flex flexDirection="column" gap={1}>
              <TextV2 typographyVariant="button2" color="mono14">
                {photoPickerTrans.allPhotos()}
              </TextV2>
              {sortedMediaCollection.length === 0 && (
                <TextV2 typographyVariant="label1" color="mono14">
                  {photoPickerTrans.allYourPhotosWillAppearHere()}
                </TextV2>
              )}
            </Flex>
            {(loading || sortedMediaCollection.length === 0) && !isUploadingPhoto ? (
              <ZeroState />
            ) : (
              <PhotoRenderer mediaCollection={sortedMediaCollection} selectedId={selectedPhotoId} onSelect={onPhotoSelect} showPhotoUploadingPlaceholder={isUploadingPhoto} />
            )}
          </Flex>
        </Flex>
      </ScrollWrapper>
    </ResponsiveDialog>
  );
};

export const PhotoPicker: FC<PhotoPickerProps> = props => {
  return (
    <PhotoPickerTelemetryProvider>
      <PhotoPickerBase {...props} />
    </PhotoPickerTelemetryProvider>
  );
};

PhotoPicker.displayName = 'PhotoPicker';
