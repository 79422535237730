import { TravelCurrencyCode, useAccommodationAnalyticsCompareBestOfferPricesQuery } from '@graphql/generated';
import { useFeatureValue } from '@shared/core/featureFlags';
import { calculateStayDates } from '@shared/utils/dateHelpers';
import { useEventInfo } from '@shared/utils/eventInfo';
import { isInIframe } from '@shared/utils/isInIframe';
import { addAction, addError } from '@shared/utils/logger';
import { useEffect, useState } from 'react';

interface AccommodationsAnalyticsCompareArgs {
  accommodationPlaceId: string;
  shouldSkip: boolean;
}

/**
 * Price Analytics - Call API from FE to fetch price analytics and send it as part of telemetry.
 *
 * @param {Object} Props - The properties required for fetching price analytics.
 * @param {string} Props.accommodationPlaceId - The unique identifier for the accommodation place.
 * @param {boolean} Props.shouldSkip - Flag indicating whether to skip fetching analytics for custom room blocks or non-hotel accommodations.
 *
 * @returns {void}
 */

export const useAccommodationsAnalyticsCompare = ({ accommodationPlaceId, shouldSkip }: AccommodationsAnalyticsCompareArgs) => {
  const [isCalledFirstTime, setIsCalledFirstTime] = useState(false);

  const { value: accommodationsAnalyticsCompareValue, loading: accommodationsAnalyticsCompareLoading } = useFeatureValue('accommodationsAnalyticsCompare');
  const accommodationsAnalyticsCompareEnabled = accommodationsAnalyticsCompareValue === 'on';

  const isPreviewing = isInIframe();

  const { eventInfo, loading: loadingEventInfo } = useEventInfo();
  const eventId = eventInfo?.eventId;
  const dateString = eventInfo?.finalizedDate?.dateString;
  const { checkIn, checkOut } = calculateStayDates(dateString);
  const checkInDate = checkIn.dateString;
  const checkOutDate = checkOut.dateString;

  /**
   * Skip to call api.
   * 1. Is already called once
   * 2. While preview in accommodations editor
   * 3. If have customUrl or group book reserved item(from cx team)
   * 4. Remote flag loading or flag not enabled
   */
  const skip = isPreviewing || isCalledFirstTime || shouldSkip || accommodationsAnalyticsCompareLoading || !accommodationsAnalyticsCompareEnabled || loadingEventInfo;

  const { data, loading, error } = useAccommodationAnalyticsCompareBestOfferPricesQuery({
    batchMode: 'slow',
    variables: {
      payload: {
        metadata: {
          eventId
        },
        currencyCode: TravelCurrencyCode.USD,
        dates: {
          checkIn: {
            high: checkInDate,
            low: checkInDate
          },
          checkOut: {
            high: checkOutDate,
            low: checkOutDate!
          }
        },
        occupancies: {
          occupancies: [
            {
              adults: 1,
              childrenAges: []
            }
          ],
          unitCount: 1
        },
        propertyIdentifier: { accommodationPlaceId }
      }
    },
    skip
  });

  useEffect(() => {
    if (error) {
      addError(error, { context: 'Failed AccommodationAnalyticsCompareBestOfferPrices' });
    }
  }, [error]);

  useEffect(() => {
    if (loading) return;
    if (data?.analyticsCompareBestOfferPrices) {
      setIsCalledFirstTime(true);
      addAction('accommodationAnalyticsCompareBestOfferPrices');
    }
  }, [loading, data]);
};
