import React, { FC } from 'react';
import { StyledRemoveSpinner } from './RotatingRingLoader.styles';

type RotatingRingLoaderProps = {
  width?: string;
  height?: string;
  borderWidth?: string;
  borderColor?: string;
  thumbColor?: string;
};

export const RotatingRingLoader: FC<RotatingRingLoaderProps> = props => {
  const { width = '30px', height = '30px', borderWidth = '3px', borderColor = '#c4c4c4', thumbColor = '#5f2698' } = props;

  return (
    <StyledRemoveSpinner width={width} height={height} borderWidth={borderWidth} borderColor={borderColor} thumbColor={thumbColor}>
      <div />
      <div />
      <div />
    </StyledRemoveSpinner>
  );
};
