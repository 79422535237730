import { useQueryParamHelper } from '@shared/core/queryString';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LinkV2, TextV2 } from '@withjoy/joykit';
import { StyledStatusBanner } from './StatusBanner.styles';
import { useHistory, useLocation } from '@react-router';
import { useFeatureValue } from '@shared/core/featureFlags';

type BannerPayload = {
  bannerKey: string;
  bannerText: string;
  closeBannerCTA?: string;
}[];

const isValidBanner = (payload: unknown): payload is BannerPayload[number] => {
  if (!payload) {
    return false;
  }

  if (!Object.hasOwn(payload, 'bannerKey')) {
    return false;
  }

  if (!Object.hasOwn(payload, 'bannerText')) {
    return false;
  }

  return true;
};

const isValidBannerData = (payload: unknown): payload is BannerPayload => {
  if (!payload) {
    return false;
  }

  if (!Array.isArray(payload)) {
    return false;
  }

  // If any objects don't have required fields, we are invalid.
  if ((payload as BannerPayload).some(banner => !isValidBanner(banner))) {
    return false;
  }

  return true;
};

export const useGetStatusBannerData = () => {
  const params = useQueryParamHelper();
  const bannerKey = params.getValue('bannerKey');
  const { value: bannerEnabled, payload: bannerPayload } = useFeatureValue('websiteBannerMapping', { skip: !bannerKey });

  if (bannerEnabled !== 'on' || !isValidBannerData(bannerPayload)) {
    return null;
  }

  return bannerPayload.find(banner => banner.bannerKey === bannerKey) ?? null;
};

const useSetBannerState = () => {
  const location = useLocation();
  const history = useHistory();

  const setBannerState = useCallback(
    (newState: string | undefined) => {
      const query = new URLSearchParams(location.search);
      if (newState && newState !== '') {
        query.set('bannerKey', newState);
      } else {
        query.delete('bannerKey');
      }
      const newQuery = query.toString();
      history.replace({ search: newQuery });
    },
    [history, location]
  );

  return { setBannerState };
};

export const StatusBanner = () => {
  const bannerData = useGetStatusBannerData();
  const [isOpen, setIsOpen] = useState(!!bannerData);
  const hasCheckedData = useRef(false);
  const { setBannerState } = useSetBannerState();

  useEffect(() => {
    if (bannerData && !hasCheckedData.current) {
      setIsOpen(true);
      hasCheckedData.current = true;
    }
  }, [bannerData]);

  const closeBanner = () => {
    setIsOpen(false);
    setBannerState('');
  };

  if (!isOpen || !bannerData) {
    return null;
  }

  return (
    <StyledStatusBanner playConfetti={true}>
      <TextV2 typographyVariant="button1">{bannerData.bannerText}</TextV2>
      <LinkV2 color="black" onClick={closeBanner} typographyVariant="body1">
        {bannerData.closeBannerCTA ?? 'Done'}
      </LinkV2>
    </StyledStatusBanner>
  );
};
