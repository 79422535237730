import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { FixedSizeGrid, GridItemKeySelector } from 'react-window';
import { VirtualScrollOuterElement } from './VirtualScrollOuterElement';
import { useWindowResizeListener } from './hooks/useWindowResizeListener';
import { useVirtualScrollWrapperContext } from './context';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';

export interface GridChildComponentProps<TItemData> {
  columnIndex: number;
  rowIndex: number;
  style: CSSProperties;
  isScrolling?: boolean;
  data: TItemData;
}
interface VirtualFixedGridProps<TItemData> {
  readonly children: React.ComponentType<GridChildComponentProps<TItemData>>;
  readonly columnCount: number;
  readonly rowCount: number;
  readonly rowHeight: (params: { containerWidth: number; containerHeight: number }) => number;
  /**
   * @deprecated Use `overscanRowCount` and `overscanColumnCount` instead.
   */
  readonly overscanCount?: number;
  readonly overscanRowCount?: number | undefined;
  readonly overscanColumnCount?: number | undefined;
  readonly itemKey?: GridItemKeySelector | undefined;

  readonly itemData: TItemData;
  readonly className?: string;
  readonly style?: React.CSSProperties;
}
export const VirtualFixedGrid = <TItemData extends unknown>({ rowHeight: itemSizeCallback, columnCount, rowCount, ...rest }: VirtualFixedGridProps<TItemData>) => {
  const { virtualScrollWrapperElement } = useVirtualScrollWrapperContext();
  const listOuterElementRef = useRef<HTMLElement | null>(null);
  const [columnnWidth, setColumnWidth] = useState(0);
  const [listHeight, setListHeight] = useState(0);
  const [listWidth, setListWidth] = useState(0);
  const [itemSize, setItemSize] = useState(0);

  const recalculateSizes = useEventCallback(() => {
    const listOuterElement = listOuterElementRef.current;

    if (virtualScrollWrapperElement && listOuterElement) {
      const nextItemSize = itemSizeCallback({ containerWidth: listOuterElement.offsetWidth, containerHeight: virtualScrollWrapperElement.clientHeight });
      setItemSize(nextItemSize);
      setColumnWidth(listOuterElement.offsetWidth / columnCount);
      setListHeight(virtualScrollWrapperElement.clientHeight);
      setListWidth(listOuterElement.offsetWidth);
    }
  });

  useWindowResizeListener(() => {
    recalculateSizes();
  });

  useEffect(() => {
    recalculateSizes();
  }, [recalculateSizes, virtualScrollWrapperElement]);

  return (
    <FixedSizeGrid
      width={listWidth}
      height={listHeight}
      rowHeight={itemSize}
      columnWidth={columnnWidth}
      rowCount={rowCount}
      outerElementType={VirtualScrollOuterElement}
      outerRef={listOuterElementRef}
      columnCount={columnCount}
      {...rest}
    />
  );
};
