export const CARD_ASPECT_RATIO = {
  _: '1.27',
  sm: '1.11'
};

export const GUTTER_SIZE = {
  _: 16,
  sm: 12
};

export const MEDIA_SKELETON = 'media-skeleton';
