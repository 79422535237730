import { useCardsRouterContext } from '@apps/card/Card.routes';
import { Flex, Box, TextV2, ButtonV2 } from '@withjoy/joykit';
import React from 'react';

interface EmptyStateProps {
  imageUrl: string;
  title: string;
  message: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ imageUrl, title, message }) => {
  const { goToShop } = useCardsRouterContext();

  return (
    <Flex flex="1" position="relative" justifyContent="center" alignItems="center" flexDirection={['column', 'row']} gap={['8px', '16px']} maxHeight={['unset', '350px']}>
      <Box width={{ xs: '200px', sm: '250px', _: '300px' }}>
        <Box as="img" src={imageUrl} width="100%"></Box>
      </Box>
      <Flex flexDirection="row" alignItems="center">
        <Flex flexDirection="column" alignItems="center" maxWidth="525px" margin={6} background="white" paddingX={8} paddingY={[0, 6]} zIndex={1}>
          <TextV2 typographyVariant="hed5" marginBottom={5} width="100%" textAlign={['center', 'left']}>
            {title}
          </TextV2>
          <TextV2 typographyVariant="body2" marginBottom={8} textAlign={['center', 'left']}>
            {message}
          </TextV2>
          <ButtonV2 intent="neutral" shape="rounded" width="100%" maxWidth="350px" onClick={() => goToShop('wedding')}>
            Browse Designs
          </ButtonV2>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyState;
