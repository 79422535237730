import React from 'react';
import { Flex, TextV2, IconV2Props, Divider } from '@withjoy/joykit';
import { MenuSectionProps } from './MenuSection';
import { TypographyVariant, pxToRem, Colors } from '@withjoy/joykit/theme';
import { StyledItemWrapper } from './BurgerMenu.styles';
import { NewBadge, NotificationPill } from '@apps/admin/routes/Dashboard/components/DashboardContentV2/PrimaryDashboardContent/ProductRowItem/ProductRowItem.styles';
import { ChevronRight, Moon } from '@withjoy/joykit/icons';
import { Link } from '@shared/core';
import { useEventCallback } from '@shared/utils/hooks/useEventCallback';
import { TextEllipsisWrapper } from '@shared/utils/formatting/textEllipsis';

export type MenuItemProps = {
  icon?: React.ComponentType<IconV2Props>;
  text: string;
  badge?: React.FC<IconV2Props>;
  newPillText?: string;
  notificationPill?: string;
  subItems?: Omit<MenuSectionProps, 'openSubmenu' | 'onClose'>[];
  onClick?: () => void;
  topDivider?: boolean;
  bottomDivider?: boolean;
  typographyVariant?: TypographyVariant;
  fontWeight?: number;
  color?: keyof Colors;
  columnGap?: number;
  iconSize?: 'sm' | 'md';
  expands?: boolean;
  isDisabled?: boolean;
  path?: string;
  pathIsInJoyWeb?: boolean;
  openSubmenu?: (items: Omit<MenuSectionProps, 'openSubmenu' | 'onClose'>[]) => void;
  onClose?: () => void;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  text,
  onClick,
  newPillText,
  topDivider,
  bottomDivider,
  typographyVariant = 'body1',
  fontWeight = 500,
  color = 'mono14',
  iconSize,
  columnGap = 8,
  expands,
  notificationPill,
  isDisabled,
  path,
  pathIsInJoyWeb,
  subItems,
  openSubmenu,
  onClose
}) => {
  const handleOnClick = useEventCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    if (subItems?.length) {
      openSubmenu?.(subItems!);
    } else {
      onClick?.();
      if (!(e.metaKey || e.ctrlKey)) onClose?.(); // To prevent close menu panel if clicked with cmd/ctr.
    }
  });
  const Icon = icon;
  const content = (
    <Flex paddingY={pxToRem(12)} alignItems={'center'} columnGap={pxToRem(columnGap)}>
      {Icon && <Icon size={iconSize || 'md'} />}
      <Flex justifyContent="flex-start" alignItems="center" gap={5} width="75%">
        <TextEllipsisWrapper>
          <TextV2 typographyVariant={typographyVariant} fontWeight={fontWeight} color={color} verticalAlign="middle">
            {text}
          </TextV2>
        </TextEllipsisWrapper>
        {newPillText && (
          <NewBadge color="#FFF" typographyVariant={'label3'}>
            {newPillText}
          </NewBadge>
        )}
      </Flex>
      {isDisabled && <Moon size={'sm'} position="absolute" right={3} />}
      {notificationPill && <NotificationPill typographyVariant={'label3'}>{notificationPill}</NotificationPill>}
      {expands && <ChevronRight position="absolute" right={0} />}
    </Flex>
  );
  return (
    <>
      {topDivider && <Divider marginY={pxToRem(16)} />}
      <Flex alignItems="center" onClick={handleOnClick} as={pathIsInJoyWeb ? Link : 'a'} href={path} to={path}>
        <StyledItemWrapper content={content} />
      </Flex>
      {bottomDivider && <Divider />}
    </>
  );
};
