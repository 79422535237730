import { styled } from '@withjoy/joykit';

export const Table = styled.table`
  overflow-x: scroll;
  text-align: left;
  font-size: 14px;
  border-spacing: 0;
  border: 1px solid black;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem 2rem 0.5rem 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
      border-right: 0;
    }
  }
`;
