import { Box, Flex, styled } from '@withjoy/joykit';
import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { createPortal } from 'react-dom';

export const StyledStatusBanner = (props: { playConfetti?: boolean; children: ReactNode }) => {
  const { children, playConfetti } = props;
  const toastRef = useRef<HTMLDivElement>(null);
  const [confettiActive, setConfettiActive] = useState(playConfetti);
  const [confettiPosition, setConfettiPosition] = useState({ x: 0, y: 0 });
  const [ready, setReady] = useState(false);

  useLayoutEffect(() => {
    if (toastRef.current) {
      const position = toastRef.current.getBoundingClientRect();

      setReady(true);
      setConfettiPosition({
        x: position.right - position.width / 2,
        y: position.y
      });
    }
  }, []);

  const canShowConfetti = typeof window === 'object' && ready;

  return (
    <>
      <StatusBannerContainer>
        {confettiActive &&
          canShowConfetti &&
          createPortal(
            <Confetti
              numberOfPieces={100}
              recycle={false}
              run={confettiActive}
              confettiSource={{
                x: confettiPosition.x,
                y: confettiPosition.y,
                w: 10,
                h: 10
              }}
              gravity={0.09}
              onConfettiComplete={() => setConfettiActive(false)}
              initialVelocityX={5}
              initialVelocityY={10}
              style={{ zIndex: 1000 }}
            />,
            document.body
          )}

        <StatusBannerInner ref={toastRef}>{children}</StatusBannerInner>
      </StatusBannerContainer>
    </>
  );
};

const StatusBannerContainer = styled(Flex)`
  z-index: ${props => Number(props.theme.zIndices.banner) - 2};
  position: fixed;

  width: 100vw;
  height: fit-content;

  padding: 0px;
  margin: 0px;

  align-items: center;
  justify-content: center;

  top: 40px;
  left: 0px;
`;

const StatusBannerInner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  z-index: ${props => props.theme.zIndices.banner};

  padding: 16px 24px 16px 24px;

  width: 100%;

  max-width: 750px;
  margin: 0px 14px 0px 14px;

  border-radius: 12px;

  background-color: white;
  box-shadow: 0px 10px 60px -20px rgb(44, 41, 37), 0px 9px 36px -30px rgb(0, 0, 0);
`;
