import React from 'react';
import { Box, Flex, TextV2 } from '@withjoy/joykit';
import { pxToRem } from '@withjoy/joykit/theme';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import { OverrideTypography } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.types';
import { Category } from '@graphql/generated';
import { useScaleGuestSiteFontSize } from '@apps/guest/components/GuestSiteTypographyOverride/hooks/useScaleGuestSiteFontSize';
import { useFeatureValue } from '@shared/core/featureFlags';

const CUSTOM_PAGE_EMPTY_STATE_IMAGE = 'https://withjoy.com/assets/public/custom-page/editor-empty-state.png';

interface CustomComponentEmptyStateProps {
  title: string;
  subTitle: string;
  isBrannanLayout?: boolean;
}

const typographyOverride: OverrideTypography = [
  { variant: 'hed1', category: Category.HEADING },
  { variant: 'body2', category: Category.PARAGRAPH }
];

export const CustomComponentEmptyState: React.FC<CustomComponentEmptyStateProps> = ({ title, subTitle, isBrannanLayout }) => {
  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const { scaleFontSize } = useScaleGuestSiteFontSize();

  return (
    <Flex flexDirection="column" alignItems="center" paddingX={6} paddingTop={pxToRem(44)} paddingBottom={isBrannanLayout ? 0 : pxToRem(44)} marginTop={isBrannanLayout ? 0 : 8}>
      <Flex flexDirection="column" alignItems="center" paddingY={pxToRem(44)} rowGap={pxToRem(44)} maxWidth={pxToRem(482)} marginX="auto">
        <Box width={pxToRem(170)}>
          <img src={CUSTOM_PAGE_EMPTY_STATE_IMAGE} alt={title} width="100%" />
        </Box>
        <GuestSiteTypographyOverride override={typographyOverride}>
          <Flex flexDirection="column" rowGap={3} width="100%">
            <TextV2
              typographyVariant="hed1"
              {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })}
              fontSize={scaleFontSize(pxToRem(26), Category.HEADING)}
              fontWeight={600}
              textAlign="center"
            >
              {title}
            </TextV2>
            <TextV2 typographyVariant="body2" {...(!fontPackExperimentEnabled && { fontFamily: 'Inter UI' })} maxWidth={pxToRem(314)} lineHeight={pxToRem(24)} textAlign="center">
              {subTitle}
            </TextV2>
          </Flex>
        </GuestSiteTypographyOverride>
      </Flex>
    </Flex>
  );
};
