import { Flex, styled, StyleSystemProps } from '@withjoy/joykit';

export const GraphicAccentContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const GraphicAccentImg = styled.img<{ pixelDensity: number }>`
  max-width: ${props => `${100 / Math.max(props.pixelDensity, 1)}%`};
  max-height: ${props => `${100 / Math.max(props.pixelDensity, 1)}%`};
  object-fit: contain;
`;

const container: StyleSystemProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '5rem', // based on 16px = 1 rem
  width: 'auto',
  maxWidth: '100%',
  overflow: 'visible' // make no assumption about the size of the <img />
};

export const styles = {
  container
};
