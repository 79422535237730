import React, { FC, useEffect, useMemo, useState } from 'react';
import { JoyKitThemeProvider, Theme } from '@withjoy/joykit';
import { layoutConfigs } from '@apps/guest/packages/layout-engine/layouts/layout.constants';
import { GuestSiteTypographyOverrideProps } from './GuestSiteTypographyOverride.types';
import { useFontPackProvider } from '@apps/guest/packages/layout-engine/layouts/FontPackProvider/FontPackProvider';
import { useFeatureValue } from '@shared/core/featureFlags';
import { toUnitless } from '@shared/joykit/packages/core/common/themes/shared/typography';

export const GuestSiteTypographyOverride: FC<GuestSiteTypographyOverrideProps> = props => {
  const { override, defaultFontFamily, children } = props;

  const { eventDesign, hasFont, loadedFonts } = useFontPackProvider();

  const layoutConfig = useMemo(() => (eventDesign?.websiteLayout.layoutType === 'brannan' ? layoutConfigs.brannan : layoutConfigs.aloha), [eventDesign?.websiteLayout.layoutType]);
  const [theme, setTheme] = useState<Theme>(eventDesign ? layoutConfig.configureThemeOverrides(eventDesign, layoutConfig.theme) : layoutConfig.theme);

  const fontPackExperimentEnabled = useFeatureValue('fontPackExperiment').value === 'treatment';

  const categories = useMemo(() => override.map(o => o.category), [override]);

  const themeValue = useMemo(() => {
    if (eventDesign && layoutConfig && fontPackExperimentEnabled) {
      const currentTheme = layoutConfig.configureThemeOverrides(eventDesign, layoutConfig.theme);
      const fontsToUpdate =
        override.map(o => {
          const font = eventDesign?.fonts?.find(font => font.category === o.category);
          return { fontToUpdate: font, variant: o.variant };
        }) || [];

      const updatedTheme: Theme = {
        ...currentTheme,
        typography: {
          ...currentTheme.typography,
          variants: {
            ...currentTheme.typography.variants,
            ...fontsToUpdate.reduce(
              (acc, { variant, fontToUpdate }) => ({
                ...acc,
                [variant]: {
                  ...currentTheme.typography.variants[variant],
                  fontFamily: fontToUpdate?.font?.fontFamily || defaultFontFamily || currentTheme.typography.variants[variant].fontFamily,
                  // convert the current font size to preferred font size percentage
                  fontSize: fontToUpdate?.sizePercent
                    ? `${toUnitless(currentTheme.typography.variants[variant].fontSize) * (fontToUpdate.sizePercent / 100)}rem`
                    : currentTheme.typography.variants[variant].fontSize
                }
              }),
              {}
            )
          }
        }
      };
      return updatedTheme;
    }
    return layoutConfig.theme;
  }, [defaultFontFamily, eventDesign, fontPackExperimentEnabled, layoutConfig, override]);

  useEffect(() => {
    if (eventDesign && fontPackExperimentEnabled) {
      const fontToLoad = eventDesign.fonts
        ?.filter(font => categories.includes(font.category))
        .map(font => font.font?.fontFamily)
        .filter(x => x) as string[];
      const getFontsUnloaded = fontToLoad.filter((font: string) => !hasFont(font));
      if (!getFontsUnloaded.length) {
        setTheme(themeValue);
      }
    }
  }, [categories, eventDesign, fontPackExperimentEnabled, hasFont, loadedFonts, themeValue]);

  return fontPackExperimentEnabled && eventDesign?.fonts ? <JoyKitThemeProvider theme={theme}>{children}</JoyKitThemeProvider> : <>{children}</>;
};
