import React, { useCallback } from 'react';
import { ButtonV2, Divider, LinkV2, styled, TextV2 } from '@withjoy/joykit';
import { useHistory } from '@shared/core';
import {
  EventTravelListFragment,
  EventTravelNoteFragment,
  FerryFragment,
  FlightFragment,
  HotelFragment,
  RentalCarFragment,
  RentalHomeFragment,
  ShuttleFragment,
  TaxiFragment,
  TentFragment,
  TrainFragment,
  TravelType,
  EventTravelBookingTypeFragment
} from '@graphql/generated';
import {
  Assistance as TravelBookingAssistantIcon,
  CampFire as TravelCampingIcon,
  Car as TravelCarIcon,
  Plane as TravelFlightIcon,
  Sign as TravelGuideIcon,
  HotelProduct as TravelHotelIcon,
  House as TravelHouseIcon,
  Note as TravelNoteIcon,
  Ferry as TravelFerryIcon,
  Shuttle as TravelShuttleIcon,
  Taxi as TravelTaxiIcon,
  Train as TravelTrainIcon,
  Calendar,
  Clock,
  DiscountTag
} from '@withjoy/joykit/icons';
import createSvgIcon from '@shared/joykit/packages/core/icons/createSvgIcon';
import { InfoBlock } from '../../primitives/InfoBlock';
import { DiscountCodeBlock, LinkBlock, ListItemBlock, MapBlock, PhoneBlock } from '../../components/ItineraryBlocks';
import { PrettyLink } from '../../components/PrettyLink';
import { Flex } from '@withjoy/joykit';
import { format, utcToZonedTime } from 'date-fns-tz';
import Booking from './components/Booking';
import { prettyUrl } from '@shared/utils/formatting/prettyUrl/prettyUrl.formatter';
import { useTravelListTelemetry } from './TravelList.telemetry';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { HertzLogo } from '@assets/icons';
import { useTranslation } from '@shared/core';
import { SafeLink } from '@shared/components/SafeLink';
import { GuestSiteTypographyOverride } from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride';
import {
  body2ToParagraphOverride,
  body3ToParagraphOverride,
  label3ToCaptionOverride
} from '@apps/guest/components/GuestSiteTypographyOverride/GuestSiteTypographyOverride.constants';
import { withWindow } from '@shared/utils/withWindow';
import { useColorPaletteProvider } from '../../layouts/ColorPaletteProvider/ColorPaletteProvider';

export const HERTZ_LINKS = ['https://withjoy.com/partner/hertz', 'https://www.hertz.com/rentacar/rental-car-deals/joy'];

export const travelTypeToIcon: Readonly<Record<TravelType, ReturnType<typeof createSvgIcon>>> = {
  [TravelType.booking]: TravelBookingAssistantIcon,
  [TravelType.list]: TravelGuideIcon,
  [TravelType.flight]: TravelFlightIcon,
  [TravelType.ferry]: TravelFerryIcon,
  [TravelType.hotel]: TravelHotelIcon,
  [TravelType.note]: TravelNoteIcon,
  [TravelType.rentalCar]: TravelCarIcon,
  [TravelType.rentalHome]: TravelHouseIcon,
  [TravelType.shuttle]: TravelShuttleIcon,
  [TravelType.taxi]: TravelTaxiIcon,
  [TravelType.tent]: TravelCampingIcon,
  [TravelType.train]: TravelTrainIcon
};

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Travel Item Blocks
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

type TravelComponentProps<T> = Readonly<{ travelItem: T; eventId?: string }>;

type HotelFragmentWithUrlTitleProps = HotelFragment & {
  url: string;
  urlTitle: string;
  isBrannan: boolean;
};

type TravelBlockBaseProps = {
  itemTitle?: Maybe<string>;
  itemType?: Maybe<string>;
  notes?: Maybe<string>;
  address?: Maybe<string>;
  discountCode?: Maybe<string>;
  telephone?: Maybe<string>;
  url?: Maybe<string>;
  urlTitle?: Maybe<string>;
  isBrannan?: boolean;
  children?: React.ReactNode;
};

export const TravelBlockBase: React.FC<TravelBlockBaseProps> = props => {
  const telemetry = useTravelListTelemetry();
  const history = useHistory();
  const { address, children, discountCode, notes, telephone, url, urlTitle, itemTitle, itemType, isBrannan, ...restProps } = props;
  const isLinkV2 = url && urlTitle;
  const isBlockLink = url && !urlTitle;

  const handleClickURL = useCallback(() => {
    telemetry.travelURLClicked(itemType || '', itemTitle || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickDiscountCode = useCallback(() => {
    telemetry.travelDiscountCodeClicked(itemType || '', itemTitle || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickTelphone = useCallback(() => {
    telemetry.travelTelephoneClicked(itemType || '', itemTitle || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAddress = useCallback(() => {
    telemetry.travelAddressClicked(itemType || '', itemTitle || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GuestSiteTypographyOverride override={body3ToParagraphOverride}>
      <InfoBlock.Group {...restProps}>
        {(address || notes || telephone || url) && (
          <InfoBlock.Group>
            {notes && (
              <Flex width="100%" alignItems={'flex-end'}>
                <TextV2 wordBreak="break-word" tagName="div" typographyVariant={'body3'}>
                  <PrettyLink prettyUrlOptions={{ www: false }}>{notes} </PrettyLink>
                </TextV2>
              </Flex>
            )}

            {discountCode && <DiscountCodeBlock handleClick={handleClickDiscountCode}>{discountCode}</DiscountCodeBlock>}
            {isBlockLink && (
              <LinkBlock handleClick={handleClickURL} url={url} ensureProtocol={true}>
                {prettyUrl(url, { www: false })}
              </LinkBlock>
            )}
            {isLinkV2 && (
              <LinkV2
                wordBreak="break-word"
                as={'div'}
                cursor={'pointer'}
                href={url as string}
                onClick={() => {
                  if (isBrannan) {
                    withWindow(global => {
                      global.scrollTo(0, 0);
                    });
                  }
                  handleClickURL();
                  history.push(url as string);
                }}
                textAlign={!isBrannan ? 'center' : 'left'}
                isExternal={false}
              >
                <InfoBlock>{urlTitle}</InfoBlock>
              </LinkV2>
            )}
            {telephone && <PhoneBlock handleClick={handleClickTelphone} phoneNumber={telephone} />}
            {address && <MapBlock handleClick={handleClickAddress} location={address} showMap={false} />}
          </InfoBlock.Group>
        )}
        {children}
      </InfoBlock.Group>
    </GuestSiteTypographyOverride>
  );
};

type TravelHertzWidgetProps = {
  itemTitle?: Maybe<string>;
  itemType?: Maybe<string>;
  notes?: Maybe<string>;
  url?: Maybe<string>;
};
const TravelHertzWidget: React.FC<TravelHertzWidgetProps> = props => {
  const { itemTitle, itemType, notes, url } = props;
  const telemetry = useTravelListTelemetry();
  const { t2 } = useTranslation('guestSiteTravel');
  const { offerTitle, ctaLabel, providedBy } = t2('hertzWidget');

  const handleClickURL = useCallback(() => {
    telemetry.travelURLClicked(itemType || '', itemTitle || '');
    if (url) {
      telemetry.travelHertzWidgetClicked(itemType || '', itemTitle || '', url, true);
      window.open(url, '_blank');
    }
  }, [itemTitle, itemType, telemetry, url]);

  return (
    <Flex flexDirection="column" borderRadius={4} backgroundColor="white" border="1px solid" borderColor="mono3" overflow="hidden" onClick={handleClickURL} cursor="pointer">
      <Flex backgroundColor="mono14" width="100%" paddingX={6} paddingY={4} justifyContent="center" alignItems="center" gap={3}>
        <DiscountTag size={24} color="white" />
        <GuestSiteTypographyOverride override={label3ToCaptionOverride}>
          <TextV2 typographyVariant="label3" color="white">
            {offerTitle}
          </TextV2>
        </GuestSiteTypographyOverride>
      </Flex>
      <Flex flexDirection="column" paddingX={6} paddingY={7} gap={7}>
        {notes && (
          <TextV2 typographyVariant="body3" color="mono14">
            {notes}
          </TextV2>
        )}
        <Flex flexDirection="column" gap={5}>
          <ButtonV2 startIcon={SearchIcon}>{ctaLabel}</ButtonV2>
          <Flex flexDirection="row" justifyContent="center" alignItems="flex-end" gap={1}>
            <TextV2 typographyVariant="label2" color="mono8">
              {providedBy}
            </TextV2>
            <HertzLogo />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelFerry: React.FC<TravelComponentProps<FerryFragment>> = ({ travelItem, ...restProps }) => {
  return (
    <TravelBlockBase
      itemTitle={travelItem.name}
      itemType="TravelFerry"
      notes={travelItem.notes}
      url={travelItem.url}
      telephone={travelItem.telephone}
      address={travelItem.address}
      {...restProps}
    />
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelFlight: React.FC<TravelComponentProps<FlightFragment>> = ({ travelItem, ...restProps }) => {
  return <TravelBlockBase itemTitle={travelItem.name} itemType="TravelFlight" discountCode={travelItem.code} notes={travelItem.notes} url={travelItem.url} {...restProps} />;
};

//////////////////////////////////////////////////////////////////////////////////

const TravelHotel: React.FC<TravelComponentProps<HotelFragmentWithUrlTitleProps>> = ({ travelItem, ...restProps }) => {
  return (
    <TravelBlockBase
      itemTitle={travelItem.name}
      itemType="TravelHotel"
      address={travelItem.address}
      notes={travelItem.notes}
      telephone={travelItem.telephone}
      url={travelItem.url}
      urlTitle={travelItem.urlTitle}
      isBrannan={travelItem.isBrannan}
      {...restProps}
    />
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelList: React.FC<TravelComponentProps<EventTravelListFragment>> = ({ travelItem, eventId, ...restProps }) => {
  const telemetry = useTravelListTelemetry();

  const handleClickURL = useCallback(
    url => {
      telemetry.travelURLClicked('TravelList', travelItem.name, url);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [travelItem]
  );

  if (!travelItem.items.length) {
    return null;
  }

  return (
    <TravelBlockBase itemType="TravelList" itemTitle={travelItem.name} {...restProps}>
      <InfoBlock.Group>
        {travelItem.items.map(({ id, title, url }) => {
          if (!title && !url) {
            return null;
          }

          const content = title || url;
          return (
            <ListItemBlock key={id} data-testid={id}>
              {url ? (
                <SafeLink wordBreak="break-word" onClick={() => handleClickURL(url)} isExternal={true} href={url} display="block" ensureProtocol={true}>
                  {content}
                </SafeLink>
              ) : (
                <PrettyLink tagName="span">{content}</PrettyLink>
              )}
            </ListItemBlock>
          );
        })}
      </InfoBlock.Group>
    </TravelBlockBase>
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelNote: React.FC<TravelComponentProps<EventTravelNoteFragment>> = ({ travelItem }) => {
  return <TravelBlockBase itemTitle={travelItem.name} itemType="TravelNote" notes={travelItem.notes} url={travelItem.url} />;
};

//////////////////////////////////////////////////////////////////////////////////

const TravelRentalCar: React.FC<TravelComponentProps<RentalCarFragment>> = props => {
  const { notes, address, telephone, url, name } = props.travelItem;
  const isHertzLink = HERTZ_LINKS.includes(url || '');

  return isHertzLink ? (
    <TravelHertzWidget itemTitle={name} itemType="TravelRentalCar" notes={notes} url={url} />
  ) : (
    <TravelBlockBase itemTitle={name} itemType="TravelRentalCar" address={address} notes={notes} telephone={telephone} url={url} />
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelRentalHome: React.FC<TravelComponentProps<RentalHomeFragment>> = props => {
  const { notes, address, telephone, url, name } = props.travelItem;
  return <TravelBlockBase itemTitle={name} itemType="TravelHotel" address={address} notes={notes} telephone={telephone} url={url} />;
};

//////////////////////////////////////////////////////////////////////////////////

export const formatShuttleDuration = (startTime: string, endTime: Maybe<string>) => {
  return [startTime, endTime].filter(x => !!x).join(' - ');
};

const LowercaseText = styled(TextV2)`
  text-transform: lowercase;
`;

const TravelShuttle: React.FC<TravelComponentProps<ShuttleFragment>> = React.memo(props => {
  const telemetry = useTravelListTelemetry();
  const { notes, items, name } = props.travelItem;
  const itemCount = items.length;

  const { textColor } = useColorPaletteProvider();
  const dividerBorderColor = textColor ? { borderColor: textColor } : {};

  const handleClickURL = useCallback(
    (url: string) => {
      telemetry.travelURLClicked('TravelShuttle', name, url);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name]
  );

  return (
    <TravelBlockBase notes={notes}>
      <InfoBlock.Group>
        {/* {name && <Text>{name}</Text>} */}
        {items.map((item, index) => {
          const { id, startTime, endTime, note, url } = item;
          if (!startTime) {
            return null;
          }

          return (
            <GuestSiteTypographyOverride key={id} override={body2ToParagraphOverride}>
              <InfoBlock.Group data-testid={`shuttle-item-${id}`}>
                <Flex alignItems="center">
                  <Calendar />
                  <TextV2 wordBreak="break-word" data-testid="shuttle-date" marginLeft={4}>
                    {format(utcToZonedTime(startTime.milliseconds, startTime.timezone || 'America/Los_Angeles'), 'MMM d', {
                      timeZone: startTime.timezone || 'America/Los_Angeles'
                    })}
                  </TextV2>
                </Flex>

                <Flex alignItems="center">
                  <Clock />
                  <LowercaseText data-testid="shuttle-timeframe" marginLeft={4}>
                    {formatShuttleDuration(startTime.hostTimeString, endTime?.hostTimeString)}
                  </LowercaseText>
                </Flex>
                {note && (
                  <TextV2 wordBreak="break-word" tagName="div">
                    <PrettyLink>{note}</PrettyLink>
                  </TextV2>
                )}
                {url && (
                  <GuestSiteTypographyOverride key={id} override={body3ToParagraphOverride}>
                    <LinkBlock handleClick={() => handleClickURL(url)} url={url} ensureProtocol={true}>
                      {prettyUrl(url)}
                    </LinkBlock>
                  </GuestSiteTypographyOverride>
                )}
                {index + 1 < itemCount && <Divider {...dividerBorderColor} />}
              </InfoBlock.Group>
            </GuestSiteTypographyOverride>
          );
        })}
      </InfoBlock.Group>
    </TravelBlockBase>
  );
});

//////////////////////////////////////////////////////////////////////////////////

const TravelTent: React.FC<TravelComponentProps<TentFragment>> = ({ travelItem, ...restProps }) => {
  return (
    <TravelBlockBase
      itemTitle={travelItem.name}
      itemType="TravelRent"
      address={travelItem.address}
      notes={travelItem.notes}
      telephone={travelItem.telephone}
      url={travelItem.url}
      {...restProps}
    />
  );
};

//////////////////////////////////////////////////////////////////////////////////

const TravelTaxi: React.FC<TravelComponentProps<TaxiFragment>> = ({ travelItem, ...restProps }) => {
  return (
    <TravelBlockBase
      itemTitle={travelItem.name}
      itemType="TravelTaxi"
      address={travelItem.address}
      notes={travelItem.notes}
      telephone={travelItem.telephone}
      url={travelItem.url}
      {...restProps}
    />
  );
};
//////////////////////////////////////////////////////////////////////////////////

const TravelTrain: React.FC<TravelComponentProps<TrainFragment>> = ({ travelItem, ...restProps }) => {
  return (
    <TravelBlockBase
      itemTitle={travelItem.name}
      itemType="TravelTrain"
      address={travelItem.address}
      notes={travelItem.notes}
      telephone={travelItem.telephone}
      url={travelItem.url}
      {...restProps}
    />
  );
};

//////////////////////////////////////////////////////////////////////////////////

export const TravelBooking: React.FC<TravelComponentProps<EventTravelBookingTypeFragment>> = ({ travelItem, ...restProps }) => {
  const telemetry = useTravelListTelemetry();
  return (
    <Booking
      eventId={travelItem.id}
      bookingType={travelItem.bookingType}
      placeId={travelItem.placeId}
      lat={travelItem.latitude}
      lng={travelItem.longitude}
      address={travelItem.address}
      timezone={travelItem.timezone}
      startTimeMs={travelItem.startTime?.milliseconds}
      endTimeMs={travelItem.endTime?.milliseconds}
      onProviderClicked={telemetry.bookingAssistantSearchProviderClicked}
      {...restProps}
    />
  );
};

//////////////////////////////////////////////////////////////////////////////////

// I don't know how to automatically infer the travelItem type
export const travelTypeToComponent = {
  // TODO: Add Booking Assistant after creating input + datepicker
  // [TravelType.booking]: TravelBooking,
  [TravelType.list]: TravelList,
  [TravelType.flight]: TravelFlight,
  [TravelType.ferry]: TravelFerry,
  [TravelType.hotel]: TravelHotel,
  [TravelType.note]: TravelNote,
  [TravelType.rentalCar]: TravelRentalCar,
  [TravelType.rentalHome]: TravelRentalHome,
  [TravelType.shuttle]: TravelShuttle,
  [TravelType.taxi]: TravelTaxi,
  [TravelType.tent]: TravelTent,
  [TravelType.train]: TravelTrain
};
