import { Box } from '@withjoy/joykit';
import { pxToRem, styled, TypographyVariant } from '@withjoy/joykit/theme';
import { createGlobalStyle } from '@withjoy/joykit';

interface RichTextEditorOverrideProps {
  Paragraph?: { props: { typographyVariant: TypographyVariant; fontFamily?: string } };
  Heading?: {
    props?: { fontFamily?: string };
    h1?: { props: { fontSize?: string } };
    h2?: { props: { fontSize?: string } };
    h3?: { props: { fontSize?: string } };
    h4?: { props: { fontSize?: string } };
    h5?: { props: { fontSize?: string } };
    h6?: { props: { fontSize?: string } };
  };
}

/* Reset all styles to default, to make effect of styles from rich text editor */
const RichTextEditorGlobalStyle = createGlobalStyle<{ isFocused?: boolean; overrides?: RichTextEditorOverrideProps; minHeight?: string }>`
  .jodit-popup-container h1, .jodit-popup-container h2, .jodit-popup-container h3, .jodit-popup-container h4, .jodit-popup-container h5, .jodit-popup-container h6 {
    all: revert;
  }
  .jodit-editor h1, .jodit-editor h2, .jodit-editor h3, .jodit-editor h4, .jodit-editor h5, .jodit-editor h6 {
    all: revert;
    font-weight: inherit;
    line-height: inherit;
    font-family: ${({ overrides, theme }) => overrides?.Heading?.props?.fontFamily || theme.typography.variants.display4.fontFamily};
  }

  .jodit-editor h1 {
    font-size: ${({ overrides }) => overrides?.Heading?.h1?.props.fontSize || pxToRem(32)};
  }
  .jodit-editor h2 {
    font-size: ${({ overrides }) => overrides?.Heading?.h2?.props.fontSize || pxToRem(24)};
  }
  .jodit-editor h3 {
    font-size: ${({ overrides }) => overrides?.Heading?.h3?.props.fontSize || pxToRem(18.72)};
  }
  .jodit-editor h4 {
    font-size: ${({ overrides }) => overrides?.Heading?.h4?.props.fontSize || pxToRem(16)};
  }
  .jodit-editor h5 {
    font-size: ${({ overrides }) => overrides?.Heading?.h5?.props.fontSize || pxToRem(13.28)};
  }
  .jodit-editor h6 {
    font-size: ${({ overrides }) => overrides?.Heading?.h6?.props.fontSize || pxToRem(10.72)};
  }

  .jodit-popup-container p,
  .jodit-editor p {
    all: revert;
    ${({ overrides, theme }) => overrides?.Paragraph?.props && theme.typography.variants[overrides.Paragraph.props.typographyVariant]};
  }
  .jodit-editor p {
    ${({ overrides }) => (overrides?.Paragraph?.props?.fontFamily ? { fontFamily: overrides.Paragraph.props.fontFamily } : {})};
  }

  span.jodit-placeholder {
    ${({ overrides, theme }) => overrides?.Paragraph?.props && theme.typography.variants[overrides.Paragraph.props.typographyVariant]};
  }

  .jodit-popup-container a,
  .jodit-editor a {
    all: revert;
    color: ${props => props.theme.colors.linkText};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
    }
    &:active {
      color: ${props => props.theme.colors.linkActive};
    }
  }

  .jodit-editor:not(.jodit-popup-container) blockquote {
    all: revert;
    text-align: left;
    border-left: 3px solid ${props => props.theme.colors.mono3};
    margin-left: ${props => props.theme.space[6]};
    padding-left: ${props => props.theme.space[5]};
    ${({ overrides, theme }) => overrides?.Paragraph?.props && theme.typography.variants[overrides.Paragraph.props.typographyVariant]};
    ${({ overrides }) => (overrides?.Paragraph?.props?.fontFamily ? { fontFamily: overrides.Paragraph.props.fontFamily } : {})};
  }

  .jodit-editor:not(.jodit-popup-container) pre {
    text-align: left;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    background-color: ${props => props.theme.colors.mono3};
    border-radius: ${props => props.theme.space[1]};
    padding: ${props => props.theme.space[1]};
  }

  .jodit-popup-container .jodit-toolbar-button__button .jodit-toolbar-button__text {
    font-family: 'Inter UI';
  }
  .jodit-wysiwyg,
  .jodit-container {
    box-shadow: ${({ isFocused, theme }) => (isFocused ? `0 0 0 2px ${theme.colors.mono14}` : `0 0 0 1px ${theme.colors.mono3}`)};
  }
  .jodit-wysiwyg {
    min-height: ${({ minHeight }) => (minHeight ? `calc(${minHeight} - ${pxToRem(53)})` : '100%')}  !important;
  }
`;

/* Override editor styles */
const StyledRichTextEditor = styled(Box)`
  div.jodit-add-new-line {
    display: none;
  }
  div.jodit-container {
    border-radius: ${props => props.theme.space[3]};
    background-color: white;
    border: none;
  }
  div.jodit-container {
    text-align: left;
    overflow: overlay;
  }
  div.jodit-toolbar__box {
    padding: ${props => props.theme.space[5]};
    border-radius: ${props => props.theme.space[3]} ${props => props.theme.space[3]} 0 0;
    border-bottom: 1px solid ${props => props.theme.colors.mono3};
  }
  div.jodit .jodit-workplace .jodit-wysiwyg {
    padding: ${props => props.theme.space[6]};
  }
  div.jodit .jodit-workplace .jodit-wysiwyg > *:first-child {
    margin-top: 0px;
  }
  span.jodit-toolbar-editor-collection .jodit-toolbar-button {
    margin: 0px;
    padding: 0px;
    height: fit-content;
    width: auto;
  }
  button.jodit-toolbar-button__button,
  span.jodit-toolbar-button {
    margin: 0px;
    padding: 0px;
    height: fit-content;
    width: auto;
    min-width: auto;
  }
  .jodit-ui-group {
    align-items: center;
    gap: ${props => props.theme.space[5]};
  }
  .jodit-ui-group_line_true {
    align-items: center;
    justify-content: end;
  }
  div.jodit-toolbar__box:not(:empty) .jodit-toolbar-editor-collection:after {
    background-color: ${props => props.theme.colors.white};
  }
  span.jodit-toolbar-button__trigger:hover:not([disabled]),
  span.jodit-toolbar-button_with-trigger_true:hover:not([disabled]),
  button.jodit-toolbar-button__button[aria-pressed='true']:not([disabled]),
  button.jodit-toolbar-button__button[aria-pressed='true']:hover:not([disabled]),
  button.jodit-toolbar-button__button:hover:not([disabled]) {
    background-color: transparent;
    border-color: transparent;
  }
  span.jodit-toolbar-button__trigger:hover:not([disabled]),
  span.jodit-toolbar-button_with-trigger_true:hover:not([disabled]),
  button.jodit-toolbar-button__button[aria-pressed='true']:hover:not([disabled]),
  button.jodit-toolbar-button__button:hover:not([disabled]) {
    color: ${props => props.theme.colors.linkHover};
    svg {
      fill: ${props => props.theme.colors.linkHover};
    }
  }
  button.jodit-toolbar-button__button[aria-pressed='true']:not([disabled]) {
    color: ${props => props.theme.colors.linkActive};
    svg {
      fill: ${props => props.theme.colors.linkActive};
    }
  }
  .jodit-ui-group__font .jodit-toolbar-button__trigger,
  .jodit-toolbar-button_left .jodit-toolbar-button__trigger {
    display: none;
  }
  /* Override editor heading toolbar styles */
  span.jodit-ui-group__paragraph {
    position: absolute;
    left: 0;
    width: ${pxToRem(100)};
  }
  span.jodit-toolbar-button_underline .jodit-toolbar-button__icon,
  span.jodit-toolbar-button_italic .jodit-toolbar-button__icon,
  span.jodit-toolbar-button_bold .jodit-toolbar-button__icon,
  span.jodit-ui-group__paragraph .jodit-toolbar-button__icon {
    display: none;
  }
  span.jodit-ui-group__paragraph .jodit-toolbar-button__button {
    width: 100%;
    min-width: ${pxToRem(52)};
    ${props => props.theme.typography.variants.button1};
  }
  span.jodit-ui-group__paragraph .jodit-toolbar-button__button .jodit-toolbar-button__text {
    margin-left: 0;
    justify-content: flex-start;
    ${props => props.theme.typography.variants.button1};
  }
  span.jodit-toolbar-button_bold .jodit-toolbar-button__button::before {
    content: 'B';
    ${props => props.theme.typography.variants.button1};
  }
  span.jodit-toolbar-button_italic .jodit-toolbar-button__button::before {
    content: 'I';
    ${props => props.theme.typography.variants.button1};
    width: ${props => props.theme.space[3]};
    font-style: italic;
  }
  span.jodit-toolbar-button_underline .jodit-toolbar-button__button::before {
    content: 'U';
    ${props => props.theme.typography.variants.button1};
    text-decoration: underline;
    text-underline-offset: 2px;
  }
  span.jodit-ui-group__paragraph button.jodit-toolbar-button__button {
    justify-content: flex-start;
  }
`;

export { StyledRichTextEditor, RichTextEditorGlobalStyle, RichTextEditorOverrideProps };
