import { Crop } from 'react-image-crop';
import { createTelemetry, createTelemetryObject } from '@shared/core';
import { PhotoPresentationLayout } from './FocusPointEditor.types';
import { EventPageType } from '@graphql/generated';

const action = 'PagePhotoEditorButtonInteracted';

const telemObject = createTelemetryObject({
  actions: {
    photoPresentationLayoutClicked: (args: { category: string; presentationLayout: PhotoPresentationLayout; pageId: string | undefined; pageType: EventPageType | undefined }) => ({
      action,
      category: args.category,
      extraInfo: {
        name: 'photoPresentationLayoutClicked',
        presentationLayout: args.presentationLayout,
        pageId: args.pageId,
        pageType: args.pageType
      }
    }),
    pagePhotoCropped: (category: string, args: { pageId: string | undefined; pageType: EventPageType | undefined; photoPresentationLayout: PhotoPresentationLayout }) => ({
      action: 'PagePhotoCropped',
      category,
      extraInfo: {
        ...args
      }
    }),
    saveFullWidthPhotoChangesButtonClicked: (category: string, args: { pageId: string | undefined; pageType: EventPageType | undefined; cropData: Crop }) => ({
      action,
      category,
      extraInfo: {
        name: 'websitePagePhoto_fullWidthEditor_saveClicked',
        ...args
      }
    }),
    editPagePhotoClicked: (category: string, pageType: string) => ({
      action,
      category,
      extraInfo: {
        name: 'editPagePhotoClicked',
        pageType
      }
    }),

    photoEditorOpened: (category: string, label: 'opened' | 'closed') => ({
      action: 'PagePhotoEditorStatus',
      category,
      label
    }),

    photoEditorChangePhotoClicked: (category: string) => ({
      action: 'PagePhotoEditorChangePhoto',
      actionType: 'click',
      category
    }),

    pagePhotoUpdated: (category: string, action: 'FocusChanged' | 'Update' | 'Delete', status: 'succeeded' | 'failed', extraInfo: Object = {}) => ({
      action: 'PagePhotoUpdated',
      category,
      extraInfo: {
        name: 'pagePhotoUpdated',
        label: action,
        status,
        ...extraInfo
      }
    })
  }
});

const { TelemetryProvider: FocusPointEditorTelemetryProvider, useTelemetry: useFocusPointEditorTelemetry } = createTelemetry(telemObject);

export { FocusPointEditorTelemetryProvider, useFocusPointEditorTelemetry };
